.listmans{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  .listmans_fio{
    width: 300px;
  }
  .listmans_dev{
    width: 300px;
  }
  .listmans_tn{
    width: 150px;
  }
  .listmans_unit{
    width: 50px;
  }

}
.listmans:hover{
  background-color: #454545;
  color: #FFF;
}

.poinsv_button {
  display: flex;
  margin-bottom: 10px;
  .poinsv_button_sel{
    width: 100px;
    height: 40px;
    outline: none;
    border: 2px solid #CCC;
  }
  .poinsv_button_plus{
    width: 100px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #CCC;
    margin-left: 10px;
    cursor: pointer;
  }
  .poinsv_button_plus:hover{
    opacity: 0.8;
    background-color: #454545;
    color: #FFFFFF;
  }
}


.workers{
  .title{
    h3{
      margin: 10px 0;
    }
    p{
      margin-bottom: 20px;
    }
  }
}

.register{
  h4{
    margin: 10px 0;
  }
  hr{
    height: 3px;
    border: none;
    background-color: #ccc;
    margin-bottom: 8px;
  }
  .workshop{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    h4{
      display: flex;
      flex-direction: column;
      width: 50%;
      justify-content: left;
    }
  }
  .reg-form{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 3px solid #ccc;
    padding: 10px 5px 5px 5px;
    max-width: 696px;

    min-width: 310px;
    .inputs{
      width: 330px;
      height: 330px;
      display: flex;
      flex-direction: column;
    }
    .photo-img{
      width: 330px;
      height: 330px;
      cursor: pointer;
      .photo{
        height: calc(100% - 13px);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ddd;
        border-radius: 10px;
        margin: 3px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        i{
          font-size: 2rem;
          color:#aaa;
        }
      }
    }
    input,textarea{
      width: calc(100% - 30px);
      border-radius: 5px;
      border: 1px solid #CCC;
      resize: none;
      outline: none;
      padding: 10px;
      margin: 3px;
    }
    p{
      margin-bottom: 20px;
    }
  }
  .zas{
    width: 1000px;
    .table-container {
      width: 1000px;
      border: 1px solid #000;
      display: flex;
      flex-direction: column;
    }

    .table-row {
      display: flex;
      width: 100%;
      color: #333333;
      font-size: 0.8rem;
      i{
        font-size: 1.5rem;
        color: #555;
      }
      .change{
        cursor: pointer;
      }
      .wait{
          color: #aaa;
      }
      .change:hover{
        background-color: #333333;
        i{
          color: #FFFFFF;
        }
      }
      .del-but:hover{
        background-color: rgba(230,23,23,0.5);
      }
      .act-but:hover{
        background-color: rgba(23,230,23,0.5);
      }
      .avatar{
        width: 100px;
        height: 100px;
        //border-radius: 25px;
        background-position: center;
        background-size: cover;
      }
    }

    .table-cell-pre {
      border: 1px solid #000;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }

    .c1{ width: 100px; }
    .c2{ width: 300px; }
    .c3{ width: 300px; }
    .c4{ width: 100px; }
    .c5{ width: 100px; }
    .c6{ width: 100px; }
    .table-header {
      background-color: #f0f0f0;
      font-weight: bold;
      border-bottom: none;
      .table-cell-pre{
        padding: 10px 0;
        font-size: .6rem;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  .buttons{
    margin: 2px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .button{
      display: flex;
      min-width: 300px;
    }
    .unuse{
      background-color: #aaaaaa;
      cursor: default;
    }
  }

}
@media screen and  (max-width: 1740px) {
  .zas{
    width: 800px !important;
  }
}
@media screen and  (max-width: 1500px) {
  .reg-form{
    width: 800px !important;
  }
  .zas{
    width: 800px !important;
  }
}
@media screen and  (max-width: 1300px) {
  .reg-form{
    width: 100% !important;
  }
  .zas{
    display: none;
  }
}



.branch-table{
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid #454545;
  .branch-item{
    display: flex;
    flex-direction: row;
    margin: 0;
    .row{
      margin: 0;
      padding: 5px;
      display: flex;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.8rem;
      border-bottom: 2px solid #454545;
      justify-content: center;
    }
    .c1{
      cursor: pointer;
      width: 100%;
    }
    .c1:hover{
      background-color: #cccccc;
      color: #333333;
    }
  }
}
.list{
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  .srt{
    display: flex;
    flex-direction: row;
    .c1{
      width: 35%;
    }
    .c2{
      width: 35%;
    }
    .c3{
      width: 20%;
      text-align: center;
    }
    .c4{
      width: 10%;
    }
  }
}
.workers{
  .buttons-cms{
    .button{
      background-color: rgb(18, 19, 56);
      color: white;
      margin: 10px;
      padding: 10px 16px 10px 10px;
      width: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
    }
  }
}
.create-branch{
  width: 100%;
  .create-branch-form{
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    input{
      width: calc(100% - 16px);
      margin: 5px;
      padding: 5px;
      border: 3px dotted #aaa;
      outline: none;
    }
    .multi100{
      margin-top: 10px;
      width: 100%;
    }
    .checkboxes{
      width: 100%;
    }
  }
}

.struct-panel{
  width: 100%;
  display: flex;
  flex-direction: column;
  .branch{
    width: 400px;
    padding: 3px;
    margin: 2px;
    border: 2px solid #333333;
    cursor: pointer;
    .name{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .text{
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.8rem;
        text-align: center;
      }
      .control{
        margin-right: 5px;
        color: #aaaaaa;
        font-size: 0.9rem;
        display: flex;
        flex-direction: row;
        .fa-xmark:hover{
          color: red;
        }
        i{
          margin-right: 5px;
        }
        i:hover{
          color: #333333;
        }
      }
      .general{
        position: relative;
        i{
          color: #aaaaaa;
          margin-top: -10px;
          margin-left: -20px;
          position: absolute;
        }
      }

    }
  }
}
.names-in-branch{
  display: flex;
  flex-direction: column;
  padding: 5px;
    .worker{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #777;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.8rem;
      margin-top: 3px;
      i{
        color: #aaaaaa;
        margin-right: 5px;
      }
      i:hover{
        color: #333333;
      }
    }
}
.create-branch-form.contacts{

  .contacts-area{
    width: 100%;
    outline: none;
    resize: none;
    border-radius: 0;
    height: 200px;
  }
}

.noti-types{
  width: 100%;
  min-width: 500px;
  h4{
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .inputs{
    width: calc(100% - 60px);
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    label{
      margin: 10px 3px;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
    input{

    }
    .select-img{
      width: calc(100% + 20px);
      border: 1px solid #ccc;
      border-radius: 3px;
      height: 200px;
      cursor: pointer;
      margin: 0 20px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      i{
        color: #333333;
        font-size: 3rem;
      }
    }
  }
}

.modal-del{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  .text{
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  .buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    .button{
      min-width: 100px;
    }
  }

}
.avablock{
  width: 200px !important;
  .ava{
    justify-content: space-between;
    .button{
      min-width: 10px !important;
      width: 30%;
      justify-content: center;
      i{
        font-size: 1.7rem;
        color: red;
      }
      .up{
        color: #3C8801;
        font-size: 1.2rem;
      }
    }
  }
}
.green-border{
  border-color: #3C8801 !important;
}