.right-block-ymweldings{
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.ymwelding_head{
width: 100%;
display: flex;
justify-content: flex-start;
flex-wrap: wrap;
align-items: center;
margin-bottom: 20px;

  .ymwelding_head_btnlast{
    width: 236px;
    height: 40px;
    background: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ymwelding_head_nameobj {
    margin: 0 10px;
    max-width: 600px;
    span{
      color: #000;
      font-weight: 700;
      margin-right: 20px;
    }
  }
  .ymwelding_head_passobj {
    width: 236px;
    height: 40px;
    background: #74A94C;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ymwelding_controller{
  max-width: calc(236px * 2 + 22px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .ymwelding_controller_ym{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .ymwelding_controller_ym_select{
      width: 236px;
      height: 40px;
      padding: 5px;
      border: 1px solid #000;
      margin-bottom: 10px;
      :focus, :hover, :active, :after{
        outline: 0;
        outline-offset: 0;
      }
    }
    .ymwelding_controller_ym_pluss{
      background: #617676;
      width: 236px;
      height: 40px;
      color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .ymwelding_controller_ym_pluss:hover{
      opacity: 0.9;
    }
  }

  .ymwelding_controller_sistembtns{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .ymwelding_controller_sistembtns_crews{
      background: #617676;
      width: 236px;
      height: 40px;
      color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .ymwelding_controller_sistembtns_itogs{
      background: #E49D4B;
      width: 236px;
      height: 40px;
      color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .ymwelding_controller_sistembtns_viewsjob{
      background: #4CC4C5;
      width: 236px;
      height: 40px;
      color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}

.ymwelding_slice{
  width: 100%;
  height: 1px;
  background: #CCC;
  margin-bottom: 10px;
}

.ymwelding_years{
  width: 100%;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  
  .ymwelding_years_head{
    width: 235px;
    height: 35px;
    background: #617676;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
  }
  .ymwelding_years_body{
    width: calc(95% - 5px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 10px;
    padding-left: 5px;
    .ymwelding_years_body_month{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 236px;
      height: 40px;
      border: 1px solid #454545;
      margin: 10px 5px 0 5px;
      .ymwelding_years_body_month_text{
        //margin-left: 20px;
        width: calc(236px - 60px);
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .ymwelding_years_body_month_settings{
        background: #D9D9D9;
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #505050;
        font-family: Montserrat;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
}
.alltabels{
  .alltabels_years{
    min-width: 250px;
    border: 4px solid #CCC;
    display: inline-block;
    padding: 0 15px 15px 0;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 20px;
    margin-left: 5px;
    .alltabels_years_head{
      width: calc(100% - 10px);
      height: 40px;
      background: rgba(18, 19, 56, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      text-align: center;
      border-radius: 16px 0;
    }
    .alltabels_years_body{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-bottom: 10px;
      padding-left: 5px;
      .alltabels_years_body_month{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 236px;
        height: 40px;
        border: 4px solid #CCC;
        border-radius: 5px;
        margin: 10px 5px 0 5px;
        padding: 0 0 0 10px;
        .alltabels_years_body_month_text{
          //margin-left: 20px;
          width: calc(236px - 60px);
          height: 40px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .alltabels_years_body_month_settings{
          background: #D9D9D9;
          width: 40px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #505050;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          cursor: pointer;
        }
      }
    }
  }
}

