@media (min-width: 1360px) {
  .custom-select-container {
    display: none;
  }
}

// Пример медиа-запроса для экранов уже 768px
@media (max-width: 1360px) {
  .custom-select-container {
    //position: ;
    width: 100% !important;

  }
}