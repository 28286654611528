.medtable{

  .table{
    width: 100%;
    display: flex;
    flex-direction: column;
    .row{
      display: flex;
      flex-direction: row;
      border: 1px solid rgba(1,1,1,0.5);
      margin: 2px 0;
      .column{
        font-weight: bold;
        font-size: 0.8rem;
        text-transform: uppercase;
        margin: 4px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .btn{
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        border: 1px solid rgba(18, 19, 56,0.6);
        border-radius:10px;
        box-shadow: 0 5px 15px 0 rgba(18, 19, 56,0.6);
        transition: 0.5s;
      }
      .btn:hover{
        transform: translate(0,-3px);
        box-shadow: 0 20px 40px 0 rgba(18, 19, 56,0.6);
      }
      .active{
        background-color: rgba(18, 19, 56,1);
      }
      .c1{width: 70%;padding-left: 10px}
      .c2{width: 10%;}
      .c3{width: 10%;}
      .c4{width: 10%;}
    }
  }
}