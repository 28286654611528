.workpage_block{

  .new_back{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
align-items: center;
    .min300{
      min-width: 320px;
    }
    @media screen and (min-width: 400px){
      .info_news{
        display: flex;
        max-width: 1000px;
        width: 100%;
        min-width: 320px;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-direction:row;
        .info{
          display: flex;
          flex-wrap: wrap;
          width: calc(50% - 40px);
          min-width: 320px;
          padding: 20px;
        }
      }
    }

    @media screen and (max-width: 400px){
      .info_news{
        display: flex;
        max-width: 1000px;
        width: 90%;
        min-width: 320px;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-direction:row;
        .info{
          display: flex;
          flex-wrap: wrap;
          width: calc(50% - 40px);
          min-width: 320px;
          padding: 0px !important;
          margin: 0px !important;
        }
      }
    }

    .toback{
      width: 100%;
      //min-width: 320px;
      background-color: #929292;
      padding: 15px;
      margin-bottom: 10px;
      color: #FFF;
      text-transform: uppercase;
      text-align: center;
      font-size: 1rem;
      border-right: 5px;
    }

    .payslip_right{
      min-width: 300px;
      background-color: #329DD9;
      padding: 15px 0 15px 15px;
      //margin: 10px;
      color: #FFF;
      font-size: 1rem;
      border-right: 5px;
      p{

        line-height: 18px;
      }
      h5{
        margin: 10px 0;
        span{
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
      .button-next{
        display: flex;
        align-items: flex-start;
        justify-content:right;

        .link{
          cursor: pointer;
          text-decoration: underline;
          margin: 20px;
          font-size: 0.8rem !important;
          color: rgba(255,255,255,0.6);
        }.link:hover{
           text-decoration: none;
           color: rgba(255,255,255,1);
         }
        i{
          margin-left:5px;
          font-size: 0.7rem;

        }
      }
    }
    .select_block {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: 10px 0;
      .select_block_title{

      }
      .select {
        width: 80%;
        height: 40px;
        margin: 0;
        padding: 0;
        input{
          border-radius: 0;
        }
      }
    }

    .between {
      background-color: transparent;
      justify-content: space-between;
      border-radius: 1px;
    }
    .size_calendar{

      margin: 20px;
      padding: 0 !important;
      background: #FFF;
      border-radius: 1px;
    }

    .calendar_content_payslip{
      width: 100%;
      justify-content: space-between;

      h4{
        margin-top: 20px;
        margin-left: 14px;
        font-weight: 600;
      }
      .dates{
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        div{
          width: calc((100% - 20px) / 7);
          height: calc((100% - 40px) / 7);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin: 10px 0;
          padding: 5px 0;
          p{
            width: 100%;
            text-align: center;
            font-size: 0.7rem;
          }
          .modal_clue{
            position: fixed;
            //margin-left: -25px;
            //margin-top: -120px;
            padding: 10px;
            width: 200px;
            height: 60px;
            display: none;
            background-color: rgba(240,240,240,1);
            border: 1px solid #000000;
            border-radius: 5px;
            h5{
              font-weight: 600;
              font-size: 0.9rem;
            }
            p{
              text-align: left;
              font-size: 0.7rem;
            }
          }

        }
        div.hov:hover{
          background-color: rgba(217,217,217,1);
        }
        .dayview2{
          background: rgba(170,170,170,1);
        }
        .dayview0{
          background: rgba(217,217,217,1);
        }
        .dayview1{
          background: #329DD9;
          border-radius: 3px;
          color: #FFF;
        }
        .today{
          //background-color: rgba(217,217,217,1);
        }
        .over{
          background-color: red;
        }
        .over:hover{
          background-color: red;
        }
        .pending{
          background-color: #FEAA2D;
        }
        .pending:hover{
          background-color: #FEAA2D;
        }
      }
    }


    .itogy_days{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 20px;

      .itogy_days_title{}
      .itogy_days_total{
        border: 1px solid #454545;
        padding: 10px 20px;
        margin-left: 20px;
        border-radius: 1px;
      }
    }
    .ktu{
      width: calc(100% - 40px);
      padding: 10px;
      border: 3px solid #329DD9;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 15px 0 15px 15px;
      border-radius: 10px;
      color: #329DD9;
      .ktu_title{
        font-weight: bold;
        width: 50px;
      }
      .ktu_result{
        width: 50px;
        font-weight: bold;
      }
      .ktu_description{
        width: calc(100% - 100px);

      }
    }
  }
}
