.workers{
  .title{
    h3{
      margin: 10px 0;
    }
    p{
      margin-bottom: 20px;
    }
  }
}
.redtext{
  color: #e74c3c;
  margin-bottom: 20px;
}
.hiddenbutton{
  color: rgba(1,1,1,0.5) !important;
  background-color: #cccccc !important;
}
.branch-table{
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid #454545;
  .branch-item{
    display: flex;
    flex-direction: row;
    margin: 0;
    .row{
      margin: 0;
      padding: 5px;
      display: flex;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.8rem;
      border-bottom: 2px solid #454545;
      justify-content: center;
    }
    .c1{
      cursor: pointer;
      width: 100%;
    }
    .c1:hover{
      background-color: #cccccc;
      color: #333333;
    }
  }
}
.list{
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  .srt{
    display: flex;
    flex-direction: row;
    .c1{
      width: 35%;
    }
    .c2{
      width: 35%;
    }
    .c3{
      width: 20%;
      text-align: center;
    }
    .c4{
      width: 10%;
    }
  }
}
.workers{
  .buttons-cms{
    .button{
      background-color: rgb(18, 19, 56);
      color: white;
      margin: 10px;
      padding: 10px 16px 10px 10px;
      width: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
    }
  }
}
.create-branch{
  width: 100%;
  .create-branch-form{
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    input{
      width: calc(100% - 16px);
      margin: 5px;
      padding: 5px;
      border: 3px dotted #aaa;
      outline: none;
    }
    .multi100{
      margin-top: 10px;
      width: 100%;
    }
    .checkboxes{
      width: 100%;
    }
  }
}

.struct-panel{
  width: 100%;
  display: flex;
  flex-direction: column;
  .branch{
    width: 400px;
    padding: 3px;
    margin: 2px;
    border: 2px solid #333333;
    cursor: pointer;
    .name{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .text{
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.8rem;
        text-align: center;
      }
      .control{
        margin-right: 5px;
        color: #aaaaaa;
        font-size: 0.9rem;
        display: flex;
        flex-direction: row;
        .fa-xmark:hover{
          color: red;
        }
        i{
          margin-right: 5px;
        }
        i:hover{
          color: #333333;
        }
      }
      .general{
        position: relative;
        i{
          color: #aaaaaa;
          margin-top: -10px;
          margin-left: -20px;
          position: absolute;
        }
      }

    }
  }
}
.names-in-branch{
  display: flex;
  flex-direction: column;
  padding: 5px;
    .worker{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #777;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.8rem;
      margin-top: 3px;
      i{
        color: #aaaaaa;
        margin-right: 5px;
      }
      i:hover{
        color: #333333;
      }
    }
}
.create-branch-form.contacts{

  .contacts-area{
    width: 100%;
    outline: none;
    resize: none;
    border-radius: 0;
    height: 200px;
  }
}

.noti-types{
  width: 100%;
  min-width: 500px;
  h4{
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .inputs{
    width: calc(100% - 60px);
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    label{
      margin: 10px 3px;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
    input{

    }
    span{
      font-weight: bold;
      text-decoration: underline;
    }
    .select-img{
      width: calc(100% + 20px);
      border: 1px solid #ccc;
      border-radius: 3px;
      height: 200px;
      cursor: pointer;
      margin: 0 20px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      i{
        color: #333333;
        font-size: 3rem;
      }
    }
  }
}

.types-table{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 2px solid #aaa;
    padding: 10px;
    cursor: pointer;
    .column{
      width: 300px;
      padding: 5px;
    }
    .column.icon{
      border-radius: 50px;
      min-height: 50px;
      width: 50px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .row:hover{
    background-color: #ddd;
    border-color: #cccccc;
  }
}