.sort{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: -25px;
  align-items: center;
  .title{
    font-size: 0.8rem;
  }
  .sortbtn{
    text-decoration: underline;
    cursor: pointer;
  }
  .sortbtn:hover{
    text-decoration: none;
  }
}

@media screen and (min-width: 700px){
  .soclist{
    width: 100%;
    height: 100%;
    .statelist_list{
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 100%;
      .statelist_list_line{
        display: flex;
        justify-content: flex-start;
        border: 4px solid #CCC;
        margin-top: 2px;
        cursor: pointer;
        .statelist_list_line_name{
          width: 60px;
          border-right: 4px solid #CCC;
        }
        .statelist_list_line_price{
          width: 100px;
          border-right: 4px solid #CCC;
        }
        .statelist_list_line_group{
          width: 300px;
          border-right: 4px solid #CCC;
        }
        .statelist_list_line_cropname{
          width: 200px;
        }
        .statelist_list_line_icon{
          width: 110px;
          justify-content: center;
          display: flex;
        }
        .statelist_list_line_del{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          cursor: pointer;
          transition: .2s;
          i{
            color: rgba(18, 19, 56, 0.6);
          }
        }
        .statelist_list_line_del:hover{
          transition: .2s;
          i{
            color: rgb(18, 19, 56);
          }
        }
      }

    }
    .soclist_title{
      font-size: 18pt;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .soclist_btns{
      display: flex;
      .soclist_upload{
        background-color: rgb(18, 19, 56);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 200px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
        margin: 0 20px 0 0;
        border-radius: 2px;
        transition: .2s;
      }
      .soclist_upload:hover{
        opacity: 0.8;
        transition: .2s;
      }
      .soclist_save{background-color: rgb(18, 19, 56);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 200px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
        margin: 0 20px 0 0;
        border-radius: 2px;
        transition: .2s;}
      .info{
        display: flex;
        flex-direction: row;
        .select_block{
          height: 40px;
          display: flex;
          min-width: 300px;
          .select_block_title{
            display: flex;
            align-items: center;
            margin-right: 20px;
            margin-bottom: 10px;
          }
          .select{
            display: flex;
            align-items: center;
            min-width:200px;
            width: 200px;
          }
        }
      }

    }
    .disable{
      background-color: rgb(100, 100, 100) !important;
    }
    .soclist_list{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      margin-top: 10px;
      .dop-info{
        border-bottom: 4px solid #CCC;
        width: 1220px;
        padding: 20px 0;
        .item{
          border: 2px solid #CCC;
        }
        .table-container {
          width: 1200px;
          overflow-x: auto; /* добавляем горизонтальную прокрутку, если таблица шире контейнера */
        }
        .table {
          display: flex;
          flex-direction: column;
        }
        .rowsmall {
          display: flex;
        }
        .cell {
          border: 1px solid #000;
          padding: 3px;
          font-size: 0.6rem;
          min-width: 30px;
          max-width: 32px;
        }
        .middle-column {
          min-width: 60px; /* задаем ширину для "широких" столбцов */
          max-width: 60px;
        }
        .wide-column {
          min-width: 120px; /* задаем ширину для "широких" столбцов */
          max-width: 120px;
        }
        .wbig-column{
          min-width: 300px; /* задаем ширину для "широких" столбцов */
          max-width: 300px;
        }
        .big-column{
          min-width: 200px; /* задаем ширину для "широких" столбцов */
          max-width: 200px;
        }
      }
      .title{
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
      }
      .soclist_list_line > * {
        display: flex;
        align-self: stretch;
        height: auto !important;
      }



      .soclist_list_line{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: fit-content;
        min-height: 40px;
        border: 4px solid #CCC;
        border-right: none;
        border-bottom: none;
        font-size: 10pt;
        align-self: stretch;

        .date{
          width: 100px!important;
          padding: 0 10px;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
        }


        .soclist_list_line_name{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch !important;
          width: 300px;
          min-height: 40px;
          height: 100%;
          border: none;
          border-right: 4px solid #CCC;
          background-color: #F1F1F1;
          border-bottom: 4px solid #CCC;
        }
        .soclist_list_line_price{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          width: 280px;
          padding: 0 10px;
          min-height: 40px;
          height: auto !important;
          border: none;
          border-right: 4px solid #CCC;
        }
        .soclist_list_line_group{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          width: 280px;
          padding: 0 10px;
          min-height: 40px;

          border: none;
          border-right: 4px solid #CCC;
        }
        .soclist_list_line_cropname{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 280px;
          padding: 0 10px;

          height: 100%;
          border: none;
        }
        .icons-container{
          width: 40px;
          margin-left: -40px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
        }
        .soclist_list_line_del{
          margin-left: -60px;
          width: 20px;
          height: 20px;
          font-size: 16pt;
          margin-top: 5px;
          font-weight: 700;
          color: #CCC;
          cursor: pointer;
          border: none;
          i{
            color: #CCC;
            transition: .2s;
            margin-right: 10px;
          }
          i:hover{
            color: #454545;
            transition: .2s;
          }
        }
        .title{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 280px;
          min-height: 40px;
          height: 100%;
          border: none;
          border-right: 4px solid #CCC;
          font-weight: 700;
          font-size: 12pt;
          background-color: #F1F1F1;
          border-bottom: 4px solid #CCC;
        }
        .nametitle{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 100%;
          border: none;
          border-right: 4px solid #CCC;
          font-weight: 700;
          font-size: 12pt;
          background-color: #F1F1F1;
          border-bottom: 4px solid #CCC;
        }
        .listpp{
          width: 60px;
        }
        .borderrightnone{
          border-right: none;
        }
        .leftborder{
          border-left: 4px solid #CCC;
        }
        .inputs{
          outline: none;
          background-color: #FFFFFF;
        }
        .inputs:focus{
          outline: none;
          background-color: #9aa9ce;
          width: 280px;
          padding: 0 10px;

        }
        .button{
          text-decoration: underline;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 0.6rem;
          font-weight: 600;
        }.button:hover{
          color: white;
        background-color: rgba(18,19,56,1);
        }
      }
      .bordertopnone{
        border-top: none;
      }

    }
    .newlineogm{
      padding: 20px;
      border: 4px dotted #CCC;
      width: fit-content;
      border-radius: 20px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      .line{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .line_title{
          font-size: 16pt;
          font-weight: 800;
          margin-right: 20px;
        }
      }
      .soclist_list_line_new{
        width: 251px;
        padding: 9px;
        margin-right: 20px;
        outline: none;
        border: 2px solid #454545;
        border-radius: 5px;
      }
      .small{
        width: 100px;
      }
      .soclist_plus{
        background-color: rgb(18, 19, 56);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 247px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
        margin: 0 20px 0 0;
        border-radius: 2px;
        transition: .2s;
        margin-top: 10px;
      }
      .soclist_plus:hover{
        opacity: 0.8;
        transition: .2s;
      }
    }
    .glass{
        position: absolute;
        backdrop-filter: blur(5px);
        top: 0px;
        left: 0px;
        min-width: 100%;
        height: 100%;
        background-color: rgba(200,200,200,0.6);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;

        .glass_board{
          width: 90%;
          height: 90%;
          background-color: rgba(255,255,255,1);
          border-radius: 2px;
          box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
          overflow-y: hidden;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          .glass_board_close{
            width: calc(100% - 30px);
            padding: 15px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            i{
              font-size: 20pt;
              color: #454545;
              cursor: pointer;
              transition: 0.05s;
            }
            i:hover{
              color: #CCC;
              transition: 0.05s;
            }
          }
          .glass_board_body{
            width: calc(100% - 80px);
            height: calc(100% - 60px - 60px);
            //border: 1px solid #454545;
            padding: 20px;
            overflow-y: auto;
            overflow-x: hidden;
            .glass_board_body_title_rock{
              width: 100%;
              display: flex;
              justify-content: center;
              text-align: center;
              font-size: 16pt;
              font-weight: 800;
              margin: 40px 0 10px;
              color: rgb(18, 19, 56);
              min-height: 50px;
            }

            @media screen and (max-width: 400px){
              .glass_board_body_title_rock{
                width: 100%;
                display: flex;
                justify-content: center;
                text-align: center;
                font-size: 12pt;
                font-weight: 800;
                margin: 40px 0 10px;
                color: rgb(18, 19, 56);
                min-height: 50px;
              }
            }
            .glass_board_step{
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 80px;
              margin-top: -60px;
            }
            .glass_board_step_next{
              width: 100%;
              max-width: 288px;
              border: 1px solid #CCC;
              min-height: 80px;
              height: 80px;
              margin-top: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #454545;
              cursor: pointer;
              background-color: #FFF;
              transition: 0.1s;
              border-radius: 5px;
            }
            .glass_board_body_docs{
              width: calc(100%);
              //min-height: 100px;
              //height: content-box;
              border: 1px solid #CCC;
              padding: 10px;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              //overflow-x: hidden;
              //overflow-y: auto;
              .glass_board_body_docs_set_name{
                width: calc(100% - 90px);
                margin: 5px 0;
                .glass_board_body_docs_set_name_title{
                  font-weight: 600;
                }
                .glass_board_body_docs_set_name_docname{
                  font-size: 10pt;
                  color: red;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .glass_board_body_docs_set_name_docname_this{
                    width: calc(100% - 15px);
                    color: rgb(18, 19, 56);
                    padding: 5px;
                    border: 1px solid rgb(18, 19, 56);
                    border-radius: 3px;
                    margin: 3px 0;
                    position: relative;
                    .del-this{
                      position: absolute;
                      right: 10px;
                      bottom: 1px;
                      width: 10px;
                      margin-right: 0px;
                      font-size: 14pt;
                    }
                  }
                }
              }
              .glass_board_body_docs_set{
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                .glass_board_body_docs_set_name{
                  width: calc(100% - 90px);
                  .glass_board_body_docs_set_name_title{
                    font-weight: 600;
                  }
                  .glass_board_body_docs_set_name_docname{
                    font-size: 10pt;
                    color: red;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    .glass_board_body_docs_set_name_docname_this{
                      width: calc(100% - 15px);
                      color: rgb(18, 19, 56);
                      padding: 5px;
                      border: 1px solid rgb(18, 19, 56);
                      border-radius: 3px;
                      margin: 3px 0;
                      position: relative;
                      .del-this{
                        position: absolute;
                        right: 10px;
                        bottom: 1px;
                        width: 10px;
                        margin-right: 0px;
                        font-size: 14pt;
                      }
                    }
                  }
                }
                .glass_board_body_docs_set_btn{
                  width: 80px;
                  height: 80px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #CCC;
                  i{
                    font-size: 20pt;
                    color: #CCC;
                    margin-left:-16px;
                  }
                }
              }

            }
            .glass_board_step_next:hover{
              color: #FFF;
              background-color: #454545;
              transition: 0.1s;
            }
            .glass_board_btn{
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: -20px;
              min-height: 80px;
              .glass_board_btn_left{
                div{
                  width: 80px;
                  height: 80px;
                  border-radius: 40px;
                  border: 2px solid #CCC;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
              }
              .glass_board_btn_right{
                div{
                  width: 80px;
                  height: 80px;
                  border-radius: 40px;
                  border: 2px solid #CCC;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
              }

              i{
                font-size: 20pt;
                color: #CCC;
              }
            }

            .btnpercent{
              position: absolute;
              width: 130px;
              height: 50px;
              margin: 15px 0 0 -130px;
              background-color: rgb(18, 19, 56);
              color: #FFFFFF;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 2px;
              cursor: pointer;
              transition: 0.2s;
            }

            .btnpercent:hover{
              opacity: 0.8;
              transition: 0.2s;
            }

            .trashpercent{
              margin: 0 0 0 -30px;
              i{
                cursor: pointer;
                color: #CCC;
                transition: .1s;
              }
              i:hover{
                color: rgb(18, 19, 56);
                transition: .1s;
              }
            }

            .glass_board_body_tit{
              display: flex;
              align-items: center;
              margin: 10px;
              font-weight: 600;
              text-transform: uppercase;
              font-size: 0.8rem;
            }

            .glass_board_body_numinput{
              width: 80px;
              padding: 10px;
              border: 3px dotted #CCC;
              border-radius: 10px;
              font-size: 12pt;
              font-weight: 600;
              color: rgb(18, 19, 56);
              text-align: center;
              outline: none;
              transition: 0.2s;
              margin-left: 10px;
              margin-right: 10px;
            }

            .glass_board_body_input{
              width: calc(100% - 26px);
              padding: 10px;
              border: 3px dotted #CCC;
              border-radius: 10px;
              font-size: 16pt;
              font-weight: 600;
              color: rgb(18, 19, 56);
              text-align: center;
              outline: none;
              transition: 0.2s;
            }

            .glass_board_body_textarea{
              margin-top: 10px;
              width: calc(100% - 46px);
              padding: 10px 20px;
              min-height: 120px;
              border: 3px dotted #CCC;
              border-radius: 10px;
              outline: none;
            }

            .glass_board_body_btn{
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 20px;
              border: 1px solid #CCC;
              color: #CCC;
              width: auto;
              max-height: 30px !important;
              cursor: pointer;
              margin-right: 10px;
              padding: 5px 30px;
              border-radius: 10px;
              margin-left: 10px;
              font-weight: 400;
              transition: .2s;
            }
            .glass_board_body_btn:hover{
              color: #454545;
              border: 1px solid #454545;
              transition: .2s;
            }

            .glass_board_body_graf{
              width: calc(100% - 26px);
              padding: 10px;
              border: 3px dotted #CCC;
              border-radius: 10px;
              font-size: 12pt;
              font-weight: 600;
              color: rgb(18, 19, 56);
              outline: none;
              transition: 0.2s;
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
              .inputs_of_graf{
                border: none;
                width: calc(100% - 40px);
                outline: none;
                font-size: 12pt;
                //height: ;
              }
              .btns{
                i{
                  font-size: 16pt;
                  margin-left: 10px;
                  cursor: pointer;
                }
                i:hover{
                  color: #CCC;
                  transition: 0.2s;
                }
              }

            }
            .glassslash{
              width: 100%;
              height: 1px;
              background-color: #CCC;
              margin-top: 10px;
            }
            .blueborder{
              border: 3px dotted rgb(18, 19, 56);
            }
            .glass_board_body_buttons{
              margin-top: 10px;
              display: flex;
              justify-content: flex-start;
              .glass_board_body_buttons_create{
                margin-right: 20px;
                padding: 20px 20px;
                border: 1px solid #CCC;
                border-radius: 4px;
                cursor: pointer;
                color: #454545;
                transition: 0.2s;
              }
              .glass_board_body_buttons_cancel{
                margin-right: 20px;
                padding: 20px 20px;
                border: 1px solid darkred;
                border-radius: 4px;
                cursor: pointer;
                color: #454545;
                transition: 0.2s;
              }

              .glass_board_body_buttons_create:hover{
                color: #000;
                border: 1px solid #454545;
              }

              .glass_board_body_buttons_cancel:hover{
                color: red;
              }
            }
            .glass_board_body_selection{
              display: flex;

              .glass_pluss_btn{
                width: 150px;
                height: 36px;
                border: 1px solid #CCC;
                border-radius: 4px;
                margin-left: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #454545;
                background-color: #FFFFFF;
                cursor: pointer;
                transition: 0.2s;
              }
              .glass_pluss_btn:hover{
                color: #FFFFFF;
                background-color: #454545;
                border: 1px solid #CCC;
                transition: 0.2s;
              }
            }

            .glass_board_body_liststatements{
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              min-width: 100px;
              .glass_board_body_liststatements_man{
                display: flex;
                width: 100%;
                border: 1px solid #CCC;
                min-height: 40px;
                margin-top: -1px;
                font-size: 10px;
                .glass_board_body_liststatements_num{
                  border-right: 1px solid #CCC;
                  width: 40px;
                  padding: 10px;
                }
                .glass_board_body_liststatements_fio{
                  border-right: 1px solid #CCC;
                  width: 200px;
                  padding: 10px;
                }
                .glass_board_body_liststatements_dev{
                  border-right: 1px solid #CCC;
                  width: 150px;
                  padding: 10px;
                }
                .glass_board_body_liststatements_view{
                  border-right: 1px solid #CCC;
                  min-width: 50px;
                  width: 150px;
                  padding: 10px;
                }
                .glass_board_body_liststatements_app{
                  border-right: 1px solid #CCC;
                  min-width: 50px;
                  width: 200px;
                  padding: 10px;
                }
                .glass_board_body_liststatements_del{
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  padding: 10px;
                  min-width: 50px;
                  width: 250px;
                  flex-wrap: wrap;
                  div{
                    margin-right: 10px;
                    padding: 5px 10px;
                    border-radius: 5px;
                  }
                  i{
                    color: #454545;
                    cursor: pointer;
                    transition: 0.1s;
                  }
                  i:hover{
                    color: #CCC;
                    transition: 0.1s;
                  }

                  .glass_board_body_liststatements_del_eye{}
                  .glass_board_body_liststatements_del_del{}
                  .glass_board_body_liststatements_del_yes{
                    border: 1px solid green;
                  }
                  .glass_board_body_liststatements_del_no{
                    border: 1px solid darkred;
                  }
                  .glass_board_body_liststatements_del_wait{
                    border: 1px solid #CCC;
                  }
                  .glass_board_body_liststatements_del_pass{
                    border: 1px solid rgb(18, 19, 56);
                    background-color: #FFFFFF;
                    cursor: pointer;
                    transition: .2s;
                  }
                  .glass_board_body_liststatements_del_pass:hover{
                    background-color: rgb(18, 19, 56);
                    color: #FFFFFF;
                    transition: .2s;
                  }
                }
              }
            }
            .glass_board_body_control{
              display: flex;
              justify-content: flex-start;
              padding: 10px;
              .glass_board_body_control_btn{
                border: 1px solid #CCC;
                border-radius: 5px;
                display: none;
                flex-direction: column;
                align-items: center;
                width: 150px;
                margin: 10px 10px 0 0;
                padding: 10px;
                cursor: pointer;
                background-color: #FFFFFF;
                transition: .2s;
              }
              .glass_board_body_control_btn:hover{
                background-color: #454545;
                color: #FFF;
                transition: .2s;
              }
            }
            .glass_board_body_documents{
              display: flex;
              align-items: flex-start;
              padding: 10px;
              .glass_board_body_documents_file{
                border: 1px solid #CCC;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 150px;
                margin: 10px 10px 0 0;
                padding: 10px;
                cursor: pointer;
                i{
                  font-size: 22pt;
                }
              }
              .glass_board_body_documents_file:hover{
                box-shadow: 0 0 3px rgba(0, 128, 255, 0.5);
              }
              .glass_board_body_documents_file_name{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 10pt;
              }
            }
            .list_approve{
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              font-size: 10pt;
              .list_approve_man{
                border: 1px solid #CCC;
                padding: 10px;
                width: 250px;
                border-radius: 5px;
                margin-right: 20px;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                .list_approve_man_name{
                  font-weight: 600;
                }
                .list_approve_man_dev{
                  font-weight: 400;
                }
                .list_approve_man_status{
                  margin-top: 5px;
                  padding: 5px 10px;
                  border: 1px solid #CCC;
                  border-radius: 2px;
                }
                .list_approve_man_comment{
                  margin-top: 5px;
                  padding: 5px 10px;
                  border: 1px solid #CCC;
                  border-radius: 2px;
                }
                .list_approve_man_slash{
                  position: absolute;
                  height: 4px;
                  top: 20px;
                  left: 0px;
                  width: 20px;
                  background-color: #CCC;
                  margin-left: -21px;
                }
              }
            }
            .glass_board_body_man{
              display: flex;
              justify-content: space-between;
              max-width: content-box;
              padding: 10px 20px;
              border: 3px dotted #CCC;
              border-radius: 10px;
              margin-top: 10px;
              .glass_board_body_man_content{
                display: flex;
                .glass_board_body_man_photo{
                  width: 60px;
                  height: 60px;
                  background-size: cover;
                  background-position: center center;
                  border-radius: 60px;
                }
                .glass_board_body_man_params{
                  margin-left: 20px;
                  .glass_board_body_man_name{
                    font-weight: 700;
                  }
                  .glass_board_body_man_dev{
                    font-size: 11pt;
                  }
                  .glass_board_body_man_departament{
                    font-size: 11pt;
                  }
                }
              }

              .glass_board_body_man_del{
                font-size: 16pt;
                i{
                  color: #454545;
                  cursor: pointer;
                  transition: 0.2s;
                }
                i:hover{
                  color: #CCC;
                  transition: 0.2s;
                }
              }
            }
            .glass_approve_body_man{
              display: flex;
              justify-content: space-between;
              max-width: content-box;
              padding: 10px 20px;
              border: 3px solid #CCC;
              border-radius: 10px;
              margin-top: 10px;
              .glass_approve_body_man_content{
                display: flex;
                .glass_approve_body_man_photo{
                  width: 60px;
                  height: 60px;
                  min-width: 60px;
                  min-height: 60px;
                  background-size: cover;
                  background-position: center center;
                  border-radius: 60px;
                }
                .glass_approve_body_man_params{
                  margin-left: 20px;
                  .glass_approve_body_man_name{
                    font-weight: 700;
                  }
                  .glass_approve_body_man_dev{
                    font-size: 11pt;
                  }
                  .glass_approve_body_man_departament{
                    font-size: 11pt;
                  }
                  .glass_approve_body_man_comment{
                    font-size: 11pt;
                    padding: 10px;
                    border: 1px solid #CCC;
                    margin: 10px 10px 0 0;
                    border-radius: 2px;
                    max-width: 400px;
                  }
                }
              }

              .glass_approve_body_man_status{
                display: flex;
                flex-wrap: wrap;
                width: 200px;
                div{
                  margin: 0 10px 10px 0;
                  padding: 5px 20px;
                  height: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 20px;
                }
                .glass_approve_body_man_status_no{
                  border: 1px solid red;
                }
                .glass_approve_body_man_status_yes{
                  border: 1px solid green;
                }
                .glass_approve_body_man_status_wait{
                  border: 1px solid #CCC;
                }
                .glass_approve_body_man_status_pass{
                  border: 1px solid darkblue;
                  cursor: pointer;
                  transition: .1s;
                }
                .glass_approve_body_man_status_pass:hover{
                  transition: .1s;
                  background-color: darkblue;
                  color: #FFF;
                }
              }
            }

          }
        }

      .glass_board_create{
        width: 90%;
        height: 90%;
        background-color: rgba(255,255,255,1);
        border-radius: 2px;
        box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        .glass_board_close{
          width: calc(100% - 30px);
          padding: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          i{
            font-size: 20pt;
            color: #454545;
            cursor: pointer;
            transition: 0.05s;
          }
          i:hover{
            color: #CCC;
            transition: 0.05s;
          }
        }
        .glass_board_body{
          width: calc(100% - 80px);
          height: calc(100% - 60px - 60px);
          //border: 1px solid #454545;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          overflow-y: auto;
          overflow-x: hidden;
          .glass_board_body_title_rock{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 16pt;
            font-weight: 800;
            margin: 40px 0 10px;
            color: rgb(18, 19, 56);
            min-height: 50px;
          }

          @media screen and (max-width: 400px){
            .glass_board_body_title_rock{
              width: 100%;
              display: flex;
              justify-content: center;
              text-align: center;
              font-size: 12pt;
              font-weight: 800;
              margin: 40px 0 10px;
              color: rgb(18, 19, 56);
              min-height: 50px;
            }
          }
          .glass_board_step{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            margin-top: -60px;
          }
          .glass_board_step_next{
            width: 100%;
            max-width: 288px;
            border: 1px solid #CCC;
            min-height: 80px;
            height: 80px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #454545;
            cursor: pointer;
            background-color: #FFF;
            transition: 0.1s;
            border-radius: 5px;
          }
          .glass_board_body_docs{
            width: calc(100%);
            //min-height: 100px;
            //height: content-box;
            border: 1px solid #CCC;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            //overflow-x: hidden;
            //overflow-y: auto;
            .glass_board_body_docs_set_name{
              width: calc(100% - 90px);
              margin: 5px 0;
              .glass_board_body_docs_set_name_title{
                font-weight: 600;
              }
              .glass_board_body_docs_set_name_docname{
                font-size: 10pt;
                color: red;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .glass_board_body_docs_set_name_docname_this{
                  width: calc(100% - 15px);
                  color: rgb(18, 19, 56);
                  padding: 5px;
                  border: 1px solid rgb(18, 19, 56);
                  border-radius: 3px;
                  margin: 3px 0;
                  position: relative;
                  .del-this{
                    position: absolute;
                    right: 10px;
                    bottom: 1px;
                    width: 10px;
                    margin-right: 0px;
                    font-size: 14pt;
                  }
                }
              }
            }
            .glass_board_body_docs_set{
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              .glass_board_body_docs_set_name{
                width: calc(100% - 90px);
                .glass_board_body_docs_set_name_title{
                  font-weight: 600;
                }
                .glass_board_body_docs_set_name_docname{
                  font-size: 10pt;
                  color: red;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .glass_board_body_docs_set_name_docname_this{
                    width: calc(100% - 15px);
                    color: rgb(18, 19, 56);
                    padding: 5px;
                    border: 1px solid rgb(18, 19, 56);
                    border-radius: 3px;
                    margin: 3px 0;
                    position: relative;
                    .del-this{
                      position: absolute;
                      right: 10px;
                      bottom: 1px;
                      width: 10px;
                      margin-right: 0px;
                      font-size: 14pt;
                    }
                  }
                }
              }
              .glass_board_body_docs_set_btn{
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #CCC;
                i{
                  font-size: 20pt;
                  color: #CCC;
                  margin-left:-16px;
                }
              }
            }

          }
          .glass_board_step_next:hover{
            color: #FFF;
            background-color: #454545;
            transition: 0.1s;
          }
          .glass_board_btn{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: -20px;
            min-height: 80px;
            .glass_board_btn_left{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }
            .glass_board_btn_right{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }

            i{
              font-size: 20pt;
              color: #CCC;
            }
          }
          .btnpercent{
            position: absolute;
            width: 130px;
            height: 50px;
            margin: 15px 0 0 -130px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            transition: 0.2s;
          }
          .btnpercent:hover{
            opacity: 0.8;
            transition: 0.2s;
          }
          .trashpercent{
            margin: 0 0 0 -30px;
            i{
              cursor: pointer;
              color: #CCC;
              transition: .1s;
            }
            i:hover{
              color: rgb(18, 19, 56);
              transition: .1s;
            }
          }
          .glass_board_body_tit{
            display: flex;
            align-items: center;
            margin: 10px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.8rem;
          }
          .glass_board_body_numinput{
            width: 80px;
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
            margin-left: 10px;
            margin-right: 10px;
          }
          .glass_board_body_input{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 16pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
          }
          .glass_board_body_textarea{
            margin-top: 10px;
            width: calc(100% - 46px);
            padding: 10px 20px;
            min-height: 120px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            outline: none;
          }
          .glass_board_body_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 20px;
            border: 1px solid #CCC;
            color: #CCC;
            width: auto;
            max-height: 30px !important;
            cursor: pointer;
            margin-right: 10px;
            padding: 5px 30px;
            border-radius: 10px;
            margin-left: 10px;
            font-weight: 400;
            transition: .2s;
          }
          .glass_board_body_btn:hover{
            color: #454545;
            border: 1px solid #454545;
            transition: .2s;
          }
          .glass_board_body_graf{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            outline: none;
            transition: 0.2s;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .inputs_of_graf{
              border: none;
              width: calc(100% - 40px);
              outline: none;
              font-size: 12pt;
              //height: ;
            }
            .btns{
              i{
                font-size: 16pt;
                margin-left: 10px;
                cursor: pointer;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }

          }
          .glassslash{
            width: 100%;
            height: 1px;
            background-color: #CCC;
            margin-top: 10px;
          }
          .blueborder{
            border: 3px dotted rgb(18, 19, 56);
          }
          .glass_board_body_buttons{
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            .glass_board_body_buttons_create{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid #CCC;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }
            .glass_board_body_buttons_cancel{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid darkred;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }

            .glass_board_body_buttons_create:hover{
              color: #000;
              border: 1px solid #454545;
            }

            .glass_board_body_buttons_cancel:hover{
              color: red;
            }
          }
          .glass_board_body_selection{
            display: flex;

            .glass_pluss_btn{
              width: 150px;
              height: 36px;
              border: 1px solid #CCC;
              border-radius: 4px;
              margin-left: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #454545;
              background-color: #FFFFFF;
              cursor: pointer;
              transition: 0.2s;
            }
            .glass_pluss_btn:hover{
              color: #FFFFFF;
              background-color: #454545;
              border: 1px solid #CCC;
              transition: 0.2s;
            }
          }

          .glass_board_body_liststatements_man{
            display: flex;
            width: 100%;
            border: 1px solid #CCC;
            min-height: 40px;
            margin-top: -1px;
            font-size: 10px;
            .glass_board_body_liststatements_num{
              border-right: 1px solid #CCC;
              width: 40px;
              padding: 10px;
            }
            .glass_board_body_liststatements_fio{
              border-right: 1px solid #CCC;
              width: 200px;
              padding: 10px;
            }
            .glass_board_body_liststatements_dev{
              border-right: 1px solid #CCC;
              width: 150px;
              padding: 10px;
            }
            .glass_board_body_liststatements_view{
              border-right: 1px solid #CCC;
              min-width: 50px;
              width: 150px;
              padding: 10px;
            }
            .glass_board_body_liststatements_app{
              border-right: 1px solid #CCC;
              min-width: 50px;
              width: 200px;
              padding: 10px;
            }
            .glass_board_body_liststatements_del{
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding: 10px;
              min-width: 50px;
              width: 250px;
              flex-wrap: wrap;
              div{
                margin-right: 10px;
                padding: 5px 10px;
                border-radius: 5px;
              }
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.1s;
              }
              i:hover{
                color: #CCC;
                transition: 0.1s;
              }

              .glass_board_body_liststatements_del_eye{}
              .glass_board_body_liststatements_del_del{}
              .glass_board_body_liststatements_del_yes{
                border: 1px solid green;
              }
              .glass_board_body_liststatements_del_no{
                border: 1px solid darkred;
              }
              .glass_board_body_liststatements_del_wait{
                border: 1px solid #CCC;
              }
              .glass_board_body_liststatements_del_pass{
                border: 1px solid rgb(18, 19, 56);
                background-color: #FFFFFF;
                cursor: pointer;
                transition: .2s;
              }
              .glass_board_body_liststatements_del_pass:hover{
                background-color: rgb(18, 19, 56);
                color: #FFFFFF;
                transition: .2s;
              }
            }
          }


          .glass_board_body_control{
            display: flex;
            justify-content: flex-start;
            padding: 10px;
            .glass_board_body_control_btn{
              border: 1px solid #CCC;
              border-radius: 5px;
              display: none;
              flex-direction: column;
              align-items: center;
              width: 150px;
              margin: 10px 10px 0 0;
              padding: 10px;
              cursor: pointer;
              background-color: #FFFFFF;
              transition: .2s;
            }
            .glass_board_body_control_btn:hover{
              background-color: #454545;
              color: #FFF;
              transition: .2s;
            }
          }

          .glass_board_body_documents{
            display: flex;
            align-items: flex-start;
            padding: 10px;
            .glass_board_body_documents_file{
              border: 1px solid #CCC;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 150px;
              margin: 10px 10px 0 0;
              padding: 10px;
              cursor: pointer;
              i{
                font-size: 22pt;
              }
            }
            .glass_board_body_documents_file:hover{
              box-shadow: 0 0 3px rgba(0, 128, 255, 0.5);
            }
            .glass_board_body_documents_file_name{
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-size: 10pt;
            }
          }

          .list_approve{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            font-size: 10pt;
            .list_approve_man{
              border: 1px solid #CCC;
              padding: 10px;
              width: 250px;
              border-radius: 5px;
              margin-right: 20px;
              position: relative;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              .list_approve_man_name{
                font-weight: 600;
              }
              .list_approve_man_dev{
                font-weight: 400;
              }
              .list_approve_man_status{
                margin-top: 5px;
                padding: 5px 10px;
                border: 1px solid #CCC;
                border-radius: 2px;
              }
              .list_approve_man_comment{
                margin-top: 5px;
                padding: 5px 10px;
                border: 1px solid #CCC;
                border-radius: 2px;
              }
              .list_approve_man_slash{
                position: absolute;
                height: 4px;
                top: 20px;
                left: 0px;
                width: 20px;
                background-color: #CCC;
                margin-left: -21px;
              }
            }
          }

          .glass_board_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_board_body_man_content{
              display: flex;
              .glass_board_body_man_photo{
                width: 60px;
                height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_board_body_man_params{
                margin-left: 20px;
                .glass_board_body_man_name{
                  font-weight: 700;
                }
                .glass_board_body_man_dev{
                  font-size: 11pt;
                }
                .glass_board_body_man_departament{
                  font-size: 11pt;
                }
              }
            }

            .glass_board_body_man_del{
              font-size: 16pt;
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.2s;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }
          }
          .glass_approve_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px solid #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_approve_body_man_content{
              display: flex;
              .glass_approve_body_man_photo{
                width: 60px;
                height: 60px;
                min-width: 60px;
                min-height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_approve_body_man_params{
                margin-left: 20px;
                .glass_approve_body_man_name{
                  font-weight: 700;
                }
                .glass_approve_body_man_dev{
                  font-size: 11pt;
                }
                .glass_approve_body_man_departament{
                  font-size: 11pt;
                }
                .glass_approve_body_man_comment{
                  font-size: 11pt;
                  padding: 10px;
                  border: 1px solid #CCC;
                  margin: 10px 10px 0 0;
                  border-radius: 2px;
                  max-width: 400px;
                }
              }
            }

            .glass_approve_body_man_status{
              display: flex;
              flex-wrap: wrap;
              width: 200px;
              div{
                margin: 0 10px 10px 0;
                padding: 5px 20px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
              }
              .glass_approve_body_man_status_no{
                border: 1px solid red;
              }
              .glass_approve_body_man_status_yes{
                border: 1px solid green;
              }
              .glass_approve_body_man_status_wait{
                border: 1px solid #CCC;
              }
              .glass_approve_body_man_status_pass{
                border: 1px solid darkblue;
                cursor: pointer;
                transition: .1s;
              }
              .glass_approve_body_man_status_pass:hover{
                transition: .1s;
                background-color: darkblue;
                color: #FFF;
              }
            }
          }

        }
      }
      }
  }

}
@media screen and (min-width: 510px) and (max-width: 700px){
  .soclist{
    width: 100%;
    height: 100%;
    .soclist_title{
      font-size: 18pt;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .soclist_btns{
      display: flex;
      .soclist_upload{
        background-color: rgb(18, 19, 56);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 200px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
        margin: 0 20px 0 0;
        border-radius: 2px;
        transition: .2s;
      }
      .soclist_upload:hover{
        opacity: 0.8;
        transition: .2s;
      }
      .soclist_save{background-color: rgb(18, 19, 56);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 200px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
        margin: 0 20px 0 0;
        border-radius: 2px;
        transition: .2s;}
      .info{
        display: flex;
        flex-direction: row;
        .select_block{
          height: 40px;
          display: flex;
          min-width: 300px;
          .select_block_title{
            display: flex;
            align-items: center;
            margin-right: 20px;
            margin-bottom: 10px;
          }
          .select{
            display: flex;
            align-items: center;
            min-width:200px;
            width: 200px;
          }
        }
      }

    }
    .disable{
      background-color: rgb(100, 100, 100) !important;
    }
    .soclist_list{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      margin-top: 10px;
      .dop-info{
        border-bottom: 4px solid #CCC;
        width: 1220px;
        padding: 20px 0;
        .item{
          border: 2px solid #CCC;
        }
        .table-container {
          width: 1200px;
          overflow-x: auto; /* добавляем горизонтальную прокрутку, если таблица шире контейнера */
        }
        .table {
          display: flex;
          flex-direction: column;
        }
        .rowsmall {
          display: flex;
        }
        .cell {
          border: 1px solid #000;
          padding: 3px;
          font-size: 0.6rem;
          min-width: 30px;
          max-width: 32px;
        }
        .middle-column {
          min-width: 60px; /* задаем ширину для "широких" столбцов */
          max-width: 60px;
        }
        .wide-column {
          min-width: 120px; /* задаем ширину для "широких" столбцов */
          max-width: 120px;
        }
        .wbig-column{
          min-width: 300px; /* задаем ширину для "широких" столбцов */
          max-width: 300px;
        }
        .big-column{
          min-width: 200px; /* задаем ширину для "широких" столбцов */
          max-width: 200px;
        }
      }
      .title{
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
      }
      .soclist_list_line > * {
        display: flex;
        align-self: stretch;
        height: auto !important;
      }

      .soclist_list_line{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: calc(100% - 60px);
        min-height: 40px;
        border: 1px solid #CCC;
        font-size: 10pt;
        align-self: stretch;
        padding: 20px 40px 20px 20px;
        margin-bottom: 10px;

        .date{
          width: 100px;
        }

        .soclist_list_line_name{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch !important;
          max-width: 300px;
          min-height: 20px;
          height: 100%;
          border: none;
          background-color: #FFF;
          margin-bottom: 8px;
          margin-right: 5px;
          padding: 4px;
        }
        .soclist_list_line_price{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          max-width: 300px;
          padding: 0 10px;
          min-height: 20px;
          height: auto !important;
          border: none;
          font-size: 10pt;
        }
        .soclist_list_line_group{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          max-width: 300px;
          padding: 0 10px;
          min-height: 40px;

          border: none;
          border-right: 4px solid #CCC;
        }
        .soclist_list_line_cropname{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          max-width: 300px;
          padding: 0 10px;

          height: 100%;
          border: none;
        }
        .icons-container{
          width: 40px;
          margin-left: -40px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
        }
        position: relative;
        .soclist_list_line_del{
          position: absolute;
          top: 10px;
          right: 10px;
          margin-left: 0px;
          width: 20px;
          height: 20px;
          font-size: 16pt;
          margin-top: 5px;
          font-weight: 700;
          color: #CCC;
          transition: .2s;
          cursor: pointer;
          border: none;
        }
        .soclist_list_line_del:hover{
          color: #454545;
          transition: .2s;
        }
        .title{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 280px;
          min-height: 40px;
          height: 100%;
          border: none;
          border-right: 4px solid #CCC;
          font-weight: 700;
          font-size: 12pt;
          background-color: #F1F1F1;
          border-bottom: 4px solid #CCC;
        }
        .nametitle{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 100%;
          border: none;
          border-right: 4px solid #CCC;
          font-weight: 700;
          font-size: 12pt;
          background-color: #F1F1F1;
          border-bottom: 4px solid #CCC;
        }
        .listpp{
          width: 60px;
        }
        .borderrightnone{
          border-right: none;
        }
        .leftborder{
          border-left: 4px solid #CCC;
        }
        .inputs{
          outline: none;
          background-color: #FFFFFF;
        }
        .inputs:focus{
          outline: none;
          background-color: #9aa9ce;
          width: 280px;
          padding: 0 10px;

        }
        .button{
          text-decoration: underline;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 0.6rem;
          font-weight: 600;
        }.button:hover{
           color: white;
           background-color: rgba(18,19,56,1);
         }
      }
      .noneblock{
        display: none !important;
      }
      .bordertopnone{
        border-top: none;
      }

    }
    .newlineogm{
      padding: 20px;
      border: 4px dotted #CCC;
      width: fit-content;
      border-radius: 20px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      .line{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .line_title{
          font-size: 16pt;
          font-weight: 800;
          margin-right: 20px;
        }
      }
      .soclist_list_line_new{
        width: 251px;
        padding: 9px;
        margin-right: 20px;
        outline: none;
        border: 2px solid #454545;
        border-radius: 5px;
      }
      .small{
        width: 100px;
      }
      .soclist_plus{
        background-color: rgb(18, 19, 56);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 247px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
        margin: 0 20px 0 0;
        border-radius: 2px;
        transition: .2s;
        margin-top: 10px;
      }
      .soclist_plus:hover{
        opacity: 0.8;
        transition: .2s;
      }
    }
    .glass{
      position: absolute;
      backdrop-filter: blur(5px);
      top: 0px;
      left: 0px;
      min-width: 100%;
      height: 100%;
      background-color: rgba(200,200,200,0.6);
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;

      .glass_board{
        width: 90%;
        height: 90%;
        background-color: rgba(255,255,255,1);
        border-radius: 2px;
        box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        .glass_board_close{
          width: calc(100% - 30px);
          padding: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          i{
            font-size: 20pt;
            color: #454545;
            cursor: pointer;
            transition: 0.05s;
          }
          i:hover{
            color: #CCC;
            transition: 0.05s;
          }
        }
        .glass_board_body{
          width: calc(100% - 80px);
          height: calc(100% - 60px - 60px);
          //border: 1px solid #454545;
          padding: 20px;
          overflow-y: auto;
          overflow-x: hidden;
          .glass_board_body_title_rock{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 16pt;
            font-weight: 800;
            margin: 40px 0 10px;
            color: rgb(18, 19, 56);
            min-height: 50px;
          }

          @media screen and (max-width: 400px){
            .glass_board_body_title_rock{
              width: 100%;
              display: flex;
              justify-content: center;
              text-align: center;
              font-size: 12pt;
              font-weight: 800;
              margin: 40px 0 10px;
              color: rgb(18, 19, 56);
              min-height: 50px;
            }
          }
          .glass_board_step{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            margin-top: -60px;
          }
          .glass_board_step_next{
            width: 100%;
            max-width: 288px;
            border: 1px solid #CCC;
            min-height: 80px;
            height: 80px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #454545;
            cursor: pointer;
            background-color: #FFF;
            transition: 0.1s;
            border-radius: 5px;
          }
          .glass_board_body_docs{
            width: calc(100%);
            //min-height: 100px;
            //height: content-box;
            border: 1px solid #CCC;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            //overflow-x: hidden;
            //overflow-y: auto;
            .glass_board_body_docs_set_name{
              width: calc(100% - 90px);
              margin: 5px 0;
              .glass_board_body_docs_set_name_title{
                font-weight: 600;
              }
              .glass_board_body_docs_set_name_docname{
                font-size: 10pt;
                color: red;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .glass_board_body_docs_set_name_docname_this{
                  width: calc(100% - 15px);
                  color: rgb(18, 19, 56);
                  padding: 5px;
                  border: 1px solid rgb(18, 19, 56);
                  border-radius: 3px;
                  margin: 3px 0;
                  position: relative;
                  .del-this{
                    position: absolute;
                    right: 10px;
                    bottom: 1px;
                    width: 10px;
                    margin-right: 0px;
                    font-size: 14pt;
                  }
                }
              }
            }
            .glass_board_body_docs_set{
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              .glass_board_body_docs_set_name{
                width: calc(100% - 90px);
                .glass_board_body_docs_set_name_title{
                  font-weight: 600;
                }
                .glass_board_body_docs_set_name_docname{
                  font-size: 10pt;
                  color: red;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .glass_board_body_docs_set_name_docname_this{
                    width: calc(100% - 15px);
                    color: rgb(18, 19, 56);
                    padding: 5px;
                    border: 1px solid rgb(18, 19, 56);
                    border-radius: 3px;
                    margin: 3px 0;
                    position: relative;
                    .del-this{
                      position: absolute;
                      right: 10px;
                      bottom: 1px;
                      width: 10px;
                      margin-right: 0px;
                      font-size: 14pt;
                    }
                  }
                }
              }
              .glass_board_body_docs_set_btn{
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #CCC;
                i{
                  font-size: 20pt;
                  color: #CCC;
                  margin-left:-16px;
                }
              }
            }

          }
          .glass_board_step_next:hover{
            color: #FFF;
            background-color: #454545;
            transition: 0.1s;
          }
          .glass_board_btn{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: -20px;
            min-height: 80px;
            .glass_board_btn_left{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }
            .glass_board_btn_right{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }

            i{
              font-size: 20pt;
              color: #CCC;
            }
          }

          .btnpercent{
            position: absolute;
            width: 130px;
            height: 50px;
            margin: 15px 0 0 -130px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            transition: 0.2s;
          }

          .btnpercent:hover{
            opacity: 0.8;
            transition: 0.2s;
          }

          .trashpercent{
            margin: 0 0 0 -30px;
            i{
              cursor: pointer;
              color: #CCC;
              transition: .1s;
            }
            i:hover{
              color: rgb(18, 19, 56);
              transition: .1s;
            }
          }

          .glass_board_body_tit{
            display: flex;
            align-items: center;
            margin: 10px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.8rem;
          }

          .glass_board_body_numinput{
            width: 80px;
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
            margin-left: 10px;
            margin-right: 10px;
          }

          .glass_board_body_input{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 16pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
          }

          .glass_board_body_textarea{
            margin-top: 10px;
            width: calc(100% - 46px);
            padding: 10px 20px;
            min-height: 120px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            outline: none;
          }

          .glass_board_body_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 20px;
            border: 1px solid #CCC;
            color: #CCC;
            width: auto;
            max-height: 30px !important;
            cursor: pointer;
            margin-right: 10px;
            padding: 5px 30px;
            border-radius: 10px;
            margin-left: 10px;
            font-weight: 400;
            transition: .2s;
          }
          .glass_board_body_btn:hover{
            color: #454545;
            border: 1px solid #454545;
            transition: .2s;
          }

          .glass_board_body_graf{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            outline: none;
            transition: 0.2s;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .inputs_of_graf{
              border: none;
              width: calc(100% - 40px);
              outline: none;
              font-size: 12pt;
              //height: ;
            }
            .btns{
              i{
                font-size: 16pt;
                margin-left: 10px;
                cursor: pointer;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }

          }
          .glassslash{
            width: 100%;
            height: 1px;
            background-color: #CCC;
            margin-top: 10px;
          }
          .blueborder{
            border: 3px dotted rgb(18, 19, 56);
          }
          .glass_board_body_buttons{
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            .glass_board_body_buttons_create{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid #CCC;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }
            .glass_board_body_buttons_cancel{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid darkred;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }

            .glass_board_body_buttons_create:hover{
              color: #000;
              border: 1px solid #454545;
            }

            .glass_board_body_buttons_cancel:hover{
              color: red;
            }
          }
          .glass_board_body_selection{
            display: flex;

            .glass_pluss_btn{
              width: 150px;
              height: 36px;
              border: 1px solid #CCC;
              border-radius: 4px;
              margin-left: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #454545;
              background-color: #FFFFFF;
              cursor: pointer;
              transition: 0.2s;
            }
            .glass_pluss_btn:hover{
              color: #FFFFFF;
              background-color: #454545;
              border: 1px solid #CCC;
              transition: 0.2s;
            }
          }

          .glass_board_body_liststatements_man{
            display: flex;
            width: 100%;
            border: 1px solid #CCC;
            min-height: 40px;
            margin-top: -1px;
            font-size: 8px;
            .glass_board_body_liststatements_num{
              border-right: 1px solid #CCC;
              width: 20px;
              padding: 10px;
            }
            .glass_board_body_liststatements_fio{
              border-right: 1px solid #CCC;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_dev{
              border-right: 1px solid #CCC;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_view{
              border-right: 1px solid #CCC;
              min-width: 50px;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_app{
              border-right: 1px solid #CCC;
              min-width: 50px;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_del{
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding: 10px;
              min-width: 50px;
              width: 250px;
              flex-wrap: wrap;
              div{
                margin-right: 10px;
                padding: 5px 10px;
                border-radius: 5px;
              }
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.1s;
              }
              i:hover{
                color: #CCC;
                transition: 0.1s;
              }

              .glass_board_body_liststatements_del_eye{}
              .glass_board_body_liststatements_del_del{}
              .glass_board_body_liststatements_del_yes{
                border: 1px solid green;
              }
              .glass_board_body_liststatements_del_no{
                border: 1px solid darkred;
              }
              .glass_board_body_liststatements_del_wait{
                border: 1px solid #CCC;
              }
              .glass_board_body_liststatements_del_pass{
                border: 1px solid rgb(18, 19, 56);
                background-color: #FFFFFF;
                cursor: pointer;
                transition: .2s;
              }
              .glass_board_body_liststatements_del_pass:hover{
                background-color: rgb(18, 19, 56);
                color: #FFFFFF;
                transition: .2s;
              }
            }
          }


          .glass_board_body_control{
            display: flex;
            justify-content: flex-start;
            padding: 10px;
            .glass_board_body_control_btn{
              border: 1px solid #CCC;
              border-radius: 5px;
              display: none;
              flex-direction: column;
              align-items: center;
              width: 150px;
              margin: 10px 10px 0 0;
              padding: 10px;
              cursor: pointer;
              background-color: #FFFFFF;
              transition: .2s;
            }
            .glass_board_body_control_btn:hover{
              background-color: #454545;
              color: #FFF;
              transition: .2s;
            }
          }

          .glass_board_body_documents{
            display: flex;
            align-items: flex-start;
            padding: 10px;
            .glass_board_body_documents_file{
              border: 1px solid #CCC;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 150px;
              margin: 10px 10px 0 0;
              padding: 10px;
              cursor: pointer;
              i{
                font-size: 22pt;
              }
            }
            .glass_board_body_documents_file:hover{
              box-shadow: 0 0 3px rgba(0, 128, 255, 0.5);
            }
            .glass_board_body_documents_file_name{
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-size: 10pt;
            }
          }

          .list_approve{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            font-size: 10pt;
            .list_approve_man{
              border: 1px solid #CCC;
              padding: 10px;
              width: 250px;
              border-radius: 5px;
              margin-right: 20px;
              position: relative;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              .list_approve_man_name{
                font-weight: 600;
              }
              .list_approve_man_dev{
                font-weight: 400;
              }
              .list_approve_man_status{
                margin-top: 5px;
                padding: 5px 10px;
                border: 1px solid #CCC;
                border-radius: 2px;
              }
              .list_approve_man_comment{
                margin-top: 5px;
                padding: 5px 10px;
                border: 1px solid #CCC;
                border-radius: 2px;
              }
              .list_approve_man_slash{
                position: absolute;
                height: 4px;
                top: 20px;
                left: 0px;
                width: 20px;
                background-color: #CCC;
                margin-left: -21px;
              }
            }
          }

          .glass_board_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_board_body_man_content{
              display: flex;
              .glass_board_body_man_photo{
                width: 60px;
                height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_board_body_man_params{
                margin-left: 20px;
                .glass_board_body_man_name{
                  font-weight: 700;
                }
                .glass_board_body_man_dev{
                  font-size: 11pt;
                }
                .glass_board_body_man_departament{
                  font-size: 11pt;
                }
              }
            }

            .glass_board_body_man_del{
              font-size: 16pt;
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.2s;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }
          }
          .glass_approve_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px solid #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_approve_body_man_content{
              display: flex;
              .glass_approve_body_man_photo{
                width: 60px;
                height: 60px;
                min-width: 60px;
                min-height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_approve_body_man_params{
                margin-left: 20px;
                .glass_approve_body_man_name{
                  font-weight: 700;
                }
                .glass_approve_body_man_dev{
                  font-size: 11pt;
                }
                .glass_approve_body_man_departament{
                  font-size: 11pt;
                }
                .glass_approve_body_man_comment{
                  font-size: 11pt;
                  padding: 10px;
                  border: 1px solid #CCC;
                  margin: 10px 10px 0 0;
                  border-radius: 2px;
                  max-width: 400px;
                }
              }
            }

            .glass_approve_body_man_status{
              display: flex;
              flex-wrap: wrap;
              width: 200px;
              div{
                margin: 0 10px 10px 0;
                padding: 5px 20px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
              }
              .glass_approve_body_man_status_no{
                border: 1px solid red;
              }
              .glass_approve_body_man_status_yes{
                border: 1px solid green;
              }
              .glass_approve_body_man_status_wait{
                border: 1px solid #CCC;
              }
              .glass_approve_body_man_status_pass{
                border: 1px solid darkblue;
                cursor: pointer;
                transition: .1s;
              }
              .glass_approve_body_man_status_pass:hover{
                transition: .1s;
                background-color: darkblue;
                color: #FFF;
              }
            }
          }

        }
      }

      .glass_board_create{
        width: 90%;
        height: 90%;
        background-color: rgba(255,255,255,1);
        border-radius: 2px;
        box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        .glass_board_close{
          width: calc(100% - 30px);
          padding: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          i{
            font-size: 20pt;
            color: #454545;
            cursor: pointer;
            transition: 0.05s;
          }
          i:hover{
            color: #CCC;
            transition: 0.05s;
          }
        }
        .glass_board_body{
          width: calc(100% - 80px);
          height: calc(100% - 60px - 60px);
          //border: 1px solid #454545;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          overflow-y: auto;
          overflow-x: hidden;
          .glass_board_body_title_rock{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 16pt;
            font-weight: 800;
            margin: 40px 0 10px;
            color: rgb(18, 19, 56);
            min-height: 50px;
          }

          @media screen and (max-width: 400px){
            .glass_board_body_title_rock{
              width: 100%;
              display: flex;
              justify-content: center;
              text-align: center;
              font-size: 12pt;
              font-weight: 800;
              margin: 40px 0 10px;
              color: rgb(18, 19, 56);
              min-height: 50px;
            }
          }
          .glass_board_step{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            margin-top: -60px;
          }
          .glass_board_step_next{
            width: 100%;
            max-width: 288px;
            border: 1px solid #CCC;
            min-height: 80px;
            height: 80px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #454545;
            cursor: pointer;
            background-color: #FFF;
            transition: 0.1s;
            border-radius: 5px;
          }
          .glass_board_body_docs{
            width: calc(100%);
            //min-height: 100px;
            //height: content-box;
            border: 1px solid #CCC;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            //overflow-x: hidden;
            //overflow-y: auto;
            .glass_board_body_docs_set_name{
              width: calc(100% - 90px);
              margin: 5px 0;
              .glass_board_body_docs_set_name_title{
                font-weight: 600;
              }
              .glass_board_body_docs_set_name_docname{
                font-size: 10pt;
                color: red;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .glass_board_body_docs_set_name_docname_this{
                  width: calc(100% - 15px);
                  color: rgb(18, 19, 56);
                  padding: 5px;
                  border: 1px solid rgb(18, 19, 56);
                  border-radius: 3px;
                  margin: 3px 0;
                  position: relative;
                  .del-this{
                    position: absolute;
                    right: 10px;
                    bottom: 1px;
                    width: 10px;
                    margin-right: 0px;
                    font-size: 14pt;
                  }
                }
              }
            }
            .glass_board_body_docs_set{
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              .glass_board_body_docs_set_name{
                width: calc(100% - 90px);
                .glass_board_body_docs_set_name_title{
                  font-weight: 600;
                }
                .glass_board_body_docs_set_name_docname{
                  font-size: 10pt;
                  color: red;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .glass_board_body_docs_set_name_docname_this{
                    width: calc(100% - 15px);
                    color: rgb(18, 19, 56);
                    padding: 5px;
                    border: 1px solid rgb(18, 19, 56);
                    border-radius: 3px;
                    margin: 3px 0;
                    position: relative;
                    .del-this{
                      position: absolute;
                      right: 10px;
                      bottom: 1px;
                      width: 10px;
                      margin-right: 0px;
                      font-size: 14pt;
                    }
                  }
                }
              }
              .glass_board_body_docs_set_btn{
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #CCC;
                i{
                  font-size: 20pt;
                  color: #CCC;
                  margin-left:-16px;
                }
              }
            }

          }
          .glass_board_step_next:hover{
            color: #FFF;
            background-color: #454545;
            transition: 0.1s;
          }
          .glass_board_btn{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: -20px;
            min-height: 80px;
            .glass_board_btn_left{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }
            .glass_board_btn_right{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }

            i{
              font-size: 20pt;
              color: #CCC;
            }
          }

          .btnpercent{
            position: absolute;
            width: 130px;
            height: 50px;
            margin: 15px 0 0 -130px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            transition: 0.2s;
          }

          .btnpercent:hover{
            opacity: 0.8;
            transition: 0.2s;
          }

          .trashpercent{
            margin: 0 0 0 -30px;
            i{
              cursor: pointer;
              color: #CCC;
              transition: .1s;
            }
            i:hover{
              color: rgb(18, 19, 56);
              transition: .1s;
            }
          }

          .glass_board_body_tit{
            display: flex;
            align-items: center;
            margin: 10px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.8rem;
          }

          .glass_board_body_numinput{
            width: 80px;
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
            margin-left: 10px;
            margin-right: 10px;
          }

          .glass_board_body_input{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 16pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
          }

          .glass_board_body_textarea{
            margin-top: 10px;
            width: calc(100% - 46px);
            padding: 10px 20px;
            min-height: 120px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            outline: none;
          }

          .glass_board_body_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 20px;
            border: 1px solid #CCC;
            color: #CCC;
            width: auto;
            max-height: 30px !important;
            cursor: pointer;
            margin-right: 10px;
            padding: 5px 30px;
            border-radius: 10px;
            margin-left: 10px;
            font-weight: 400;
            transition: .2s;
          }
          .glass_board_body_btn:hover{
            color: #454545;
            border: 1px solid #454545;
            transition: .2s;
          }

          .glass_board_body_graf{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            outline: none;
            transition: 0.2s;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .inputs_of_graf{
              border: none;
              width: calc(100% - 40px);
              outline: none;
              font-size: 12pt;
              //height: ;
            }
            .btns{
              i{
                font-size: 16pt;
                margin-left: 10px;
                cursor: pointer;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }

          }
          .glassslash{
            width: 100%;
            height: 1px;
            background-color: #CCC;
            margin-top: 10px;
          }
          .blueborder{
            border: 3px dotted rgb(18, 19, 56);
          }
          .glass_board_body_buttons{
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            .glass_board_body_buttons_create{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid #CCC;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }
            .glass_board_body_buttons_cancel{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid darkred;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }

            .glass_board_body_buttons_create:hover{
              color: #000;
              border: 1px solid #454545;
            }

            .glass_board_body_buttons_cancel:hover{
              color: red;
            }
          }
          .glass_board_body_selection{
            display: flex;

            .glass_pluss_btn{
              width: 150px;
              height: 36px;
              border: 1px solid #CCC;
              border-radius: 4px;
              margin-left: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #454545;
              background-color: #FFFFFF;
              cursor: pointer;
              transition: 0.2s;
            }
            .glass_pluss_btn:hover{
              color: #FFFFFF;
              background-color: #454545;
              border: 1px solid #CCC;
              transition: 0.2s;
            }
          }

          .glass_board_body_liststatements_man{
            display: flex;
            width: 100%;
            border: 1px solid #CCC;
            min-height: 40px;
            margin-top: -1px;
            font-size: 8px;
            .glass_board_body_liststatements_num{
              border-right: 1px solid #CCC;
              width: 20px;
              padding: 10px;
            }
            .glass_board_body_liststatements_fio{
              border-right: 1px solid #CCC;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_dev{
              border-right: 1px solid #CCC;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_view{
              border-right: 1px solid #CCC;
              min-width: 50px;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_app{
              border-right: 1px solid #CCC;
              min-width: 50px;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_del{
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding: 10px;
              min-width: 50px;
              width: 250px;
              flex-wrap: wrap;
              div{
                margin-right: 10px;
                padding: 5px 10px;
                border-radius: 5px;
              }
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.1s;
              }
              i:hover{
                color: #CCC;
                transition: 0.1s;
              }

              .glass_board_body_liststatements_del_eye{}
              .glass_board_body_liststatements_del_del{}
              .glass_board_body_liststatements_del_yes{
                border: 1px solid green;
              }
              .glass_board_body_liststatements_del_no{
                border: 1px solid darkred;
              }
              .glass_board_body_liststatements_del_wait{
                border: 1px solid #CCC;
              }
              .glass_board_body_liststatements_del_pass{
                border: 1px solid rgb(18, 19, 56);
                background-color: #FFFFFF;
                cursor: pointer;
                transition: .2s;
              }
              .glass_board_body_liststatements_del_pass:hover{
                background-color: rgb(18, 19, 56);
                color: #FFFFFF;
                transition: .2s;
              }
            }
          }


          .glass_board_body_control{
            display: flex;
            justify-content: flex-start;
            padding: 10px;
            .glass_board_body_control_btn{
              border: 1px solid #CCC;
              border-radius: 5px;
              display: none;
              flex-direction: column;
              align-items: center;
              width: 150px;
              margin: 10px 10px 0 0;
              padding: 10px;
              cursor: pointer;
              background-color: #FFFFFF;
              transition: .2s;
            }
            .glass_board_body_control_btn:hover{
              background-color: #454545;
              color: #FFF;
              transition: .2s;
            }
          }

          .glass_board_body_documents{
            display: flex;
            align-items: flex-start;
            padding: 10px;
            .glass_board_body_documents_file{
              border: 1px solid #CCC;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 150px;
              margin: 10px 10px 0 0;
              padding: 10px;
              cursor: pointer;
              i{
                font-size: 22pt;
              }
            }
            .glass_board_body_documents_file:hover{
              box-shadow: 0 0 3px rgba(0, 128, 255, 0.5);
            }
            .glass_board_body_documents_file_name{
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-size: 10pt;
            }
          }

          .list_approve{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            font-size: 10pt;
            .list_approve_man{
              border: 1px solid #CCC;
              padding: 10px;
              width: 250px;
              border-radius: 5px;
              margin-right: 20px;
              position: relative;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              .list_approve_man_name{
                font-weight: 600;
              }
              .list_approve_man_dev{
                font-weight: 400;
              }
              .list_approve_man_status{
                margin-top: 5px;
                padding: 5px 10px;
                border: 1px solid #CCC;
                border-radius: 2px;
              }
              .list_approve_man_comment{
                margin-top: 5px;
                padding: 5px 10px;
                border: 1px solid #CCC;
                border-radius: 2px;
              }
              .list_approve_man_slash{
                position: absolute;
                height: 4px;
                top: 20px;
                left: 0px;
                width: 20px;
                background-color: #CCC;
                margin-left: -21px;
              }
            }
          }

          .glass_board_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_board_body_man_content{
              display: flex;
              .glass_board_body_man_photo{
                width: 60px;
                height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_board_body_man_params{
                margin-left: 20px;
                .glass_board_body_man_name{
                  font-weight: 700;
                }
                .glass_board_body_man_dev{
                  font-size: 11pt;
                }
                .glass_board_body_man_departament{
                  font-size: 11pt;
                }
              }
            }

            .glass_board_body_man_del{
              font-size: 16pt;
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.2s;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }
          }
          .glass_approve_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px solid #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_approve_body_man_content{
              display: flex;
              .glass_approve_body_man_photo{
                width: 60px;
                height: 60px;
                min-width: 60px;
                min-height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_approve_body_man_params{
                margin-left: 20px;
                .glass_approve_body_man_name{
                  font-weight: 700;
                }
                .glass_approve_body_man_dev{
                  font-size: 11pt;
                }
                .glass_approve_body_man_departament{
                  font-size: 11pt;
                }
                .glass_approve_body_man_comment{
                  font-size: 11pt;
                  padding: 10px;
                  border: 1px solid #CCC;
                  margin: 10px 10px 0 0;
                  border-radius: 2px;
                  max-width: 400px;
                }
              }
            }

            .glass_approve_body_man_status{
              display: flex;
              flex-wrap: wrap;
              width: 200px;
              div{
                margin: 0 10px 10px 0;
                padding: 5px 20px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
              }
              .glass_approve_body_man_status_no{
                border: 1px solid red;
              }
              .glass_approve_body_man_status_yes{
                border: 1px solid green;
              }
              .glass_approve_body_man_status_wait{
                border: 1px solid #CCC;
              }
              .glass_approve_body_man_status_pass{
                border: 1px solid darkblue;
                cursor: pointer;
                transition: .1s;
              }
              .glass_approve_body_man_status_pass:hover{
                transition: .1s;
                background-color: darkblue;
                color: #FFF;
              }
            }
          }

        }
      }
    }
    .glasser{
      position: absolute !important;
      backdrop-filter: blur(5px) !important;
      top: 0px !important;
      left: 0px !important;
      width: 100% !important;
      height: 100% !important;
      background-color: rgba(200,200,200,0.6) !important;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;

      .glass_board{
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,1);
        border-radius: 2px;
        box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        .glass_board_close{
          width: calc(100% - 30px);
          padding: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          i{
            font-size: 20pt;
            color: #454545;
            cursor: pointer;
            transition: 0.05s;
          }
          i:hover{
            color: #CCC;
            transition: 0.05s;
          }
        }
        .glass_board_body{
          width: calc(100% - 80px);
          height: calc(100% - 60px - 60px);
          //border: 1px solid #454545;
          padding: 20px;
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          .glass_board_body_title_rock{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 16pt;
            font-weight: 800;
            margin: 40px 0 10px;
            color: rgb(18, 19, 56);
            min-height: 50px;
          }

          @media screen and (max-width: 400px){
            .glass_board_body_title_rock{
              width: 100%;
              display: flex;
              justify-content: center;
              text-align: center;
              font-size: 12pt;
              font-weight: 800;
              margin: 40px 0 10px;
              color: rgb(18, 19, 56);
              min-height: 50px;
            }
          }
          .glass_board_step{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            margin-top: -60px;
          }
          .glass_board_step_next{
            width: 100%;
            max-width: 288px;
            border: 1px solid #CCC;
            min-height: 80px;
            height: 80px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #454545;
            cursor: pointer;
            background-color: #FFF;
            transition: 0.1s;
            border-radius: 5px;
          }
          .glass_board_body_docs{
            width: calc(100%);
            //min-height: 100px;
            //height: content-box;
            border: 1px solid #CCC;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            //overflow-x: hidden;
            //overflow-y: auto;
            .glass_board_body_docs_set_name{
              width: calc(100% - 90px);
              margin: 5px 0;
              .glass_board_body_docs_set_name_title{
                font-weight: 600;
              }
              .glass_board_body_docs_set_name_docname{
                font-size: 10pt;
                color: red;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .glass_board_body_docs_set_name_docname_this{
                  width: calc(100% - 15px);
                  color: rgb(18, 19, 56);
                  padding: 5px;
                  border: 1px solid rgb(18, 19, 56);
                  border-radius: 3px;
                  margin: 3px 0;
                  position: relative;
                  .del-this{
                    position: absolute;
                    right: 10px;
                    bottom: 1px;
                    width: 10px;
                    margin-right: 0px;
                    font-size: 14pt;
                  }
                }
              }
            }
            .glass_board_body_docs_set{
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              .glass_board_body_docs_set_name{
                width: calc(100% - 90px);
                .glass_board_body_docs_set_name_title{
                  font-weight: 600;
                }
                .glass_board_body_docs_set_name_docname{
                  font-size: 10pt;
                  color: red;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .glass_board_body_docs_set_name_docname_this{
                    width: calc(100% - 15px);
                    color: rgb(18, 19, 56);
                    padding: 5px;
                    border: 1px solid rgb(18, 19, 56);
                    border-radius: 3px;
                    margin: 3px 0;
                    position: relative;
                    .del-this{
                      position: absolute;
                      right: 10px;
                      bottom: 1px;
                      width: 10px;
                      margin-right: 0px;
                      font-size: 14pt;
                    }
                  }
                }
              }
              .glass_board_body_docs_set_btn{
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #CCC;
                i{
                  font-size: 20pt;
                  color: #CCC;
                  margin-left:-16px;
                }
              }
            }

          }
          .glass_board_step_next:hover{
            color: #FFF;
            background-color: #454545;
            transition: 0.1s;
          }
          .glass_board_btn{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: -20px;
            min-height: 80px;
            .glass_board_btn_left{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }
            .glass_board_btn_right{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }

            i{
              font-size: 20pt;
              color: #CCC;
            }
          }


          .btnpercent{
            position: absolute;
            width: 130px;
            height: 50px;
            margin: 15px 0 0 -130px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            transition: 0.2s;
          }

          .btnpercent:hover{
            opacity: 0.8;
            transition: 0.2s;
          }

          .trashpercent{
            margin: 0 0 0 -30px;
            i{
              cursor: pointer;
              color: #CCC;
              transition: .1s;
            }
            i:hover{
              color: rgb(18, 19, 56);
              transition: .1s;
            }
          }

          .glass_board_body_tit{
            display: flex;
            align-items: center;
            margin: 10px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.8rem;
          }

          .glass_board_body_numinput{
            width: 80px;
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
            margin-left: 10px;
            margin-right: 10px;
          }

          .glass_board_body_input{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 16pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
          }

          .glass_board_body_textarea{
            margin-top: 10px;
            width: calc(100% - 46px);
            padding: 10px 20px;
            min-height: 120px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            outline: none;
          }

          .glass_board_body_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 20px;
            border: 1px solid #CCC;
            color: #CCC;
            width: auto;
            max-height: 30px !important;
            cursor: pointer;
            margin-right: 10px;
            padding: 5px 30px;
            border-radius: 10px;
            margin-left: 10px;
            font-weight: 400;
            transition: .2s;
          }
          .glass_board_body_btn:hover{
            color: #454545;
            border: 1px solid #454545;
            transition: .2s;
          }

          .glass_board_body_graf{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            outline: none;
            transition: 0.2s;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .inputs_of_graf{
              border: none;
              width: calc(100% - 40px);
              outline: none;
              font-size: 12pt;
              //height: ;
            }
            .btns{
              i{
                font-size: 16pt;
                margin-left: 10px;
                cursor: pointer;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }

          }
          .glassslash{
            width: 100%;
            height: 1px;
            background-color: #CCC;
            margin-top: 10px;
          }
          .blueborder{
            border: 3px dotted rgb(18, 19, 56);
          }
          .glass_board_body_buttons{
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            .glass_board_body_buttons_create{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid #CCC;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }
            .glass_board_body_buttons_cancel{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid darkred;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }

            .glass_board_body_buttons_create:hover{
              color: #000;
              border: 1px solid #454545;
            }

            .glass_board_body_buttons_cancel:hover{
              color: red;
            }
          }
          .glass_board_body_selection{
            display: flex;

            .glass_pluss_btn{
              width: 150px;
              height: 36px;
              border: 1px solid #CCC;
              border-radius: 4px;
              margin-left: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #454545;
              background-color: #FFFFFF;
              cursor: pointer;
              transition: 0.2s;
            }
            .glass_pluss_btn:hover{
              color: #FFFFFF;
              background-color: #454545;
              border: 1px solid #CCC;
              transition: 0.2s;
            }
          }

          .glass_board_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_board_body_man_content{
              display: flex;
              .glass_board_body_man_photo{
                width: 60px;
                height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_board_body_man_params{
                margin-left: 20px;
                .glass_board_body_man_name{
                  font-weight: 700;
                }
                .glass_board_body_man_dev{
                  font-size: 11pt;
                }
                .glass_board_body_man_departament{
                  font-size: 11pt;
                }
              }
            }

            .glass_board_body_man_del{
              font-size: 16pt;
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.2s;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }
          }

        }
      }
    }
  }
}

@media screen and (max-width: 510px){
  .soclist{
    width: 100%;
    height: 100%;
    .soclist_title{
      font-size: 18pt;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .soclist_btns{
      display: flex;
      .soclist_upload{
        background-color: rgb(18, 19, 56);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 200px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
        margin: 0 20px 0 0;
        border-radius: 2px;
        transition: .2s;
      }
      .soclist_upload:hover{
        opacity: 0.8;
        transition: .2s;
      }
      .soclist_save{background-color: rgb(18, 19, 56);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 200px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
        margin: 0 20px 0 0;
        border-radius: 2px;
        transition: .2s;}
      .info{
        display: flex;
        flex-direction: row;
        .select_block{
          height: 40px;
          display: flex;
          min-width: 300px;
          .select_block_title{
            display: flex;
            align-items: center;
            margin-right: 20px;
            margin-bottom: 10px;
          }
          .select{
            display: flex;
            align-items: center;
            min-width:200px;
            width: 200px;
          }
        }
      }

    }
    .disable{
      background-color: rgb(100, 100, 100) !important;
    }
    .soclist_list{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      margin-top: 10px;
      .dop-info{
        border-bottom: 4px solid #CCC;
        width: 1220px;
        padding: 20px 0;
        .item{
          border: 2px solid #CCC;
        }
        .table-container {
          width: 1200px;
          overflow-x: auto; /* добавляем горизонтальную прокрутку, если таблица шире контейнера */
        }
        .table {
          display: flex;
          flex-direction: column;
        }
        .rowsmall {
          display: flex;
        }
        .cell {
          border: 1px solid #000;
          padding: 3px;
          font-size: 0.6rem;
          min-width: 30px;
          max-width: 32px;
        }
        .middle-column {
          min-width: 60px; /* задаем ширину для "широких" столбцов */
          max-width: 60px;
        }
        .wide-column {
          min-width: 120px; /* задаем ширину для "широких" столбцов */
          max-width: 120px;
        }
        .wbig-column{
          min-width: 300px; /* задаем ширину для "широких" столбцов */
          max-width: 300px;
        }
        .big-column{
          min-width: 200px; /* задаем ширину для "широких" столбцов */
          max-width: 200px;
        }
      }
      .title{
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
      }
      .soclist_list_line > * {
        display: flex;
        align-self: stretch;
        height: auto !important;
      }

      .soclist_list_line{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: calc(100% - 60px);
        min-height: 40px;
        border: 1px solid #CCC;
        font-size: 10pt;
        align-self: stretch;
        padding: 20px 40px 20px 20px;
        margin-bottom: 10px;

        .date{
          width: 100px;
        }

        .soclist_list_line_name{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch !important;
          max-width: 300px;
          min-height: 20px;
          height: 100%;
          border: none;
          background-color: #FFF;
          margin-bottom: 8px;
          margin-right: 5px;
          padding: 4px;
        }
        .soclist_list_line_price{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          max-width: 300px;
          padding: 0 10px;
          min-height: 20px;
          height: auto !important;
          border: none;
          font-size: 10pt;
        }
        .soclist_list_line_group{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          max-width: 300px;
          padding: 0 10px;
          min-height: 40px;

          border: none;
          border-right: 4px solid #CCC;
        }
        .soclist_list_line_cropname{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          max-width: 300px;
          padding: 0 10px;

          height: 100%;
          border: none;
        }
        .icons-container{
          width: 40px;
          margin-left: -40px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
        }
        position: relative;
        .soclist_list_line_del{
          position: absolute;
          top: 10px;
          right: 10px;
          margin-left: 0px;
          width: 20px;
          height: 20px;
          font-size: 16pt;
          margin-top: 5px;
          font-weight: 700;
          color: #CCC;
          transition: .2s;
          cursor: pointer;
          border: none;
        }
        .soclist_list_line_del:hover{
          color: #454545;
          transition: .2s;
        }
        .title{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 280px;
          min-height: 40px;
          height: 100%;
          border: none;
          border-right: 4px solid #CCC;
          font-weight: 700;
          font-size: 12pt;
          background-color: #F1F1F1;
          border-bottom: 4px solid #CCC;
        }
        .nametitle{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 100%;
          border: none;
          border-right: 4px solid #CCC;
          font-weight: 700;
          font-size: 12pt;
          background-color: #F1F1F1;
          border-bottom: 4px solid #CCC;
        }
        .listpp{
          width: 60px;
        }
        .borderrightnone{
          border-right: none;
        }
        .leftborder{
          border-left: 4px solid #CCC;
        }
        .inputs{
          outline: none;
          background-color: #FFFFFF;
        }
        .inputs:focus{
          outline: none;
          background-color: #9aa9ce;
          width: 280px;
          padding: 0 10px;

        }
        .button{
          text-decoration: underline;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 0.6rem;
          font-weight: 600;
        }.button:hover{
           color: white;
           background-color: rgba(18,19,56,1);
         }
      }
      .noneblock{
        display: none !important;
      }
      .bordertopnone{
        border-top: none;
      }

    }
    .newlineogm{
      padding: 20px;
      border: 4px dotted #CCC;
      width: fit-content;
      border-radius: 20px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      .line{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .line_title{
          font-size: 16pt;
          font-weight: 800;
          margin-right: 20px;
        }
      }
      .soclist_list_line_new{
        width: 251px;
        padding: 9px;
        margin-right: 20px;
        outline: none;
        border: 2px solid #454545;
        border-radius: 5px;
      }
      .small{
        width: 100px;
      }
      .soclist_plus{
        background-color: rgb(18, 19, 56);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 247px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
        margin: 0 20px 0 0;
        border-radius: 2px;
        transition: .2s;
        margin-top: 10px;
      }
      .soclist_plus:hover{
        opacity: 0.8;
        transition: .2s;
      }
    }
    .glass{
      position: absolute;
      backdrop-filter: blur(5px);
      top: 0px;
      left: 0px;
      min-width: 100%;
      height: 100%;
      background-color: rgba(200,200,200,0.6);
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;

      .glass_board{
        width: 90%;
        height: 90%;
        background-color: rgba(255,255,255,1);
        border-radius: 2px;
        box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        .glass_board_close{
          width: calc(100% - 30px);
          padding: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          i{
            font-size: 20pt;
            color: #454545;
            cursor: pointer;
            transition: 0.05s;
          }
          i:hover{
            color: #CCC;
            transition: 0.05s;
          }
        }
        .glass_board_body{
          width: calc(100% - 80px);
          height: calc(100% - 60px - 60px);
          //border: 1px solid #454545;
          padding: 20px;
          overflow-y: auto;
          overflow-x: hidden;
          .glass_board_body_title_rock{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 16pt;
            font-weight: 800;
            margin: 40px 0 10px;
            color: rgb(18, 19, 56);
            min-height: 50px;
          }

          @media screen and (max-width: 400px){
            .glass_board_body_title_rock{
              width: 100%;
              display: flex;
              justify-content: center;
              text-align: center;
              font-size: 12pt;
              font-weight: 800;
              margin: 40px 0 10px;
              color: rgb(18, 19, 56);
              min-height: 50px;
            }
          }
          .glass_board_step{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            margin-top: -60px;
          }
          .glass_board_step_next{
            width: 100%;
            max-width: 288px;
            border: 1px solid #CCC;
            min-height: 80px;
            height: 80px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #454545;
            cursor: pointer;
            background-color: #FFF;
            transition: 0.1s;
            border-radius: 5px;
          }
          .glass_board_body_docs{
            width: calc(100%);
            //min-height: 100px;
            //height: content-box;
            border: 1px solid #CCC;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            //overflow-x: hidden;
            //overflow-y: auto;
            .glass_board_body_docs_set_name{
              width: calc(100% - 90px);
              margin: 5px 0;
              .glass_board_body_docs_set_name_title{
                font-weight: 600;
              }
              .glass_board_body_docs_set_name_docname{
                font-size: 10pt;
                color: red;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .glass_board_body_docs_set_name_docname_this{
                  width: calc(100% - 15px);
                  color: rgb(18, 19, 56);
                  padding: 5px;
                  border: 1px solid rgb(18, 19, 56);
                  border-radius: 3px;
                  margin: 3px 0;
                  position: relative;
                  .del-this{
                    position: absolute;
                    right: 10px;
                    bottom: 1px;
                    width: 10px;
                    margin-right: 0px;
                    font-size: 14pt;
                  }
                }
              }
            }
            .glass_board_body_docs_set{
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              .glass_board_body_docs_set_name{
                width: calc(100% - 90px);
                .glass_board_body_docs_set_name_title{
                  font-weight: 600;
                }
                .glass_board_body_docs_set_name_docname{
                  font-size: 10pt;
                  color: red;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .glass_board_body_docs_set_name_docname_this{
                    width: calc(100% - 15px);
                    color: rgb(18, 19, 56);
                    padding: 5px;
                    border: 1px solid rgb(18, 19, 56);
                    border-radius: 3px;
                    margin: 3px 0;
                    position: relative;
                    .del-this{
                      position: absolute;
                      right: 10px;
                      bottom: 1px;
                      width: 10px;
                      margin-right: 0px;
                      font-size: 14pt;
                    }
                  }
                }
              }
              .glass_board_body_docs_set_btn{
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #CCC;
                i{
                  font-size: 20pt;
                  color: #CCC;
                  margin-left:-16px;
                }
              }
            }

          }
          .glass_board_step_next:hover{
            color: #FFF;
            background-color: #454545;
            transition: 0.1s;
          }
          .glass_board_btn{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: -20px;
            min-height: 80px;
            .glass_board_btn_left{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }
            .glass_board_btn_right{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }

            i{
              font-size: 20pt;
              color: #CCC;
            }
          }

          .btnpercent{
            position: absolute;
            width: 130px;
            height: 50px;
            margin: 15px 0 0 -130px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            transition: 0.2s;
          }

          .btnpercent:hover{
            opacity: 0.8;
            transition: 0.2s;
          }

          .trashpercent{
            margin: 0 0 0 -30px;
            i{
              cursor: pointer;
              color: #CCC;
              transition: .1s;
            }
            i:hover{
              color: rgb(18, 19, 56);
              transition: .1s;
            }
          }

          .glass_board_body_tit{
            display: flex;
            align-items: center;
            margin: 10px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.8rem;
          }

          .glass_board_body_numinput{
            width: 80px;
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
            margin-left: 10px;
            margin-right: 10px;
          }

          .glass_board_body_input{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 16pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
          }

          .glass_board_body_textarea{
            margin-top: 10px;
            width: calc(100% - 46px);
            padding: 10px 20px;
            min-height: 120px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            outline: none;
          }

          .glass_board_body_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 20px;
            border: 1px solid #CCC;
            color: #CCC;
            width: auto;
            max-height: 30px !important;
            cursor: pointer;
            margin-right: 10px;
            padding: 5px 30px;
            border-radius: 10px;
            margin-left: 10px;
            font-weight: 400;
            transition: .2s;
          }
          .glass_board_body_btn:hover{
            color: #454545;
            border: 1px solid #454545;
            transition: .2s;
          }

          .glass_board_body_graf{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            outline: none;
            transition: 0.2s;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .inputs_of_graf{
              border: none;
              width: calc(100% - 40px);
              outline: none;
              font-size: 12pt;
              //height: ;
            }
            .btns{
              i{
                font-size: 16pt;
                margin-left: 10px;
                cursor: pointer;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }

          }
          .glassslash{
            width: 100%;
            height: 1px;
            background-color: #CCC;
            margin-top: 10px;
          }
          .blueborder{
            border: 3px dotted rgb(18, 19, 56);
          }
          .glass_board_body_buttons{
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            .glass_board_body_buttons_create{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid #CCC;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }
            .glass_board_body_buttons_cancel{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid darkred;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }

            .glass_board_body_buttons_create:hover{
              color: #000;
              border: 1px solid #454545;
            }

            .glass_board_body_buttons_cancel:hover{
              color: red;
            }
          }
          .glass_board_body_selection{
            display: flex;

            .glass_pluss_btn{
              width: 150px;
              height: 36px;
              border: 1px solid #CCC;
              border-radius: 4px;
              margin-left: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #454545;
              background-color: #FFFFFF;
              cursor: pointer;
              transition: 0.2s;
            }
            .glass_pluss_btn:hover{
              color: #FFFFFF;
              background-color: #454545;
              border: 1px solid #CCC;
              transition: 0.2s;
            }
          }

          .glass_board_body_liststatements_man{
            display: flex;
            width: 100%;
            border: 1px solid #CCC;
            min-height: 40px;
            margin-top: -1px;
            font-size: 8px;
            flex-wrap: wrap;
            margin-bottom: 10px;
            div{
              //background-color: #F1F1F1;
              margin: 3px 3px 3px 3px;
              border: 1px solid #CCC;
            }
            .glass_board_body_liststatements_num{
              border-right: 1px solid #CCC;
              width: 20px;
              padding: 10px;
            }
            .glass_board_body_liststatements_fio{
              border-right: 1px solid #CCC;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_dev{
              border-right: 1px solid #CCC;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_view{
              border-right: 1px solid #CCC;
              min-width: 50px;
              width: 100px;
              padding: 10px;
              display: none;
            }
            .glass_board_body_liststatements_app{
              border-right: 1px solid #CCC;
              min-width: 50px;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_del{
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding: 10px;
              min-width: 50px;
              width: 250px;
              flex-wrap: wrap;
              div{
                margin-right: 10px;
                padding: 5px 10px;
                border-radius: 5px;
              }
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.1s;
              }
              i:hover{
                color: #CCC;
                transition: 0.1s;
              }

              .glass_board_body_liststatements_del_eye{}
              .glass_board_body_liststatements_del_del{}
              .glass_board_body_liststatements_del_yes{
                border: 1px solid green;
              }
              .glass_board_body_liststatements_del_no{
                border: 1px solid darkred;
              }
              .glass_board_body_liststatements_del_wait{
                border: 1px solid #CCC;
              }
              .glass_board_body_liststatements_del_pass{
                border: 1px solid rgb(18, 19, 56);
                background-color: #FFFFFF;
                cursor: pointer;
                transition: .2s;
              }
              .glass_board_body_liststatements_del_pass:hover{
                background-color: rgb(18, 19, 56);
                color: #FFFFFF;
                transition: .2s;
              }
            }
            .bold{
              display: none;
            }
          }


          .glass_board_body_control{
            display: flex;
            justify-content: flex-start;
            padding: 10px;
            .glass_board_body_control_btn{
              border: 1px solid #CCC;
              border-radius: 5px;
              display: none;
              flex-direction: column;
              align-items: center;
              width: 150px;
              margin: 10px 10px 0 0;
              padding: 10px;
              cursor: pointer;
              background-color: #FFFFFF;
              transition: .2s;
            }
            .glass_board_body_control_btn:hover{
              background-color: #454545;
              color: #FFF;
              transition: .2s;
            }
          }

          .glass_board_body_documents{
            display: flex;
            align-items: flex-start;
            padding: 10px;
            .glass_board_body_documents_file{
              border: 1px solid #CCC;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 150px;
              margin: 10px 10px 0 0;
              padding: 10px;
              cursor: pointer;
              i{
                font-size: 22pt;
              }
            }
            .glass_board_body_documents_file:hover{
              box-shadow: 0 0 3px rgba(0, 128, 255, 0.5);
            }
            .glass_board_body_documents_file_name{
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-size: 10pt;
            }
          }

          .list_approve{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            font-size: 10pt;
            .list_approve_man{
              border: 1px solid #CCC;
              padding: 10px;
              width: 250px;
              border-radius: 5px;
              margin-right: 20px;
              position: relative;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              .list_approve_man_name{
                font-weight: 600;
              }
              .list_approve_man_dev{
                font-weight: 400;
              }
              .list_approve_man_status{
                margin-top: 5px;
                padding: 5px 10px;
                border: 1px solid #CCC;
                border-radius: 2px;
              }
              .list_approve_man_comment{
                margin-top: 5px;
                padding: 5px 10px;
                border: 1px solid #CCC;
                border-radius: 2px;
              }
              .list_approve_man_slash{
                position: absolute;
                height: 4px;
                top: 20px;
                left: 0px;
                width: 20px;
                background-color: #CCC;
                margin-left: -21px;
              }
            }
          }

          .glass_board_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_board_body_man_content{
              display: flex;
              .glass_board_body_man_photo{
                width: 60px;
                height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_board_body_man_params{
                margin-left: 20px;
                .glass_board_body_man_name{
                  font-weight: 700;
                }
                .glass_board_body_man_dev{
                  font-size: 11pt;
                }
                .glass_board_body_man_departament{
                  font-size: 11pt;
                }
              }
            }

            .glass_board_body_man_del{
              font-size: 16pt;
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.2s;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }
          }
          .glass_approve_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            flex-wrap: wrap;
            padding: 10px 20px;
            border: 3px solid #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_approve_body_man_content{
              display: flex;

              .glass_approve_body_man_photo{
                width: 60px;
                height: 60px;
                min-width: 60px;
                min-height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_approve_body_man_params{
                margin-left: 20px;
                .glass_approve_body_man_name{
                  font-weight: 700;
                  font-size: 0.8rem;
                }
                .glass_approve_body_man_dev{
                  font-size: 0.8rem;
                }
                .glass_approve_body_man_departament{
                  font-size: 0.8rem;
                }
                .glass_approve_body_man_comment{
                  font-size: 0.8rem;
                  padding: 10px;
                  border: 1px solid #CCC;
                  margin: 10px 10px 0 0;
                  border-radius: 2px;
                  max-width: 400px;
                }
              }
            }

            .glass_approve_body_man_status{
              display: flex;
              flex-wrap: wrap;
              width: 200px;
              margin-top: 10px;
              justify-content: center;
              div{
                margin: 0 10px 10px 0;
                padding: 5px 20px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                font-size: 0.8rem;
              }
              .glass_approve_body_man_status_no{
                border: 1px solid red;
              }
              .glass_approve_body_man_status_yes{
                border: 1px solid green;
              }
              .glass_approve_body_man_status_wait{
                border: 1px solid #CCC;
              }
              .glass_approve_body_man_status_pass{
                border: 1px solid darkblue;
                cursor: pointer;
                transition: .1s;
              }
              .glass_approve_body_man_status_pass:hover{
                transition: .1s;
                background-color: darkblue;
                color: #FFF;
              }
            }
          }

        }
      }

      .glass_board_create{
        width: 90%;
        height: 90%;
        background-color: rgba(255,255,255,1);
        border-radius: 2px;
        box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        .glass_board_close{
          width: calc(100% - 30px);
          padding: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          i{
            font-size: 20pt;
            color: #454545;
            cursor: pointer;
            transition: 0.05s;
          }
          i:hover{
            color: #CCC;
            transition: 0.05s;
          }
        }
        .glass_board_body{
          width: calc(100% - 80px);
          height: calc(100% - 60px - 60px);
          //border: 1px solid #454545;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          overflow-y: auto;
          overflow-x: hidden;
          .glass_board_body_title_rock{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 16pt;
            font-weight: 800;
            margin: 40px 0 10px;
            color: rgb(18, 19, 56);
            min-height: 50px;
          }

          @media screen and (max-width: 400px){
            .glass_board_body_title_rock{
              width: 100%;
              display: flex;
              justify-content: center;
              text-align: center;
              font-size: 12pt;
              font-weight: 800;
              margin: 40px 0 10px;
              color: rgb(18, 19, 56);
              min-height: 50px;
            }
          }
          .glass_board_step{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            margin-top: -60px;
          }
          .glass_board_step_next{
            width: 100%;
            max-width: 288px;
            border: 1px solid #CCC;
            min-height: 80px;
            height: 80px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #454545;
            cursor: pointer;
            background-color: #FFF;
            transition: 0.1s;
            border-radius: 5px;
          }
          .glass_board_body_docs{
            width: calc(100%);
            //min-height: 100px;
            //height: content-box;
            border: 1px solid #CCC;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            //overflow-x: hidden;
            //overflow-y: auto;
            .glass_board_body_docs_set_name{
              width: calc(100% - 90px);
              margin: 5px 0;
              .glass_board_body_docs_set_name_title{
                font-weight: 600;
              }
              .glass_board_body_docs_set_name_docname{
                font-size: 10pt;
                color: red;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .glass_board_body_docs_set_name_docname_this{
                  width: calc(100% - 15px);
                  color: rgb(18, 19, 56);
                  padding: 5px;
                  border: 1px solid rgb(18, 19, 56);
                  border-radius: 3px;
                  margin: 3px 0;
                  position: relative;
                  .del-this{
                    position: absolute;
                    right: 10px;
                    bottom: 1px;
                    width: 10px;
                    margin-right: 0px;
                    font-size: 14pt;
                  }
                }
              }
            }
            .glass_board_body_docs_set{
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              .glass_board_body_docs_set_name{
                width: calc(100% - 90px);
                .glass_board_body_docs_set_name_title{
                  font-weight: 600;
                }
                .glass_board_body_docs_set_name_docname{
                  font-size: 10pt;
                  color: red;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .glass_board_body_docs_set_name_docname_this{
                    width: calc(100% - 15px);
                    color: rgb(18, 19, 56);
                    padding: 5px;
                    border: 1px solid rgb(18, 19, 56);
                    border-radius: 3px;
                    margin: 3px 0;
                    position: relative;
                    .del-this{
                      position: absolute;
                      right: 10px;
                      bottom: 1px;
                      width: 10px;
                      margin-right: 0px;
                      font-size: 14pt;
                    }
                  }
                }
              }
              .glass_board_body_docs_set_btn{
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #CCC;
                i{
                  font-size: 20pt;
                  color: #CCC;
                  margin-left:-16px;
                }
              }
            }

          }
          .glass_board_step_next:hover{
            color: #FFF;
            background-color: #454545;
            transition: 0.1s;
          }
          .glass_board_btn{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: -20px;
            min-height: 80px;
            .glass_board_btn_left{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }
            .glass_board_btn_right{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }

            i{
              font-size: 20pt;
              color: #CCC;
            }
          }

          .btnpercent{
            position: absolute;
            width: 130px;
            height: 50px;
            margin: 15px 0 0 -130px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            transition: 0.2s;
          }

          .btnpercent:hover{
            opacity: 0.8;
            transition: 0.2s;
          }

          .trashpercent{
            margin: 0 0 0 -30px;
            i{
              cursor: pointer;
              color: #CCC;
              transition: .1s;
            }
            i:hover{
              color: rgb(18, 19, 56);
              transition: .1s;
            }
          }

          .glass_board_body_tit{
            display: flex;
            align-items: center;
            margin: 10px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.8rem;
          }

          .glass_board_body_numinput{
            width: 80px;
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
            margin-left: 10px;
            margin-right: 10px;
          }

          .glass_board_body_input{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 16pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
          }

          .glass_board_body_textarea{
            margin-top: 10px;
            width: calc(100% - 46px);
            padding: 10px 20px;
            min-height: 120px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            outline: none;
          }

          .glass_board_body_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 20px;
            border: 1px solid #CCC;
            color: #CCC;
            width: auto;
            max-height: 30px !important;
            cursor: pointer;
            margin-right: 10px;
            padding: 5px 30px;
            border-radius: 10px;
            margin-left: 10px;
            font-weight: 400;
            transition: .2s;
          }
          .glass_board_body_btn:hover{
            color: #454545;
            border: 1px solid #454545;
            transition: .2s;
          }

          .glass_board_body_graf{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            outline: none;
            transition: 0.2s;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .inputs_of_graf{
              border: none;
              width: calc(100% - 40px);
              outline: none;
              font-size: 12pt;
              //height: ;
            }
            .btns{
              i{
                font-size: 16pt;
                margin-left: 10px;
                cursor: pointer;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }

          }
          .glassslash{
            width: 100%;
            height: 1px;
            background-color: #CCC;
            margin-top: 10px;
          }
          .blueborder{
            border: 3px dotted rgb(18, 19, 56);
          }
          .glass_board_body_buttons{
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            .glass_board_body_buttons_create{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid #CCC;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }
            .glass_board_body_buttons_cancel{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid darkred;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }

            .glass_board_body_buttons_create:hover{
              color: #000;
              border: 1px solid #454545;
            }

            .glass_board_body_buttons_cancel:hover{
              color: red;
            }
          }
          .glass_board_body_selection{
            display: flex;

            .glass_pluss_btn{
              width: 150px;
              height: 36px;
              border: 1px solid #CCC;
              border-radius: 4px;
              margin-left: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #454545;
              background-color: #FFFFFF;
              cursor: pointer;
              transition: 0.2s;
            }
            .glass_pluss_btn:hover{
              color: #FFFFFF;
              background-color: #454545;
              border: 1px solid #CCC;
              transition: 0.2s;
            }
          }

          .glass_board_body_liststatements_man{
            display: flex;
            width: 100%;
            border: 1px solid #CCC;
            min-height: 40px;
            margin-top: -1px;
            font-size: 8px;
            flex-wrap: wrap;

            div{
              background-color: #F1F1F1;
            }
            .glass_board_body_liststatements_num{
              border-right: 1px solid #CCC;
              width: 20px;
              padding: 10px;
            }
            .glass_board_body_liststatements_fio{
              border-right: 1px solid #CCC;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_dev{
              border-right: 1px solid #CCC;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_view{
              border-right: 1px solid #CCC;
              min-width: 50px;
              width: 100px;
              padding: 10px;
              display: none;
            }
            .glass_board_body_liststatements_app{
              border-right: 1px solid #CCC;
              min-width: 50px;
              width: 100px;
              padding: 10px;
            }
            .glass_board_body_liststatements_del{
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding: 10px;
              min-width: 50px;
              width: 250px;
              flex-wrap: wrap;
              div{
                margin-right: 10px;
                padding: 5px 10px;
                border-radius: 5px;
              }
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.1s;
              }
              i:hover{
                color: #CCC;
                transition: 0.1s;
              }

              .glass_board_body_liststatements_del_eye{}
              .glass_board_body_liststatements_del_del{}
              .glass_board_body_liststatements_del_yes{
                border: 1px solid green;
              }
              .glass_board_body_liststatements_del_no{
                border: 1px solid darkred;
              }
              .glass_board_body_liststatements_del_wait{
                border: 1px solid #CCC;
              }
              .glass_board_body_liststatements_del_pass{
                border: 1px solid rgb(18, 19, 56);
                background-color: #FFFFFF;
                cursor: pointer;
                transition: .2s;
              }
              .glass_board_body_liststatements_del_pass:hover{
                background-color: rgb(18, 19, 56);
                color: #FFFFFF;
                transition: .2s;
              }
            }
          }


          .glass_board_body_control{
            display: flex;
            justify-content: flex-start;
            padding: 10px;
            .glass_board_body_control_btn{
              border: 1px solid #CCC;
              border-radius: 5px;
              display: none;
              flex-direction: column;
              align-items: center;
              width: 150px;
              margin: 10px 10px 0 0;
              padding: 10px;
              cursor: pointer;
              background-color: #FFFFFF;
              transition: .2s;
            }
            .glass_board_body_control_btn:hover{
              background-color: #454545;
              color: #FFF;
              transition: .2s;
            }
          }

          .glass_board_body_documents{
            display: flex;
            align-items: flex-start;
            padding: 10px;
            .glass_board_body_documents_file{
              border: 1px solid #CCC;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 150px;
              margin: 10px 10px 0 0;
              padding: 10px;
              cursor: pointer;
              i{
                font-size: 22pt;
              }
            }
            .glass_board_body_documents_file:hover{
              box-shadow: 0 0 3px rgba(0, 128, 255, 0.5);
            }
            .glass_board_body_documents_file_name{
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-size: 10pt;
            }
          }

          .list_approve{
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            font-size: 10pt;
            .list_approve_man{
              border: 1px solid #CCC;
              padding: 10px;
              width: 250px;
              border-radius: 5px;
              margin-right: 20px;
              position: relative;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              .list_approve_man_name{
                font-weight: 600;
              }
              .list_approve_man_dev{
                font-weight: 400;
              }
              .list_approve_man_status{
                margin-top: 5px;
                padding: 5px 10px;
                border: 1px solid #CCC;
                border-radius: 2px;
              }
              .list_approve_man_comment{
                margin-top: 5px;
                padding: 5px 10px;
                border: 1px solid #CCC;
                border-radius: 2px;
              }
              .list_approve_man_slash{
                position: absolute;
                height: 4px;
                top: 20px;
                left: 0px;
                width: 20px;
                background-color: #CCC;
                margin-left: -21px;
              }
            }
          }

          .glass_board_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_board_body_man_content{
              display: flex;
              .glass_board_body_man_photo{
                width: 60px;
                height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_board_body_man_params{
                margin-left: 20px;
                .glass_board_body_man_name{
                  font-weight: 700;
                }
                .glass_board_body_man_dev{
                  font-size: 11pt;
                }
                .glass_board_body_man_departament{
                  font-size: 11pt;
                }
              }
            }

            .glass_board_body_man_del{
              font-size: 16pt;
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.2s;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }
          }
          .glass_approve_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px solid #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_approve_body_man_content{
              display: flex;
              .glass_approve_body_man_photo{
                width: 60px;
                height: 60px;
                min-width: 60px;
                min-height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_approve_body_man_params{
                margin-left: 20px;
                .glass_approve_body_man_name{
                  font-weight: 700;
                }
                .glass_approve_body_man_dev{
                  font-size: 11pt;
                }
                .glass_approve_body_man_departament{
                  font-size: 11pt;
                }
                .glass_approve_body_man_comment{
                  font-size: 11pt;
                  padding: 10px;
                  border: 1px solid #CCC;
                  margin: 10px 10px 0 0;
                  border-radius: 2px;
                  max-width: 400px;
                }
              }
            }

            .glass_approve_body_man_status{
              display: flex;
              flex-wrap: wrap;
              width: 200px;
              div{
                margin: 0 10px 10px 0;
                padding: 5px 20px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
              }
              .glass_approve_body_man_status_no{
                border: 1px solid red;
              }
              .glass_approve_body_man_status_yes{
                border: 1px solid green;
              }
              .glass_approve_body_man_status_wait{
                border: 1px solid #CCC;
              }
              .glass_approve_body_man_status_pass{
                border: 1px solid darkblue;
                cursor: pointer;
                transition: .1s;
              }
              .glass_approve_body_man_status_pass:hover{
                transition: .1s;
                background-color: darkblue;
                color: #FFF;
              }
            }
          }

        }
      }
    }
    .glasser{
      position: absolute !important;
      backdrop-filter: blur(5px) !important;
      top: 0px !important;
      left: 0px !important;
      width: 100% !important;
      height: 100% !important;
      background-color: rgba(200,200,200,0.6) !important;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;

      .glass_board{
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,1);
        border-radius: 2px;
        box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        .glass_board_close{
          width: calc(100% - 30px);
          padding: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          i{
            font-size: 20pt;
            color: #454545;
            cursor: pointer;
            transition: 0.05s;
          }
          i:hover{
            color: #CCC;
            transition: 0.05s;
          }
        }
        .glass_board_body{
          width: calc(100% - 80px);
          height: calc(100% - 60px - 60px);
          //border: 1px solid #454545;
          padding: 20px;
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          .glass_board_body_title_rock{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 16pt;
            font-weight: 800;
            margin: 40px 0 10px;
            color: rgb(18, 19, 56);
            min-height: 50px;
          }

          @media screen and (max-width: 400px){
            .glass_board_body_title_rock{
              width: 100%;
              display: flex;
              justify-content: center;
              text-align: center;
              font-size: 12pt;
              font-weight: 800;
              margin: 40px 0 10px;
              color: rgb(18, 19, 56);
              min-height: 50px;
            }
          }
          .glass_board_step{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            margin-top: -60px;
          }
          .glass_board_step_next{
            width: 100%;
            max-width: 288px;
            border: 1px solid #CCC;
            min-height: 80px;
            height: 80px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #454545;
            cursor: pointer;
            background-color: #FFF;
            transition: 0.1s;
            border-radius: 5px;
          }
          .glass_board_body_docs{
            width: calc(100%);
            //min-height: 100px;
            //height: content-box;
            border: 1px solid #CCC;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            //overflow-x: hidden;
            //overflow-y: auto;
            .glass_board_body_docs_set_name{
              width: calc(100% - 90px);
              margin: 5px 0;
              .glass_board_body_docs_set_name_title{
                font-weight: 600;
              }
              .glass_board_body_docs_set_name_docname{
                font-size: 10pt;
                color: red;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .glass_board_body_docs_set_name_docname_this{
                  width: calc(100% - 15px);
                  color: rgb(18, 19, 56);
                  padding: 5px;
                  border: 1px solid rgb(18, 19, 56);
                  border-radius: 3px;
                  margin: 3px 0;
                  position: relative;
                  .del-this{
                    position: absolute;
                    right: 10px;
                    bottom: 1px;
                    width: 10px;
                    margin-right: 0px;
                    font-size: 14pt;
                  }
                }
              }
            }
            .glass_board_body_docs_set{
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              .glass_board_body_docs_set_name{
                width: calc(100% - 90px);
                .glass_board_body_docs_set_name_title{
                  font-weight: 600;
                }
                .glass_board_body_docs_set_name_docname{
                  font-size: 10pt;
                  color: red;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .glass_board_body_docs_set_name_docname_this{
                    width: calc(100% - 15px);
                    color: rgb(18, 19, 56);
                    padding: 5px;
                    border: 1px solid rgb(18, 19, 56);
                    border-radius: 3px;
                    margin: 3px 0;
                    position: relative;
                    .del-this{
                      position: absolute;
                      right: 10px;
                      bottom: 1px;
                      width: 10px;
                      margin-right: 0px;
                      font-size: 14pt;
                    }
                  }
                }
              }
              .glass_board_body_docs_set_btn{
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #CCC;
                i{
                  font-size: 20pt;
                  color: #CCC;
                  margin-left:-16px;
                }
              }
            }

          }
          .glass_board_step_next:hover{
            color: #FFF;
            background-color: #454545;
            transition: 0.1s;
          }
          .glass_board_btn{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: -20px;
            min-height: 80px;
            .glass_board_btn_left{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }
            .glass_board_btn_right{
              div{
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 2px solid #CCC;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }
            }

            i{
              font-size: 20pt;
              color: #CCC;
            }
          }


          .btnpercent{
            position: absolute;
            width: 130px;
            height: 50px;
            margin: 15px 0 0 -130px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
            transition: 0.2s;
          }

          .btnpercent:hover{
            opacity: 0.8;
            transition: 0.2s;
          }

          .trashpercent{
            margin: 0 0 0 -30px;
            i{
              cursor: pointer;
              color: #CCC;
              transition: .1s;
            }
            i:hover{
              color: rgb(18, 19, 56);
              transition: .1s;
            }
          }

          .glass_board_body_tit{
            display: flex;
            align-items: center;
            margin: 10px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.8rem;
          }

          .glass_board_body_numinput{
            width: 80px;
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
            margin-left: 10px;
            margin-right: 10px;
          }

          .glass_board_body_input{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 16pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            text-align: center;
            outline: none;
            transition: 0.2s;
          }

          .glass_board_body_textarea{
            margin-top: 10px;
            width: calc(100% - 46px);
            padding: 10px 20px;
            min-height: 120px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            outline: none;
          }

          .glass_board_body_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 20px;
            border: 1px solid #CCC;
            color: #CCC;
            width: auto;
            max-height: 30px !important;
            cursor: pointer;
            margin-right: 10px;
            padding: 5px 30px;
            border-radius: 10px;
            margin-left: 10px;
            font-weight: 400;
            transition: .2s;
          }
          .glass_board_body_btn:hover{
            color: #454545;
            border: 1px solid #454545;
            transition: .2s;
          }

          .glass_board_body_graf{
            width: calc(100% - 26px);
            padding: 10px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            font-size: 12pt;
            font-weight: 600;
            color: rgb(18, 19, 56);
            outline: none;
            transition: 0.2s;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .inputs_of_graf{
              border: none;
              width: calc(100% - 40px);
              outline: none;
              font-size: 12pt;
              //height: ;
            }
            .btns{
              i{
                font-size: 16pt;
                margin-left: 10px;
                cursor: pointer;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }

          }
          .glassslash{
            width: 100%;
            height: 1px;
            background-color: #CCC;
            margin-top: 10px;
          }
          .blueborder{
            border: 3px dotted rgb(18, 19, 56);
          }
          .glass_board_body_buttons{
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            .glass_board_body_buttons_create{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid #CCC;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }
            .glass_board_body_buttons_cancel{
              margin-right: 20px;
              padding: 20px 20px;
              border: 1px solid darkred;
              border-radius: 4px;
              cursor: pointer;
              color: #454545;
              transition: 0.2s;
            }

            .glass_board_body_buttons_create:hover{
              color: #000;
              border: 1px solid #454545;
            }

            .glass_board_body_buttons_cancel:hover{
              color: red;
            }
          }
          .glass_board_body_selection{
            display: flex;

            .glass_pluss_btn{
              width: 150px;
              height: 36px;
              border: 1px solid #CCC;
              border-radius: 4px;
              margin-left: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #454545;
              background-color: #FFFFFF;
              cursor: pointer;
              transition: 0.2s;
            }
            .glass_pluss_btn:hover{
              color: #FFFFFF;
              background-color: #454545;
              border: 1px solid #CCC;
              transition: 0.2s;
            }
          }

          .glass_board_body_man{
            display: flex;
            justify-content: space-between;
            max-width: content-box;
            padding: 10px 20px;
            border: 3px dotted #CCC;
            border-radius: 10px;
            margin-top: 10px;
            .glass_board_body_man_content{
              display: flex;
              .glass_board_body_man_photo{
                width: 60px;
                height: 60px;
                background-size: cover;
                background-position: center center;
                border-radius: 60px;
              }
              .glass_board_body_man_params{
                margin-left: 20px;
                .glass_board_body_man_name{
                  font-weight: 700;
                }
                .glass_board_body_man_dev{
                  font-size: 11pt;
                }
                .glass_board_body_man_departament{
                  font-size: 11pt;
                }
              }
            }

            .glass_board_body_man_del{
              font-size: 16pt;
              i{
                color: #454545;
                cursor: pointer;
                transition: 0.2s;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }
          }

        }
      }
    }
  }
}

.glass_board_body_docs_set_btn{
  cursor: pointer;
  position: relative;
}
.glass_board_body_docs_set_btn:hover{
  background-color: rgba(1,1,1,0.1);
}
.glass_board_btn{
  z-index: 1000;
}
.glass_board_btn_left div,.glass_board_btn_right div{
  cursor: pointer !important;
}
.glass_board_btn_left div:hover,.glass_board_btn_right div:hover{
  background-color: rgba(1,1,1,0.1) !important;
}


.soclist_title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  a{
    margin-right: 20px;
    color: #999;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    i{
      margin-top: 3px;
      margin-left: 5px;
    }
  }
  a:hover{
    color: black;
  }
}
.statelist_list_line{
  min-height: 46px;
  max-width: 1060px;
  display: flex;
  justify-content: space-between !important;
}
.statelist_list_line_del{
  display: flex;
  justify-content: space-between;
  i{
    font-size: 1.3rem;
    color: rgba(18, 19, 56, 0.6);
  }
}