.controller{
  .header{
    display: flex;
    justify-content: space-between;
    .back{
      background-color: rgb(18, 19, 56);
      color: white;
      padding: 10px 16px 10px 10px;
      width: 150px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.7rem;
      cursor: pointer;
      i{
        font-size: 0.9rem;
        margin: 0 10px;
      }
    }
  }

  .title{
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: underline;
    margin: 15px 0;
  }
  .menu{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.crew{
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0 20px 20px;
}
.oldering{
  font-size: 0.8rem;
  color: #aaaaaa;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  margin-top: -20px;
  margin-left: 20px;
  margin-bottom: 20px;
  &:hover{
    color: #777;
  }
}
.filesbtn{
  display: flex;
  .button{
    margin-bottom: 15px;
    background-color: rgb(18, 19, 56);
    color: white;
    padding: 10px 16px 10px 10px;
    width: 150px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.7rem;
    cursor: pointer;
  }
}
.lost{
  text-align: center;
}