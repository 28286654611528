.tabel{
    margin: 20px;
}
.selects{
    width: 250px;
}
.check{
    display: inline-block;
    margin: 10px 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #aaaaaa;
    cursor: pointer;
}
.check:hover{
    color: black;
}

@media screen and (min-width: 600px){
    .tabwel_header{
        display: flex;
        flex-direction: column;
        width: calc(100% - 40px);

        .tabwelding_header_upper{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            .tabwelding_header_upper_backbtn{
                width: 236px;
                height: 40px;
                background: #D9D9D9;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .tabwelding_header_upper_title{
                span{
                    margin-right: 20px;
                    margin-left: 20px;
                }
            }
            .tabwelding_header_upper_controlbtn{
                width: 236px;
                height: 40px;
                margin: 5px;
                background: rgba(18,19,56,1);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
                cursor: pointer;
            }
        }
        .tabwelding_header_newcrewblock{
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;
            .tabwelding_header_newcrewblock_select{
                width: 236px;
                height: 40px;
                border: 1px solid #000


            }
            .tabwelding_header_newcrewblock_plusbtn{
                width: 236px;
                height: 36px;
                background: #617676;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
                cursor: pointer;
            }
            .tabwelding_header_newcrewblock_plusbtn:hover{
                opacity: 0.9;
            }
            .back-button{
                height: 18px;

            }
        }
    }
}
@media screen and (max-width: 600px){
    .tabwel_header{
        display: flex;
        flex-direction: column;
        width: calc(100%);

        .tabwelding_header_upper{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            .tabwelding_header_upper_backbtn{
                width: 100%;
                height: 40px;
                background: #D9D9D9;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .tabwelding_header_upper_title{
                span{
                    margin-right: 20px;
                    margin-left: 20px;
                }
            }
            .tabwelding_header_upper_controlbtn{
                width: 100%;
                height: 40px;
                background: #E49D4B;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
                cursor: pointer;
                margin-bottom: 5px;
            }
        }
        .tabwelding_header_newcrewblock{
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;
            .tabwelding_header_newcrewblock_select{
                width: 236px;
                height: 40px;
                border: 1px solid #000


            }
            .tabwelding_header_newcrewblock_plusbtn{
                width: 236px;
                height: 36px;
                background: #617676;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
                cursor: pointer;
            }
            .tabwelding_header_newcrewblock_plusbtn:hover{
                opacity: 0.9;
            }
            .back-button{
                height: 18px;

            }
        }
    }
}

.tab_tabel{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .tab_tabel_upper{
        width: calc(236px * 2 + 20px);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .tab_tabel_upper_title{
            font-weight: 600;
        }
        .tab_tabel_upper_plus{
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 236px;
            height: 40px;
            background: #617676;
            color: #FFF;
        }

    }


    .tab_tabel_tabelman{
        width: 100%;
        height:100vh;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .tab_tabel_tabelman_strock{
            display: flex;
            justify-content: flex-start;
            width: 100%;
            height: 108px;
            margin-top: 1px;

            .tab_tabel_tabelman_strock_num{
                width: 40px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                font-size: 10pt;
                border-top: 1px solid #454545;
                border-right: 0px;
                border-left: 1px solid #454545;
                border-bottom: 1px solid #454545;
                i{
                    color: rgb(18, 19, 56);
                    cursor: pointer;
                }
                i:hover{
                    color: rgba(18, 19, 56, 0.8);
                }
            }
            .tab_tabel_tabelman_strock_fio{
                width: 200px;
                height: 100%;
                border-top: 1px solid #454545;
                border-right: 0px;
                border-left: 1px solid #454545;
                border-bottom: 1px solid #454545;
                font-size: 10pt;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 0 5px;

                .tab_tabel_tabelman_strock_fio_name{
                    margin-bottom: 5px;
                    font-weight: 600;
                }
                .tab_tabel_tabelman_strock_fio_dev{

                }
            }
            .tab_tabel_tabelman_strock_calendar{
                width: 550px;
                height: 108px;
                display: flex;
                justify-content: flex-start;
                //align-items: flex-start;
                font-size: 10pt;
                border-top: 1px solid #454545;
                border-right: 0px;
                border-left: 1px solid #454545;
                border-bottom: 1px solid #454545;
                .tab_tabel_tabelman_strock_calendar_s{
                    //width: 100%;
                    //height: 108px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    span{
                        font-size: 8pt;
                    }
                    .tab_tabel_tabelman_strock_calendar_column{
                        width: 100%;
                        height: 108px;
                    }
                    //align-items: center;
                    .tab_tabel_tabelman_strock_calendar_column_day {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        width: calc(550px / 16);
                        height: 54px;
                        .tab_tabel_tabelman_s_c_c_day_title{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: calc(100%);
                            height: 14px;
                            border-top: 0px;
                            border-right: 1px solid #454545;
                            border-left: 0px;
                            border-bottom: 1px solid #454545;
                        }
                        .tab_tabel_tabelman_s_c_c_day_content{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: calc(100% + 1px);
                            height: calc((100% - 14px));
                            border-top: 0px;
                            border-right: 1px solid #454545;
                            border-left: 1px solid #454545;
                            border-bottom: 0px;
                            text-align: center;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            -o-appearance: none;
                            appearance: none;
                            margin-left: 0px;
                        }

                        .tab_tabel_tabelman_s_c_c_day_content:focus{
                            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
                        }
                        .tab_tabel_tabelman_s_c_c_day_content:active{
                            cursor: grabbing;
                        }
                        .no-left-border{
                            border-left: 0px;
                            width: calc(100%);
                        }

                        .no-left-border_sel{
                            border-left: 0px;
                            //border-right: 1px solid #454545;
                        }

                        .top-border-1px{
                            border-top: 1px solid #454545;
                            margin-top: -1px;
                        }
                        .tab_tabel_tabelman_s_c_c_day_content:focus{
                            outline: none;
                            outline: 0;
                            outline-offset: 0;
                        }
                        .border-b{
                            border-bottom: 1px solid #454545;
                        }
                    }
                }

            }


            .tab_tabel_tabelman_strock_dop{
                width: calc(550px / 12);
                height: 108px;
                display: flex;
                justify-content: flex-start;
                //align-items: flex-start;
                font-size: 10pt;
                border-top: 1px solid #454545;
                border-right: 0px;
                border-left: 0px solid #454545;
                border-bottom: 1px solid #454545;
                .tab_tabel_tabelman_strock_calendar_s{
                    //width: 100%;
                    //height: 108px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    span{
                        font-size: 8pt;
                    }
                    .tab_tabel_tabelman_strock_calendar_column{
                        width: 100%;
                        height: 108px;
                    }
                    //align-items: center;
                    .tab_tabel_tabelman_strock_calendar_column_day {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        width: calc(550px / 12);
                        height: 54px;
                        .tab_tabel_tabelman_s_c_c_day_title{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: calc(100%);
                            height: 14px;
                            border-top: 0px;
                            border-right: 1px solid #454545;
                            border-left: 0px;
                            border-bottom: 1px solid #454545;
                        }
                        .tab_tabel_tabelman_s_c_c_day_content{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: calc((100% - 14px));
                            border-top: 0px;
                            border-right: 1px solid #454545;
                            border-left: 1px solid #454545;
                            border-bottom: 0px;
                            text-align: center;
                            margin: 0 -1px -1px -1px;
                        }

                        .no-left-border{
                            border-left: 0px;
                            width: calc(100%);
                        }

                        .top-border-1px{
                            border-top: 1px solid #454545;
                            margin-top: -1px;
                        }
                        .tab_tabel_tabelman_s_c_c_day_content:focus{
                            outline: none;
                            outline: 0;
                            outline-offset: 0;
                        }
                        .border-b{
                            border-bottom: 1px solid #454545;
                        }
                    }
                }

            }

            .tab_tabel_tabelman_strock_shifrtransport{
                width: calc(550px / 4);
                height: 108px;
                display: flex;
                justify-content: flex-start;
                //align-items: flex-start;
                font-size: 10pt;
                border-top: 1px solid #454545;
                border-right: 0px;
                border-left: 0px solid #454545;
                border-bottom: 1px solid #454545;
                .tab_tabel_tabelman_strock_calendar_s{
                    //width: 100%;
                    //height: 108px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    span{
                        font-size: 8pt;
                    }

                    .tab_tabel_tabelman_strock_calendar_column{
                        width: 100%;
                        height: 108px;
                    }
                    //align-items: center;
                    .tab_tabel_tabelman_strock_calendar_column_day {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        width: calc(550px / 4);
                        height: 54px;
                        .tab_tabel_tabelman_s_c_c_day_title{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: calc(100%);
                            height: 14px;
                            border-top: 0px;
                            border-right: 1px solid #454545;
                            border-left: 0px;
                            border-bottom: 1px solid #454545;
                        }
                        .tab_tabel_tabelman_s_c_c_day_content{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: calc(100% - 1px);
                            height: calc((100% - 14px));
                            border-top: 0px;
                            border-right: 1px solid #454545;
                            border-left: 0px solid #454545;
                            border-bottom: 0px;
                            text-align: center;
                            margin: 0 -1px -1px 1px;
                        }

                        .no-left-border{
                            border-left: 0px;
                            width: calc(100%);
                        }

                        .top-border-1px{
                            border-top: 1px solid #454545;
                            margin-top: -1px;
                        }
                        .tab_tabel_tabelman_s_c_c_day_content:focus{
                            outline: none;
                            outline: 0;
                            outline-offset: 0;
                        }
                        .border-b{
                            border-bottom: 1px solid #454545;
                        }
                    }
                }

            }

            .tab_tabel_tabelman_strock_itogy{
                width: 60px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 10pt;
                border-top: 1px solid #454545;
                border-right: 1px solid #454545;
                border-left: 1px solid #454545;
                border-bottom: 1px solid #454545;

                .tab_tabel_tabelman_strock_itogy-header{
                    width: 100%;
                    height: 14px;
                    border-bottom: 1px solid #454545;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .tab_tabel_tabelman_strock_itogy-sum{
                    width: 100%;
                    height: calc(100% - 14px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .tab_tabel_tabelman_strock_comment{
                width: calc(550px / 2.5 - 10px);
                height: 108px;
                display: flex;
                justify-content: flex-start;
                //align-items: flex-start;
                border-top: 1px solid #454545;
                border-right: 1px solid #454545;
                border-left: 0px solid #454545;
                border-bottom: 1px solid #454545;
                font-size: 8pt;
                padding: 0 5px;
            }
        }

    }

}

.itogy_form{

    .title{}
    .filter{}
    .tbl{
        //width: calc(100vw - 300px);
        //height: calc(100vh - 220px);
        //overflow-y: auto;
        //overflow-x: auto;
        //white-space: nowrap;
        scrollbar-position: start;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        font-size: 8pt;

        .head{
            position: sticky;
            top: 0px;
        }
        table {
            border-collapse: collapse; /* Объединяет границы ячеек в одну линию */
            //width: 100%; /* Ширина таблицы */
            position: relative;
            .head{
                position: sticky;
                top: 60px;
                background-color: #FFFFFF;
            }
        }

        th, td {
            border: 1px solid #000; /* Граница 1px, черного цвета */
            padding: 8px; /* Отступ внутри ячейки */
        }
    }
}

.hiddentabel{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1,1,1,0.5);
    z-index: 10000000;
}
#hiddenfocus{
    position: fixed;
    top:0;
    left:0;
    width: 0;
    height: 0;
    overflow: hidden;
    outline: none;
}

.activeindex{
    z-index: 100000000;
    select{
        cursor: grabbing;
    }
}