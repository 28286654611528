.delblock{
  display: flex;
  justify-content: center;
  .delblock_center{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    justify-content: space-between;
  }
  .delblock_center_question{
    font-weight: 700;
    font-size: 16pt;
    text-align: center;
    margin-top: 30px;
  }
  .delblock_center_btns{
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .delblock_center_btns_del{
    background-color: rgba(215, 26, 0, 0.5);
    color: white;
    margin: 10px;
    padding: 10px 16px 10px 10px;
    width: 150px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
  }
  .delblock_center_btns_cancel{
    background-color: rgb(18, 19, 56);
    color: white;
    margin: 10px;
    padding: 10px 16px 10px 10px;
    width: 150px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
  }
}