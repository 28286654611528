//.css-1xc3v61-indicatorContainer{
//  display: none !important;
//}
//
//.css-1hb7zxy-IndicatorsContainer{
//  display: none !important;
//}

.modalka{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 10001;
  will-change: transform;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);

  .modalka_w{
    width: 95vw;
    height: 95vh;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .modalka_w_up{
      width: 95%;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      font-size: 20pt;
      cursor: pointer;
    }
    .list_comms{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      height: 100%;
      max-width: 1000px;
      overflow-y: auto;
      .newmess{
        display: flex;
        width: 100%;
        .form_comm{
          min-width: calc(70% - 30px);
          height: 40px;
          border: 1px solid #CCC;
          border-radius: 10px;
          padding: 0 10px;
        }
        .form_btn{
          margin-left: 15px;
          min-width: calc(30% - 15px);
          height: 40px;
          border: 1px solid #CCC;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          cursor: pointer;
        }
        .form_btn:hover{
          border: 1px solid #454545;
        }
      }
      .list_comms_com{
        position: relative;
        width: calc(100% - 50px);
        //min-height: 60px;
        height: content-box;
        border: 1px solid #CCC;
        border-radius: 10px;
        margin-top: 10px;
        padding: 20px;

        .list_comms_com_remove{
          position: absolute;
          top:calc(50% - 18px);
          right: 28px;
          i{
            cursor: pointer;
            font-size: 2rem;
            color: rgba(200,100,100,0.5);
          }
          i:hover{
            color: rgba(200,100,100,0.9);
          }
        }
        .list_comms_com_autor{
          margin-bottom: 10px;
          font-weight: 600;
        }
        .list_comms_com_comment{
          width: calc(100% - 50px);
          height: 100%;
          min-height: 40px;
        }
      }

    }

  }
}

.right-block-tabwelding{
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  min-height: 100%;
}

@media screen and (min-width: 1200px){
  .tabwelding_slice{
    width: calc(100% - 80px);
    height: 1px;
    background: #CCC;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1199px){
  .tabwelding_slice{
    max-width: 800px;
    height: 1px;
    background: #CCC;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 500px) {
  .tabwelding_header {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);

    .tabwelding_header_upper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;

      .tabwelding_header_upper_backbtn {
        width: 236px;
        height: 40px;
        background: #D9D9D9;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .tabwelding_header_upper_title {
        span {
          margin-right: 20px;
          margin-left: 20px;
        }
      }

      .tabwelding_header_upper_controlbtn {
        width: 236px;
        height: 40px;
        background: #E49D4B;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        cursor: pointer;
      }

      .back-button {
        height: 18px;

      }
    }

    .tabwelding_header_newcrewblock {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;

      .tabwelding_header_newcrewblock_select {
        width: 236px;
        height: 40px;
        border: 1px solid #000


      }

      .tabwelding_header_newcrewblock_plusbtn {
        width: 236px;
        height: 36px;
        background: #617676;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        cursor: pointer;
      }

      .tabwelding_header_newcrewblock_plusbtn:hover {
        opacity: 0.9;
      }

      @media screen and (min-width: 500px) {
        .back-button {
          height: 18px;

        }
      }
      @media screen and (max-width: 500px) {
        .back-button {
          height: 18px;
          width: calc(100% + 20px) !important;
        }
      }

    }
  }
}
@media screen and (max-width: 500px) {
  .tabwelding_header {
    display: flex;
    flex-direction: column;
    width: calc(100%);

    .tabwelding_header_upper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;

      .tabwelding_header_upper_title {
        span {
          margin-right: 20px;
          margin-left: 20px;
        }
      }

      .tabwelding_header_upper_controlbtn {
        width: 236px;
        height: 40px;
        background: #E49D4B;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        cursor: pointer;
      }

      .back-button {
        height: 18px;
        width: 100% !important;
      }
    }

    .tabwelding_header_newcrewblock {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;

      .tabwelding_header_newcrewblock_select {
        width: 236px;
        height: 40px;
        border: 1px solid #000


      }

      .tabwelding_header_newcrewblock_plusbtn {
        width: 236px;
        height: 36px;
        background: #617676;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        cursor: pointer;
      }

      .tabwelding_header_newcrewblock_plusbtn:hover {
        opacity: 0.9;
      }

      @media screen and (min-width: 500px) {
        .back-button {
          height: 18px;

        }
      }
      @media screen and (max-width: 500px) {
        .back-button {
          height: 18px;
          width: calc(100% + 20px) !important;
        }
      }

    }
  }
}

.tabwelding_crews {
  //z-index: 11 !important;
}
@media screen and (min-width: 1200px) {
  .tabwelding_crews {
    width: calc(100% - 0px);
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0px 0 10px 0;
    margin-top: -10px;
    margin-left: -1px;
    position: sticky;
    z-index: 1;
    top: 60px;
    background: rgb(236, 236, 236);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);

    .tabwelding_crews_block {
      margin: 10px 10px 0 0;
      width: 250px;
      height: 34px;
      border: 4px solid #CCC;
      background-color: #FFFFFF;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
    }

    .tabwelding_crews_block:hover {
      background: rgba(0,0,0,0.5);
      opacity: 0.9;
      color: #D9D9D9;
      transition: 0.2s;
    }

    .bgactive {
      background: rgba(0,0,0,0.5);
      color: #FFF;
    }

    .bgactive:hover {
      background: #CCC;
      color: #454545;
    }
  }
}

@media screen and (max-width: 1199px) {
  .tabwelding_crews {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0px 0 10px 0;
    margin-top: -10px;
    margin-left: -1px;
    position: sticky;
    z-index: 1;
    top: 60px;
    background: rgb(236, 236, 236);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);

    .tabwelding_crews_block {
      margin: 10px 10px 0 0;
      //width: 250px;
      //height: 34px;
      padding: 5px 15px;
      font-size: 10pt;
      border: 4px solid #CCC;
      background-color: #FFFFFF;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
    }

    .tabwelding_crews_block:hover {
      background: rgba(0,0,0,0.5);
      opacity: 0.9;
      color: #D9D9D9;
      transition: 0.2s;
    }

    .bgactive {
      background: rgba(0,0,0,0.5);
      color: #FFF;
    }

    .bgactive:hover {
      background: #CCC;
      color: #454545;
    }
  }
}


.tabelviewwork{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tabwelding_viewswork{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  min-height: 100%;
  order: 0;
  .tabwelding_viewswork_upper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .tabwelding_viewswork_upper_title{
      width: 236px;
      margin-right: 20px;
      font-weight: 600;
    }
    .tabwelding_viewswork_upper_plusbtn{
      width: 236px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      margin-right: 30px;
      background: #617676;
    }
    .tabwelding_viewswork_upper_date{
      font-weight: 600;
    }
  }
}




.tabwelding_tabel{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .tabwelding_tabel_upper{
    width: calc(236px * 2 + 20px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .tabwelding_tabel_upper_title{
      font-weight: 600;
    }
    .tabwelding_tabel_upper_plus{
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 236px;
      height: 40px;
      background: #617676;
      color: #FFF;
    }

  }


  .tabwelding_tabel_tabelman{
    width: 100%;
    //min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    //.tabwelding_tabel_tabelman_upper{
    //  display: flex;
    //  justify-content: flex-start;
    //  width: 100%;
    //  height: 20px;
    //  .tabwelding_tabel_tabelman_upper_num{
    //    width: 50px;
    //    height: 100%;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    font-size: 10pt;
    //    border-top: 1px solid #454545;
    //    border-right: 0px;
    //    border-left: 1px solid #454545;
    //    border-bottom: 1px solid #454545;
    //  }
    //  .tabwelding_tabel_tabelman_upper_fio{
    //    width: 250px;
    //    height: 100%;
    //    border-top: 1px solid #454545;
    //    border-right: 0px;
    //    border-left: 1px solid #454545;
    //    border-bottom: 1px solid #454545;
    //  }
    //  .tabwelding_tabel_tabelman_upper_title{
    //    width: 700px;
    //    height: 100%;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    font-size: 10pt;
    //    border-top: 1px solid #454545;
    //    border-right: 0px;
    //    border-left: 1px solid #454545;
    //    border-bottom: 1px solid #454545;
    //  }
    //  .tabwelding_tabel_tabelman_upper_itogy{
    //    width: 120px;
    //    height: 100%;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    font-size: 10pt;
    //    border-top: 1px solid #454545;
    //    border-right: 1px solid #454545;
    //    border-left: 1px solid #454545;
    //    border-bottom: 1px solid #454545;
    //  }
    //}


    .tabwelding_tabel_tabelman_strock{
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 108px;
      margin-top: 1px;

      .tabwelding_tabel_tabelman_strock_num{
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 10pt;
        border-top: 1px solid #454545;
        border-right: 0px;
        border-left: 1px solid #454545;
        border-bottom: 1px solid #454545;
        flex-direction: column;
        i{
          cursor: pointer;
        }
      }
      .tabwelding_tabel_tabelman_strock_fio{
        width: 200px;
        height: 100%;
        border-top: 1px solid #454545;
        border-right: 0px;
        border-left: 1px solid #454545;
        border-bottom: 1px solid #454545;
        font-size: 10pt;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 5px;
      }
      .tabwelding_tabel_tabelman_strock_calendar{
        width: 550px;
        height: 108px;
        display: flex;
        justify-content: flex-start;
        font-size: 10pt;
        border-top: 1px solid #454545;
        border-right: 0px;
        border-left: 1px solid #454545;
        border-bottom: 1px solid #454545;
        .tabwelding_tabel_tabelman_strock_calendar_s{
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .tabwelding_tabel_tabelman_strock_calendar_column{
            width: 100%;
            height: 108px;
          }
          //align-items: center;
          .tabwelding_tabel_tabelman_strock_calendar_column_day {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: calc(550px / 16);
            height: 54px;
            .tabwelding_tabel_tabelman_s_c_c_day_title{
              display: flex;
              justify-content: center;
              align-items: center;
              width: calc(100%);
              height: 14px;
              border-top: 0px;
              border-right: 1px solid #454545;
              border-left: 0px;
              border-bottom: 1px solid #454545;
            }
            .tabwelding_tabel_tabelman_s_c_c_day_content{
              display: flex;
              justify-content: center;
              align-items: center;
              width: calc(100% - 1px);
              height: calc((100% - 14px));
              border-top: 0px;
              border-right: 1px solid #454545;
              border-left: 1px solid #454545;
              border-bottom: 0px;
              text-align: center;

            }

            .no-left-border{
              border-left: 0px;
              width: calc(100%);
            }

            .top-border-1px{
              border-top: 1px solid #454545;
              margin-top: -1px;
            }
            .tabwelding_tabel_tabelman_s_c_c_day_content:hover, active, focus{
              outline: none;
            }
            .border-b{
              border-bottom: 1px solid #454545;
            }
          }
        }

      }
      .tabwelding_tabel_tabelman_strock_itogy{
        width: 120px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 10pt;
        border-top: 1px solid #454545;
        border-right: 1px solid #454545;
        border-left: 1px solid #454545;
        border-bottom: 1px solid #454545;

        .tabwelding_tabel_tabelman_strock_itogy-header{
          width: 100%;
          height: 15px;
          border-bottom: 1px solid #454545;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .tabwelding_tabel_tabelman_strock_itogy-sum{
          width: 100%;
          height: calc(100% - 15px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

  }

}
.delete{
  .buttons{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.none-dis{
  display: none;
}



.days_comm{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-content: flex-start;
  //margin-left: 180px;
  .days_comm_day{
    width: 30px;
    min-width: 30px;
    height: 20px;
    padding: 5px;
    border: 1px solid #CCC;
    cursor: pointer;
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .days_comm_day:hover{
    background-color: #CCC;
  }
  .full-comment{
    background-color: rgba(1,1,1,0.1);
    color: #333;
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.buttons-modal{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px;
  .button{
    background-color: rgb(18, 19, 56);
    color: white;
    margin: 10px;
    padding: 10px 16px 10px 10px;
    width: 150px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
  }
  .da{
    background-color: rgb(180, 19, 56);
  }
}

.works-text{
  word-break: break-all;
}