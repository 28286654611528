.aboutus{
  margin-left: 250px;
  max-width: calc(100% - 250px);

  .title {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 600;
    margin: 30px 0;
    color: #333;
  }
  .weare{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 20px auto;
    .und{
      width: 50%;
      min-width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      .we{
        width: 50%;
        p{

        }
      }
    }
  }
  .abouttext{
    display: flex;
    padding: 20px 40px;
    width: calc(100% - 80px);
    text-align: left;
    .text{
      font-size: 0.9rem;
      width: 70%;
      p{
        margin: 30px 0;
      }
      .punkts{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
        min-width: 350px;
        .ele{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 24%;
          min-width: 150px;
          i{
            background-color: rgba(1,37,52);
            margin: 10px;
            font-size: 1.8rem;
            color:#FFFFFF;
            border-radius: 50px;
            min-width: 50px;
            max-width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
          }
          h5{
            span{
              display: block;
              font-size:2rem;
            }
          }
        }
      }
    }
    .izo{
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}
.timeline {
  .subtitle {
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #666;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  text-align: center;
  .timelineContainer {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 10px;
  }

  .timelineItem {
    position: relative;
    flex: 0 0 280px;
    text-align: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: grab;
    &.odd {
      .date{
        order: 0;
        margin-bottom: 10px;
      }
      .iconContainer {
        order: 1;
      }
      .linecontainer{
        order: 2;
      }
      .center{
        order: 3;
      }
      .content {
        order: 4;
      }

    }
    &.even {
      .date{
        order: 4;
        margin-top: 10px;
      }
      .iconContainer {
        order: 3;
      }
      .linecontainer{
        order: 2;
      }
      .center{
        order: 1;
      }
      .content {
        order: 0;
        display: flex;
        flex-direction: column;
        //align-items: flex-end; //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        //justify-content: flex-end;
      }
    }
    .date {
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      -webkit-user-select: none; /* для старых версий браузеров */
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .linecontainer{
      height: 135px;
      display: flex;
      justify-content: center;
      align-items: center;
      .line{
        height: 100%;
        width: 8px;
      }
    }
    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      color: white;
      font-size: 3rem;
    }

    .content {
      min-height: 250px;
      max-height: 250px;
      padding: 15px;
      position: relative;
      z-index: 1;
      -webkit-user-select: none; /* для старых версий браузеров */
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .itemTitle {
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .description {
      font-size: 0.78rem;
      color: #666;
      //text-align: left;
      word-spacing:6px;
    }
  }
  .center{
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .circle{
      width: 23px;
      height: 23px;
      border-radius: 23px;
      background-color: #FFFFFF;
    }
  }
  .fadein {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.2s ease-out, transform 0.8s ease-out;
  }

  /* Стиль для элементов, когда они появляются */
  .fadein.show {
    opacity: 1;
    transform: translateY(0);
  }

}
.color1{
  .center{
    background-color: rgba(1,37,52);
  }
  .itemTitle {
    color: rgba(1,37,52);
  }
  .line{
    background-color: rgba(1,37,52);
  }
  .iconContainer {
    background: rgba(1,37,52);
  }
  .date {
    color:rgba(1,37,52);
  }
}
.color2{
  .center{
    background-color: rgba(0,56,80);
  }
  .itemTitle {
    color:rgba(0,56,80);
  }
  .line{
    background-color: rgba(0,56,80);
  }
  .iconContainer {
    background: rgba(0,56,80);
  }
  .date {
    color:rgba(0,56,80);
  }
}
.color3{
  .center{
    background-color:rgba(65,100,130);
  }
  .itemTitle {
    color:rgba(65,100,130);
  }
  .line{
    background-color:rgba(65,100,130);
  }
  .iconContainer {
    background:rgba(65,100,130);
  }
  .date {
    color:rgba(65,100,130);
  }
}
.color4{
  .center{
    background-color: rgba(95,134,159);
  }
  .itemTitle {
    color: rgba(95,134,159);
  }
  .line{
    background-color:rgba(95,134,159);
  }
  .iconContainer {
    background:rgba(95,134,159);
  }
  .date {
    color:rgba(95,134,159);
  }
}
.color5{
  .center{
    background-color: rgba(59,137,172);
  }
  .itemTitle {
    color:rgba(59,137,172);
  }
  .line{
    background-color:rgba(59,137,172);
  }
  .iconContainer {
    background:rgba(59,137,172);
  }
  .date {
    color:rgba(59,137,172);
  }
}
.color6{
  .center{
    background-color:rgba(12,91,125);
  }
  .itemTitle {
    color:rgba(12,91,125);
  }
  .line{
    background-color:rgba(12,91,125);
  }
  .iconContainer {
    background:rgba(12,91,125);
  }
  .date {
    color:rgba(12,91,125);
  }
}
.color7{
  .center{
    background-color: rgba(1,37,52);
  }
  .itemTitle {
    color: rgba(1,37,52);
  }
  .line{
    background-color: rgba(1,37,52);
  }
  .iconContainer {
    background: rgba(1,37,52);
  }
  .date {
    color:rgba(1,37,52);
  }
}
.color8{
  .center{
    background-color: rgba(0,56,80);
  }
  .itemTitle {
    color:rgba(0,56,80);
  }
  .line{
    background-color: rgba(0,56,80);
  }
  .iconContainer {
    background: rgba(0,56,80);
  }
  .date {
    color:rgba(0,56,80);
  }
}
.color9{
  .center{
    background-color:rgba(65,100,130);
  }
  .itemTitle {
    color:rgba(65,100,130);
  }
  .line{
    background-color:rgba(65,100,130);
  }
  .iconContainer {
    background:rgba(65,100,130);
  }
  .date {
    color:rgba(65,100,130);
  }
}
.color10{
  .center{
    background-color: rgba(95,134,159);
  }
  .itemTitle {
    color: rgba(95,134,159);
  }
  .line{
    background-color:rgba(95,134,159);
  }
  .iconContainer {
    background:rgba(95,134,159);
  }
  .date {
    color:rgba(95,134,159);
  }
}
.color11{
  .center{
    background-color: rgba(59,137,172);
  }
  .itemTitle {
    color:rgba(59,137,172);
  }
  .line{
    background-color:rgba(59,137,172);
  }
  .iconContainer {
    background:rgba(59,137,172);
  }
  .date {
    color:rgba(59,137,172);
  }
}
.color12{
  .center{
    background-color:rgba(12,91,125);
  }
  .itemTitle {
    color:rgba(12,91,125);
  }
  .line{
    background-color:rgba(12,91,125);
  }
  .iconContainer {
    background:rgba(12,91,125);
  }
  .date {
    color:rgba(12,91,125);
  }
}
.color13{
  .center{
    background-color: rgba(1,37,52);
  }
  .itemTitle {
    color: rgba(1,37,52);
  }
  .line{
    background-color: rgba(1,37,52);
  }
  .iconContainer {
    background: rgba(1,37,52);
  }
  .date {
    color:rgba(1,37,52);
  }
}
.color14{
  .center{
    background-color: rgba(0,56,80);
  }
  .itemTitle {
    color:rgba(0,56,80);
  }
  .line{
    background-color: rgba(0,56,80);
  }
  .iconContainer {
    background: rgba(0,56,80);
  }
  .date {
    color:rgba(0,56,80);
  }
}
.color15{
  .center{
    background-color:rgba(65,100,130);
  }
  .itemTitle {
    color:rgba(65,100,130);
  }
  .line{
    background-color:rgba(65,100,130);
  }
  .iconContainer {
    background:rgba(65,100,130);
  }
  .date {
    color:rgba(65,100,130);
  }
}
.color16{
  .center{
    background-color: rgba(95,134,159);
  }
  .itemTitle {
    color: rgba(95,134,159);
  }
  .line{
    background-color:rgba(95,134,159);
  }
  .iconContainer {
    background:rgba(95,134,159);
  }
  .date {
    color:rgba(95,134,159);
  }
}
.color17{
  .center{
    background-color: rgba(59,137,172);
  }
  .itemTitle {
    color:rgba(59,137,172);
  }
  .line{
    background-color:rgba(59,137,172);
  }
  .iconContainer {
    background:rgba(59,137,172);
  }
  .date {
    color:rgba(59,137,172);
  }
}
.color18{
  .center{
    background-color:rgba(12,91,125);
  }
  .itemTitle {
    color:rgba(12,91,125);
  }
  .line{
    background-color:rgba(12,91,125);
  }
  .iconContainer {
    background:rgba(12,91,125);
  }
  .date {
    color:rgba(12,91,125);
  }
}
.color19{
  .center{
    background-color: rgba(1,37,52);
  }
  .itemTitle {
    color: rgba(1,37,52);
  }
  .line{
    background-color: rgba(1,37,52);
  }
  .iconContainer {
    background: rgba(1,37,52);
  }
  .date {
    color:rgba(1,37,52);
  }
}
.color20{
  .center{
    background-color: rgba(0,56,80);
  }
  .itemTitle {
    color:rgba(0,56,80);
  }
  .line{
    background-color: rgba(0,56,80);
  }
  .iconContainer {
    background: rgba(0,56,80);
  }
  .date {
    color:rgba(0,56,80);
  }
}
.color21{
  .center{
    background-color:rgba(65,100,130);
  }
  .itemTitle {
    color:rgba(65,100,130);
  }
  .line{
    background-color:rgba(65,100,130);
  }
  .iconContainer {
    background:rgba(65,100,130);
  }
  .date {
    color:rgba(65,100,130);
  }
}
.color22{
  .center{
    background-color: rgba(95,134,159);
  }
  .itemTitle {
    color: rgba(95,134,159);
  }
  .line{
    background-color:rgba(95,134,159);
  }
  .iconContainer {
    background:rgba(95,134,159);
  }
  .date {
    color:rgba(95,134,159);
  }
}
.color23{
  .center{
    background-color: rgba(59,137,172);
  }
  .itemTitle {
    color:rgba(59,137,172);
  }
  .line{
    background-color:rgba(59,137,172);
  }
  .iconContainer {
    background:rgba(59,137,172);
  }
  .date {
    color:rgba(59,137,172);
  }
}
.color24{
  .center{
    background-color:rgba(12,91,125);
  }
  .itemTitle {
    color:rgba(12,91,125);
  }
  .line{
    background-color:rgba(12,91,125);
  }
  .iconContainer {
    background:rgba(12,91,125);
  }
  .date {
    color:rgba(12,91,125);
  }
}
.color25{
  .center{
    background-color: rgba(1,37,52);
  }
  .itemTitle {
    color: rgba(1,37,52);
  }
  .line{
    background-color: rgba(1,37,52);
  }
  .iconContainer {
    background: rgba(1,37,52);
  }
  .date {
    color:rgba(1,37,52);
  }
}
.color26{
  .center{
    background-color: rgba(0,56,80);
  }
  .itemTitle {
    color:rgba(0,56,80);
  }
  .line{
    background-color: rgba(0,56,80);
  }
  .iconContainer {
    background: rgba(0,56,80);
  }
  .date {
    color:rgba(0,56,80);
  }
}
.color27{
  .center{
    background-color:rgba(65,100,130);
  }
  .itemTitle {
    color:rgba(65,100,130);
  }
  .line{
    background-color:rgba(65,100,130);
  }
  .iconContainer {
    background:rgba(65,100,130);
  }
  .date {
    color:rgba(65,100,130);
  }
}
.color28{
  .center{
    background-color: rgba(95,134,159);
  }
  .itemTitle {
    color: rgba(95,134,159);
  }
  .line{
    background-color:rgba(95,134,159);
  }
  .iconContainer {
    background:rgba(95,134,159);
  }
  .date {
    color:rgba(95,134,159);
  }
}
.color29{
  .center{
    background-color: rgba(59,137,172);
  }
  .itemTitle {
    color:rgba(59,137,172);
  }
  .line{
    background-color:rgba(59,137,172);
  }
  .iconContainer {
    background:rgba(59,137,172);
  }
  .date {
    color:rgba(59,137,172);
  }
}
.color30{
  .center{
    background-color:rgba(12,91,125);
  }
  .itemTitle {
    color:rgba(12,91,125);
  }
  .line{
    background-color:rgba(12,91,125);
  }
  .iconContainer {
    background:rgba(12,91,125);
  }
  .date {
    color:rgba(12,91,125);
  }
}
.color31{
  .center{
    background-color: rgba(1,37,52);
  }
  .itemTitle {
    color: rgba(1,37,52);
  }
  .line{
    background-color: rgba(1,37,52);
  }
  .iconContainer {
    background: rgba(1,37,52);
  }
  .date {
    color:rgba(1,37,52);
  }
}
.carousel {
  position: relative;
  width: calc(100% - 180px);
  height: 120px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 0 40px;
  margin: 0 auto;
  z-index: 2;
  scroll-behavior: smooth;
  .carouselimage {
    opacity: 0.2;
    margin: 10px;
    transition: 0.5s ease-in-out;
    img{
      max-height: 80px;
    }
  }
  .carouselimage:hover{
    opacity: 1;
  }
  .carouselimage.active {
    left: 50%;
    transform: translateX(-50%);
  }
}
.carouselbuttons{
  position: relative;
  width: 100%;
  height: 120px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -120px;
  z-index: 1;
  .navir,.navil{
    position: absolute;
    top: 40px;
    font-size: 1.8rem;
    color:rgba(1,1,1,0.3);
    cursor: pointer;
    z-index: 10;
  }
  .navir{
    right: 10px;
  }
  .navil{
    left: 10px;
  }
}

@media screen and (max-width: 1050px){
  .aboutus {
    margin-left: 70px !important;
    max-width: calc(100% - 70px) !important;
    .subtitle {
      margin-bottom: 20px !important;
      margin-top: 10px !important;
    }
    .text{
      width: 100% !important;
    }
    .izo{
      display: none !important;
    }
  }
}
@media screen and (max-width: 800px){
  .aboutus {
    margin-left: 0 !important;
    max-width: 100% !important;
    .title {
      margin-top: 3px !important;
      font-size: 1rem !important;
      margin-bottom: 0 !important;
    }
    .subtitle {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }

}
