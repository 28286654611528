@media screen and (min-width: 900px){
  .new_controll{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 40px);
    height: calc(100% - 100px);
    margin-top: 20px;
    padding: 20px;
    overflow-y: auto;
    overflow-x: unset;
    .new_controll_form{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      min-height: 40px;
      margin-bottom: 40px;
      .new_controll_form_title{
        font-size: 16pt;
        margin-bottom: 10px;
      }
      .new_controll_form_this{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        @media screen and (min-width: 1300px) {
          .new_controll_form_this_strock {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            min-height: 40px;

            .new_controll_form_this_strock_number {
              width: 10%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 0;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_shifr {
              width: 10%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_date {
              width: 15%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_way {
              width: 15%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_access {
              width: 15%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_size {
              width: 15%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_tube {
              width: 20%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            input {
              width: 100%;
              height: 100%;
              border: 0px solid #454545;
              padding: 0 4px;
              background-color: #FFF;

            }
            .bigger{
              display: flex;
            }
            .small{
              display: none;
            }
            input:focus {
              outline: none;
            }
          }
        }
        @media screen and (max-width: 1300px) {
          .new_controll_form_this_strock {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            min-height: 40px;
            font-size: 10pt;

            .new_controll_form_this_strock_number {
              width: 10%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 0;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_shifr {
              width: 10%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_date {
              width: 15%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_way {
              width: 15%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_access {
              width: 15%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_size {
              width: 15%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            .new_controll_form_this_strock_tube {
              width: 20%;
              height: 100%;
              border: 1px solid #454545;
              margin: -1px 0 0 -1px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #D9D9D9;
            }

            input {
              width: 100%;
              height: 100%;
              border: 0px solid #454545;
              padding: 0 4px;
              background-color: #FFF;

            }
            .bigger{
              display: flex;
            }
            .small{
              display: none;
            }
            input:focus {
              outline: none;
            }
          }
        }

      }

      .new_controll_form_btns{

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 20px;
        margin-bottom: 40px;

        .new_controll_form_btns_insert{
          display: flex;
          padding: 10px 40px;
          background-color: #03AEAE;
          border: none;
          border-radius: 3px;
          margin-right: 10px;
          color: #FFF;
          cursor: pointer;
        }
        .new_controll_form_btns_create{
          display: flex;
          padding: 10px 40px;
          background-color: #74A94B;
          border: none;
          border-radius: 3px;
          margin-right: 10px;
          color: #FFF;
          cursor: pointer;
        }
        .noactive{
          display: flex;
          padding: 10px 40px;
          background-color: #D9D9D9;
          border: none;
          border-radius: 3px;
          margin-right: 10px;
          color: #FFF;
          cursor: pointer;
        }
      }
    }

    .new_controll_list{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      max-height: 500px;
      overflow-y: auto;
      margin-top: 0;

      .new_controll_list_this_strock_up {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 20px;
        border-radius: 4px 4px 0 0;
        background: #454545;
      }
      @media screen and (min-width: 1300px) {
        .new_controll_list_this_strock {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          min-height: 40px;


          .new_controll_list_this_strock_pp {
            width: 5%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_number {
            width: 10%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_shifr {
            width: 10%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_date {
            width: 15%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_way {
            width: 15%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_access {
            width: 15%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_size {
            width: 15%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_tube {
            width: 15%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;

          }
          i{
            position: absolute;
            right: 40px;
            margin-top: 10px;
          }
        }
      }
      @media screen and (max-width: 1300px) {
        .new_controll_list_this_strock {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          min-height: 40px;
          font-size: 10pt;

          .new_controll_list_this_strock_pp {
            width: 5%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_number {
            width: 10%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_shifr {
            width: 10%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_date {
            width: 15%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_way {
            width: 15%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_access {
            width: 15%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_size {
            width: 15%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_tube {
            width: 15%;
            height: 100%;
            border: 1px solid #454545;
            margin: -1px 0 0 -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
            position: relative;

          }
          i{
            position: absolute;
            right: 40px;
            margin-top: 10px;
          }
        }
      }

    }
  }
  .view-connections{
    position: relative;
    i{
      position: absolute;
      left: calc(100% - 20px);
      top:10px;
      cursor: pointer;
      font-size: 1.2rem;
      color: rgba(1,1,1,0.5);
    }
    i:hover{
      color: rgba(100,1,1,0.7);
    }
  }
}
@media screen and (min-width: 300px) and (max-width: 900px){
  .new_controll{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin-top: -20px;
    padding: 20px;
    overflow-y: auto;
    overflow-x: unset;
    .new_controll_form{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: auto !important;
      margin-bottom: 5px;
      .new_controll_form_title{
        font-size: 16pt;
        margin-bottom: 10px;
      }
      .new_controll_form_this{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 100% !important;
        #title_strock{
          display: none;
        }
          .new_controll_form_this_strock {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            height: content-box !important;
            font-size: 10pt;
            #title_strock{
              display: none;
            }
            .new_controll_form_this_strock_number {
              width: 120px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #ffffff;
              padding: 10px;
              border: 1px solid #CCC;
            }

            .new_controll_form_this_strock_shifr {
              width: 120px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #ffffff;
              padding: 10px;
              border: 1px solid #CCC;
            }

            .new_controll_form_this_strock_date {
              width: 120px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #ffffff;
              padding: 10px;
              border: 1px solid #CCC;
            }

            .new_controll_form_this_strock_way {
              width: 120px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #ffffff;
              padding: 10px;
              border: 1px solid #CCC;
            }

            .new_controll_form_this_strock_access {
              width: 120px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #ffffff;
              padding: 10px;
              border: 1px solid #CCC;
            }

            .new_controll_form_this_strock_size {
              width: 120px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #ffffff;
              padding: 10px;
              border: 1px solid #CCC;
            }

            .new_controll_form_this_strock_tube {
              width: 120px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              background-color: #ffffff;
              padding: 10px;
              border: 1px solid #CCC;
            }

            input {
              width: 100%;
              height: 100%;
              border: 0px solid #454545;
              padding: 0 4px;
              background-color: #FFF;

            }
            .bigger{
              display: none;
            }
            .small{
              display: flex;
            }
            input:focus {
              outline: none;
            }

        }

      }

      .new_controll_form_btns{

        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 5px;
        margin-bottom: 0px;
        width: 100%;
        .new_controll_form_btns_insert{
          display: flex;
          padding: 10px 20px;
          background-color: #03AEAE;
          border: none;
          border-radius: 3px;
          margin-right: 2px;
          color: #FFF;
          cursor: pointer;
        }
        .new_controll_form_btns_create{
          display: flex;
          padding: 10px 20px;
          background-color: #74A94B;
          border: none;
          border-radius: 3px;
          margin-right: 2px;
          color: #FFF;
          cursor: pointer;
        }
        .noactive{
          display: flex;
          padding: 10px 20px;
          background-color: #D9D9D9;
          border: none;
          border-radius: 3px;
          margin-right: 10px;
          color: #FFF;
          cursor: pointer;
        }
      }
    }

    .new_controll_list{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      max-height: 500px;
      overflow-y: auto;
      margin-top: 0;
      #title_list{
        display: none;
      }
      .new_controll_list_this_strock_up {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 20px;
        border-radius: 4px 4px 0 0;
        background: #454545;
      }
        .new_controll_list_this_strock {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 26px);
          min-height: auto !important;
          padding: 10px;
          border: 3px solid #CCC;
          border-radius: 10px;
          margin-top: 10px;

          .new_controll_list_this_strock_pp {
            width: 50px;
            min-height: 40px;
            border: none;
            border-radius: 5px;
            margin: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_number {
            width: 100px;
            min-height: 40px;
            border: none;
            border-radius: 5px;
            margin: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_shifr {
            width: 100px;
            min-height: 40px;
            border: none;
            border-radius: 5px;
            margin: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_date {
            width: 100px;
            min-height: 40px;
            border: none;
            border-radius: 5px;
            margin: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_way {
            width: 100px;
            min-height: 40px;
            border: none;
            border-radius: 5px;
            margin: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_access {
            width: 100px;
            min-height: 40px;
            border: none;
            border-radius: 5px;
            margin: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_size {
            width: 100px;
            min-height: 40px;
            border: none;
            border-radius: 5px;
            margin: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
          }

          .new_controll_list_this_strock_tube {
            width: 100% !important;
            min-height: 40px;
            border: none;
            border-radius: 5px;
            margin: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #F1F1F1;
            i{
              position: absolute;
              right: 40px;
              margin-top: 10px;
            }
          }
        }

    }
  }
  .view-connections{
    position: relative;
    i{
      position: absolute;
      left: calc(100% - 20px);
      top:10px;
      cursor: pointer;
      font-size: 1.2rem;
      color: rgba(1,1,1,0.5);
    }
    i:hover{
      color: rgba(100,1,1,0.7);
    }
  }
}