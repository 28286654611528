.structure_new{
  .title{
    font-size: 1.2rem ;
    font-weight: bold;
  }
  h4{
    margin: 10px;
    font-weight: 400;
  }
}

.btnnavigate{
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  .btnnavigate_tree{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid #454545;
    border-radius: 2px;
    cursor: pointer;
  }
  .btnnavigate_deps{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid #454545;
    border-radius: 2px;
    margin-left: 5px;
    cursor: pointer;
  }
  .btnnavigate_tree:hover{
    background-color: #292f33;
    color: #FFFFFF;
  }
  .btnnavigate_deps:hover{
    background-color: #292f33;
    color: #FFFFFF;
  }
  .active{
    background-color: #CCCCCC;
  }
}

.structure{
  .title{
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
  }
  .image{
    width: 100%;
    overflow: scroll;
    img{
      min-width: 1000px;
      width: 100%;
    }
  }
  .button{
    cursor: pointer;
    background-color: rgb(18, 19, 56);
    color: white;
    margin: 3px;
    padding: 10px 30px;
    width: 160px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    border-radius: 10px;
    z-index: 2;
  }
}
.contact{
  .title{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem;
    margin: 10px;
  }
  textarea{
    outline: none;
    width: calc(100% - 20px);
    padding: 10px;
  }
  .contact-text{

    resize: none;
    height: 120px;
    background-color: transparent;
  }
  .send-text{

    resize: none;
    height: 120px;
  }
  .buttons{
    margin: 0;
    justify-content: space-around;
  }
}

@media screen and (min-width: 1600px){
  .structure_new{
    width: 100%;
    max-height: 100%;
    .structure_new_forest{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 90px;
      .structure_new_forest_cuedo{
        margin-top: 100px;
        .structure_new_forest_cuedo_general{
          display: flex;
          flex-direction: column;
          width: 60px;
          min-height: calc(100%);
          border-top: 3px solid #454545;
          border-left: 3px solid #454545;
          .structure_new_forest_cuedo_card{
            min-width: 300px;
            height: 100%;
            min-height: 110px;
            border-radius: 5px;
            border: 3px solid #CCC;
            background-color: #F0F0F0;
            margin-left: 60px;
            margin-top: -60px;
            margin-bottom: 80px;
            position: relative;
            .slash_rang{
              width: 60px;
              height: 3px;
              position: absolute;
              background-color: #454545;
              bottom: 55px;
              left: -63px;
            }

            .structure_new_forest_cuedo_card_top{
              width: 300px;
              height: 20px;
            }
            .structure_new_forest_cuedo_card_center{
              width: 300px;
              min-height: 30px;
              transition: .2s;
              .structure_new_forest_cuedo_card_center_content{
                display: none;
                flex-direction: column;
                width: 300px;
                min-height: 220px;
                background-color: #CCC;
                .structure_new_forest_cuedo_card_center_content_person{
                  position: relative;
                  width: 250px;
                  height: 220px;
                  .structure_new_forest_cuedo_card_center_content_person_photo{
                    position: absolute;
                    bottom: 0;
                    border: none;
                    width: 250px;
                    left: 25px;
                    height: 220px;
                    background-size: cover;
                    background-position: center center;

                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact{
                    margin: 5px 0 0 10px;
                    padding: 5px 10px;
                    z-index: 10;
                    position: absolute;
                    border: 1px solid #329DD9;
                    background-color: rgba(255,255,255,0.5);
                    border-radius: 2px;
                    color: rgb(18, 19, 56);
                    cursor: pointer;
                    transition: .2s;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                    transition: .2s;
                    background-color: #329DD9;
                    color: rgb(255, 255, 255);
                  }
                  .structure_new_forest_cuedo_card_center_content_person_disc{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 300px;
                    left: 0px;
                    height: 80px;
                    background-size: cover;
                    background-color: rgba(255,255,255,0.5);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .structure_new_forest_cuedo_card_center_content_person_disc_name{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-weight: 700;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-size: 10pt;
                    }
                  }
                }

              }
              .structure_new_forest_cuedo_card_center_button{
                width: 300px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .structure_new_forest_cuedo_card_center_button_slash{
                  width: 70px;
                  height: 2px;
                  background-color: #CCC;
                  border-radius: 2px;
                  margin: 3px 0;
                }
              }


            }
            .structure_new_forest_cuedo_card_center:hover{
              .structure_new_forest_cuedo_card_center_button{
                .structure_new_forest_cuedo_card_center_button_slash{
                  background-color: #1f1f1f;
                  transition: .2s;
                }
              }
            }

            .structure_new_forest_cuedo_card_bottom{
              width: 280px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 10px;
            }


          }
          .two_cueda{
            border-left: 3px solid #454545;
            margin-left: 205px;
            margin-top: -90px;
            padding: 30px 0 0px 0;
            margin-bottom: 140px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_two{
              width: 300px;
              height: 100%;
              min-height: 110px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 60px;
              //margin-top: 30px;
              margin-bottom: 50px;
              position: relative;
              .slash_rang{
                width: 60px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -63px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 20px;
              }
              .structure_new_forest_cuedo_card_center{
                width: 300px;
                min-height: 30px;
                transition: .2s;
                .structure_new_forest_cuedo_card_center_content{
                  display: none;
                  flex-direction: column;
                  min-width: 300px;
                  height: 220px;
                  background-color: #CCC;
                  .structure_new_forest_cuedo_card_center_content_person{
                    position: relative;
                    width: 250px;
                    height: 220px;
                    .structure_new_forest_cuedo_card_center_content_person_photo{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 250px;
                      left: 25px;
                      height: 220px;
                      background-size: cover;
                      background-position: center center;

                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact{
                      margin: 5px 0 0 10px;
                      padding: 5px 10px;
                      z-index: 10;
                      position: absolute;
                      border: 1px solid #329DD9;
                      background-color: rgba(255,255,255,0.5);
                      border-radius: 2px;
                      color: rgb(18, 19, 56);
                      cursor: pointer;
                      transition: .2s;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                      transition: .2s;
                      background-color: #329DD9;
                      color: rgb(255, 255, 255);
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 300px;
                      left: 0px;
                      height: 80px;
                      background-size: cover;
                      background-color: rgba(255,255,255,0.5);
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      .structure_new_forest_cuedo_card_center_content_person_disc_name{
                        width: 100%;
                        height: 40px;
                        text-align: center;
                        font-weight: 700;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                        width: 100%;
                        height: 40px;
                        text-align: center;
                        font-size: 10pt;
                      }
                    }
                  }
                }
                .structure_new_forest_cuedo_card_center_button{
                  width: 300px;
                  height: 30px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  .structure_new_forest_cuedo_card_center_button_slash{
                    width: 70px;
                    height: 2px;
                    background-color: #CCC;
                    border-radius: 2px;
                    margin: 3px 0;
                  }
                }

              }
              .structure_new_forest_cuedo_card_center:hover{
                .structure_new_forest_cuedo_card_center_button{
                  .structure_new_forest_cuedo_card_center_button_slash{
                    background-color: #1f1f1f;
                    transition: .2s;
                  }
                }
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 280px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 10px;
              }

            }
            .finalblock{
              margin-bottom: -60px;
            }
            .four_cueda{
              border-left: 3px solid #454545;
              margin-left: 105px;
              margin-top: -90px;
              padding: 50px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 300px;
                height: 100%;
                min-height: 80px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 60px;
                //margin-top: 30px;
                margin-bottom: 10px;
                position: relative;
                cursor: pointer;
                transition: .2s;
                .slash_rang{
                  width: 60px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -63px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 5px;
                }

                .structure_new_forest_cuedo_card_bottom{
                  width: 280px;
                  height: 55px;
                  padding: 10px;
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  align-items: center;
                }

              }
              .structure_new_forest_cuedo_card_tree:hover{
                background-color: #1f1f1f;
                color: #FFF;
                transition: .2s;
              }
              .honest{
                margin-left: 380px !important;
                margin-top: -45px;
                margin-bottom: -30px;
                //position: relative;
                .slash_rang{
                  width: 380px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 35px;
                  left: -383px;
                }
              }

              .finalblock{
                margin-bottom: -60px;
              }
            }
            .tree_cueda{
              border-left: 3px solid #454545;
              margin-left: 205px;
              margin-top: -90px;
              padding: 70px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 300px;
                height: 100%;
                min-height: 110px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 60px;
                //margin-top: 30px;
                margin-bottom: 50px;
                position: relative;



                .slash_rang{
                  width: 60px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -63px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 20px;
                }
                .structure_new_forest_cuedo_card_center{
                  width: 300px;
                  min-height: 30px;
                  transition: .2s;
                  .structure_new_forest_cuedo_card_center_content{
                    display: none;
                    flex-direction: column;
                    width: 300px;
                    min-height: 220px;
                    background-color: #CCC;
                    .structure_new_forest_cuedo_card_center_content_person{
                      position: relative;
                      width: 250px;
                      height: 220px;
                      .structure_new_forest_cuedo_card_center_content_person_photo{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 250px;
                        left: 25px;
                        height: 220px;
                        background-size: cover;
                        background-position: center center;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact{
                        margin: 5px 0 0 10px;
                        padding: 5px 10px;
                        z-index: 10;
                        position: absolute;
                        border: 1px solid #329DD9;
                        background-color: rgba(255,255,255,0.5);
                        border-radius: 2px;
                        color: rgb(18, 19, 56);
                        cursor: pointer;
                        transition: .2s;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                        transition: .2s;
                        background-color: #329DD9;
                        color: rgb(255, 255, 255);
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 300px;
                        left: 0px;
                        height: 80px;
                        background-size: cover;
                        background-color: rgba(255,255,255,0.5);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .structure_new_forest_cuedo_card_center_content_person_disc_name{
                          width: 100%;
                          height: 30%;
                          text-align: center;
                          font-weight: 700;
                        }
                        .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                          width: 100%;
                          height: 20%;
                          text-align: center;
                          font-size: 10pt;
                        }
                      }
                    }
                  }
                  .structure_new_forest_cuedo_card_center_button{
                    width: 300px;
                    height: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    .structure_new_forest_cuedo_card_center_button_slash{
                      width: 70px;
                      height: 2px;
                      background-color: #CCC;
                      border-radius: 2px;
                      margin: 3px 0;
                    }
                  }

                }
                .structure_new_forest_cuedo_card_center:hover{
                  .structure_new_forest_cuedo_card_center_button{
                    .structure_new_forest_cuedo_card_center_button_slash{
                      background-color: #1f1f1f;
                      transition: .2s;
                    }
                  }
                }
                .structure_new_forest_cuedo_card_bottom{
                  width: 280px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  padding: 10px;
                }



              }
              .finalblock{
                margin-bottom: -60px;
              }

              .four_cueda{
                border-left: 3px solid #454545;
                margin-left: 105px;
                margin-top: -90px;
                padding: 50px 0 0px 0;
                margin-bottom: 40px;
                //height: calc(100% - 20px);
                .backtop{
                  padding-bottom: -30px;
                }
                div{
                  //height: calc(100% - 60px);
                }
                .structure_new_forest_cuedo_card_tree{
                  width: 300px;
                  height: 100%;
                  min-height: 80px;
                  border-radius: 5px;
                  border: 3px solid dodgerblue;
                  background-color: #F0F0F0;
                  margin-left: 60px;
                  //margin-top: 30px;
                  margin-bottom: 10px;
                  position: relative;
                  cursor: pointer;
                  transition: .2s;
                  .slash_rang{
                    width: 60px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 55px;
                    left: -63px;
                  }

                  .structure_new_forest_cuedo_card_top{
                    width: 300px;
                    height: 5px;
                  }

                  .structure_new_forest_cuedo_card_bottom{
                    width: 280px;
                    height: 55px;
                    padding: 10px;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                  }

                }
                .structure_new_forest_cuedo_card_tree:hover{
                  background-color: #1f1f1f;
                  color: #FFF;
                  transition: .2s;
                }
                .honest{
                  margin-left: 380px !important;
                  margin-top: -45px;
                  margin-bottom: -30px;
                  //position: relative;
                  .slash_rang{
                    width: 380px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 35px;
                    left: -383px;
                  }
                }

                .finalblock{
                  margin-bottom: -60px;
                }
              }

            }
          }

          .five_cueda{
            border-left: 3px solid #454545;
            margin-left: 105px;
            margin-top: -90px;
            padding: 50px 0 0px 0;
            margin-bottom: 80px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_tree{
              width: 300px;
              height: 100%;
              min-height: 80px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 60px;
              //margin-top: 30px;
              margin-bottom: 10px;
              position: relative;
              transition: .2s;
              cursor: pointer;


              .slash_rang{
                width: 60px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -63px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 5px;
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 280px;
                height: 55px;
                padding: 10px;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
              }

            }

            .structure_new_forest_cuedo_card_tree:hover{
              background-color: #1f1f1f;
              color: #FFF;
              transition: .2s;
            }
            .finalblock{
              margin-bottom: -60px;
            }
          }


          .finalblock{
            margin-bottom: -58px;


          }
        }
        .structure_new_forest_cuedo_dother{
        }
      }

    }

  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px){
  .structure_new{
    width: 100%;
    max-height: 100%;
    .structure_new_forest{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 90px;
      .structure_new_forest_cuedo{
        margin-top: 100px;
        .structure_new_forest_cuedo_general{
          display: flex;
          flex-direction: column;
          width: 30px;
          min-height: calc(100%);
          border-top: 3px solid #454545;
          border-left: 3px solid #454545;
          .structure_new_forest_cuedo_card{
            min-width: 300px;
            height: 100%;
            min-height: 110px;
            border-radius: 5px;
            border: 3px solid #CCC;
            background-color: #F0F0F0;
            margin-left: 30px;
            margin-top: -60px;
            margin-bottom: 80px;
            position: relative;
            .slash_rang{
              width: 30px;
              height: 3px;
              position: absolute;
              background-color: #454545;
              bottom: 55px;
              left: -33px;
            }

            .structure_new_forest_cuedo_card_top{
              width: 300px;
              height: 20px;
            }
            .structure_new_forest_cuedo_card_center{
              width: 300px;
              min-height: 30px;
              transition: .2s;
              .structure_new_forest_cuedo_card_center_content{
                display: none;
                flex-direction: column;
                width: 300px;
                min-height: 220px;
                background-color: #CCC;
                .structure_new_forest_cuedo_card_center_content_person{
                  position: relative;
                  width: 250px;
                  height: 220px;
                  .structure_new_forest_cuedo_card_center_content_person_photo{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 250px;
                    left: 25px;
                    height: 220px;
                    background-size: cover;
                    background-position: center center;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact{
                    margin: 5px 0 0 10px;
                    padding: 5px 10px;
                    z-index: 10;
                    position: absolute;
                    border: 1px solid #329DD9;
                    background-color: rgba(255,255,255,0.5);
                    border-radius: 2px;
                    color: rgb(18, 19, 56);
                    cursor: pointer;
                    transition: .2s;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                    transition: .2s;
                    background-color: #329DD9;
                    color: rgb(255, 255, 255);
                  }
                  .structure_new_forest_cuedo_card_center_content_person_disc{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 300px;
                    left: 0px;
                    height: 80px;
                    background-size: cover;
                    background-color: rgba(255,255,255,0.5);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .structure_new_forest_cuedo_card_center_content_person_disc_name{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-weight: 700;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-size: 10pt;
                    }
                  }
                }

              }
              .structure_new_forest_cuedo_card_center_button{
                width: 300px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .structure_new_forest_cuedo_card_center_button_slash{
                  width: 70px;
                  height: 2px;
                  background-color: #CCC;
                  border-radius: 2px;
                  margin: 3px 0;
                }
              }


            }
            .structure_new_forest_cuedo_card_center:hover{
              .structure_new_forest_cuedo_card_center_button{
                .structure_new_forest_cuedo_card_center_button_slash{
                  background-color: #1f1f1f;
                  transition: .2s;
                }
              }
            }

            .structure_new_forest_cuedo_card_bottom{
              width: 280px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 10px;
            }


          }
          .two_cueda{
            border-left: 3px solid #454545;
            margin-left: 55px;
            margin-top: -90px;
            padding: 30px 0 0px 0;
            margin-bottom: 140px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_two{
              width: 300px;
              height: 100%;
              min-height: 110px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 30px;
              //margin-top: 30px;
              margin-bottom: 50px;
              position: relative;
              .slash_rang{
                width: 30px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -33px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 20px;
              }
              .structure_new_forest_cuedo_card_center{
                width: 300px;
                min-height: 30px;
                transition: .2s;
                .structure_new_forest_cuedo_card_center_content{
                  display: none;
                  flex-direction: column;
                  min-width: 300px;
                  height: 220px;
                  background-color: #CCC;
                  .structure_new_forest_cuedo_card_center_content_person{
                    position: relative;
                    width: 250px;
                    height: 220px;
                    .structure_new_forest_cuedo_card_center_content_person_photo{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 250px;
                      left: 25px;
                      height: 220px;
                      background-size: cover;
                      background-position: center center;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact{
                      margin: 5px 0 0 10px;
                      padding: 5px 10px;
                      z-index: 10;
                      position: absolute;
                      border: 1px solid #329DD9;
                      background-color: rgba(255,255,255,0.5);
                      border-radius: 2px;
                      color: rgb(18, 19, 56);
                      cursor: pointer;
                      transition: .2s;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                      transition: .2s;
                      background-color: #329DD9;
                      color: rgb(255, 255, 255);
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 300px;
                      left: 0px;
                      height: 80px;
                      background-size: cover;
                      background-color: rgba(255,255,255,0.5);
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      .structure_new_forest_cuedo_card_center_content_person_disc_name{
                        width: 100%;
                        height: 40px;
                        text-align: center;
                        font-weight: 700;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                        width: 100%;
                        height: 40px;
                        text-align: center;
                        font-size: 10pt;
                      }
                    }
                  }
                }
                .structure_new_forest_cuedo_card_center_button{
                  width: 300px;
                  height: 30px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  .structure_new_forest_cuedo_card_center_button_slash{
                    width: 70px;
                    height: 2px;
                    background-color: #CCC;
                    border-radius: 2px;
                    margin: 3px 0;
                  }
                }

              }
              .structure_new_forest_cuedo_card_center:hover{
                .structure_new_forest_cuedo_card_center_button{
                  .structure_new_forest_cuedo_card_center_button_slash{
                    background-color: #1f1f1f;
                    transition: .2s;
                  }
                }
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 280px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 10px;
              }

            }
            .finalblock{
              margin-bottom: -60px;
            }
            .four_cueda{
              border-left: 3px solid #454545;
              margin-left: 55px;
              margin-top: -90px;
              padding: 50px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 300px;
                height: 100%;
                min-height: 80px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 60px;
                //margin-top: 30px;
                margin-bottom: 10px;
                position: relative;
                cursor: pointer;
                transition: .2s;
                .slash_rang{
                  width: 60px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -63px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 5px;
                }

                .structure_new_forest_cuedo_card_bottom{
                  width: 280px;
                  height: 55px;
                  padding: 10px;
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  align-items: center;
                }

              }
              .structure_new_forest_cuedo_card_tree:hover{
                background-color: #1f1f1f;
                color: #FFF;
                transition: .2s;
              }
              .honest{
                margin-left: 380px !important;
                margin-top: -45px;
                margin-bottom: -30px;
                //position: relative;
                .slash_rang{
                  width: 380px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 35px;
                  left: -383px;
                }
              }

              .finalblock{
                margin-bottom: -60px;
              }
            }
            .tree_cueda{
              border-left: 3px solid #454545;
              margin-left: 55px;
              margin-top: -90px;
              padding: 70px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 300px;
                height: 100%;
                min-height: 110px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 30px;
                //margin-top: 30px;
                margin-bottom: 50px;
                position: relative;



                .slash_rang{
                  width: 30px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -33px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 20px;
                }
                .structure_new_forest_cuedo_card_center{
                  width: 300px;
                  min-height: 30px;
                  transition: .2s;
                  .structure_new_forest_cuedo_card_center_content{
                    display: none;
                    flex-direction: column;
                    width: 300px;
                    min-height: 220px;
                    background-color: #CCC;
                    .structure_new_forest_cuedo_card_center_content_person{
                      position: relative;
                      width: 250px;
                      height: 220px;
                      .structure_new_forest_cuedo_card_center_content_person_photo{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 250px;
                        left: 25px;
                        height: 220px;
                        background-size: cover;
                        background-position: center center;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact{
                        margin: 5px 0 0 10px;
                        padding: 5px 10px;
                        z-index: 10;
                        position: absolute;
                        border: 1px solid #329DD9;
                        background-color: rgba(255,255,255,0.5);
                        border-radius: 2px;
                        color: rgb(18, 19, 56);
                        cursor: pointer;
                        transition: .2s;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                        transition: .2s;
                        background-color: #329DD9;
                        color: rgb(255, 255, 255);
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 300px;
                        left: 0px;
                        height: 80px;
                        background-size: cover;
                        background-color: rgba(255,255,255,0.5);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .structure_new_forest_cuedo_card_center_content_person_disc_name{
                          width: 100%;
                          height: 30%;
                          text-align: center;
                          font-weight: 700;
                        }
                        .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                          width: 100%;
                          height: 20%;
                          text-align: center;
                          font-size: 10pt;
                        }
                      }
                    }
                  }
                  .structure_new_forest_cuedo_card_center_button{
                    width: 300px;
                    height: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    .structure_new_forest_cuedo_card_center_button_slash{
                      width: 70px;
                      height: 2px;
                      background-color: #CCC;
                      border-radius: 2px;
                      margin: 3px 0;
                    }
                  }

                }
                .structure_new_forest_cuedo_card_center:hover{
                  .structure_new_forest_cuedo_card_center_button{
                    .structure_new_forest_cuedo_card_center_button_slash{
                      background-color: #1f1f1f;
                      transition: .2s;
                    }
                  }
                }
                .structure_new_forest_cuedo_card_bottom{
                  width: 280px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  padding: 10px;
                }



              }
              .finalblock{
                margin-bottom: -60px;
              }

              .four_cueda{
                border-left: 3px solid #454545;
                margin-left: 55px;
                margin-top: -90px;
                padding: 50px 0 0px 0;
                margin-bottom: 40px;
                //height: calc(100% - 20px);
                .backtop{
                  padding-bottom: -30px;
                }
                div{
                  //height: calc(100% - 60px);
                }
                .structure_new_forest_cuedo_card_tree{
                  width: 300px;
                  height: 100%;
                  min-height: 80px;
                  border-radius: 5px;
                  border: 3px solid dodgerblue;
                  background-color: #F0F0F0;
                  margin-left: 60px;
                  //margin-top: 30px;
                  margin-bottom: 10px;
                  position: relative;
                  cursor: pointer;
                  transition: .2s;
                  .slash_rang{
                    width: 60px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 55px;
                    left: -63px;
                  }

                  .structure_new_forest_cuedo_card_top{
                    width: 300px;
                    height: 5px;
                  }

                  .structure_new_forest_cuedo_card_bottom{
                    width: 280px;
                    height: 55px;
                    padding: 10px;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                  }

                }
                .structure_new_forest_cuedo_card_tree:hover{
                  background-color: #1f1f1f;
                  color: #FFF;
                  transition: .2s;
                }
                .honest{
                  margin-left: 380px !important;
                  margin-top: -45px;
                  margin-bottom: -30px;
                  //position: relative;
                  .slash_rang{
                    width: 380px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 35px;
                    left: -383px;
                  }
                }

                .finalblock{
                  margin-bottom: -60px;
                }
              }

            }
          }

          .five_cueda{
            border-left: 3px solid #454545;
            margin-left: 105px;
            margin-top: -90px;
            padding: 50px 0 0px 0;
            margin-bottom: 80px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_tree{
              width: 300px;
              height: 100%;
              min-height: 80px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 60px;
              //margin-top: 30px;
              margin-bottom: 10px;
              position: relative;
              transition: .2s;
              cursor: pointer;


              .slash_rang{
                width: 60px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -63px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 5px;
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 280px;
                height: 55px;
                padding: 10px;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
              }

            }

            .structure_new_forest_cuedo_card_tree:hover{
              background-color: #1f1f1f;
              color: #FFF;
              transition: .2s;
            }
            .finalblock{
              margin-bottom: -60px;
            }
          }


          .finalblock{
            margin-bottom: -58px;


          }
        }
        .structure_new_forest_cuedo_dother{
        }
      }

    }

  }
}
@media screen and (min-width: 1050px) and (max-width: 1200px){
  .structure_new{
    width: 100%;
    max-height: 100%;
    .structure_new_forest{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 90px;
      .structure_new_forest_cuedo{
        margin-top: 100px;
        .structure_new_forest_cuedo_general{
          display: flex;
          flex-direction: column;
          width: 20px;
          min-height: calc(100%);
          border-top: 3px solid #454545;
          border-left: 3px solid #454545;
          .structure_new_forest_cuedo_card{
            min-width: 300px;
            height: 100%;
            min-height: 110px;
            border-radius: 5px;
            border: 3px solid #CCC;
            background-color: #F0F0F0;
            margin-left: 20px;
            margin-top: -60px;
            margin-bottom: 80px;
            position: relative;
            .slash_rang{
              width: 20px;
              height: 3px;
              position: absolute;
              background-color: #454545;
              bottom: 55px;
              left: -23px;
            }

            .structure_new_forest_cuedo_card_top{
              width: 300px;
              height: 20px;
            }
            .structure_new_forest_cuedo_card_center{
              width: 300px;
              min-height: 30px;
              transition: .2s;
              .structure_new_forest_cuedo_card_center_content{
                display: none;
                flex-direction: column;
                width: 300px;
                min-height: 220px;
                background-color: #CCC;
                .structure_new_forest_cuedo_card_center_content_person{
                  position: relative;
                  width: 250px;
                  height: 220px;
                  .structure_new_forest_cuedo_card_center_content_person_photo{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 250px;
                    left: 25px;
                    height: 220px;
                    background-size: cover;
                    background-position: center center;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact{
                    margin: 5px 0 0 10px;
                    padding: 5px 10px;
                    z-index: 10;
                    position: absolute;
                    border: 1px solid #329DD9;
                    background-color: rgba(255,255,255,0.5);
                    border-radius: 2px;
                    color: rgb(18, 19, 56);
                    cursor: pointer;
                    transition: .2s;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                    transition: .2s;
                    background-color: #329DD9;
                    color: rgb(255, 255, 255);
                  }
                  .structure_new_forest_cuedo_card_center_content_person_disc{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 300px;
                    left: 0px;
                    height: 80px;
                    background-size: cover;
                    background-color: rgba(255,255,255,0.5);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .structure_new_forest_cuedo_card_center_content_person_disc_name{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-weight: 700;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-size: 10pt;
                    }
                  }
                }

              }
              .structure_new_forest_cuedo_card_center_button{
                width: 300px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .structure_new_forest_cuedo_card_center_button_slash{
                  width: 70px;
                  height: 2px;
                  background-color: #CCC;
                  border-radius: 2px;
                  margin: 3px 0;
                }
              }


            }
            .structure_new_forest_cuedo_card_center:hover{
              .structure_new_forest_cuedo_card_center_button{
                .structure_new_forest_cuedo_card_center_button_slash{
                  background-color: #1f1f1f;
                  transition: .2s;
                }
              }
            }

            .structure_new_forest_cuedo_card_bottom{
              width: 280px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 10px;
            }


          }
          .two_cueda{
            border-left: 3px solid #454545;
            margin-left: 35px;
            margin-top: -90px;
            padding: 30px 0 0px 0;
            margin-bottom: 140px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_two{
              width: 300px;
              height: 100%;
              min-height: 110px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 20px;
              //margin-top: 30px;
              margin-bottom: 50px;
              position: relative;
              .slash_rang{
                width: 20px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -23px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 20px;
              }
              .structure_new_forest_cuedo_card_center{
                width: 300px;
                min-height: 30px;
                transition: .2s;
                .structure_new_forest_cuedo_card_center_content{
                  display: none;
                  flex-direction: column;
                  min-width: 300px;
                  height: 220px;
                  background-color: #CCC;
                  .structure_new_forest_cuedo_card_center_content_person{
                    position: relative;
                    width: 250px;
                    height: 220px;
                    .structure_new_forest_cuedo_card_center_content_person_photo{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 250px;
                      left: 25px;
                      height: 220px;
                      background-size: cover;
                      background-position: center center;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact{
                      margin: 5px 0 0 10px;
                      padding: 5px 10px;
                      z-index: 10;
                      position: absolute;
                      border: 1px solid #329DD9;
                      background-color: rgba(255,255,255,0.5);
                      border-radius: 2px;
                      color: rgb(18, 19, 56);
                      cursor: pointer;
                      transition: .2s;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                      transition: .2s;
                      background-color: #329DD9;
                      color: rgb(255, 255, 255);
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 300px;
                      left: 0px;
                      height: 80px;
                      background-size: cover;
                      background-color: rgba(255,255,255,0.5);
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      .structure_new_forest_cuedo_card_center_content_person_disc_name{
                        width: 100%;
                        height: 30%;
                        text-align: center;
                        font-weight: 700;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                        width: 100%;
                        height: 20%;
                        text-align: center;
                        font-size: 10pt;
                      }
                    }
                  }
                }
                .structure_new_forest_cuedo_card_center_button{
                  width: 300px;
                  height: 30px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  .structure_new_forest_cuedo_card_center_button_slash{
                    width: 70px;
                    height: 2px;
                    background-color: #CCC;
                    border-radius: 2px;
                    margin: 3px 0;
                  }
                }

              }
              .structure_new_forest_cuedo_card_center:hover{
                .structure_new_forest_cuedo_card_center_button{
                  .structure_new_forest_cuedo_card_center_button_slash{
                    background-color: #1f1f1f;
                    transition: .2s;
                  }
                }
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 280px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 10px;
              }

            }
            .finalblock{
              margin-bottom: -60px;
            }
            .four_cueda{
              border-left: 3px solid #454545;
              margin-left: 35px;
              margin-top: -90px;
              padding: 50px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 300px;
                height: 100%;
                min-height: 80px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 60px;
                //margin-top: 30px;
                margin-bottom: 10px;
                position: relative;
                cursor: pointer;
                transition: .2s;
                .slash_rang{
                  width: 60px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -63px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 5px;
                }

                .structure_new_forest_cuedo_card_bottom{
                  width: 280px;
                  height: 55px;
                  padding: 10px;
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  align-items: center;
                }

              }
              .structure_new_forest_cuedo_card_tree:hover{
                background-color: #1f1f1f;
                color: #FFF;
                transition: .2s;
              }
              .honest{
                margin-left: 380px !important;
                margin-top: -45px;
                margin-bottom: -30px;
                //position: relative;
                .slash_rang{
                  width: 380px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 35px;
                  left: -383px;
                }
              }

              .finalblock{
                margin-bottom: -60px;
              }
            }
            .tree_cueda{
              border-left: 3px solid #454545;
              margin-left: 35px;
              margin-top: -90px;
              padding: 70px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 300px;
                height: 100%;
                min-height: 110px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 20px;
                //margin-top: 30px;
                margin-bottom: 50px;
                position: relative;



                .slash_rang{
                  width: 20px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -23px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 20px;
                }
                .structure_new_forest_cuedo_card_center{
                  width: 300px;
                  min-height: 30px;
                  transition: .2s;
                  .structure_new_forest_cuedo_card_center_content{
                    display: none;
                    flex-direction: column;
                    width: 300px;
                    min-height: 220px;
                    background-color: #CCC;
                    .structure_new_forest_cuedo_card_center_content_person{
                      position: relative;
                      width: 250px;
                      height: 220px;
                      .structure_new_forest_cuedo_card_center_content_person_photo{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 250px;
                        left: 25px;
                        height: 220px;
                        background-size: cover;
                        background-position: center center;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact{
                        margin: 5px 0 0 10px;
                        padding: 5px 10px;
                        z-index: 10;
                        position: absolute;
                        border: 1px solid #329DD9;
                        background-color: rgba(255,255,255,0.5);
                        border-radius: 2px;
                        color: rgb(18, 19, 56);
                        cursor: pointer;
                        transition: .2s;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                        transition: .2s;
                        background-color: #329DD9;
                        color: rgb(255, 255, 255);
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 300px;
                        left: 0px;
                        height: 80px;
                        background-size: cover;
                        background-color: rgba(255,255,255,0.5);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .structure_new_forest_cuedo_card_center_content_person_disc_name{
                          width: 100%;
                          height: 30%;
                          text-align: center;
                          font-weight: 700;
                        }
                        .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                          width: 100%;
                          height: 20%;
                          text-align: center;
                          font-size: 10pt;
                        }
                      }
                    }
                  }
                  .structure_new_forest_cuedo_card_center_button{
                    width: 300px;
                    height: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    .structure_new_forest_cuedo_card_center_button_slash{
                      width: 70px;
                      height: 2px;
                      background-color: #CCC;
                      border-radius: 2px;
                      margin: 3px 0;
                    }
                  }

                }
                .structure_new_forest_cuedo_card_center:hover{
                  .structure_new_forest_cuedo_card_center_button{
                    .structure_new_forest_cuedo_card_center_button_slash{
                      background-color: #1f1f1f;
                      transition: .2s;
                    }
                  }
                }
                .structure_new_forest_cuedo_card_bottom{
                  width: 280px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  padding: 10px;
                }



              }
              .finalblock{
                margin-bottom: -60px;
              }

              .four_cueda{
                border-left: 3px solid #454545;
                margin-left: 35px;
                margin-top: -90px;
                padding: 50px 0 0px 0;
                margin-bottom: 40px;
                //height: calc(100% - 20px);
                .backtop{
                  padding-bottom: -30px;
                }
                div{
                  //height: calc(100% - 60px);
                }
                .structure_new_forest_cuedo_card_tree{
                  width: 300px;
                  height: 100%;
                  min-height: 80px;
                  border-radius: 5px;
                  border: 3px solid dodgerblue;
                  background-color: #F0F0F0;
                  margin-left: 20px;
                  //margin-top: 30px;
                  margin-bottom: 10px;
                  position: relative;
                  cursor: pointer;
                  transition: .2s;
                  .slash_rang{
                    width: 20px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 55px;
                    left: -23px;
                  }

                  .structure_new_forest_cuedo_card_top{
                    width: 300px;
                    height: 5px;
                  }

                  .structure_new_forest_cuedo_card_bottom{
                    width: 280px;
                    height: 55px;
                    padding: 10px;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                  }

                }
                .structure_new_forest_cuedo_card_tree:hover{
                  background-color: #1f1f1f;
                  color: #FFF;
                  transition: .2s;
                }
                .honest{
                  margin-left: 340px !important;
                  margin-top: -45px;
                  margin-bottom: -30px;
                  //position: relative;
                  .slash_rang{
                    width: 340px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 35px;
                    left: -343px;
                  }
                }

                .finalblock{
                  margin-bottom: -60px;
                }
              }

            }
          }

          .five_cueda{
            border-left: 3px solid #454545;
            margin-left: 105px;
            margin-top: -90px;
            padding: 50px 0 0px 0;
            margin-bottom: 80px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_tree{
              width: 300px;
              height: 100%;
              min-height: 80px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 60px;
              //margin-top: 30px;
              margin-bottom: 10px;
              position: relative;
              transition: .2s;
              cursor: pointer;


              .slash_rang{
                width: 60px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -63px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 5px;
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 280px;
                height: 55px;
                padding: 10px;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
              }

            }

            .structure_new_forest_cuedo_card_tree:hover{
              background-color: #1f1f1f;
              color: #FFF;
              transition: .2s;
            }
            .finalblock{
              margin-bottom: -60px;
            }
          }


          .finalblock{
            margin-bottom: -58px;


          }
        }
        .structure_new_forest_cuedo_dother{
        }
      }

    }

  }
}
@media screen and (min-width: 944px) and (max-width: 1050px){
  .structure_new{
    width: 100%;
    max-height: 100%;
    .structure_new_forest{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 90px;
      .structure_new_forest_cuedo{
        margin-top: 100px;
        .structure_new_forest_cuedo_general{
          display: flex;
          flex-direction: column;
          width: 30px;
          min-height: calc(100%);
          border-top: 3px solid #454545;
          border-left: 3px solid #454545;
          .structure_new_forest_cuedo_card{
            min-width: 300px;
            height: 100%;
            min-height: 110px;
            border-radius: 5px;
            border: 3px solid #CCC;
            background-color: #F0F0F0;
            margin-left: 30px;
            margin-top: -60px;
            margin-bottom: 80px;
            position: relative;
            .slash_rang{
              width: 30px;
              height: 3px;
              position: absolute;
              background-color: #454545;
              bottom: 55px;
              left: -33px;
            }

            .structure_new_forest_cuedo_card_top{
              width: 300px;
              height: 20px;
            }
            .structure_new_forest_cuedo_card_center{
              width: 300px;
              min-height: 30px;
              transition: .2s;
              .structure_new_forest_cuedo_card_center_content{
                display: none;
                flex-direction: column;
                width: 300px;
                min-height: 220px;
                background-color: #CCC;
                .structure_new_forest_cuedo_card_center_content_person{
                  position: relative;
                  width: 250px;
                  height: 220px;
                  .structure_new_forest_cuedo_card_center_content_person_photo{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 250px;
                    left: 25px;
                    height: 220px;
                    background-size: cover;
                    background-position: center center;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact{
                    margin: 5px 0 0 10px;
                    padding: 5px 10px;
                    z-index: 10;
                    position: absolute;
                    border: 1px solid #329DD9;
                    background-color: rgba(255,255,255,0.5);
                    border-radius: 2px;
                    color: rgb(18, 19, 56);
                    cursor: pointer;
                    transition: .2s;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                    transition: .2s;
                    background-color: #329DD9;
                    color: rgb(255, 255, 255);
                  }
                  .structure_new_forest_cuedo_card_center_content_person_disc{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 300px;
                    left: 0px;
                    height: 80px;
                    background-size: cover;
                    background-color: rgba(255,255,255,0.5);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .structure_new_forest_cuedo_card_center_content_person_disc_name{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-weight: 700;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-size: 10pt;
                    }
                  }
                }

              }
              .structure_new_forest_cuedo_card_center_button{
                width: 300px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .structure_new_forest_cuedo_card_center_button_slash{
                  width: 70px;
                  height: 2px;
                  background-color: #CCC;
                  border-radius: 2px;
                  margin: 3px 0;
                }
              }


            }
            .structure_new_forest_cuedo_card_center:hover{
              .structure_new_forest_cuedo_card_center_button{
                .structure_new_forest_cuedo_card_center_button_slash{
                  background-color: #1f1f1f;
                  transition: .2s;
                }
              }
            }

            .structure_new_forest_cuedo_card_bottom{
              width: 280px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 10px;
            }


          }
          .two_cueda{
            border-left: 3px solid #454545;
            margin-left: 55px;
            margin-top: -90px;
            padding: 30px 0 0px 0;
            margin-bottom: 140px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_two{
              width: 300px;
              height: 100%;
              min-height: 110px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 30px;
              //margin-top: 30px;
              margin-bottom: 50px;
              position: relative;
              .slash_rang{
                width: 30px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -33px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 20px;
              }
              .structure_new_forest_cuedo_card_center{
                width: 300px;
                min-height: 30px;
                transition: .2s;
                .structure_new_forest_cuedo_card_center_content{
                  display: none;
                  flex-direction: column;
                  min-width: 300px;
                  height: 220px;
                  background-color: #CCC;
                  .structure_new_forest_cuedo_card_center_content_person{
                    position: relative;
                    width: 250px;
                    height: 220px;
                    .structure_new_forest_cuedo_card_center_content_person_photo{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 250px;
                      left: 25px;
                      height: 220px;
                      background-size: cover;
                      background-position: center center;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact{
                      margin: 5px 0 0 10px;
                      padding: 5px 10px;
                      z-index: 10;
                      position: absolute;
                      border: 1px solid #329DD9;
                      background-color: rgba(255,255,255,0.5);
                      border-radius: 2px;
                      color: rgb(18, 19, 56);
                      cursor: pointer;
                      transition: .2s;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                      transition: .2s;
                      background-color: #329DD9;
                      color: rgb(255, 255, 255);
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 300px;
                      left: 0px;
                      height: 80px;
                      background-size: cover;
                      background-color: rgba(255,255,255,0.5);
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      .structure_new_forest_cuedo_card_center_content_person_disc_name{
                        width: 100%;
                        height: 30%;
                        text-align: center;
                        font-weight: 700;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                        width: 100%;
                        height: 20%;
                        text-align: center;
                        font-size: 10pt;
                      }
                    }
                  }
                }
                .structure_new_forest_cuedo_card_center_button{
                  width: 300px;
                  height: 30px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  .structure_new_forest_cuedo_card_center_button_slash{
                    width: 70px;
                    height: 2px;
                    background-color: #CCC;
                    border-radius: 2px;
                    margin: 3px 0;
                  }
                }

              }
              .structure_new_forest_cuedo_card_center:hover{
                .structure_new_forest_cuedo_card_center_button{
                  .structure_new_forest_cuedo_card_center_button_slash{
                    background-color: #1f1f1f;
                    transition: .2s;
                  }
                }
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 280px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 10px;
              }

            }
            .finalblock{
              margin-bottom: -60px;
            }
            .four_cueda{
              border-left: 3px solid #454545;
              margin-left: 55px;
              margin-top: -90px;
              padding: 50px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 300px;
                height: 100%;
                min-height: 80px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 60px;
                //margin-top: 30px;
                margin-bottom: 10px;
                position: relative;
                cursor: pointer;
                transition: .2s;
                .slash_rang{
                  width: 60px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -63px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 5px;
                }

                .structure_new_forest_cuedo_card_bottom{
                  width: 280px;
                  height: 55px;
                  padding: 10px;
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  align-items: center;
                }

              }
              .structure_new_forest_cuedo_card_tree:hover{
                background-color: #1f1f1f;
                color: #FFF;
                transition: .2s;
              }
              .honest{
                margin-left: 380px !important;
                margin-top: -45px;
                margin-bottom: -30px;
                //position: relative;
                .slash_rang{
                  width: 380px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 35px;
                  left: -383px;
                }
              }

              .finalblock{
                margin-bottom: -60px;
              }
            }
            .tree_cueda{
              border-left: 3px solid #454545;
              margin-left: 55px;
              margin-top: -90px;
              padding: 70px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 300px;
                height: 100%;
                min-height: 110px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 30px;
                //margin-top: 30px;
                margin-bottom: 50px;
                position: relative;



                .slash_rang{
                  width: 30px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -33px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 20px;
                }
                .structure_new_forest_cuedo_card_center{
                  width: 300px;
                  min-height: 30px;
                  transition: .2s;
                  .structure_new_forest_cuedo_card_center_content{
                    display: none;
                    flex-direction: column;
                    width: 300px;
                    min-height: 220px;
                    background-color: #CCC;
                    .structure_new_forest_cuedo_card_center_content_person{
                      position: relative;
                      width: 250px;
                      height: 220px;
                      .structure_new_forest_cuedo_card_center_content_person_photo{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 250px;
                        left: 25px;
                        height: 220px;
                        background-size: cover;
                        background-position: center center;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact{
                        margin: 5px 0 0 10px;
                        padding: 5px 10px;
                        z-index: 10;
                        position: absolute;
                        border: 1px solid #329DD9;
                        background-color: rgba(255,255,255,0.5);
                        border-radius: 2px;
                        color: rgb(18, 19, 56);
                        cursor: pointer;
                        transition: .2s;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                        transition: .2s;
                        background-color: #329DD9;
                        color: rgb(255, 255, 255);
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 300px;
                        left: 0px;
                        height: 80px;
                        background-size: cover;
                        background-color: rgba(255,255,255,0.5);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .structure_new_forest_cuedo_card_center_content_person_disc_name{
                          width: 100%;
                          height: 30%;
                          text-align: center;
                          font-weight: 700;
                        }
                        .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                          width: 100%;
                          height: 20%;
                          text-align: center;
                          font-size: 10pt;
                        }
                      }
                    }
                  }
                  .structure_new_forest_cuedo_card_center_button{
                    width: 300px;
                    height: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    .structure_new_forest_cuedo_card_center_button_slash{
                      width: 70px;
                      height: 2px;
                      background-color: #CCC;
                      border-radius: 2px;
                      margin: 3px 0;
                    }
                  }

                }
                .structure_new_forest_cuedo_card_center:hover{
                  .structure_new_forest_cuedo_card_center_button{
                    .structure_new_forest_cuedo_card_center_button_slash{
                      background-color: #1f1f1f;
                      transition: .2s;
                    }
                  }
                }
                .structure_new_forest_cuedo_card_bottom{
                  width: 280px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  padding: 10px;
                }



              }
              .finalblock{
                margin-bottom: -60px;
              }

              .four_cueda{
                border-left: 3px solid #454545;
                margin-left: 55px;
                margin-top: -90px;
                padding: 50px 0 0px 0;
                margin-bottom: 40px;
                //height: calc(100% - 20px);
                .backtop{
                  padding-bottom: -30px;
                }
                div{
                  //height: calc(100% - 60px);
                }
                .structure_new_forest_cuedo_card_tree{
                  width: 300px;
                  height: 100%;
                  min-height: 80px;
                  border-radius: 5px;
                  border: 3px solid dodgerblue;
                  background-color: #F0F0F0;
                  margin-left: 60px;
                  //margin-top: 30px;
                  margin-bottom: 10px;
                  position: relative;
                  cursor: pointer;
                  transition: .2s;
                  .slash_rang{
                    width: 60px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 55px;
                    left: -63px;
                  }

                  .structure_new_forest_cuedo_card_top{
                    width: 300px;
                    height: 5px;
                  }

                  .structure_new_forest_cuedo_card_bottom{
                    width: 280px;
                    height: 55px;
                    padding: 10px;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                  }

                }
                .structure_new_forest_cuedo_card_tree:hover{
                  background-color: #1f1f1f;
                  color: #FFF;
                  transition: .2s;
                }
                .honest{
                  margin-left: 380px !important;
                  margin-top: -45px;
                  margin-bottom: -30px;
                  //position: relative;
                  .slash_rang{
                    width: 380px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 35px;
                    left: -383px;
                  }
                }

                .finalblock{
                  margin-bottom: -60px;
                }
              }

            }
          }

          .five_cueda{
            border-left: 3px solid #454545;
            margin-left: 105px;
            margin-top: -90px;
            padding: 50px 0 0px 0;
            margin-bottom: 80px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_tree{
              width: 300px;
              height: 100%;
              min-height: 80px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 60px;
              //margin-top: 30px;
              margin-bottom: 10px;
              position: relative;
              transition: .2s;
              cursor: pointer;


              .slash_rang{
                width: 60px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -63px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 5px;
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 280px;
                height: 55px;
                padding: 10px;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
              }

            }

            .structure_new_forest_cuedo_card_tree:hover{
              background-color: #1f1f1f;
              color: #FFF;
              transition: .2s;
            }
            .finalblock{
              margin-bottom: -60px;
            }
          }


          .finalblock{
            margin-bottom: -58px;


          }
        }
        .structure_new_forest_cuedo_dother{
        }
      }

    }

  }
}
@media screen and (min-width: 800px) and (max-width: 944px){
  .structure_new{
    width: calc(100% - 5px);
    max-height: 100%;
    margin-left: 5px;
    .structure_new_forest{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 90px;
      .structure_new_forest_cuedo{
        margin-top: 100px;
        .structure_new_forest_cuedo_general{
          display: flex;
          flex-direction: column;
          width: 10px;
          min-height: calc(100%);
          border-top: 3px solid #454545;
          border-left: 3px solid #454545;
          .structure_new_forest_cuedo_card{
            min-width: 300px;
            height: 100%;
            min-height: 110px;
            border-radius: 5px;
            border: 3px solid #CCC;
            background-color: #F0F0F0;
            margin-left: 10px;
            margin-top: -60px;
            margin-bottom: 80px;
            position: relative;
            .slash_rang{
              width: 10px;
              height: 3px;
              position: absolute;
              background-color: #454545;
              bottom: 55px;
              left: -13px;
            }

            .structure_new_forest_cuedo_card_top{
              width: 300px;
              height: 20px;
            }
            .structure_new_forest_cuedo_card_center{
              width: 300px;
              min-height: 30px;
              transition: .2s;
              .structure_new_forest_cuedo_card_center_content{
                display: none;
                flex-direction: column;
                width: 300px;
                min-height: 220px;
                background-color: #CCC;
                .structure_new_forest_cuedo_card_center_content_person{
                  position: relative;
                  width: 250px;
                  height: 220px;
                  .structure_new_forest_cuedo_card_center_content_person_photo{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 250px;
                    left: 25px;
                    height: 220px;
                    background-size: cover;
                    background-position: center center;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact{
                    margin: 5px 0 0 10px;
                    padding: 5px 10px;
                    z-index: 10;
                    position: absolute;
                    border: 1px solid #329DD9;
                    background-color: rgba(255,255,255,0.5);
                    border-radius: 2px;
                    color: rgb(18, 19, 56);
                    cursor: pointer;
                    transition: .2s;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                    transition: .2s;
                    background-color: #329DD9;
                    color: rgb(255, 255, 255);
                  }
                  .structure_new_forest_cuedo_card_center_content_person_disc{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 300px;
                    left: 0px;
                    height: 80px;
                    background-size: cover;
                    background-color: rgba(255,255,255,0.5);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .structure_new_forest_cuedo_card_center_content_person_disc_name{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-weight: 700;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-size: 10pt;
                    }
                  }
                }

              }
              .structure_new_forest_cuedo_card_center_button{
                width: 300px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .structure_new_forest_cuedo_card_center_button_slash{
                  width: 70px;
                  height: 2px;
                  background-color: #CCC;
                  border-radius: 2px;
                  margin: 3px 0;
                }
              }


            }
            .structure_new_forest_cuedo_card_center:hover{
              .structure_new_forest_cuedo_card_center_button{
                .structure_new_forest_cuedo_card_center_button_slash{
                  background-color: #1f1f1f;
                  transition: .2s;
                }
              }
            }

            .structure_new_forest_cuedo_card_bottom{
              width: 280px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 10px;
            }


          }
          .two_cueda{
            border-left: 3px solid #454545;
            margin-left: 20px;
            margin-top: -90px;
            padding: 30px 0 0px 0;
            margin-bottom: 140px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_two{
              width: 300px;
              height: 100%;
              min-height: 110px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 10px;
              //margin-top: 30px;
              margin-bottom: 50px;
              position: relative;
              .slash_rang{
                width: 10px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -13px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 20px;
              }
              .structure_new_forest_cuedo_card_center{
                width: 300px;
                min-height: 30px;
                transition: .2s;
                .structure_new_forest_cuedo_card_center_content{
                  display: none;
                  flex-direction: column;
                  min-width: 300px;
                  height: 220px;
                  background-color: #CCC;
                  .structure_new_forest_cuedo_card_center_content_person{
                    position: relative;
                    width: 250px;
                    height: 220px;
                    .structure_new_forest_cuedo_card_center_content_person_photo{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 250px;
                      left: 25px;
                      height: 220px;
                      background-size: cover;
                      background-position: center center;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact{
                      margin: 5px 0 0 10px;
                      padding: 5px 10px;
                      z-index: 10;
                      position: absolute;
                      border: 1px solid #329DD9;
                      background-color: rgba(255,255,255,0.5);
                      border-radius: 2px;
                      color: rgb(18, 19, 56);
                      cursor: pointer;
                      transition: .2s;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                      transition: .2s;
                      background-color: #329DD9;
                      color: rgb(255, 255, 255);
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 300px;
                      left: 0px;
                      height: 80px;
                      background-size: cover;
                      background-color: rgba(255,255,255,0.5);
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      .structure_new_forest_cuedo_card_center_content_person_disc_name{
                        width: 100%;
                        height: 30%;
                        text-align: center;
                        font-weight: 700;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                        width: 100%;
                        height: 20%;
                        text-align: center;
                        font-size: 10pt;
                      }
                    }
                  }
                }
                .structure_new_forest_cuedo_card_center_button{
                  width: 300px;
                  height: 30px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  .structure_new_forest_cuedo_card_center_button_slash{
                    width: 70px;
                    height: 2px;
                    background-color: #CCC;
                    border-radius: 2px;
                    margin: 3px 0;
                  }
                }

              }
              .structure_new_forest_cuedo_card_center:hover{
                .structure_new_forest_cuedo_card_center_button{
                  .structure_new_forest_cuedo_card_center_button_slash{
                    background-color: #1f1f1f;
                    transition: .2s;
                  }
                }
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 280px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 10px;
              }

            }
            .finalblock{
              margin-bottom: -60px;
            }
            .four_cueda{
              border-left: 3px solid #454545;
              margin-left: 20px;
              margin-top: -90px;
              padding: 50px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 250px;
                height: 100%;
                min-height: 80px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 10px;
                //margin-top: 30px;
                margin-bottom: 10px;
                position: relative;
                cursor: pointer;
                transition: .2s;
                .slash_rang{
                  width: 10px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -13px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 5px;
                }

                .structure_new_forest_cuedo_card_bottom{
                  width: 230px;
                  height: 55px;
                  padding: 10px;
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  align-items: center;
                  font-size: 10pt;
                }

              }
              .structure_new_forest_cuedo_card_tree:hover{
                background-color: #1f1f1f;
                color: #FFF;
                transition: .2s;
              }
              .honest{
                margin-left: 280px !important;
                margin-top: -45px;
                margin-bottom: -30px;
                //position: relative;
                .slash_rang{
                  width: 280px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 35px;
                  left: -283px;
                }
              }

              .finalblock{
                margin-bottom: -60px;
              }
            }
            .tree_cueda{
              border-left: 3px solid #454545;
              margin-left: 20px;
              margin-top: -90px;
              padding: 70px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 300px;
                height: 100%;
                min-height: 110px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 10px;
                //margin-top: 30px;
                margin-bottom: 50px;
                position: relative;



                .slash_rang{
                  width: 10px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -13px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 20px;
                }
                .structure_new_forest_cuedo_card_center{
                  width: 300px;
                  min-height: 30px;
                  transition: .2s;
                  .structure_new_forest_cuedo_card_center_content{
                    display: none;
                    flex-direction: column;
                    width: 300px;
                    min-height: 220px;
                    background-color: #CCC;
                    .structure_new_forest_cuedo_card_center_content_person{
                      position: relative;
                      width: 250px;
                      height: 220px;
                      .structure_new_forest_cuedo_card_center_content_person_photo{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 250px;
                        left: 25px;
                        height: 220px;
                        background-size: cover;
                        background-position: center center;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact{
                        margin: 5px 0 0 10px;
                        padding: 5px 10px;
                        z-index: 10;
                        position: absolute;
                        border: 1px solid #329DD9;
                        background-color: rgba(255,255,255,0.5);
                        border-radius: 2px;
                        color: rgb(18, 19, 56);
                        cursor: pointer;
                        transition: .2s;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                        transition: .2s;
                        background-color: #329DD9;
                        color: rgb(255, 255, 255);
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 300px;
                        left: 0px;
                        height: 80px;
                        background-size: cover;
                        background-color: rgba(255,255,255,0.5);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .structure_new_forest_cuedo_card_center_content_person_disc_name{
                          width: 100%;
                          height: 30%;
                          text-align: center;
                          font-weight: 700;
                        }
                        .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                          width: 100%;
                          height: 20%;
                          text-align: center;
                          font-size: 10pt;
                        }
                      }
                    }
                  }
                  .structure_new_forest_cuedo_card_center_button{
                    width: 300px;
                    height: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    .structure_new_forest_cuedo_card_center_button_slash{
                      width: 70px;
                      height: 2px;
                      background-color: #CCC;
                      border-radius: 2px;
                      margin: 3px 0;
                    }
                  }

                }
                .structure_new_forest_cuedo_card_center:hover{
                  .structure_new_forest_cuedo_card_center_button{
                    .structure_new_forest_cuedo_card_center_button_slash{
                      background-color: #1f1f1f;
                      transition: .2s;
                    }
                  }
                }
                .structure_new_forest_cuedo_card_bottom{
                  width: 280px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  padding: 10px;
                }



              }
              .finalblock{
                margin-bottom: -60px;
              }

              .four_cueda{
                border-left: 3px solid #454545;
                margin-left: 20px;
                margin-top: -90px;
                padding: 50px 0 0px 0;
                margin-bottom: 40px;
                //height: calc(100% - 20px);
                .backtop{
                  padding-bottom: -30px;
                }
                div{
                  //height: calc(100% - 60px);
                }
                .structure_new_forest_cuedo_card_tree{
                  width: 250px;
                  height: 100%;
                  min-height: 80px;
                  border-radius: 5px;
                  border: 3px solid dodgerblue;
                  background-color: #F0F0F0;
                  margin-left: 10px;
                  //margin-top: 30px;
                  margin-bottom: 10px;
                  position: relative;
                  cursor: pointer;
                  transition: .2s;
                  .slash_rang{
                    width: 10px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 55px;
                    left: -13px;
                  }

                  .structure_new_forest_cuedo_card_top{
                    width: 300px;
                    height: 5px;
                  }

                  .structure_new_forest_cuedo_card_bottom{
                    width: 230px;
                    height: 55px;
                    padding: 10px;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    font-size: 10pt;
                  }

                }
                .structure_new_forest_cuedo_card_tree:hover{
                  background-color: #1f1f1f;
                  color: #FFF;
                  transition: .2s;
                }
                .honest{
                  margin-left: 280px !important;
                  margin-top: -45px;
                  margin-bottom: -30px;
                  //position: relative;
                  .slash_rang{
                    width: 280px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 35px;
                    left: -283px;
                  }
                }

                .finalblock{
                  margin-bottom: -60px;
                }
              }

            }
          }

          .five_cueda{
            border-left: 3px solid #454545;
            margin-left: 50px;
            margin-top: -90px;
            padding: 50px 0 0px 0;
            margin-bottom: 80px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_tree{
              width: 250px;
              height: 100%;
              min-height: 80px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 10px;
              //margin-top: 30px;
              margin-bottom: 10px;
              position: relative;
              transition: .2s;
              cursor: pointer;


              .slash_rang{
                width: 10px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -13px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 5px;
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 230px;
                height: 55px;
                padding: 10px;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                font-size: 10pt;
              }

            }

            .structure_new_forest_cuedo_card_tree:hover{
              background-color: #1f1f1f;
              color: #FFF;
              transition: .2s;
            }
            .finalblock{
              margin-bottom: -60px;
            }
          }


          .finalblock{
            margin-bottom: -58px;


          }
        }
        .structure_new_forest_cuedo_dother{
        }
      }

    }

  }
}
@media screen and (min-width: 375px) and (max-width: 800px){
  .structure_new{
    width: 100%;
    max-height: 100%;
    margin-left: -10px;
    .structure_new_forest{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 90px;
      .structure_new_forest_cuedo{
        margin-top: 100px;
        .structure_new_forest_cuedo_general{
          display: flex;
          flex-direction: column;
          width: 10px;
          min-height: calc(100%);
          border-top: 3px solid #454545;
          border-left: 3px solid #454545;
          .structure_new_forest_cuedo_card{
            min-width: 280px;
            height: 100%;
            min-height: 110px;
            border-radius: 5px;
            border: 3px solid #CCC;
            background-color: #F0F0F0;
            margin-left: 10px;
            margin-top: -60px;
            margin-bottom: 80px;
            position: relative;
            .slash_rang{
              width: 10px;
              height: 3px;
              position: absolute;
              background-color: #454545;
              bottom: 55px;
              left: -13px;
            }

            .structure_new_forest_cuedo_card_top{
              width: 300px;
              height: 20px;
            }
            .structure_new_forest_cuedo_card_center{
              width: 280px;
              min-height: 30px;
              transition: .2s;
              .structure_new_forest_cuedo_card_center_content{
                display: none;
                flex-direction: column;
                width: 280px;
                min-height: 220px;
                background-color: #CCC;
                .structure_new_forest_cuedo_card_center_content_person{
                  position: relative;
                  width: 230px;
                  height: 220px;
                  .structure_new_forest_cuedo_card_center_content_person_photo{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 230px;
                    left: 25px;
                    height: 220px;
                    background-size: cover;
                    background-position: center center;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact{
                    margin: 5px 0 0 10px;
                    padding: 5px 10px;
                    z-index: 10;
                    position: absolute;
                    border: 1px solid #329DD9;
                    background-color: rgba(255,255,255,0.5);
                    border-radius: 2px;
                    color: rgb(18, 19, 56);
                    cursor: pointer;
                    transition: .2s;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                    transition: .2s;
                    background-color: #329DD9;
                    color: rgb(255, 255, 255);
                  }
                  .structure_new_forest_cuedo_card_center_content_person_disc{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 280px;
                    left: 0px;
                    height: 80px;
                    background-size: cover;
                    background-color: rgba(255,255,255,0.5);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .structure_new_forest_cuedo_card_center_content_person_disc_name{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-weight: 700;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-size: 10pt;
                    }
                  }
                }

              }
              .structure_new_forest_cuedo_card_center_button{
                width: 280px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .structure_new_forest_cuedo_card_center_button_slash{
                  width: 70px;
                  height: 2px;
                  background-color: #CCC;
                  border-radius: 2px;
                  margin: 3px 0;
                }
              }


            }
            .structure_new_forest_cuedo_card_center:hover{
              .structure_new_forest_cuedo_card_center_button{
                .structure_new_forest_cuedo_card_center_button_slash{
                  background-color: #1f1f1f;
                  transition: .2s;
                }
              }
            }

            .structure_new_forest_cuedo_card_bottom{
              width: 280px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 10px;
            }


          }
          .two_cueda{
            border-left: 3px solid #454545;
            margin-left: 20px;
            margin-top: -90px;
            padding: 30px 0 0px 0;
            margin-bottom: 140px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_two{
              width: 260px;
              height: 100%;
              min-height: 110px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 10px;
              //margin-top: 30px;
              margin-bottom: 50px;
              position: relative;
              .slash_rang{
                width: 10px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -13px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 260px;
                height: 20px;
              }
              .structure_new_forest_cuedo_card_center{
                width: 260px;
                min-height: 30px;
                transition: .2s;
                .structure_new_forest_cuedo_card_center_content{
                  display: none;
                  flex-direction: column;
                  width: 260px;
                  height: 220px;
                  background-color: #CCC;
                  .structure_new_forest_cuedo_card_center_content_person{
                    position: relative;
                    width: 220px;
                    height: 220px;
                    .structure_new_forest_cuedo_card_center_content_person_photo{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 220px;
                      left: 20px;
                      height: 220px;
                      background-size: cover;
                      background-position: center center;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact{
                      margin: 5px 0 0 10px;
                      padding: 5px 10px;
                      z-index: 10;
                      position: absolute;
                      border: 1px solid #329DD9;
                      background-color: rgba(255,255,255,0.5);
                      border-radius: 2px;
                      color: rgb(18, 19, 56);
                      cursor: pointer;
                      transition: .2s;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                      transition: .2s;
                      background-color: #329DD9;
                      color: rgb(255, 255, 255);
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 260px;
                      left: 0px;
                      height: 80px;
                      background-size: cover;
                      background-color: rgba(255,255,255,0.5);
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      .structure_new_forest_cuedo_card_center_content_person_disc_name{
                        width: 100%;
                        height: 30%;
                        text-align: center;
                        font-weight: 700;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                        width: 100%;
                        height: 20%;
                        text-align: center;
                        font-size: 10pt;
                      }
                    }
                  }
                }
                .structure_new_forest_cuedo_card_center_button{
                  width: 260px;
                  height: 30px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  .structure_new_forest_cuedo_card_center_button_slash{
                    width: 70px;
                    height: 2px;
                    background-color: #CCC;
                    border-radius: 2px;
                    margin: 3px 0;
                  }
                }

              }
              .structure_new_forest_cuedo_card_center:hover{
                .structure_new_forest_cuedo_card_center_button{
                  .structure_new_forest_cuedo_card_center_button_slash{
                    background-color: #1f1f1f;
                    transition: .2s;
                  }
                }
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 260px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 10px;
              }

            }
            .finalblock{
              margin-bottom: -60px;
            }
            .four_cueda{
              border-left: 3px solid #454545;
              margin-left: 20px;
              margin-top: -90px;
              padding: 50px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 250px;
                height: 100%;
                min-height: 80px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 10px;
                //margin-top: 30px;
                margin-bottom: 10px;
                position: relative;
                cursor: pointer;
                transition: .2s;
                .slash_rang{
                  width: 10px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -13px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 300px;
                  height: 5px;
                }

                .structure_new_forest_cuedo_card_bottom{
                  width: 230px;
                  height: 55px;
                  padding: 10px;
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  align-items: center;
                  font-size: 10pt;
                }

              }
              .structure_new_forest_cuedo_card_tree:hover{
                background-color: #1f1f1f;
                color: #FFF;
                transition: .2s;
              }
              .honest{
                margin-left: 10px !important;
                margin-top: 0px;
                margin-bottom: 10px;
                //position: relative;
                .slash_rang{
                  width: 10px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 35px;
                  left: -13px;
                }
              }

              .finalblock{
                margin-bottom: -60px;
              }
            }
            .tree_cueda{
              border-left: 3px solid #454545;
              margin-left: 20px;
              margin-top: -90px;
              padding: 70px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 260px;
                height: 100%;
                min-height: 110px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 10px;
                //margin-top: 30px;
                margin-bottom: 50px;
                position: relative;



                .slash_rang{
                  width: 10px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -13px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 260px;
                  height: 20px;
                }
                .structure_new_forest_cuedo_card_center{
                  width: 260px;
                  min-height: 30px;
                  transition: .2s;
                  .structure_new_forest_cuedo_card_center_content{
                    display: none;
                    flex-direction: column;
                    width: 260px;
                    min-height: 220px;
                    background-color: #CCC;
                    .structure_new_forest_cuedo_card_center_content_person{
                      position: relative;
                      width: 220px;
                      height: 220px;
                      .structure_new_forest_cuedo_card_center_content_person_photo{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 220px;
                        left: 20px;
                        height: 220px;
                        background-size: cover;
                        background-position: center center;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact{
                        margin: 5px 0 0 10px;
                        padding: 5px 10px;
                        z-index: 10;
                        position: absolute;
                        border: 1px solid #329DD9;
                        background-color: rgba(255,255,255,0.5);
                        border-radius: 2px;
                        color: rgb(18, 19, 56);
                        cursor: pointer;
                        transition: .2s;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                        transition: .2s;
                        background-color: #329DD9;
                        color: rgb(255, 255, 255);
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 260px;
                        left: 0px;
                        height: 80px;
                        background-size: cover;
                        background-color: rgba(255,255,255,0.5);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .structure_new_forest_cuedo_card_center_content_person_disc_name{
                          width: 100%;
                          height: 40px;
                          text-align: center;
                          font-weight: 700;
                        }
                        .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                          width: 100%;
                          height: 20%;
                          text-align: center;
                          font-size: 10pt;
                        }
                      }
                    }
                  }
                  .structure_new_forest_cuedo_card_center_button{
                    width: 260px;
                    height: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    .structure_new_forest_cuedo_card_center_button_slash{
                      width: 70px;
                      height: 2px;
                      background-color: #CCC;
                      border-radius: 2px;
                      margin: 3px 0;
                    }
                  }

                }
                .structure_new_forest_cuedo_card_center:hover{
                  .structure_new_forest_cuedo_card_center_button{
                    .structure_new_forest_cuedo_card_center_button_slash{
                      background-color: #1f1f1f;
                      transition: .2s;
                    }
                  }
                }
                .structure_new_forest_cuedo_card_bottom{
                  width: 240px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  padding: 10px;
                }



              }
              .finalblock{
                margin-bottom: -60px;
              }

              .four_cueda{
                border-left: 3px solid #454545;
                margin-left: 20px;
                margin-top: -90px;
                padding: 50px 0 0px 0;
                margin-bottom: 40px;
                //height: calc(100% - 20px);
                .backtop{
                  padding-bottom: -30px;
                }
                div{
                  //height: calc(100% - 60px);
                }
                .structure_new_forest_cuedo_card_tree{
                  width: 235px;
                  height: 100%;
                  min-height: 80px;
                  border-radius: 5px;
                  border: 3px solid dodgerblue;
                  background-color: #F0F0F0;
                  margin-left: 10px;
                  //margin-top: 30px;
                  margin-bottom: 10px;
                  position: relative;
                  cursor: pointer;
                  transition: .2s;
                  .slash_rang{
                    width: 10px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 55px;
                    left: -13px;
                  }

                  .structure_new_forest_cuedo_card_top{
                    width: 300px;
                    height: 5px;
                  }

                  .structure_new_forest_cuedo_card_bottom{
                    width: 210px;
                    height: 55px;
                    padding: 10px;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    font-size: 10pt;
                  }

                }
                .structure_new_forest_cuedo_card_tree:hover{
                  background-color: #1f1f1f;
                  color: #FFF;
                  transition: .2s;
                }
                .honest{
                  margin-left: 10px !important;
                  margin-top: -0px;
                  margin-bottom: 10px;
                  //position: relative;
                  .slash_rang{
                    width: 10px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 35px;
                    left: -13px;
                  }
                }

                .finalblock{
                  margin-bottom: -60px;
                }
              }

            }
          }

          .five_cueda{
            border-left: 3px solid #454545;
            margin-left: 20px;
            margin-top: -90px;
            padding: 50px 0 0px 0;
            margin-bottom: 80px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_tree{
              width: 250px;
              height: 100%;
              min-height: 80px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 10px;
              //margin-top: 30px;
              margin-bottom: 10px;
              position: relative;
              transition: .2s;
              cursor: pointer;


              .slash_rang{
                width: 10px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -13px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 300px;
                height: 5px;
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 230px;
                height: 55px;
                padding: 10px;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                font-size: 10pt;
              }

            }

            .structure_new_forest_cuedo_card_tree:hover{
              background-color: #1f1f1f;
              color: #FFF;
              transition: .2s;
            }
            .finalblock{
              margin-bottom: -60px;
            }
          }


          .finalblock{
            margin-bottom: -58px;


          }
        }
        .structure_new_forest_cuedo_dother{
        }
      }

    }

  }
}
@media screen and (max-width: 374px){
  .structure_new{
    width: 100%;
    max-height: 100%;
    margin-left: -10px;
    .structure_new_forest{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 90px;
      .structure_new_forest_cuedo{
        margin-top: 100px;
        .structure_new_forest_cuedo_general{
          display: flex;
          flex-direction: column;
          width: 10px;
          min-height: calc(100%);
          border-top: 3px solid #454545;
          border-left: 3px solid #454545;
          .structure_new_forest_cuedo_card{
            min-width: 280px;
            height: 100%;
            min-height: 110px;
            border-radius: 5px;
            border: 3px solid #CCC;
            background-color: #F0F0F0;
            margin-left: 5px;
            margin-top: -60px;
            margin-bottom: 80px;
            position: relative;
            .slash_rang{
              width: 5px;
              height: 3px;
              position: absolute;
              background-color: #454545;
              bottom: 55px;
              left: -8px;
            }

            .structure_new_forest_cuedo_card_top{
              width: 300px;
              height: 20px;
            }
            .structure_new_forest_cuedo_card_center{
              width: 280px;
              min-height: 30px;
              transition: .2s;
              .structure_new_forest_cuedo_card_center_content{
                display: none;
                flex-direction: column;
                width: 280px;
                min-height: 220px;
                background-color: #CCC;
                .structure_new_forest_cuedo_card_center_content_person{
                  position: relative;
                  width: 230px;
                  height: 220px;
                  .structure_new_forest_cuedo_card_center_content_person_photo{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 230px;
                    left: 25px;
                    height: 220px;
                    background-size: cover;
                    background-position: center center;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact{
                    margin: 5px 0 0 10px;
                    padding: 5px 10px;
                    z-index: 10;
                    position: absolute;
                    border: 1px solid #329DD9;
                    background-color: rgba(255,255,255,0.5);
                    border-radius: 2px;
                    color: rgb(18, 19, 56);
                    cursor: pointer;
                    transition: .2s;
                  }
                  .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                    transition: .2s;
                    background-color: #329DD9;
                    color: rgb(255, 255, 255);
                  }
                  .structure_new_forest_cuedo_card_center_content_person_disc{
                    position: absolute;
                    bottom: 0px;
                    border: none;
                    width: 280px;
                    left: 0px;
                    height: 80px;
                    background-size: cover;
                    background-color: rgba(255,255,255,0.5);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .structure_new_forest_cuedo_card_center_content_person_disc_name{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-weight: 700;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                      width: 100%;
                      height: 40px;
                      text-align: center;
                      font-size: 10pt;
                    }
                  }
                }

              }
              .structure_new_forest_cuedo_card_center_button{
                width: 280px;
                height: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .structure_new_forest_cuedo_card_center_button_slash{
                  width: 70px;
                  height: 2px;
                  background-color: #CCC;
                  border-radius: 2px;
                  margin: 3px 0;
                }
              }


            }
            .structure_new_forest_cuedo_card_center:hover{
              .structure_new_forest_cuedo_card_center_button{
                .structure_new_forest_cuedo_card_center_button_slash{
                  background-color: #1f1f1f;
                  transition: .2s;
                }
              }
            }

            .structure_new_forest_cuedo_card_bottom{
              width: 280px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding: 10px;
            }


          }
          .two_cueda{
            border-left: 3px solid #454545;
            margin-left: 8px;
            margin-top: -90px;
            padding: 30px 0 0px 0;
            margin-bottom: 140px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_two{
              width: 260px;
              height: 100%;
              min-height: 110px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 5px;
              //margin-top: 30px;
              margin-bottom: 50px;
              position: relative;
              .slash_rang{
                width: 5px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -8px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 260px;
                height: 20px;
              }
              .structure_new_forest_cuedo_card_center{
                width: 260px;
                min-height: 30px;
                transition: .2s;
                .structure_new_forest_cuedo_card_center_content{
                  display: none;
                  flex-direction: column;
                  width: 260px;
                  height: 220px;
                  background-color: #CCC;
                  .structure_new_forest_cuedo_card_center_content_person{
                    position: relative;
                    width: 220px;
                    height: 220px;
                    .structure_new_forest_cuedo_card_center_content_person_photo{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 220px;
                      left: 20px;
                      height: 220px;
                      background-size: cover;
                      background-position: center center;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact{
                      margin: 5px 0 0 10px;
                      padding: 5px 10px;
                      z-index: 10;
                      position: absolute;
                      border: 1px solid #329DD9;
                      background-color: rgba(255,255,255,0.5);
                      border-radius: 2px;
                      color: rgb(18, 19, 56);
                      cursor: pointer;
                      transition: .2s;
                    }
                    .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                      transition: .2s;
                      background-color: #329DD9;
                      color: rgb(255, 255, 255);
                    }
                    .structure_new_forest_cuedo_card_center_content_person_disc{
                      position: absolute;
                      bottom: 0px;
                      border: none;
                      width: 260px;
                      left: 0px;
                      height: 80px;
                      background-size: cover;
                      background-color: rgba(255,255,255,0.5);
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      .structure_new_forest_cuedo_card_center_content_person_disc_name{
                        width: 100%;
                        height: 30%;
                        text-align: center;
                        font-weight: 700;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                        width: 100%;
                        height: 20%;
                        text-align: center;
                        font-size: 10pt;
                      }
                    }
                  }
                }
                .structure_new_forest_cuedo_card_center_button{
                  width: 260px;
                  height: 30px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  .structure_new_forest_cuedo_card_center_button_slash{
                    width: 70px;
                    height: 2px;
                    background-color: #CCC;
                    border-radius: 2px;
                    margin: 3px 0;
                  }
                }

              }
              .structure_new_forest_cuedo_card_center:hover{
                .structure_new_forest_cuedo_card_center_button{
                  .structure_new_forest_cuedo_card_center_button_slash{
                    background-color: #1f1f1f;
                    transition: .2s;
                  }
                }
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 260px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 10px;
              }

            }
            .finalblock{
              margin-bottom: -60px;
            }
            .four_cueda{
              border-left: 3px solid #454545;
              margin-left: 8px;
              margin-top: -90px;
              padding: 50px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 245px;
                height: 100%;
                min-height: 80px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 10px;
                //margin-top: 30px;
                margin-bottom: 10px;
                position: relative;
                cursor: pointer;
                transition: .2s;
                .slash_rang{
                  width: 10px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -13px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 230px;
                  height: 5px;
                }

                .structure_new_forest_cuedo_card_bottom{
                  width: 225px;
                  height: 55px;
                  padding: 10px;
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  align-items: center;
                  font-size: 10pt;
                }

              }
              .structure_new_forest_cuedo_card_tree:hover{
                background-color: #1f1f1f;
                color: #FFF;
                transition: .2s;
              }
              .honest{
                margin-left: 10px !important;
                margin-top: 0px;
                margin-bottom: 10px;
                //position: relative;
                .slash_rang{
                  width: 10px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 35px;
                  left: -13px;
                }
              }

              .finalblock{
                margin-bottom: -60px;
              }
            }
            .tree_cueda{
              border-left: 3px solid #454545;
              margin-left: 8px;
              margin-top: -90px;
              padding: 70px 0 0px 0;
              margin-bottom: 40px;
              //height: calc(100% - 20px);
              .backtop{
                padding-bottom: -30px;
              }
              div{
                //height: calc(100% - 60px);
              }
              .structure_new_forest_cuedo_card_tree{
                width: 260px;
                height: 100%;
                min-height: 110px;
                border-radius: 5px;
                border: 3px solid dodgerblue;
                background-color: #F0F0F0;
                margin-left: 5px;
                //margin-top: 30px;
                margin-bottom: 50px;
                position: relative;



                .slash_rang{
                  width: 5px;
                  height: 3px;
                  position: absolute;
                  background-color: #454545;
                  bottom: 55px;
                  left: -8px;
                }

                .structure_new_forest_cuedo_card_top{
                  width: 260px;
                  height: 20px;
                }
                .structure_new_forest_cuedo_card_center{
                  width: 260px;
                  min-height: 30px;
                  transition: .2s;
                  .structure_new_forest_cuedo_card_center_content{
                    display: none;
                    flex-direction: column;
                    width: 260px;
                    min-height: 220px;
                    background-color: #CCC;
                    .structure_new_forest_cuedo_card_center_content_person{
                      position: relative;
                      width: 220px;
                      height: 220px;
                      .structure_new_forest_cuedo_card_center_content_person_photo{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 220px;
                        left: 20px;
                        height: 220px;
                        background-size: cover;
                        background-position: center center;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact{
                        margin: 5px 0 0 10px;
                        padding: 5px 10px;
                        z-index: 10;
                        position: absolute;
                        border: 1px solid #329DD9;
                        background-color: rgba(255,255,255,0.5);
                        border-radius: 2px;
                        color: rgb(18, 19, 56);
                        cursor: pointer;
                        transition: .2s;
                      }
                      .structure_new_forest_cuedo_card_center_content_person_contact:hover{
                        transition: .2s;
                        background-color: #329DD9;
                        color: rgb(255, 255, 255);
                      }
                      .structure_new_forest_cuedo_card_center_content_person_disc{
                        position: absolute;
                        bottom: 0px;
                        border: none;
                        width: 260px;
                        left: 0px;
                        height: 80px;
                        background-size: cover;
                        background-color: rgba(255,255,255,0.5);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .structure_new_forest_cuedo_card_center_content_person_disc_name{
                          width: 100%;
                          height: 40px;
                          text-align: center;
                          font-weight: 700;
                        }
                        .structure_new_forest_cuedo_card_center_content_person_disc_dev{
                          width: 100%;
                          height: 20%;
                          text-align: center;
                          font-size: 10pt;
                        }
                      }
                    }
                  }
                  .structure_new_forest_cuedo_card_center_button{
                    width: 260px;
                    height: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    .structure_new_forest_cuedo_card_center_button_slash{
                      width: 70px;
                      height: 2px;
                      background-color: #CCC;
                      border-radius: 2px;
                      margin: 3px 0;
                    }
                  }

                }
                .structure_new_forest_cuedo_card_center:hover{
                  .structure_new_forest_cuedo_card_center_button{
                    .structure_new_forest_cuedo_card_center_button_slash{
                      background-color: #1f1f1f;
                      transition: .2s;
                    }
                  }
                }
                .structure_new_forest_cuedo_card_bottom{
                  width: 240px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  padding: 10px;
                }



              }
              .finalblock{
                margin-bottom: -60px;
              }

              .four_cueda{
                border-left: 3px solid #454545;
                margin-left: 8px;
                margin-top: -90px;
                padding: 50px 0 0px 0;
                margin-bottom: 40px;
                //height: calc(100% - 20px);
                .backtop{
                  padding-bottom: -30px;
                }
                div{
                  //height: calc(100% - 60px);
                }
                .structure_new_forest_cuedo_card_tree{
                  width: 235px;
                  height: 100%;
                  min-height: 80px;
                  border-radius: 5px;
                  border: 3px solid dodgerblue;
                  background-color: #F0F0F0;
                  margin-left: 10px;
                  //margin-top: 30px;
                  margin-bottom: 10px;
                  position: relative;
                  cursor: pointer;
                  transition: .2s;
                  .slash_rang{
                    width: 10px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 55px;
                    left: -13px;
                  }

                  .structure_new_forest_cuedo_card_top{
                    width: 225px;
                    height: 5px;
                  }

                  .structure_new_forest_cuedo_card_bottom{
                    width: 210px;
                    height: 55px;
                    padding: 10px;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    font-size: 10pt;
                  }

                }
                .structure_new_forest_cuedo_card_tree:hover{
                  background-color: #1f1f1f;
                  color: #FFF;
                  transition: .2s;
                }
                .honest{
                  margin-left: 10px !important;
                  margin-top: -0px;
                  margin-bottom: 10px;
                  //position: relative;
                  .slash_rang{
                    width: 10px;
                    height: 3px;
                    position: absolute;
                    background-color: #454545;
                    bottom: 35px;
                    left: -13px;
                  }
                }

                .finalblock{
                  margin-bottom: -60px;
                }
              }

            }
          }

          .five_cueda{
            border-left: 3px solid #454545;
            margin-left: 20px;
            margin-top: -90px;
            padding: 50px 0 0px 0;
            margin-bottom: 80px;
            //height: calc(100% - 20px);
            .backtop{
              padding-bottom: -30px;
            }
            div{
              //height: calc(100% - 60px);
            }
            .structure_new_forest_cuedo_card_tree{
              width: 250px;
              height: 100%;
              min-height: 80px;
              border-radius: 5px;
              border: 3px solid dodgerblue;
              background-color: #F0F0F0;
              margin-left: 10px;
              //margin-top: 30px;
              margin-bottom: 10px;
              position: relative;
              transition: .2s;
              cursor: pointer;


              .slash_rang{
                width: 10px;
                height: 3px;
                position: absolute;
                background-color: #454545;
                bottom: 55px;
                left: -13px;
              }

              .structure_new_forest_cuedo_card_top{
                width: 225px;
                height: 5px;
              }

              .structure_new_forest_cuedo_card_bottom{
                width: 230px;
                height: 55px;
                padding: 10px;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                font-size: 10pt;
              }

            }

            .structure_new_forest_cuedo_card_tree:hover{
              background-color: #1f1f1f;
              color: #FFF;
              transition: .2s;
            }
            .finalblock{
              margin-bottom: -60px;
            }
          }


          .finalblock{
            margin-bottom: -58px;


          }
        }
        .structure_new_forest_cuedo_dother{
        }
      }

    }

  }
}



@media screen and (min-width: 1600px){
  .glass{
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(200,200,200,0.6);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .glass_board{
      width: 90%;
      height: 90%;
      background-color: rgba(255,255,255,1);
      border-radius: 2px;
      box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
      overflow-y: hidden;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      .glass_board_close{
        width: calc(100% - 30px);
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        i{
          font-size: 20pt;
          color: #454545;
          cursor: pointer;
          transition: 0.05s;
        }
        i:hover{
          color: #CCC;
          transition: 0.05s;
        }
      }
      .glass_board_body{
        width: calc(100% - 80px);
        height: calc(100% - 60px - 60px);
        //border: 1px solid #454545;
        padding: 20px;
        overflow-y: auto;
        overflow-x: hidden;

        .glass_title{
          margin-left: 20px;
          font-weight: 800;
          font-size: 20pt;
          color: #454545;
        }
        .glass_list_persons{
          display: flex;
          flex-direction: column;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          padding: 20px;
          .glass_list_persons_man{
            width: calc(100% - 20px);
            //min-height: 60px;
            border: 1px solid #454545;
            border-radius: 5px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .glass_list_persons_man_photo{
              position: relative;
              width: 60px;
              height: 60px;
              border-radius: 60px;
              border: none;
              background-size: cover;
              background-position: center;
            }
            .glass_list_persons_man_name{
              width: 300px;
              height: 30px;
              margin-left: 20px;
              font-weight: 700;
            }
            .glass_list_persons_man_dev{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_branch{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_phone{
              //width: 120px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_btn{
              cursor: pointer;
              width: 120px;
              height: 30px;
              margin-left: 20px;
              padding: 5px 10px ;
              border: 1px solid #454545;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
            }

          }
        }

      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px){
  .glass{
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(200,200,200,0.6);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .glass_board{
      width: 90%;
      height: 90%;
      background-color: rgba(255,255,255,1);
      border-radius: 2px;
      box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
      overflow-y: hidden;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      .glass_board_close{
        width: calc(100% - 30px);
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        i{
          font-size: 20pt;
          color: #454545;
          cursor: pointer;
          transition: 0.05s;
        }
        i:hover{
          color: #CCC;
          transition: 0.05s;
        }
      }
      .glass_board_body{
        width: calc(100% - 80px);
        height: calc(100% - 60px - 60px);
        //border: 1px solid #454545;
        padding: 20px;
        overflow-y: auto;
        overflow-x: hidden;

        .glass_title{
          margin-left: 20px;
          font-weight: 800;
          font-size: 20pt;
          color: #454545;
        }
        .glass_list_persons{
          display: flex;
          flex-direction: column;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          padding: 20px;
          .glass_list_persons_man{
            width: calc(100% - 20px);
            //min-height: 60px;
            border: 1px solid #454545;
            border-radius: 5px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .glass_list_persons_man_photo{
              width: 60px;
              height: 60px;
              border-radius: 60px;
              border: none;
              background-size: cover;
              margin-left: 20px;
              margin-bottom: 20px;
            }
            .glass_list_persons_man_name{
              width: 300px;
              height: 30px;
              margin-left: 20px;
              font-weight: 700;
            }
            .glass_list_persons_man_dev{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_branch{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_phone{
              //width: 120px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_btn{
              cursor: pointer;
              width: 120px;
              height: 30px;
              margin-left: 20px;
              padding: 5px 10px ;
              border: 1px solid #454545;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
            }
            .glass_list_persons_man_btn:hover{
              background-color: #333;
              color: #FFFFFF;
            }
          }
        }

      }
    }
  }
}
@media screen and (min-width: 1050px) and (max-width: 1200px){
  .glass{
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(200,200,200,0.6);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .glass_board{
      width: 90%;
      height: 90%;
      background-color: rgba(255,255,255,1);
      border-radius: 2px;
      box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
      overflow-y: hidden;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      .glass_board_close{
        width: calc(100% - 30px);
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        i{
          font-size: 20pt;
          color: #454545;
          cursor: pointer;
          transition: 0.05s;
        }
        i:hover{
          color: #CCC;
          transition: 0.05s;
        }
      }
      .glass_board_body{
        width: calc(100% - 80px);
        height: calc(100% - 60px - 60px);
        //border: 1px solid #454545;
        padding: 20px;
        overflow-y: auto;
        overflow-x: hidden;

        .glass_title{
          margin-left: 20px;
          font-weight: 800;
          font-size: 20pt;
          color: #454545;
        }
        .glass_list_persons{
          display: flex;
          flex-direction: column;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          padding: 20px;
          .glass_list_persons_man{
            width: calc(100% - 20px);
            //min-height: 60px;
            border: 1px solid #454545;
            border-radius: 5px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .glass_list_persons_man_photo{
              width: 60px;
              height: 60px;
              border-radius: 60px;
              border: none;
              background-size: cover;
            }
            .glass_list_persons_man_name{
              width: 300px;
              height: 30px;
              margin-left: 20px;
              font-weight: 700;
            }
            .glass_list_persons_man_dev{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_branch{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_phone{
              width: 120px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_btn{
              width: 120px;
              height: 30px;
              margin-left: 20px;
              padding: 5px 10px ;
              border: 1px solid #454545;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
            }
          }
        }

      }
    }
  }
}
@media screen and (min-width: 944px) and (max-width: 1050px){
  .glass{
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(200,200,200,0.6);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .glass_board{
      width: 90%;
      height: 90%;
      background-color: rgba(255,255,255,1);
      border-radius: 2px;
      box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
      overflow-y: hidden;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      .glass_board_close{
        width: calc(100% - 30px);
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        i{
          font-size: 20pt;
          color: #454545;
          cursor: pointer;
          transition: 0.05s;
        }
        i:hover{
          color: #CCC;
          transition: 0.05s;
        }
      }
      .glass_board_body{
        width: calc(100% - 80px);
        height: calc(100% - 60px - 60px);
        //border: 1px solid #454545;
        padding: 20px;
        overflow-y: auto;
        overflow-x: hidden;

        .glass_title{
          margin-left: 20px;
          font-weight: 800;
          font-size: 20pt;
          color: #454545;
        }
        .glass_list_persons{
          display: flex;
          flex-direction: column;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          padding: 20px;
          .glass_list_persons_man{
            width: calc(100% - 20px);
            //min-height: 60px;
            border: 1px solid #454545;
            border-radius: 5px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .glass_list_persons_man_photo{
              width: 60px;
              height: 60px;
              border-radius: 60px;
              border: none;
              background-size: cover;
            }
            .glass_list_persons_man_name{
              width: 300px;
              height: 30px;
              margin-left: 20px;
              font-weight: 700;
            }
            .glass_list_persons_man_dev{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_branch{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_phone{
              width: 120px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_btn{
              width: 120px;
              height: 30px;
              margin-left: 20px;
              padding: 5px 10px ;
              border: 1px solid #454545;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
            }
          }
        }

      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 944px){
  .glass{
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(200,200,200,0.6);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .glass_board{
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255,1);
      border-radius: 2px;
      box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
      overflow-y: hidden;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      .glass_board_close{
        width: calc(100% - 30px);
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        i{
          font-size: 20pt;
          color: #454545;
          cursor: pointer;
          transition: 0.05s;
        }
        i:hover{
          color: #CCC;
          transition: 0.05s;
        }
      }
      .glass_board_body{
        width: calc(100% - 40px);
        height: calc(100% - 60px - 60px);
        //border: 1px solid #454545;
        padding: 20px;
        overflow-y: auto;
        overflow-x: hidden;

        .glass_title{
          margin-left: 20px;
          font-weight: 800;
          font-size: 20pt;
          color: #454545;
        }
        .glass_list_persons{
          display: flex;
          flex-direction: column;
          width: calc(100% - 0px);
          height: calc(100% - 40px);
          padding: 20px 0;
          .glass_list_persons_man{
            width: calc(100% - 20px);
            //min-height: 60px;
            border: 1px solid #454545;
            border-radius: 5px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .glass_list_persons_man_photo{
              width: 60px;
              height: 60px;
              border-radius: 60px;
              border: none;
              background-size: cover;
              margin-left: 20px;
              margin-bottom: 20px;
            }
            .glass_list_persons_man_name{
              width: 300px;
              height: 30px;
              margin-left: 20px;
              font-weight: 700;
            }
            .glass_list_persons_man_dev{
              width: 300px;
              height: 30px;
              margin-left: 20px;
              font-size: 10pt;
              margin-top: 10px;
            }
            .glass_list_persons_man_branch{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_phone{
              //max-width: 120px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_btn{
              width: 120px;
              height: 30px;
              margin-left: 20px;
              padding: 5px 10px ;
              border: 1px solid #454545;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
            }
          }
        }

      }
    }
  }
}
@media screen and (min-width: 375px) and (max-width: 800px){
  .glass{
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(200,200,200,0.6);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .glass_board{
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255,1);
      border-radius: 2px;
      box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
      overflow-y: hidden;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      .glass_board_close{
        width: calc(100% - 30px);
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        i{
          font-size: 20pt;
          color: #454545;
          cursor: pointer;
          transition: 0.05s;
        }
        i:hover{
          color: #CCC;
          transition: 0.05s;
        }
      }
      .glass_board_body{
        width: calc(100% - 40px);
        height: calc(100% - 60px - 60px);
        //border: 1px solid #454545;
        padding: 20px;
        overflow-y: auto;
        overflow-x: hidden;

        .glass_title{
          margin-left: 20px;
          font-weight: 800;
          font-size: 20pt;
          color: #454545;
        }
        .glass_list_persons{
          display: flex;
          flex-direction: column;
          width: calc(100% - 0px);
          height: calc(100% - 40px);
          padding: 20px 0;
          .glass_list_persons_man{
            width: calc(100% - 20px);
            //min-height: 60px;
            border: 1px solid #454545;
            border-radius: 5px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .glass_list_persons_man_photo{
              width: 60px;
              height: 60px;
              border-radius: 60px;
              border: none;
              background-size: cover;
              margin-left: 20px;
              margin-bottom: 20px;
            }
            .glass_list_persons_man_name{
              width: 300px;
              height: 30px;
              margin-left: 20px;
              font-weight: 700;
            }
            .glass_list_persons_man_dev{
              width: 300px;
              height: 30px;
              margin-left: 20px;
              font-size: 10pt;
              margin-top: 10px;
            }
            .glass_list_persons_man_branch{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_phone{
              //max-width: 120px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_btn{
              width: 120px;
              height: 30px;
              margin-left: 20px;
              padding: 5px 10px ;
              border: 1px solid #454545;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
            }
          }
        }

      }
    }
  }
}
@media screen and (max-width: 374px){
  .glass{
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(200,200,200,0.6);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .glass_board{
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255,1);
      border-radius: 2px;
      box-shadow: 0px 0px 10px rgba(150,150,150,0.7);
      overflow-y: hidden;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      .glass_board_close{
        width: calc(100% - 30px);
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        i{
          font-size: 20pt;
          color: #454545;
          cursor: pointer;
          transition: 0.05s;
        }
        i:hover{
          color: #CCC;
          transition: 0.05s;
        }
      }
      .glass_board_body{
        width: calc(100% - 40px);
        height: calc(100% - 60px - 60px);
        //border: 1px solid #454545;
        padding: 20px;
        overflow-y: auto;
        overflow-x: hidden;

        .glass_title{
          margin-left: 20px;
          font-weight: 800;
          font-size: 20pt;
          color: #454545;
        }
        .glass_list_persons{
          display: flex;
          flex-direction: column;
          width: calc(100% - 0px);
          height: calc(100% - 40px);
          padding: 20px 0;
          .glass_list_persons_man{
            width: calc(100% - 20px);
            //min-height: 60px;
            border: 1px solid #454545;
            border-radius: 5px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .glass_list_persons_man_photo{
              width: 60px;
              height: 60px;
              border-radius: 60px;
              border: none;
              background-size: cover;
              margin-left: 20px;
              margin-bottom: 20px;
            }
            .glass_list_persons_man_name{
              width: 300px;
              height: 30px;
              margin-left: 20px;
              font-weight: 700;
            }
            .glass_list_persons_man_dev{
              width: 300px;
              height: 30px;
              margin-left: 20px;
              font-size: 10pt;
              margin-top: 10px;
            }
            .glass_list_persons_man_branch{
              width: 300px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_phone{
              width: 120px;
              height: 30px;
              margin-left: 20px;
            }
            .glass_list_persons_man_btn{
              width: 120px;
              height: 30px;
              margin-left: 20px;
              padding: 5px 10px ;
              border: 1px solid #454545;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
            }
          }
        }

      }
    }
  }
}

.glass_list_persons_man_photo{
  position: relative;
  background-position: center !important;
}

.online1{
  position: absolute;
  color: rgba(75,179,75,1);
  bottom: 0;
  right: 0;
}

.online2{
  position: absolute;
  color: rgba(75,179,75,1);
  right: 0;
  font-size: 1.3rem;
  top:10px;
}

.searching-card{
  display: flex;
  width: calc(100% - 20px);
  border: 3px solid #CCC;
  border-radius: 5px;
  background-color: #F0F0F0;
  padding: 5px;
  .person{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 100%;
    flex-wrap: wrap;
    .photo{
      min-width: 60px;
      height: 60px;
      border-radius: 60px;
      border: none;
      background-size: cover;
      background-position: center;
      position: relative;
    }

    .name,.dev,.branch,.phone{
      margin: 0 10px;
    }
    .button{
      cursor: pointer;
      width: 120px;
      height: 30px;
      margin: 0 20px;
      padding: 5px 10px;
      border: 1px solid #454545;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }
    .button:hover{
      background-color: #333;
      color: #FFFFFF;
    }
  }
}
.cancel-search{
  display: inline-block;
  cursor: pointer;
  font-weight: 300;
  font-size: 0.7rem;
  text-transform: uppercase;
  margin-top: 10px;
  margin-left: 3px;
  width: auto;
}
.cancel-search:hover{
  font-weight: 400;
  text-decoration: underline;
}

.structure_new_forest_cuedo_card_center_content_person_disc{
  .structure_new_forest_cuedo_card_center_content_person_disc_name{
    font-size: 0.85rem !important;
  }
  .structure_new_forest_cuedo_card_center_content_person_disc_dev{
    font-size: 0.7rem !important;
  }
}
.online-status{
  position: absolute;
  font-size: 0.6rem;
  bottom: 8px;
  right: -160px;
  width: 150px;
  color: #aaaaaa;
}
.structure_new_forest_cuedo_card_center_content_person_contact {
  top:0;
  left:0;
}