.setting-main{
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h5{
    text-align: center;
    font-size: 1.3rem;
  }
  .link{
    width: 100%;
  }
  .list{
    display: flex;
    width: 20%;
    flex-direction: column;
    border: 3px solid #ccc;
    padding: 10px;
    overflow-y: auto;
    .head{
      font-weight: 700;
      text-align: center;
      border-bottom: 3px solid #ccc;
      padding-bottom: 10px;
      text-transform: uppercase;
    }
    .item{
      margin: 10px 0;
      cursor: pointer;
      font-size: 0.9rem;
      text-align: center;
      border: 2px solid #ccc;
      padding: 4px;
    }
    .item:hover{
      border: 2px solid rgba(5, 5, 5, 0.7);
    }
  }
  .left{
    margin-right: 20px;
  }
  .rigth{
    margin-left: 20px;
  }
  .setting-blocks{
    height:100%;
    width: 60%;
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header-btns{
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 20px;
      .button{
        margin: 0 10px;
        padding: 20px 20px;
        border: 1px solid #CCC;
        border-radius: 4px;
        cursor: pointer;
        color: #454545;
        transition: 0.2s;
      }
      .green{
        background-color: darkgreen;
        color: #FFFFFF;
      }
    }
    .box{
      width: 100%;
      height: 500px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .setting{
        position: relative;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border:2px solid #ccc;
        z-index: 1;
        width: 30%;
        height: 200px;
        border-radius: 10px;
        div.text{
          padding: 5px;
          text-align: center;
          margin: 5px 0;
          color: #FFFFFF;
          font-weight: 600;
        }
        .name{
          font-size: 1.2rem;
        }
        .title{

        }
        .overlay{
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 10px;
          z-index: -1;
          width: 100%;
          height: 100%;
          background-color: rgba(1,1,1,0.6);
        }
      }
    }

  }

}