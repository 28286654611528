.container{
  display: flex;
  flex-direction: column;
  .title{
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #555;
  }
  .btns{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .yearbutton,.monthbutton{
      display: flex;
      padding: 20px 40px;
      margin: 10px;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: 800;
      background-image: url("../../assets/images/noise.avif");
      background-repeat: repeat;
      background-color: rgba(18, 19, 56, 0.6);
      color: rgba(255,255,255,0.8);
      border-radius: 3px;
      transition: all 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
      &:hover{
        background-color:rgba(18, 19, 56, 0.4);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25), 0 4px 6px rgba(0, 0, 0, 0.1);
      }
      &.active {
        background-color:rgba(18, 19, 56, 1);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1);
        transform: translateY(2px);
      }
    }
    .actionbutton{
      background-color:rgba(1, 1, 1, 0.3);
      &:hover{
        background-color:rgba(1, 1, 1, 0.6);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25), 0 4px 6px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .ogmlistbtns{
    display: flex;
    .ogmlistupload{
      background-color: rgb(18, 19, 56);
      color: white;
      padding: 10px 16px 10px 10px;
      width: 200px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      margin: 0 20px 0 0;
      border-radius: 2px;
      transition: .2s;
    }
    .ogmlistupload:hover{
      opacity: 0.8;
      transition: .2s;
    }
    .ogmlistsave{
      background-color: rgb(18, 19, 56);
      color: white;
      padding: 10px 16px 10px 10px;
      width: 200px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      margin: 0 20px 0 0;
      border-radius: 2px;
      transition: .2s;}
    .info{
      display: flex;
      flex-direction: row;
      .selectblock{
        height: 40px;
        display: flex;
        min-width: 300px;
        .selectblocktitle{
          display: flex;
          align-items: center;
          margin-right: 20px;
          margin-bottom: 10px;
        }
        .select{
          display: flex;
          align-items: center;
          min-width:200px;
          width: 200px;
        }
      }
    }
    .disable{
      background-color: rgb(100, 100, 100) !important;
    }
  }
  .ogmlist{
    width: 100%;
    height: 100%;
    .ogmlisttitle{
      font-size: 18pt;
      font-weight: 700;
      margin-bottom: 10px;
    }


    .ogmlist_list{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      margin-top: 10px;
      .dop-info{
        border-bottom: 4px solid #CCC;
        width: 1220px;
        padding: 20px 0;
        .item{
          border: 2px solid #CCC;
        }
        .table-container {
          width: 1200px;
          overflow-x: auto; /* добавляем горизонтальную прокрутку, если таблица шире контейнера */
        }
        .table {
          display: flex;
          flex-direction: column;
        }
        .rowsmall {
          display: flex;
        }
        .cell {
          border: 1px solid #000;
          padding: 3px;
          font-size: 0.6rem;
          min-width: 30px;
          max-width: 32px;
        }
        .middle-column {
          min-width: 60px; /* задаем ширину для "широких" столбцов */
          max-width: 60px;
        }
        .wide-column {
          min-width: 120px; /* задаем ширину для "широких" столбцов */
          max-width: 120px;
        }
        .wbig-column{
          min-width: 300px; /* задаем ширину для "широких" столбцов */
          max-width: 300px;
        }
        .big-column{
          min-width: 200px; /* задаем ширину для "широких" столбцов */
          max-width: 200px;
        }
      }
      .title{
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
      }
      .ogmlist_list_line > * {
        display: flex;
        align-self: stretch;
        height: auto !important;
      }
      .ogmlist_list_line{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: fit-content;
        min-height: 40px;
        border: 4px solid #CCC;
        font-size: 10pt;
        align-self: stretch;
        .ogmlist_list_line_name{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch !important;
          width: 300px;
          min-height: 40px;
          height: 100%;
          border: none;
          border-right: 4px solid #CCC;
          background-color: #F1F1F1;
        }
        .ogmlist_list_line_price{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          width: 280px;
          padding: 0 10px;
          min-height: 40px;
          height: auto !important;
          border: none;
          border-right: 4px solid #CCC;
        }
        .ogmlist_list_line_group{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          width: 280px;
          padding: 0 10px;
          min-height: 40px;

          border: none;
          border-right: 4px solid #CCC;
        }
        .ogmlist_list_line_cropname{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 280px;
          padding: 0 10px;

          height: 100%;
          border: none;
        }
        .icons-container{
          width: 40px;
          margin-left: -40px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
        }
        .ogmlistlistlinedel{
          margin-left: -20px;
          width: 20px;
          height: 20px;
          font-size: 16pt;
          margin-top: 5px;
          font-weight: 700;
          color: #CCC;
          transition: .2s;
          cursor: pointer;
        }
        .ogmlist_list_line_del:hover{
          color: #454545;
          transition: .2s;
        }
        .title{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 280px;
          min-height: 40px;
          height: 100%;
          border: none;
          border-right: 4px solid #CCC;
          font-weight: 700;
          font-size: 12pt;
          background-color: #F1F1F1;
        }
        .nametitle{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 300px;
          height: 100%;
          border: none;
          border-right: 4px solid #CCC;
          font-weight: 700;
          font-size: 12pt;
          background-color: #F1F1F1;
        }
        .borderrightnone{
          border-right: none;
        }
        .leftborder{
          border-left: 4px solid #CCC;
        }
        .inputs{
          outline: none;
          background-color: #FFFFFF;
        }
        .inputs:focus{
          outline: none;
          background-color: #9aa9ce;
          width: 280px;
          padding: 0 10px;

        }
        .button{
          text-decoration: underline;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 0.6rem;
          font-weight: 600;
        }.button:hover{
           color: white;
           background-color: rgba(18,19,56,1);
         }
      }
      .bordertopnone{
        border-top: none;
      }

    }
    .newlineogm{
      padding: 20px;
      border: 4px dotted #CCC;
      width: fit-content;
      border-radius: 20px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      .line{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .line_title{
          font-size: 16pt;
          font-weight: 800;
          margin-right: 20px;
        }
      }
      .ogmlist_list_line_new{
        width: 251px;
        padding: 9px;
        margin-right: 20px;
        outline: none;
        border: 2px solid #454545;
        border-radius: 5px;
      }
      .small{
        width: 100px;
      }
      .ogmlist_plus{
        background-color: rgb(18, 19, 56);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 247px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
        margin: 0 20px 0 0;
        border-radius: 2px;
        transition: .2s;
        margin-top: 10px;
      }
      .ogmlist_plus:hover{
        opacity: 0.8;
        transition: .2s;
      }
    }
  }
  .ogmlistlist{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    .dopinfo{
      border-bottom: 4px solid #CCC;
      width: 1220px;
      padding: 20px 0;
      .item{
        border: 2px solid #CCC;
      }
      .tablecontainer {
        width: 1200px;
        overflow-x: auto;
      }
      .table{
        display: flex;
        flex-direction: column;
      }
      .rowsmall {
        display: flex;
      }
      .cell {
        border: 1px solid #000;
        padding: 3px;
        font-size: 0.6rem;
        min-width: 30px;
        max-width: 32px;
      }
      .middlecolumn {
        min-width: 60px;
        max-width: 60px;
      }
      .widecolumn {
        min-width: 120px;
        max-width: 120px;
      }
      .wbigcolumn{
        min-width: 300px;
        max-width: 300px;
      }
      .bigcolumn{
        min-width: 200px;
        max-width: 200px;
      }
    }
    .title{
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    .ogmlistlistline > * {
      display: flex;
      align-self: stretch;
      height: auto !important;
    }
    .ogmlistlistline{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: fit-content;
      min-height: 40px;
      border: 4px solid #CCC;
      font-size: 10pt;
      align-self: stretch;
      .ogmlistlistlinename{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch !important;
        width: 300px;
        min-height: 40px;
        height: 100%;
        border: none;
        border-right: 4px solid #CCC;
        background-color: #F1F1F1;
      }
      .ogmlistlistlineprice{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        width: 280px;
        padding: 0 10px;
        min-height: 40px;
        height: auto !important;
        border: none;
        border-right: 4px solid #CCC;
      }
      .ogmlistlistlinegroup{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        width: 280px;
        padding: 0 10px;
        min-height: 40px;

        border: none;
        border-right: 4px solid #CCC;
      }
      .ogmlistlistlinecropname{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 280px;
        padding: 0 10px;
        height: 100%;
        border: none;
      }
      .iconscontainer{
        width: 40px;
        margin-left: -40px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
      .ogmlistlistlinedel{
        margin-left: -20px;
        width: 20px;
        height: 20px;
        font-size: 16pt;
        margin-top: 5px;
        font-weight: 700;
        color: #CCC;
        transition: .2s;
        cursor: pointer;
      }
      .ogmlistlistlinedel:hover{
        color: #454545;
        transition: .2s;
      }
      .title{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 280px;
        min-height: 40px;
        height: 100%;
        border: none;
        border-right: 4px solid #CCC;
        font-weight: 700;
        font-size: 12pt;
        background-color: #F1F1F1;
      }
      .nametitle{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 100%;
        border: none;
        border-right: 4px solid #CCC;
        font-weight: 700;
        font-size: 12pt;
        background-color: #F1F1F1;
      }
      .borderrightnone{
        border-right: none;
      }
      .leftborder{
        border-left: 4px solid #CCC;
      }
      .inputs{
        outline: none;
        background-color: #FFFFFF;
      }
      .inputs:focus{
        outline: none;
        background-color: #9aa9ce;
        width: 280px;
        padding: 0 10px;

      }
      .button{
        text-decoration: underline;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 600;
      }.button:hover{
         color: white;
         background-color: rgba(18,19,56,1);
       }
    }
    .bordertopnone{
      border-top: none;
    }

  }
  .newlineogm{
    padding: 20px;
    border: 4px dotted #CCC;
    width: fit-content;
    border-radius: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .line{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .linetitle{
        font-size: 16pt;
        font-weight: 800;
        margin-right: 20px;
      }
    }
    .ogmlistlistlinenew{
      width: 251px;
      padding: 9px;
      margin-right: 20px;
      outline: none;
      border: 2px solid #454545;
      border-radius: 5px;
    }
    .small{
      width: 100px;
    }
    .ogmlistplus{
      background-color: rgb(18, 19, 56);
      color: white;
      padding: 10px 16px 10px 10px;
      width: 247px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      margin: 0 20px 0 0;
      border-radius: 2px;
      transition: .2s;
      margin-top: 10px;
    }
    .ogmlistplus:hover{
      opacity: 0.8;
      transition: .2s;
    }
  }
  .copy{
    .selectcontainer{
      padding: 20px;
    }
    .buttons{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      .button{
        display: flex;
        padding: 20px 40px;
        margin: 10px;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 800;
        background-image: url("../../assets/images/noise.avif");
        background-repeat: repeat;
        background-color: rgba(18, 19, 56, 0.6);
        color: rgba(255,255,255,0.8);
        border-radius: 3px;
        transition: all 0.3s ease;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
        &:hover{
          background-color:rgba(18, 19, 56, 0.4);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25), 0 4px 6px rgba(0, 0, 0, 0.1);
        }
        &.active {
          background-color:rgba(18, 19, 56, 1);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1);
          transform: translateY(2px);
        }
      }
      .da{
        background-color: rgba(180, 19, 56, 0.6);
      }
    }
  }


}
