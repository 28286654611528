@media screen and (min-width: 1350px){
  .cms-head{
    padding: 20px;
    h5{
      margin: 5px;
      font-size: 0.9rem;
    }
    p{
      margin: 5px;
      font-size: 0.8rem;
    }
    span{
      font-weight: bold;

      display: block;
    }
  }
  .small-inputs,.phone{
    display: flex;
    flex-direction: column;
    width: 30%;
    input{
      width: 80%;
      margin: 10px;
      border-radius: 5px;
      padding: 10px;
      border: none;
      border-bottom: 1px solid #CCC;
      outline: none;
    }
  }
  .flex{
    display: flex;
  }
  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  .gallery-item {
    width: calc(33.33% - 20px);
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 320px;
    cursor: pointer;
    position: relative;
  }
  .gallery-item .img {
    width: 100%;
    height: 88%;
    display: block;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

  }
  .text{

    padding: 10px;
  }
  .image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  .image-text {
    font-size: 14px;
    font-weight: bold;
  }
  .fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .fullscreen-container{
      width: 90%;
      height: 80%;
      background-color: rgba(20,20,20,1);
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      position: relative;

      .fullscreen-image {
        position: relative;
        width: 80%;
        height: 100%;
        border-radius: 10px 0 0 10px;
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        z-index: 2;

        .prev-button,
        .next-button {
          position: absolute;
          height: 100%;
          width: calc(50% - 40px);
          padding: 0 20px;
          top: 0px;
          left: 0px;
          //transform: translateY(-50%);
          background-color: transparent;
          border: none;
          color: #999;
          font-size: 24px;
          cursor: pointer;
          z-index: 3000000;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .next-button{
          left: 50%;
          justify-content: flex-end;
        }
        .prev-button:hover,
        .next-button:hover{
          i{
            color: #fff;
          }
        }
        img{
          margin-top: -40px;
          max-height: calc(100% - 40px);
          max-width: 100%;
        }
        .image-description {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 20px;
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }


      }
      .data-container{
        width: 20%;
        background-color: #eee;
        border-radius: 0 10px 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .buttons{
          width: 100%;
          margin: 0;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .button{
            border-radius: 20px;
            padding: 10px 40px;
            margin: 20px 0;
            font-size: 0.7rem !important;
            i{
              font-size: 1.6rem;
            }
          }
          .button.cancel{
            background-color: rgba(40,40,40,0.2);
          }
          .cancel:hover{
            background-color: rgba(118,19,2,1);
          }
        }
        .info{

        }

        .about{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          .avatar{
            min-width: 40px;
            width: 40px;
            height: 40px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 100%;
          }
          .text{
            font-size: 0.7rem;
            .name{
              color: rgba(18,19,56,1);
              font-size: 0.8rem;
            }
            .date{
              color: #aaa;

            }
          }
        }
        .answers{
          display: flex;
          align-items: center;
          margin: 0 5px;
          font-size: 0.9rem;
          span{
            font-size: 0.8rem;
            color: #aaa;
          }
          i{
            font-size: 1.4rem;
            cursor: pointer;
            color: rgba(18,19,56,1);
            margin-right: 7px;
          }
          .fa-calendar{
            margin-left: 20px;
            font-size: 1.3rem;
          }
          padding: 10px;
        }
      }
    }
    .overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(5px);
      z-index: -1;
      cursor: pointer;
    }
    .close-fullscreen{
      position: absolute;
      top:20px;
      right: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      i{
        font-size: 1.5rem;
        color: #cccccc;
      }
    }
  }
  hr{
    width: 100%;
    height: 1px;
    border: none;
    background-color: #cccccc;
  }
  .prev-button {
    left: 20px;
  }
  .next-button {
    right: 20px;
  }
  .small-text{
    color: #aaa;
    margin: 20px 0;
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .radio-button-container{
    .radio-button input[type=radio] {
      max-width: 18px;
      min-width: 18px;
      width: 18px;
      height: 18px;
    }
    font-size: 0.8rem;
    label{
      min-height: 30px;
    }
  }
  .img{
    position: relative;
  }
  .nomination-text{
    display: none;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1,1,1,0.7);
    justify-content: center;
    align-items: center;
    p{
      text-align: center;
      font-weight: 600;
      color: #FFFFFF;
      font-size: 2rem;
      text-transform: uppercase;
    }
  }
  .send-contest{
    position: fixed;
    bottom: 4%;
    cursor: pointer;
    left: calc(50% - 200px);
    background-color: rgba(11,181,11,0.9);
    color: white;
    min-width: 300px;
    max-width: 500px;
    width: 1000px;
    height: 50px;
    padding: 20px 60px;
    border-radius: 20px;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 600;
    display: none;
    align-items: center;
    justify-content: center;
  }
  .send-contest.grey{
    background-color: rgba(11,11,11,0.4);
    color: white;
    font-size: 1.2rem;
  }
  .bold{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p{
      font-size: 1.2rem;
      margin: 10px;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
    }
  }
  .information{

  }
}

@media screen and (min-width: 820px) and (max-width: 1350px){
  .cms-head{
    padding: 20px;
    h5{
      margin: 5px;
      font-size: 0.9rem;
    }
    p{
      margin: 5px;
      font-size: 0.8rem;
    }
    span{
      font-weight: bold;

      display: block;
    }
  }
  .small-inputs,.phone{
    display: flex;
    flex-direction: column;
    width: 50%;
    input{
      width: 80%;
      margin: 10px;
      border-radius: 5px;
      padding: 10px;
      border: none;
      border-bottom: 1px solid #CCC;
      outline: none;
    }
  }
  .flex{
    display: flex;
  }
  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  .gallery-item {
    width: calc(33.33% - 20px);
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 320px;
    cursor: pointer;
    position: relative;
  }
  .gallery-item .img {
    width: 100%;
    height: 88%;
    display: block;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

  }
  .text{

    padding: 10px;
  }
  .image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  .image-text {
    font-size: 14px;
    font-weight: bold;
  }
  .fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .fullscreen-container{
      width: 90%;
      height: 80%;
      background-color: rgba(20,20,20,1);
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      position: relative;

      .fullscreen-image {
        position: relative;
        width: 60%;
        height: 100%;
        border-radius: 10px 0 0 10px;
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        z-index: 2;

        .prev-button,
        .next-button {
          position: absolute;
          height: 100%;
          width: calc(50% - 40px);
          padding: 0 20px;
          top: 0px;
          left: 0px;
          //transform: translateY(-50%);
          background-color: transparent;
          border: none;
          color: #999;
          font-size: 24px;
          cursor: pointer;
          z-index: 3000000;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .next-button{
          left: 50%;
          justify-content: flex-end;
        }
        .prev-button:hover,
        .next-button:hover{
          i{
            color: #fff;
          }
        }
        img{
          margin-top: -40px;
          max-height: calc(100% - 40px);
          max-width: 100%;
        }
        .image-description {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 20px;
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }


      }
      .data-container{
        width: 40%;
        background-color: #eee;
        border-radius: 0 10px 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .buttons{
          width: 100%;
          margin: 0;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .button{
            border-radius: 20px;
            padding: 10px 40px;
            margin: 20px 0;
            font-size: 0.7rem !important;
            i{
              font-size: 1.6rem;
            }
          }
          .button.cancel{
            background-color: rgba(40,40,40,0.2);
          }
          .cancel:hover{
            background-color: rgba(118,19,2,1);
          }
        }
        .info{

        }

        .about{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          .avatar{
            min-width: 40px;
            width: 40px;
            height: 40px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 100%;
          }
          .text{
            font-size: 0.7rem;
            .name{
              color: rgba(18,19,56,1);
              font-size: 0.8rem;
            }
            .date{
              color: #aaa;

            }
          }
        }
        .answers{
          display: flex;
          align-items: center;
          margin: 0 5px;
          font-size: 0.9rem;
          span{
            font-size: 0.8rem;
            color: #aaa;
          }
          i{
            font-size: 1.4rem;
            cursor: pointer;
            color: rgba(18,19,56,1);
            margin-right: 7px;
          }
          .fa-calendar{
            margin-left: 20px;
            font-size: 1.3rem;
          }
          padding: 10px;
        }
      }
    }
    .overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(5px);
      z-index: -1;
      cursor: pointer;
    }
    .close-fullscreen{
      position: absolute;
      top:20px;
      right: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      i{
        font-size: 1.5rem;
        color: #cccccc;
      }
    }
  }
  hr{
    width: 100%;
    height: 1px;
    border: none;
    background-color: #cccccc;
  }
  .prev-button {
    left: 20px;
  }
  .next-button {
    right: 20px;
  }
  .small-text{
    color: #aaa;
    margin: 20px 0;
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .radio-button-container{
    .radio-button input[type=radio] {
      max-width: 18px;
      min-width: 18px;
      width: 18px;
      height: 18px;
    }
    font-size: 0.8rem;
    label{
      min-height: 30px;
    }
  }
  .img{
    position: relative;
  }
  .nomination-text{
    display: none;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1,1,1,0.7);
    justify-content: center;
    align-items: center;
    p{
      text-align: center;
      font-weight: 600;
      color: #FFFFFF;
      font-size: 2rem;
      text-transform: uppercase;
    }
  }
  .send-contest{
    position: fixed;
    bottom: 4%;
    cursor: pointer;
    left: calc(50% - 200px);
    background-color: rgba(11,181,11,0.9);
    color: white;
    min-width: 300px;
    max-width: 500px;
    width: 1000px;
    height: 50px;
    padding: 20px 60px;
    border-radius: 20px;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 600;
    display: none;
    align-items: center;
    justify-content: center;
  }
  .send-contest.grey{
    background-color: rgba(11,11,11,0.4);
    color: white;
    font-size: 1.2rem;
  }
  .bold{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p{
      font-size: 1.2rem;
      margin: 10px;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
    }
  }
  .information{

  }
}

@media screen and (min-width: 400px) and (max-width: 820px){
  .cms-head{
    padding: 20px;
    h5{
      margin: 5px;
      font-size: 0.9rem;
    }
    p{
      margin: 5px;
      font-size: 0.8rem;
    }
    span{
      font-weight: bold;

      display: block;
    }
  }
  .small-inputs,.phone{
    display: flex;
    flex-direction: column;
    width: 100%;
    input{
      width: 80%;
      margin: 10px;
      border-radius: 5px;
      padding: 10px;
      border: none;
      border-bottom: 1px solid #CCC;
      outline: none;
    }
  }
  .flex{
    display: flex;
  }
  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  .gallery-item {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 320px;
    cursor: pointer;
    position: relative;
  }
  .gallery-item .img {
    width: 100%;
    height: 88%;
    display: block;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

  }
  .text{

    padding: 10px;
  }
  .image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  .image-text {
    font-size: 14px;
    font-weight: bold;
  }
  .fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .fullscreen-container{
      width: 90%;
      height: 88%;
      margin-top: 20px;
      background-color: rgba(20,20,20,1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      position: relative;

      .fullscreen-image {
        position: relative;
        width: 100%;
        height: 50%;
        border-radius: 10px 0 0 10px;
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        z-index: 2;

        .prev-button,
        .next-button {
          position: absolute;
          height: 100%;
          width: calc(50% - 40px);
          padding: 0 20px;
          top: 0px;
          left: 0px;
          //transform: translateY(-50%);
          background-color: transparent;
          border: none;
          color: #999;
          font-size: 24px;
          cursor: pointer;
          z-index: 3000000;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .next-button{
          left: 50%;
          justify-content: flex-end;
        }
        .prev-button:hover,
        .next-button:hover{
          i{
            color: #fff;
          }
        }
        img{
          margin-top: -40px;
          max-height: calc(100% - 40px);
          max-width: 100%;
        }
        .image-description {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 20px;
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }


      }
      .data-container{
        width: 100%;
        height: 50%;
        background-color: #eee;
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .buttons{
          width: 100%;
          margin: 0;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .button{
            width: calc(100% - 90px);
            border-radius: 20px;
            padding: 10px 40px;
            margin: 2px 0;
            font-size: 0.7rem !important;
            i{
              font-size: 1.6rem;
            }
          }
          .button.cancel{
            background-color: rgba(40,40,40,0.2);
          }
          .cancel:hover{
            background-color: rgba(118,19,2,1);
          }
        }
        .info{

        }

        .about{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          .avatar{
            min-width: 40px;
            width: 40px;
            height: 40px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 100%;
          }
          .text{
            font-size: 0.7rem;
            .name{
              color: rgba(18,19,56,1);
              font-size: 0.8rem;
            }
            .date{
              color: #aaa;

            }
          }
        }
        .answers{
          display: flex;
          align-items: center;
          margin: 0 5px;
          font-size: 0.9rem;
          span{
            font-size: 0.8rem;
            color: #aaa;
          }
          i{
            font-size: 1.4rem;
            cursor: pointer;
            color: rgba(18,19,56,1);
            margin-right: 7px;
          }
          .fa-calendar{
            margin-left: 20px;
            font-size: 1.3rem;
          }
          padding: 10px;
        }
      }
    }
    .overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(5px);
      z-index: -1;
      cursor: pointer;
    }
    .close-fullscreen{
      position: absolute;
      top:20px;
      right: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      i{
        font-size: 1.5rem;
        color: #cccccc;
      }
    }
  }
  hr{
    width: 100%;
    height: 1px;
    border: none;
    background-color: #cccccc;
  }
  .prev-button {
    left: 20px;
  }
  .next-button {
    right: 20px;
  }
  .small-text{
    color: #aaa;
    margin: 20px 0;
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .radio-button-container{
    .radio-button input[type=radio] {
      max-width: 18px;
      min-width: 18px;
      width: 18px;
      height: 18px;
    }
    font-size: 0.7rem;
    label{
      min-height: 20px;
    }
  }
  .img{
    position: relative;
  }
  .nomination-text{
    display: none;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1,1,1,0.7);
    justify-content: center;
    align-items: center;
    p{
      text-align: center;
      font-weight: 600;
      color: #FFFFFF;
      font-size: 2rem;
      text-transform: uppercase;
    }
  }
  .send-contest{
    position: fixed;
    bottom: 4%;
    cursor: pointer;
    left: calc(50% - 200px);
    background-color: rgba(11,181,11,0.9);
    color: white;
    min-width: 300px;
    max-width: 500px;
    width: 1000px;
    height: 50px;
    padding: 20px 60px;
    border-radius: 20px;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 600;
    display: none;
    align-items: center;
    justify-content: center;
  }
  .send-contest.grey{
    background-color: rgba(11,11,11,0.4);
    color: white;
    font-size: 1.2rem;
  }
  .bold{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p{
      font-size: 1.2rem;
      margin: 10px;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
    }
  }
  .information{

  }

}

@media screen and (max-width: 400px){
  .cms-head{
    padding: 20px;
    h5{
      margin: 5px;
      font-size: 0.9rem;
    }
    p{
      margin: 5px;
      font-size: 0.8rem;
    }
    span{
      font-weight: bold;

      display: block;
    }
  }
  .small-inputs,.phone{
    display: flex;
    flex-direction: column;
    width: 100%;
    input{
      width: 80%;
      margin: 10px;
      border-radius: 5px;
      padding: 10px;
      border: none;
      border-bottom: 1px solid #CCC;
      outline: none;
    }
  }
  .flex{
    display: flex;
  }
  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  .gallery-item {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 320px;
    cursor: pointer;
    position: relative;
  }
  .gallery-item .img {
    width: 100%;
    height: 88%;
    display: block;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

  }
  .text{

    padding: 10px;
  }
  .image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  .image-text {
    font-size: 14px;
    font-weight: bold;
  }
  .fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .fullscreen-container{
      width: 90%;
      height: 88%;
      margin-top: 20px;
      background-color: rgba(20,20,20,1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      position: relative;

      .fullscreen-image {
        position: relative;
        width: 100%;
        height: 50%;
        border-radius: 10px 0 0 10px;
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        z-index: 2;

        .prev-button,
        .next-button {
          position: absolute;
          height: 100%;
          width: calc(50% - 40px);
          padding: 0 20px;
          top: 0px;
          left: 0px;
          //transform: translateY(-50%);
          background-color: transparent;
          border: none;
          color: #999;
          font-size: 24px;
          cursor: pointer;
          z-index: 3000000;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .next-button{
          left: 50%;
          justify-content: flex-end;
        }
        .prev-button:hover,
        .next-button:hover{
          i{
            color: #fff;
          }
        }
        img{
          margin-top: -40px;
          max-height: calc(100% - 40px);
          max-width: 100%;
        }
        .image-description {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 20px;
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }


      }
      .data-container{
        width: 100%;
        height: 55%;
        background-color: #eee;
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .buttons{
          width: 100%;
          margin: 0;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .button{
            width: calc(100% - 90px);
            border-radius: 20px;
            padding: 5px 30px;
            margin: 2px 0;
            font-size: 0.7rem !important;
            i{
              font-size: 1.6rem;
            }
          }
          .button.cancel{
            background-color: rgba(40,40,40,0.2);
          }
          .cancel:hover{
            background-color: rgba(118,19,2,1);
          }
        }
        .info{

        }

        .about{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 5px;
          .avatar{
            min-width: 30px;
            width: 30px;
            height: 30px;
            margin-left: 10px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 100%;
          }
          .text{
            font-size: 0.6rem;
            .name{
              color: rgba(18,19,56,1);
              font-size: 0.8rem;
            }
            .date{
              color: #aaa;

            }
          }
        }
        .answers{
          display: flex;
          align-items: center;
          margin: 0 5px;
          font-size: 0.9rem;
          span{
            font-size: 0.8rem;
            color: #aaa;
          }
          i{
            font-size: 1.0rem;
            cursor: pointer;
            color: rgba(18,19,56,1);
            margin-right: 7px;
          }
          .fa-calendar{
            margin-left: 20px;
            font-size: 1.0rem;
          }
          padding: 10px;
        }
      }
    }
    .overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(5px);
      z-index: -1;
      cursor: pointer;
    }
    .close-fullscreen{
      position: absolute;
      top:20px;
      right: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      i{
        font-size: 1.3rem;
        color: #cccccc;
      }
    }
  }
  hr{
    width: 100%;
    height: 1px;
    border: none;
    background-color: #cccccc;
  }
  .prev-button {
    left: 20px;
  }
  .next-button {
    right: 20px;
  }
  .small-text{
    color: #aaa;
    margin: 20px 0;
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .radio-button-container{
    .radio-button input[type=radio] {
      max-width: 18px;
      min-width: 18px;
      width: 18px;
      height: 18px;
    }
    font-size: 0.7rem;
    label{
      min-height: 20px;
    }
  }
  .img{
    position: relative;
  }
  .nomination-text{
    display: none;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1,1,1,0.7);
    justify-content: center;
    align-items: center;
    p{
      text-align: center;
      font-weight: 600;
      color: #FFFFFF;
      font-size: 2rem;
      text-transform: uppercase;
    }
  }
  .send-contest{
    position: fixed;
    bottom: 4%;
    cursor: pointer;
    left: calc(50% - 200px);
    background-color: rgba(11,181,11,0.9);
    color: white;
    min-width: 300px;
    max-width: 500px;
    width: 1000px;
    height: 50px;
    padding: 20px 60px;
    border-radius: 20px;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 600;
    display: none;
    align-items: center;
    justify-content: center;
  }
  .send-contest.grey{
    background-color: rgba(11,11,11,0.4);
    color: white;
    font-size: 1.2rem;
  }
  .bold{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p{
      font-size: 1.2rem;
      margin: 10px;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
    }
  }
  .information{

  }
}

.results-contest{
  width: 100%;
  .granpri{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h2{
      margin-bottom: 20px;
    }
    h3{
      display: flex;
      text-align: center;
    }
    i{
      margin-top: 10px;
      font-size: 3rem;
      color:rgba(225,195,0,1);

    }
  }
  .work{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px;
    .name{
      font-size: 1rem;
      font-weight: 600;
      margin-top: 10px;
      text-align: center;
    }
    .kidwork{
      margin-top: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 330px;
      height: 220px;
      border-radius: 20px;
    }
  }
}
.win-nomi{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  i{
    color:rgba(225,195,0,1);
    margin-top: 10px;
    font-size: 3rem;
  }
}

.border-golos{
  border: 1px solid #333;
  margin: 3px;
  padding: 3px;
}
.results{
  overflow: scroll;
  max-height: 65vh;
}