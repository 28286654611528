.container{
  margin: 20px;

  .buttons{
    margin: 20px;
    display: flex;
    flex-direction: row;
    .button{
      border-radius: 3px;
      padding: 10px;
      font-size: 0.8rem;
      margin: 0 10px 0 0;
      cursor: pointer;
      &:hover{
        background-color: rgba(217,217,217,1);
      }
      i{
        margin: 0 5px;
        font-size: 0.8rem;
      }
    }

    .grey{
      background-color:rgba(18,19,56,1);
      color: rgba(236,236,236,1);
      padding: 10px 16px 10px 10px;
    }
    .grey:hover{
      background-color: rgba(97,118,118,1);
    }
  }
  .filesheader{
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    height: 50px;
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .gohome{
      display: flex;
      flex-direction: row;
      align-items: center;
      i{
        font-size: 1.6rem;
        color: #888;
        cursor: pointer;
        margin-right: 20px;
      }
      &:hover{
        color: #333;
      }
      .path{
        font-size: 0.7rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        span{
          margin: 0 10px;
        }
      }
    }

  }
  .explorer{
    margin: 20px 0;
    user-select:none;
    .folder{
      &:hover{
        background-color: rgba(1,1,1,0.1);
      }
      .file{
        cursor: pointer;
      }
      .selected{
        background-color: rgba(1,1,1,0.1);
      }
    }
    .back{
      display: flex;
      flex-direction: row;
      cursor: pointer;
      align-items: center;
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: bold;
      margin: 3px 0;
      i{
        margin-top: 1px;
        margin-right: 5px;
        font-size: 1rem;
      }
    }
  }
  .sendlabel{
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 10px 0;
    display: block;
  }
  .notifier{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    textarea{
      width: calc(85% - 50px);
      resize: none;
      padding: 15px 20px;
      min-height: 40px;
      border-radius: 20px 0 0 20px;
      outline: none;
    }
    .notify{
      width: 15%;
      text-align: center;
      height: 72px;
      margin-left: -8px;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 0.9rem;
      font-weight: 800;
      background-image: url("../../assets/images/noise.avif");
      background-repeat: repeat;
      background-color: rgba(18, 19, 56, 0.6);
      color: rgba(255, 255, 255, 0.8);
      border-radius: 0 20px 20px 0;
      transition: all 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
      &:hover{
        background-color:rgba(18, 19, 56, 0.4);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25), 0 4px 6px rgba(0, 0, 0, 0.1);
      }
      &.active {
        background-color:rgba(18, 19, 56, 1);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1);
        transform: translateY(2px);
      }
    }
  }

}