
.big_workpage{
  margin-left: 80px !important;
  width: calc(100% - 40px) !important;
  padding: 20px !important;
}

@media screen and (min-width: 1200px){
  .workpage_block{
    margin-left: 250px;
    width: calc(100% - 40px);
    padding: 20px;
  }

  .workpage_block_slider{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (min-width: 1050px) and (max-width: 1200px){
  .workpage_block{
    margin-left: 250px;
    width: calc(100% - 40px);
    padding: 20px;
  }

  .workpage_block_slider{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
}
@media screen and (min-width: 800px) and (max-width: 1050px){
  .workpage_block{
    margin-left: 60px;
    width: calc(100% - 40px);
    padding: 20px;
  }

  .workpage_block_slider{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
}
@media screen and (max-width: 800px){
  .workpage_block{
    margin-left: 0;
    width: calc(100% - 40px);
    padding: 20px;
  }
  .workpage_block_slider{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
  }
  .big_workpage{
    margin-left: 0 !important;
    width: calc(100% - 40px) !important;
    padding: 20px !important;
  }
}
@media screen and (max-width: 500px){
  .workpage_block{
    margin-left: 0;
    width: calc(100% - 20px);
    padding: 10px;
  }

  .workpage_block_slider{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
  }
  .big_workpage{
    margin-left: 0 !important;
    width: calc(100% - 40px) !important;
    padding: 20px !important;
  }
}


