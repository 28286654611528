.spherevoice{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: -5px -20px;
  border-radius: 105px;
  background: linear-gradient(145deg, #F1F1F1, #131537); /* Градиентный фон */
  //box-shadow: 7px 7px 14px #0d0e27, -7px -7px 14px #24275d;
  margin: 0;
  width: 180px;
  height: 180px;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
  cursor: pointer;
  .tit{
    position: absolute;
    bottom: 50px;
  }
  .reload{
    position: absolute;
    background-color: #131537 !important;
    width: 162px;
    height: 162px;
    border-radius: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 36pt;
    z-index: 1001;
  }
  .control{
    position: absolute;
    top: 200px;
    audio{
      width: 200px;
    }
    .history_mess_pen_btn{
      width: 200px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #131537 !important;
      color: #FFFFFF;
      border-radius: 28px;
    }
  }
}
.mic-container {
  max-width: 200px !important;
  height: 200px;
  //border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  padding: 0 !important;
  //background-color: #FFC0CB !important;
}

.sound-wave {
  width: 200px !important;
  height: 200px;
  border-radius: 100px;
  transform: scale(0.8); /* Уменьшаем размер содержимого */
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
  margin: 0px !important;
  padding: -50px !important;
}

//.sound-wave > div {
//  height: 100%;
//}
//
//.sound-wave svg {
//  height: 100%;
//}
//
//.sound-wave path {
//  stroke-width: 15 !important; /* Задаем толщину линии */
//}