.modal-list{
  overflow-y: auto;
  max-height: 75vh;
}
.answers-list{
  display: flex;
  flex-direction: column;
  width: 100%;

  .first-line{
    border-top: 3px solid #999999;
  }
  .first-row{
    border-left: 3px solid #999999;
  }
  .header{
    .answers-list-row{
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
  }
  .data{
      cursor: pointer;
  }
  .data:hover{
    background-color: rgba(1,1,1,0.6);
    color: #fff;
  }
  .answers-list-line{
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 3px solid #999999;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: #555;
    .answers-list-row{
      display: flex;
      border-right:3px solid #999999;
      padding: 5px;
    }
    .c1{
      width: 35%;
    }
    .c2{
      width: 45%;
    }
    .c3{
      width: 20%;
    }

  }
}

.answers-box{
  overflow-y: auto;
  height: 100%;
  .answer{
  }
  h3{
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
  }
  h4{
    font-size: 0.8rem;
    text-decoration: underline;
    font-weight: bold;
  }
}
@media screen and (min-width: 500px){
  .analyt{
    background-color: #999;
    color: #FFFFFF;
    justify-content: left;
    align-items: flex-start;
    width: calc(50% - 40px);
    min-width: 320px;
    padding: 20px;
    p{
      margin: 10px 0;
    }
    span{
      font-weight: 600;
    }
    .button{
      font-size: 0.8rem;
      margin-top: -10px;
      text-decoration: underline;
      cursor: pointer;
    }
    .button:hover{
      color: #aaaaaa;
    }
  }
  .borderrightnone{
    display: flex !important;
  }
  .dd{
    display: none !important;
  }
}
@media screen and (max-width: 500px){
  .analyt{
    background-color: #999;
    color: #FFFFFF;
    justify-content: left;
    align-items: flex-start;
    width: calc(40% - 40px);
    min-width: 300px !important;
    padding: 10px;
    font-size: 10pt;
    p{
      margin: 10px 0;
    }
    span{
      font-weight: 600;
    }
    .button{
      font-size: 0.8rem;
      margin-top: -10px;
      text-decoration: underline;
      cursor: pointer;
    }
    .button:hover{
      color: #aaaaaa;
    }
  }
  .modal_active{
    .middle-modal-win-files{
      height: 90% !important;
    }

  }

  .workers{
    .answers-list{
      font-size: 10pt;
      .answers-list-line{
        font-size: 8pt;
        .borderrightnone{
          display: none;
        }
        .dd{
          display: flex;
        }
      }
    }
  }
}

.analytics-cms{
  .title{
    h1{
      font-size: 1.1rem;
    }
  }
  .types-history{
    margin-top: 20px;
    h2{
      font-size: 1rem;
    }
  }
  .form-analytics{
    .input{
      width: calc(100% - 8px);
      border: 1px solid #aaaaaa;
      outline: none;
      padding: 4px;
    }
    .button{
      background-color: rgb(18, 19, 56);
      color: white;
      margin-top: 10px;
      padding: 10px 16px 10px 10px;
      width: calc(100% - 24px);
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;

    }
  }
  .table{
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #888;
    .row{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px 5px 5px 5px;
      margin: 0;
      .column{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .s1{
        width: 50px;
        border-right: 2px solid #888;
        font-weight: bold;
      }
      .s2{
        width: 250px;
      }
    }

    .header{
      border-bottom: 2px solid #888;
      font-weight: bold;
    }
  }
}


.table-container-history {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  width: 1110px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: auto;
  max-height: 55vh;

  .table-header {
    display: flex;
    background-color: #f4f4f4;
    padding: 10px;
    font-weight: bold;
    position: fixed;
    border-bottom: 2px solid #454545;

  }
  .table-row {

    display: flex;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    .table-cell{
      font-size: 0.6rem;

    }
  }
  .pusto{
    margin-top: 22px;
  }
  .table-row:last-child {
    border-bottom: none;
  }

  .table-cell {
    padding: 0 10px;
    text-align: left;

  }

  .table-cell:first-child {
    width: 200px !important;
  }

  .table-cell:nth-child(2) {
    width: 200px;
  }

  .table-cell:nth-child(3){
    width: 400px;
  }
  .table-cell:nth-child(4) {
    width: 140px;
  }
  .table-cell:nth-child(5) {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.history-sort{
  margin-bottom:-10px;
}
.pagination{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  .step{
    font-size: 0.7rem;
    margin: 2px;
    cursor: pointer;
    color: #555;
  }
  .selected{
    font-size: 1.9rem;
  }
  .step:hover{
    color: black;
  }
}
.small-button{
  font-size: 0.7rem;
  margin: 5px;
  cursor: pointer;
}

.flex-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ccc;
}

.flex-row {
  display: flex;
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
}

.flex-cell {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.table_welding{
  display: flex;
  flex-direction: column;
  .table_welding_selector{
    width: 200px;
    height: 40px;
    border: 2px solid #454545;
    margin-bottom: 5px;
  }
  .table_welding_button{
    width: 200px;
    height: 40px;
    background-color: #454545;
    color: #FFFFFF;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.table_all{}
.table_all_title{}
.table_welding_table{
  border-collapse: collapse; /* Убирает промежутки между ячейками */
  border-spacing: 0; /* Устанавливает нулевое расстояние между ячейками */
  td{
    border: 1px solid #454545;
    padding: 3px;
  }
  th{
    border: 1px solid #454545;
    padding: 3px;
  }

}