$dredColor:rgba(215,26,0,0.5);
@media screen and (min-width: 700px) {
  .survey-block {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .survey-box {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 20px;
      margin: 0 0 -20px -20px;
      width: calc(100% + 0px);
    }

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h3 {
        font-weight: 600;
        font-size: 1.8rem;
        margin: 20px 20px 150px 20px;
        color: rgb(255, 255, 255);
        background-color: rgba(0, 0, 0, 0.7);
        padding: 5px 15px;
      }
    }

    .text {
      h4 {
        font-weight: 400;
        font-size: 1.1rem;
        margin: 20px;
      }
    }

    .image {
      width: 100%;
      height: 0px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 10px;
    }

    .scale {
      width: calc(100% - 80px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 20px 0;
      padding: 0 20px;

      .item {
        //min-width: 60px;
        width: 6%;
        height: 30px;
        border-radius: 5px;
        margin: 5px;
        padding: 0 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border: 1px solid #454545;
        background-color: rgba(18, 19, 56, 0.4);
        cursor: pointer;
      }

      .selected, .itemhover:hover {
        background-color: rgba(18, 19, 56, 1);
      }

    }

    .next {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .button {
        background-color: rgba(18, 19, 56, 1);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 200px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
      }

      .text {
        //margin-bottom: 20px;
      }
    }

    .statistics {
      .answers {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;

        .survey-table {
          width: calc(70% - 40px);
          min-width: 550px;
          margin: 20px;

          .survey-table-header {
            display: flex;
            background-color: transparent;
            font-weight: bold;
            border-bottom: 2px solid #454545;
          }

          .column {
            border: none;
            justify-content: center;
            align-items: center;

            p {
              text-align: center;
            }
          }

          .с1 {
            min-width: 90px;
            width: 70%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
          }

          .с2 {
            width: 15%;
          }

          .с3 {
            min-width: 60px;
            width: 15%;

          }

        }

        .item {
          display: flex;
          flex-direction: row;
          align-items: center;

          .text {
            font-size: 1rem;
            font-weight: 600;
          }

          .ic {
            margin-right: 10px;
            font-size: 0.5rem;
          }
        }
      }
    }


  }
}
@media screen and (max-width: 700px) {
  .survey-block {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .survey-box {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 20px;
      margin: 0 0 -20px -20px;
      width: calc(100% + 0px);
    }

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h3 {
        font-weight: 600;
        font-size: 1.2rem;
        margin: 20px 20px 150px 20px;
        color: rgb(255, 255, 255);
        background-color: rgba(0, 0, 0, 0.7);
        padding: 5px 15px;
      }
    }

    .text {
      h4 {
        font-weight: 400;
        font-size: 1.1rem;
        margin: 20px;
      }
    }

    .image {
      width: 100%;
      height: 0px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 10px;
    }

    .scale {
      width: calc(100% - 80px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 20px 0;
      padding: 0 0px;

      .item {
        //min-width: 60px;
        width: 6%;
        height: 30px;
        border-radius: 5px;
        margin: 5px;
        padding: 0 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border: 1px solid #454545;
        background-color: rgba(18, 19, 56, 0.4);
        cursor: pointer;
      }

      .selected, .itemhover:hover {
        background-color: rgba(18, 19, 56, 1);
      }

    }

    .next {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .button {
        background-color: rgba(18, 19, 56, 1);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 200px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
      }

      .text {
        //margin-bottom: 20px;
      }
    }

    .statistics {
      .answers {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;

        .survey-table {
          width: calc(70% - 40px);
          min-width: 300px;
          margin-left: -10px;

          .survey-table-header {
            display: flex;
            background-color: transparent;
            font-weight: bold;
            border-bottom: 2px solid #454545;
          }

          .column {
            border: none;
            justify-content: center;
            align-items: center;

            p {
              text-align: center;
            }
          }

          .с1 {
            min-width: 90px;
            width: 70%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
          }

          .с2 {
            width: 15%;
          }

          .с3 {
            min-width: 60px;
            width: 15%;

          }

        }

        .item {
          display: flex;
          flex-direction: row;
          align-items: center;

          .text {
            font-size: 1rem;
            font-weight: 600;
          }

          .ic {
            margin-right: 10px;
            font-size: 0.5rem;
          }
        }
      }
    }


  }
}
@media screen and (min-width: 700px){
  .schedule{
  max-width: 30%;
  min-width: 250px;
  .circle{
    margin: 30px;
  }
}
}
@media screen and (max-width: 700px){
  .schedule{
    display: none;
    max-width: 30%;
    min-width: 250px;
    .circle{
      margin: 30px;
    }
  }
}
@media screen and (min-width: 700px){
  .survey-table {
    width: 100%;
    min-width: 332px;
    border-collapse: collapse;
    margin-bottom: 20px;
    .table_list_cap{
      width: 100%;
      height: 8px;
      border-radius: 5px 5px 0 0;
      background: rgb(18, 19, 56);
    }
    .survey-table-header {
      display: flex;
      background-color: #f2f2f2;
      font-weight: bold;
      border-bottom:2px solid #454545;
    }

    .survey-row {
      display: flex;
      width: 100%;

      cursor: pointer;

      &:last-child {
        border-bottom:1px solid #454545;
      }
    }
    .survey-row:hover {
      background: rgb(18, 19, 56);
      color: #FFF;
    }
    .column {
      padding: 10px;
      border-top: 1px solid #454545;
      border-left: 1px solid #454545;

      &:last-child {
        border-right: 1px solid #454545;
      }
    }
    .с1{
      min-width: 90px;
      width: 10%;
    }
    .с2{
      width: 70%;
    }
    .с3{
      min-width: 60px;
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .с4{
      min-width: 120px;
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .next{
      display: flex;
      margin-top: 40px;
      width: 100%;
      .button{
        background-color:rgba(18,19,56,1);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 200px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
      }
      .text{
        margin-bottom: 20px;
      }
    }
  }
}
@media screen and (max-width: 700px){
  .survey-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    .table_list_cap{
      width: 100%;
      height: 8px;
      border-radius: 5px 5px 0 0;
      background: rgb(18, 19, 56);
    }
    .survey-table-header {
      display: flex;
      background-color: #f2f2f2;
      font-weight: bold;
      border-bottom:2px solid #454545;
    }

    .survey-row {
      display: flex;
      width: 100%;
      cursor: pointer;

      &:last-child {
        border-bottom:1px solid #454545;
      }
    }
    .survey-row:hover {
      background: rgb(18, 19, 56);
      color: #FFF;
    }
    .column {
      padding: 10px;
      border-top: 1px solid #454545;
      border-left: 1px solid #454545;
      font-size: 10pt;
      &:last-child {
        border-right: 1px solid #454545;
      }
    }
    .с1{
      min-width: 90px;
      width: 10%;
      display: none;
      font-size: 10pt !important;
    }
    .с2{
      width: 70%;
    }
    .с3{
      min-width: 40px;
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .с4{
      min-width: 80px;
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .next{
      display: flex;
      margin-top: 40px;
      width: 100%;
      .button{
        background-color:rgba(18,19,56,1);
        color: white;
        padding: 10px 16px 10px 10px;
        width: 200px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.8rem;
        cursor: pointer;
      }
      .text{
        margin-bottom: 20px;
      }
    }
  }
}
.radio-button-container {
  display: flex;
  flex-direction: column;

  .radio-button {
    display: inline-flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .radio-button input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid rgb(18, 19, 56);
    border-radius: 50%;
    margin-right: 8px;
    outline: none;
    cursor: pointer;
  }
  .radio-button input[type="radio"]:checked {
    background-color:rgb(18, 19, 56);
  }

  .radio-label {
    font-size: 16px;
  }
}
.buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px;
  .button{
    background-color:rgba(18,19,56,1);
    color: white;
    margin: 10px;
    padding: 10px 16px 10px 10px;
    width: 150px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
  }
  .button.da{
    background-color: $dredColor;
  }
}

.survey-setting{
  .create-title{
    width: calc(100% - 40px);
    font-size: 18pt;
    font-weight: 600;
    color: rgb(18, 19, 56);
    padding: 0 20px;
  }
  .save-box{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 10px 20px;
    .button{
      margin-right: 20px;
      padding: 20px 20px;
      border: 1px solid #CCC;
      border-radius: 4px;
      cursor: pointer;
      color: #454545;
      transition: 0.2s;
    }
    .green{
      background-color: darkgreen;
      color: #FFFFFF;
    }
  }
  .setting-list{
    width: calc(100% - 40px);
    padding: 20px;
    background-color: #FFF;
    border-radius: 10px;
    .title{
      width: calc(100% - 46px);
      padding: 20px;
      border: 3px dotted #CCC;
      border-radius: 10px;
      font-size: 24pt;
      font-weight: 600;
      color: rgb(18, 19, 56);
      text-align: center;
      outline: none;
      transition: 0.2s;
    }
    .text{
      margin-top:20px;
      width: calc(100% - 46px);
      padding: 10px 20px;
      min-height: 120px;
      border: 3px dotted #CCC;
      border-radius: 10px;
      outline: none;
    }
    .image{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E3E3E3;
      width: calc(100% - 46px);
      height: 200px;
      border: 3px dotted #CCC;
      margin: 10px 0 0 0;
      padding: 10px 20px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.2s;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      i {
        color: #CCC;
        font-size: 24pt;
        border: 1px solid #CCC;
        padding: 10px;
        border-radius: 3px;
        transition: 0.2s;
      }
    }
    .title-type{
      width: calc(100% - 46px);
      padding: 20px;
      margin-top: 20px;
      border: 3px dotted #CCC;
      border-radius: 10px;
      color: rgb(18, 19, 56);
      text-align: center;
      outline: none;
      transition: 0.2s;
      p{
        font-size: 24pt;
        font-weight: 600;
      }
    }
    .checkbox-container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 20px;
      cursor: pointer;
      font-size: 18px;
      user-select: none;
      margin-top: 20px;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 5px;

        &::after {
          content: '';
          position: absolute;
          display: none;
          left: 8px;
          top: 3px;
          width: 6px;
          height: 12px;
          border: solid white;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      }
      input:checked ~ .checkmark {
        background-color: rgb(18, 19, 56);
      }
      input:checked ~ .checkmark::after {
        display: block;
      }

    }
  }
  .questions{
    width: 100%;
  }
  .columns{
    width: calc(100% - 80px);
    margin: 20px 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    .item {
      display: flex;
      justify-content: left;
      input{
        width: 100%;
        height: 40px;
        padding: 10px;
        margin: 10px;
        border: 3px dotted #CCC;
        border-radius: 10px;
        outline: none;

      }
    }
  }
  .rows{
    width: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    padding: 0 20px;
    .item {
      width: 6%;
      height: 30px;
      border-radius: 5px;
      margin: 5px;
      padding: 0 2%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #454545;
      background-color: rgba(18, 19, 56, 0.4);
      cursor: pointer;
      input{
        border: none;
        width: 100%;
        background-color: transparent;
        text-align: center;
        color: white;
      }
    }
  }
  .add-btn{
    min-height: 20px;
    border: 1px solid rgb(18, 19, 56);
    color:  rgb(18, 19, 56);

    max-height: 30px !important;
    cursor: pointer;
    margin-right: 10px;
    padding: 5px 30px;
    border-radius: 10px;
    width: 200px;
  }
  .add-btn:hover{
    background-color: #04B800;
    color: #FFFFFF;
  }
}
.red-solid-border{
  border: 1px solid rgb(200, 19, 46) !important;
}
.red-solid-border:hover{
  color: rgb(200, 19, 46) !important;
  background-color: transparent !important;
}
.cms-head{
  margin: 10px 0;
}
.back-button{
  background-color:rgba(18,19,56,1);
  color: white;
  padding: 10px 16px 10px 10px;
  width: 200px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: pointer;
  margin: 10px 0;
  i{
    margin: 0 10px;
  }
}
.small-box{
  padding: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin: 0 !important;
  padding-top: 10px !important;
}
.small-heigth{
  height: calc(100% - 10px) !important;

}
.small-pad{
  padding-bottom: 20px !important;
}
.small-h{
  padding: 5px !important;
  margin: 0 10px !important;
  font-size: 1.5rem !important;
}
.small-p{
  padding: 0 !important;
  margin:10px !important;
}
.small-s{
  width: calc(100% - 20px) !important;
  padding: 10px !important;
  margin: 0!important;
  .item{
    width: 2% !important;
  }
}
.no-padding{
  padding: 0 !important;
}
.small-radio{
  width: calc(100% - 40px);
  display: flex;
  padding: 0 20px !important;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.landing-survey{
  margin: 20px !important;
  height: calc(100vh - 80px) !important;
  justify-content: center !important;
  align-items: center !important;
  .survey-box{
    margin-top: 20px !important;
  }
  .radio-button-text{
    font-size: 1.2rem;
  }
  .small-p{
    font-size: 1.2rem !important;
  }
}