.icon_openai{
  background-image: url("../../assets/images/icon_openai.png");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: contain;
}

@media screen and (min-width: 1200px){
  .navbar_block{
    background: #F1F1F1;
    width: 250px;
    height: calc(100vh - 40px - 20px);
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .navbar_block_menu{
      width: 85%;
      height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      .navbar_block_menu_strock{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 30px;
        margin: 2.5px 0;
        color: #454545;
        font-size: 10pt;
        cursor: pointer;
        padding: 3px 0;
        .navbar_block_menu_strock_icon{
          width: 30px;
          height: 30px;
          background-color: rgba(18,19,56,1);
          border-radius: 2px;
          position: relative;
          .messindicate{
            position: absolute;
            top: -2px;
            right: -3px;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: red;
            animation: blink 2s infinite alternate;
          }
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0.6; }
            100% { opacity: 1; }
          }
        }
        .navbar_block_menu_strock_description{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: calc(100% - 30px - 10px);
          height: 30px;
        }

        .icon_home{
          background-image: url("icons/home.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_news{
          background-image: url("icons/news.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_com{
          background-image: url("icons/comunity.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_forest{
          background-image: url("icons/forest.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_doc{
          background-image: url("icons/doc.svg");
          background-size: 50%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_task{
          background-image: url("icons/task.svg");
          background-size: 65%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_lk{
          background-image: url("icons/lk.svg");
          background-size: 65%;
          background-position: center center;
          background-repeat: no-repeat;
        }
        transition: 0.3s;
      }

      .navbar_block_menu_strock:hover,.selected{
        margin-left: 2px;
        transition: 0.3s;
        .navbar_block_menu_strock_icon{
          width: 34px;
          height: 34px;
        }
      }
      .selected{
        background-color: rgba(18,19,56,0.5);
        color: white;
      }
    }

    .navbar_block_dopmenu{
      width: calc(85% - 20px);
      height: calc(100vh - 40px - 20px - 20px - 320px - 40px - 20px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      background: #ECECEC;
      border-radius: 8px;
      padding: 10px;
      .navbar_block_dopmenu_list{
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 10pt;

        .navbar_block_dopmenu_list_strock {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 25px;
          transition: 0.2s;
          cursor: pointer;

          .navbar_block_dopmenu_list_icon {
            width: 30px;

            i {
              color: #454545;
            }
          }

          .navbar_block_dopmenu_list_description{

          }

        }

        .navbar_block_dopmenu_list_strock:hover{
          transition: 0.2s;
          text-decoration: underline;
        }
      }
      .navbar_block_dopmenu_more{
        height: 30px;
        width: 90%;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

      }
    }
  }
}
@media screen and (min-width: 1050px) and (max-width: 1200px){
  .navbar_block{
    background: #F1F1F1;
    width: 250px;
    height: calc(100vh - 40px - 20px);
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .navbar_block_menu{
      width: 85%;
      height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      .navbar_block_menu_strock{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 30px;
        margin: 2.5px 0;
        color: #454545;
        font-size: 10pt;
        cursor: pointer;
        padding: 3px 0;
        .navbar_block_menu_strock_icon{
          width: 30px;
          height: 30px;
          background-color: rgba(18,19,56,1);
          border-radius: 2px;
          position: relative;
          .messindicate{
            position: absolute;
            top: -2px;
            right: -3px;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: red;
            animation: blink 2s infinite alternate;
          }
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0.6; }
            100% { opacity: 1; }
          }
        }
        .navbar_block_menu_strock_description{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: calc(100% - 30px - 10px);
          height: 30px;
        }

        .icon_home{
          background-image: url("icons/home.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_news{
          background-image: url("icons/news.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_com{
          background-image: url("icons/comunity.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_forest{
          background-image: url("icons/forest.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_doc{
          background-image: url("icons/doc.svg");
          background-size: 50%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_task{
          background-image: url("icons/task.svg");
          background-size: 65%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_lk{
          background-image: url("icons/lk.svg");
          background-size: 65%;
          background-position: center center;
          background-repeat: no-repeat;
        }
        transition: 0.3s;
      }

      .navbar_block_menu_strock:hover,.selected{
        margin-left: 2px;
        transition: 0.3s;
        .navbar_block_menu_strock_icon{
          width: 34px;
          height: 34px;
        }
      }
      .selected{
        background-color: rgba(18,19,56,0.5);
        color: white;
      }
    }

    .navbar_block_dopmenu{
      width: calc(85% - 20px);
      height: calc(100vh - 40px - 20px - 20px - 320px - 40px - 20px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      background: #ECECEC;
      border-radius: 8px;
      padding: 10px;
      .navbar_block_dopmenu_list{
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 10pt;

        .navbar_block_dopmenu_list_strock {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 25px;
          transition: 0.2s;
          cursor: pointer;

          .navbar_block_dopmenu_list_icon {
            width: 30px;

            i {
              color: #454545;
            }
          }

          .navbar_block_dopmenu_list_description{

          }

        }

        .navbar_block_dopmenu_list_strock:hover{
          transition: 0.2s;
          text-decoration: underline;
        }
      }
      .navbar_block_dopmenu_more{
        height: 30px;
        width: 90%;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 1050px){
  .navbar_block{
    background: #F1F1F1;
    width: 80px;
    height: calc(100vh - 40px - 20px);
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .navbar_block_menu{
      width: 85%;
      height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      .navbar_block_menu_strock{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30px;
        margin: 2.5px 0;
        color: #454545;
        font-size: 10pt;
        cursor: pointer;
        padding: 3px 0;
        .navbar_block_menu_strock_icon{
          width: 30px;
          height: 30px;
          background-color: rgba(18,19,56,1);
          border-radius: 2px;
          position: relative;
          .messindicate{
            position: absolute;
            top: -2px;
            right: -3px;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: red;
            animation: blink 2s infinite alternate;
          }
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0.6; }
            100% { opacity: 1; }
          }
        }
        .navbar_block_menu_strock_description{
          display: none;
          justify-content: flex-start;
          align-items: center;
          width: calc(100% - 30px - 10px);
          height: 30px;
        }

        .icon_home{
          background-image: url("icons/home.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_news{
          background-image: url("icons/news.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_com{
          background-image: url("icons/comunity.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_forest{
          background-image: url("icons/forest.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_doc{
          background-image: url("icons/doc.svg");
          background-size: 50%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_task{
          background-image: url("icons/task.svg");
          background-size: 65%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_lk{
          background-image: url("icons/lk.svg");
          background-size: 65%;
          background-position: center center;
          background-repeat: no-repeat;
        }
        transition: 0.3s;
      }

      .navbar_block_menu_strock:hover,.selected{
        margin-left: 2px;
        transition: 0.3s;
        .navbar_block_menu_strock_icon{
          width: 34px;
          height: 34px;
        }
      }
      .selected{
        background-color: rgba(18,19,56,0.5);
        color: white;
      }
    }
    .navbar_block_dopmenu{
      width: calc(85% - 20px);
      height: calc(100vh - 40px - 20px - 20px - 320px - 40px - 20px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      background: #ECECEC;
      border-radius: 8px;
      padding: 10px;
      .navbar_block_dopmenu_list{
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 10pt;

        .navbar_block_dopmenu_list_strock {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 25px;
          transition: 0.2s;
          cursor: pointer;
          margin-bottom: 10px;
          .navbar_block_dopmenu_list_icon {
            width: 30px;

            i {
              font-size: 16pt;
              color: #454545;
            }
          }

          .navbar_block_dopmenu_list_description{
              display: none;
          }

        }

        .navbar_block_dopmenu_list_strock:hover{
          transition: 0.2s;
          text-decoration: underline;
        }
      }
      .navbar_block_dopmenu_more{
        height: 30px;
        width: 90%;
        margin-bottom: 10px;
        display: none;
        justify-content: flex-start;
        align-items: center;

      }
    }
  }
}
@media screen and (max-width: 800px){
  .navbar_block{
    background: #F1F1F1;
    width: 250px;
    height: calc(100vh - 40px - 20px);
    position: fixed;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .navbar_block_menu{
      width: 85%;
      height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      .navbar_block_menu_strock{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 30px;
        margin: 2.5px 0;
        color: #454545;
        font-size: 10pt;
        cursor: pointer;
        padding: 3px 0;
        .navbar_block_menu_strock_icon{
          width: 30px;
          height: 30px;
          background-color: rgba(18,19,56,1);
          border-radius: 2px;
        }
        .navbar_block_menu_strock_description{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: calc(100% - 30px - 10px);
          height: 30px;
        }

        .icon_home{
          background-image: url("icons/home.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_news{
          background-image: url("icons/news.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_com{
          background-image: url("icons/comunity.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_forest{
          background-image: url("icons/forest.svg");
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_doc{
          background-image: url("icons/doc.svg");
          background-size: 50%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_task{
          background-image: url("icons/task.svg");
          background-size: 65%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .icon_lk{
          background-image: url("icons/lk.svg");
          background-size: 65%;
          background-position: center center;
          background-repeat: no-repeat;
        }
        transition: 0.3s;
      }

      .navbar_block_menu_strock:hover,.selected{
        margin-left: 2px;
        transition: 0.3s;
        .navbar_block_menu_strock_icon{
          width: 34px;
          height: 34px;
        }
      }
      .selected{
        background-color: rgba(18,19,56,0.5);
        color: white;
      }
    }

    .navbar_block_dopmenu{
      width: calc(85% - 20px);
      height: calc(100vh - 40px - 20px - 20px - 320px - 40px - 20px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      background: #ECECEC;
      border-radius: 8px;
      padding: 10px;
      .navbar_block_dopmenu_list{
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 10pt;

        .navbar_block_dopmenu_list_strock {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 25px;
          transition: 0.2s;
          cursor: pointer;

          .navbar_block_dopmenu_list_icon {
            width: 30px;

            i {
              color: #454545;
            }
          }

          .navbar_block_dopmenu_list_description{

          }

        }

        .navbar_block_dopmenu_list_strock:hover{
          transition: 0.2s;
          text-decoration: underline;
        }
      }
      .navbar_block_dopmenu_more{
        height: 30px;
        width: 90%;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

      }
    }
  }
}
.popup-notifications{
  position: absolute;
  top: 60px;
  background-color: #fff;
  color: #333333;
  z-index: 99999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
  .popup-setting{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: #222;
    padding: 7px 10px;
    font-size: 0.8rem;
    font-weight: 600;
    .settings{
      cursor: pointer;
    }
  }
  .notification{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-top: 1px solid #ddd;
    .notification-image{
      min-width: 40px;
      height: 40px;
      background-size: cover;
      background-position: center center;
      border-radius: 100%;
      margin-right: 10px;
    }
    .info-notification{
      display: flex;
      flex-direction: column;
      max-width: 345px;
      .notification-title{
        font-size: 0.8rem;
        font-weight: 600;
      }
      .notification-text{
        font-size: 0.7rem;
      }
      .notification-button{
        display: flex;
        padding: 5px 0;
        .button{
          width: auto;
          color: #FFFFFF;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 0.7rem;
          padding: 5px 15px;
          border-radius: 5px;
          background-color: rgb(18, 19, 56);
        }

      }
    }
  }
  .popup-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid #ddd;
    .footer-link{
      padding: 10px 0;
      font-size: 0.8rem;
      cursor: pointer;
      width: 100%;
      text-align: center;
      border-radius: 0 0 10px 10px;
    }
    .footer-link:hover{
      background-color: #eee;
    }
  }
  p{
    background-color: transparent;
  }
}

.hidden-menu{
  width: 80px;
  height: calc(100vh - 60px);
  .navbar_block_menu{
    width: 85%;
    height: 320px;
    margin-top: 10px;

    .navbar_block_menu_strock{
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .navbar_block_menu_strock_icon{
        width: 30px;
        height: 30px;

      }
      .navbar_block_menu_strock_description{
        display: none;
      }

    }

    .navbar_block_menu_strock:hover,.selected{
      .navbar_block_menu_strock_icon{
        width: 34px;
        height: 34px;
      }
    }
  }
  .navbar_block_dopmenu{
    width: calc(85% - 20px);
    height: calc(100vh - 40px - 20px - 20px - 320px - 40px - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    background: #ECECEC;
    border-radius: 8px;
    padding: 10px;
    .navbar_block_dopmenu_list{
      width: 100%;
      height: calc(100% - 40px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 10pt;

      .navbar_block_dopmenu_list_strock {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 25px;
        transition: 0.2s;
        cursor: pointer;
        margin-bottom: 10px;
        .navbar_block_dopmenu_list_icon {
          width: 30px;

          i {
            font-size: 16pt;
            color: #454545;
          }
        }

        .navbar_block_dopmenu_list_description{
          display: none;
        }

      }

      .navbar_block_dopmenu_list_strock:hover{
        transition: 0.2s;
        text-decoration: underline;
      }
    }
    .navbar_block_dopmenu_more{
      height: 30px;
      width: 90%;
      margin-bottom: 10px;
      display: none;
      justify-content: flex-start;
      align-items: center;

    }
  }
}