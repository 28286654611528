.tabelvw{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  .viewswork{
    margin: 10px 0;
    border: 1px solid #333333;
    border-bottom: none;
    display: inline-block;
    .name{
      font-size: 0.7rem;
      padding: 5px;
      display: flex;
      width: auto;
      justify-content: space-between;
      .text{
        margin: 0 10px;
        display: inline-block;
      }
      .remove{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1.2rem;
        margin: 0 10px;
        color:rgba(1,1,1,0.8);
        cursor: pointer;
        i{
          margin: 0 5px;
        }
        .redhover:hover{
          color:rgba(250,100,100,0.8);
        }
        .orahover:hover{
          color:#ffa500;
        }
      }
    }
    .tabel{
      display: flex;

      flex-direction: row;
      border-top: 1px solid #333;
      .day{
        .nd{
          text-align: center;
          height: 16px;
          font-size: 0.7rem;
          padding: 3px;
          font-weight: 600;
          border-right: 1px solid #333;
        }
        .plan{

        }
        .fact{

        }
        input{
          width: 30px;
          height: 30px;
          text-align: center;
          font-size: 0.8rem;
          border: 1px solid #333;
          margin-top: -1px;
          margin-left: -1px;
          outline: none;
        }
        .disable{
          background-color: #DEDEDE;
        }
        .active{

        }
        .text,.btext{
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          margin-top: -1px;
          margin-left: -1px;
          border: 1px solid #333;
          width: 50px;
          height: 30px;
          font-size: 0.6rem;
          font-weight: 600;
        }
        .btext{
          height:61px;
        }
      }
    }
  }
}
.comment{
  margin: 10px;
  padding: 10px;
  border: 1px inset rgba(1,1,1,0.3);
  border-radius: 10px;
  resize: none;
  width: calc(100% - 40px);
  outline: none;
}
.modalabel{
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #666;
  margin: 20px 10px 0 10px;
  display: inline-block;
}

.repairs{
  margin-bottom: 10px;
  label{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.7rem;
    color: #666;
    margin: 0 20px 0 20px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-right: 1px solid #333;
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    .notred{
      i:hover{
        color: #FEAA2D;
      }
    }
    i{
      margin-right: 10px;
      margin-top: 2px;
      cursor: pointer;
      &:hover{
        color: #e74c3c;
      }
    }


  }
  .repair{
    display: flex;
    flex-direction: row;
    border-left: 1px solid #333;
    margin-left: 20px;
    .repairday{
      .nd{
        text-align: center;
        height: 16px;
        font-size: 0.7rem;
        padding: 3px;
        font-weight: 600;
        border-right: 1px solid #333;
        border-top: 1px solid #333;
      }
      .plan{

      }
      .fact{

      }
      input{
        width: 30px;
        height: 30px;
        text-align: center;
        font-size: 0.8rem;
        border: 1px solid #333;
        margin-top: -1px;
        margin-left: -1px;
        outline: none;
      }
      .disable{
        background-color: #DEDEDE;
      }
      .active{

      }
      .text,.btext{
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        margin-top: -1px;
        margin-left: -1px;
        border: 1px solid #333;
        width: 50px;
        height: 30px;
        font-size: 0.6rem;
        font-weight: 600;
      }
      .btext{
        height:61px;
      }
    }

  }
}

@media screen and (max-width: 1425px) {

}