
$headerHeight:80px;
.tasks_block{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  .navigation{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #FFF;
    border-radius: 5px;
    margin-bottom: 10px;
    div{
      margin: 5px 0 0 0;
      cursor: pointer;
    }
    .navigation_leftpath{
      display: flex;
      div{
        transition: .2s;
      }
      div:hover{
        opacity: 0.8;
        transition: .2s;
      }
      .navigation_leftpath_tasks{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        padding: 0 30px 5px 20px;
        div{
          position: absolute;
          right: 0px;
          top: 0px;
          background: rgba(18,19,56,1);;
          padding: 0 10px;
          border-radius: 10px;
          color: #FFF;
        }
      }
      .navigation_leftpath_make{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        padding: 0 30px 5px 20px;
        div{
          position: absolute;
          right: 0px;
          top: 0px;
          background: rgba(18,19,56,1);
          padding: 0 10px;
          border-radius: 10px;
          color: #FFF;
        }
      }
      .navigation_leftpath_helper{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        padding: 0 30px 5px 20px;
        div{
          position: absolute;
          right: 0px;
          top: 0px;
          background: rgba(18,19,56,1);
          padding: 0 10px;
          border-radius: 10px;
          color: #FFF;
        }
      }
      .navigation_leftpath_pass{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        padding: 0 30px 5px 20px;
        div{
          position: absolute;
          right: 0px;
          top: 0px;
          background: rgba(18,19,56,1);
          padding: 0 10px;
          border-radius: 10px;
          color: #FFF;
        }
      }
      .navigation_leftpath_listen{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        padding: 0 30px 5px 20px;
        div{
          position: absolute;
          right: 0px;
          top: 0px;
          background: rgba(18,19,56,1);
          padding: 0 10px;
          border-radius: 10px;
          color: #FFF;
        }
      }

      .navigation_leftpath_slash{
        height: 50px;
        width: 1px;
        background-color: rgba(18,19,56,1);
        margin: 0 10px;
      }

      .navigation_rightpath_projects{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        padding: 0 30px 5px 20px;
        div{
          position: absolute;
          right: 0px;
          top: 0px;
          background: rgba(18,19,56,1);
          padding: 0 10px;
          border-radius: 10px;
          color: #FFF;
        }
      }
      .navigation_rightpath_kpi{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        padding: 0 30px 5px 20px;
        div{
          position: absolute;
          right: 0px;
          top: 0px;
          background: rgba(18,19,56,1);
          padding: 0 10px;
          border-radius: 10px;
          color: #FFF;
        }
      }
    }
    .navigation_rightpath{
      display: flex;

      .navigation_more{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        padding: 0 30px 5px 20px;
        margin-right: 10px;
        div{
          position: absolute;
          right: 0px;
          top: 0px;
          background: #454545;
          padding: 0 10px;
          border-radius: 10px;
          color: #FFF;
        }
      }
    }

  }
  .titlecap{
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    //background-color: #FFF;
    width: 100%;
    padding: 10px 0;
    border-radius: 5px;
    .titlecap_name{
      display: flex;
      margin-left: 20px;
      .titlecap_name_text{
        font-size: 20pt;
        font-weight: 800;
      }
      .titlecap_name_star{
        font-size: 16pt;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
        transition: .2s;
      }
      .titlecap_name_star:hover{
        opacity: 0.8;
        transition: .2s;
      }
    }
    .titlecap_btn_plus_task{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      padding: 5px 5px 5px 20px;
      color: #F1F1F1;
      background-color: rgba(18,19,56,1);
      border-radius: 4px;
      .titlecap_btn_plus_task_name{
        cursor: pointer;
      }
      .titlecap_btn_plus_task_slash{
        background-color: #F1F1F1;
        height: 100%;
        width: 1px;
        opacity: 0.5;
        filter: blur(1px);
        margin-left: 10px;
      }
      .titlecap_btn_plus_task_open{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        cursor: pointer;
      }
    }
    .titlecap_in_work{
      display: flex;
      .titlecap_in_work_search{
        margin-left: 20px;
        height: 33px;
        border: 1px solid #CCC;
        padding: 0 10px;
        width: 400px;
        margin-right: 20px;
        border-radius: 2px;
        outline: none;
      }
    }
    .titlecap_settbtns{
      display: flex;
      color: #454545;
      .titlecap_settbtns_settings{
        width: 33px;
        height: 33px;
        border: 1px solid #CCC;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-radius: 2px;
      }
      .titlecap_settbtns_flash{
        width: 33px;
        height: 33px;
        border: 1px solid #CCC;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-radius: 2px;
      }
    }
  }
  .cap_table{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .cap_table_left{
      display: flex;
      justify-content: flex-start;
      align-items: center;

      div{
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;
        transition: .2s;
      }
      div:hover{
        opacity: 0.8;
        transition: .2s;
      }
      .cap_table_left_list{}
      .cap_table_left_time{}
      .cap_table_left_myplan{}
      .cap_table_left_calendar{}
      .cap_table_left_grant{}
      .cap_table_left_inside{
        color: #CCC;
      }
    }
    .cap_table_right{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;

      .cap_table_right_jobs{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #454545;
        height: 30px;
        padding: 0 20px;
        border: 1px solid #CCC;
        border-radius: 8px;
        i{
          margin-right: 10px;
        }
      }
    }
  }
  .table_list_new {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .table_list_cap{
      width: 100%;
      height: 8px;
      border-radius: 5px 5px 0 0;
      background:  rgba(18,19,56,1);
    }

    .table_list_strock{
      width: calc(100% - 2px);
      min-height: 50px;
      border: 1px solid #454545;
      display: flex;
      justify-content: flex-start;
      margin-top: -1px;
      font-size: 10pt;
      cursor: pointer;
      .flex_center{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .table_list_strock_datein{
        width: 7.95%;
        min-height: 50px;
        border-right: 1px solid #454545;
      }
      .table_list_strock_dateto{
        width: 7.95%;
        min-height: 50px;
        border-right: 1px solid #454545;
      }
      .table_list_strock_title{
        width: 28.3%;
        min-height: 50px;
        border-right: 1px solid #454545;
      }
      .table_list_strock_icon{
        width: 4.15%;
        min-height: 50px;
        border-right: 1px solid #454545;
        i{
          font-size: 16pt;
        }
        .fa-arrow-left{
          color: rgba(18,19,56,1);
        }
        .fa-arrow-right{
          color: rgba(18,19,56,0.5);
        }
      }
      .table_list_strock_performance{
        width: 16.7%;
        min-height: 50px;
        border-right: 1px solid #454545;
        font-size: 10px;
        justify-content: space-between;
        .table_list_strock_p_indicate{
          width: 131px;
          height: 14px;
          border: 1px solid #CCC;
          border-radius: 4px;
          margin-left: 5px;
          .table_list_strock_p_indicate_bluegreen{
            width: 96%;
            height: 70%;
            border: 1px solid #03AEAE;
            border-radius: 3px;
            .table_list_strock_p_indicate_bg_indicate{
              //width: 96%;
              height: 90%;
              border: none;
              //border-radius: 3px;
              background-image: url("../../assets/images/indicate.svg");
              background-position: center center;
              //background-size: cover;
              //background-repeat: no-repeat;
            }
          }
        }
        .table_list_strock_p_percent{
          width: 40px;
        }
        .table_list_strock_p_time{
          width: 60px;
          min-height: 60%;
          margin-right: 5px;
          border: 1px solid #454545;
          border-radius: 4px;
          background: #FFF;
          padding: 5px;
        }
      }
      .table_list_strock_status{
        width: 7%;
        min-height: 50px;
        border-right: 1px solid #454545;
      }
      .table_list_strock_category{
        width: 7.65%;
        min-height: 50px;
        border-right: 1px solid #454545;
        font-size: 10px;
      }
      .table_list_strock_priority{
        width: 12.6%;
        min-height: 50px;
        border-right: 1px solid #454545;
      }
      .table_list_strock_edit{
        width: 3.85%;
        min-height: 50px;
        border-right: 1px solid #454545;
        transition: .2s;
      }
      .table_list_strock_edit:hover{
        background: #454545;
        transition: .1s;
      }
      .table_list_strock_close{
        width: 3.85%;
        min-height: 50px;
        transition: .2s;
      }
      .table_list_strock_close:hover {
        background: red;
        transition: .1s;
      }
    }
    .table_list_strock:hover{
      background: rgba(18,19,56,1);
      color: #FFF;
      .table_list_strock_p_time{
        color: #454545;
      }
    }
  }
}

#newCanvasContainer {
  width: 470px;
  max-height: calc(100vh - $headerHeight - 45px);
  overflow: hidden;
  cursor: grab;
  position: relative;
}
#documentway{
  //background-color: $transBlackColor;
}

.title_task{
  width:auto;
  margin: 20px 20px 0;
  height: 30px;
  background-color: #FFF;
  border-radius: 10px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  h1{
    color: #454545;
    font-size: 0.8rem;
  }
  .top-box{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    .left-box{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }
    .right-box{
      padding-right: 30px;
    }
  }
}

.task_work_page_box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .left-box{
    width: 40%;
    //min-width: 470px;
  }
  .right-box{
    margin-top: 20px;
    padding-right: 30px;
    width: calc(60% - 30px);
    .top-box-inside{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .top-box-inside-left{
        width: 48%;
        min-width: 320px;
        margin-bottom: 10px;
        margin-left: 10px;

      }
      .top-box-inside-right{
        width: 48%;
        min-width: 320px;
        margin-bottom: 10px;
        margin-left: 10px;
      }
    }
    .bottom-box_new{
      .performers-obj_new{
        min-width: 320px;
        width: 100%;
        height: 100%;
        //border: 1px solid #454545;
        display: flex;
        flex-direction: column;
        .title-performers {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          background-color: #CCC;
          border-radius: 5px 5px 0 0;
          p{

          }
        }
        .info-box{
          padding:0 20px;
          p{
            font-size: 0.8rem;
            margin-top: 5px;
          }
          h3{
            margin-top: 20px;
          }
          h4{
            font-size: 0.9rem;
            margin-top: 5px;
          }
          .list-button{
            cursor: pointer;
            color: #329DD9;
            text-transform: uppercase;
            font-size: 0.6rem;
            font-weight: 600;
          }
          .works-list{
            display: none;
            transition: display 0.5s ease;
            p{
              cursor: pointer;
              color: #329DD9;
              text-transform: uppercase;
              font-size: 0.6rem;
              font-weight: 600;
            }
          }
        }
        .people-list{
          margin-top: 20px;
        }
      }
      .results-obj_new{
        margin-top: 30px !important;
        margin-bottom: 30px;
        .button-text{
          font-size: 0.6rem;
          font-weight: 600;
          cursor: pointer;
          color: #329DD9;
          text-transform: uppercase;
          display: flex;
          justify-content: right;
          padding-top: 4px;
        }
        .attaches{
          justify-content: center;
          i{
            font-size: 30pt;
            margin-bottom: 10px;
            color: rgba(18,19,56,0.8);
          }
          a{
            width: 13% !important;
          }
        }
      }
      .attach-obj_new{
        width: calc(100% - 50px);
        margin-left: 10px;
        min-width: 320px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        background-color: #FFF;
        padding: 20px;
        .attach-title{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          background-color: #CCC;
          //border-radius: 5px 5px 0 0;
        }
        .autoheight{
          height: auto !important;
          padding-bottom: 30px !important;
        }
        .attaches{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 90%;
          padding-bottom: 15px;
          height: 130px;
          overflow: hidden;
          a{
            width: 25%;
            border: 1px solid #CCC;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            margin-top: 15px;
            padding:5px;
            align-items: center;
            justify-content: space-between;
            .img{
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
            }
            p{
              font-weight: 500;
              font-size: 0.53rem;
            }
          }
          .docx{
            width: 59px;
            height: 48px;
            background-image: url("../../assets/images/docicon.svg");
          }
          .xlsx{
            width: 58px;
            height: 57px;
            background-image: url("../../assets/images/excicon.svg");
          }
          .jpg{
            width: 59px;
            height: 56px;
            background-image: url("../../assets/images/jpgicon.svg");
          }
          .pdf{
            width: 50px;
            height: 48px;
            background-image: url("../../assets/images/pdficon.svg");
          }
        }
        .button{
          cursor: pointer;
          padding: 5px 0;
          margin-top: -20px;
          width: 100%;
          height: 20px;
          display: flex;
          justify-content: center;
          background-color: rgba(1,1,1,0.5);
          color: #FFF;
          border-radius: 0 0 5px 5px;
        }
      }
    }

    .dis-none{
      display: none;
    }
  }
}
.top-box-inside-left_new{
  width: calc(48% - 40px);
  min-width: 300px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: #FFF;
  border-radius: 10px;
  padding: 20px;
}
.top-box-inside-right_new{
  width: calc(48% - 40px);
  min-width: 320px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: #FFF;
  border-radius: 10px;
  padding: 20px;
  .performers-obj_new{
    min-width: 320px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title-performers {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      background-color: #CCC;
      border-radius: 5px 5px 0 0;
      p{

      }
    }
    .info-box{
      padding:0 20px;
      p{
        font-size: 0.8rem;
        margin-top: 5px;
      }
      h3{
        margin-top: 20px;
      }
      h4{
        font-size: 0.9rem;
        margin-top: 5px;
      }
      .list-button{
        cursor: pointer;
        color:  rgba(18,19,56,1);
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 600;
      }
      .works-list{
        display: none;
        transition: display 0.5s ease;
        p{
          cursor: pointer;
          color:  rgba(18,19,56,1);
          text-transform: uppercase;
          font-size: 0.6rem;
          font-weight: 600;
        }
      }
    }
    .people-list{
      margin-top: 20px;
    }
  }
}
.title_name_task{
  width: auto;
  display: flex;
  flex-direction: column;
  .top-box{
    width: 100%;
    display: flex;
    justify-content: space-around;
    background: #FFF;
    .status{
      width: 48%;
      padding: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #CCC !important;
      border-radius: 5px;
    }
    .level{
      width: 48%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(18,19,56,1) !important;
      color: #F1F1F1;
      border-radius: 5px;
    }
  }

  .info{
    display: flex;
    font-size: 10pt;
    margin-top: 10px;
    .left-box-info{

    }
    .right-box-info{
      margin-left: 10px;
      .text{}
      .button{
        margin-top: 5px;
        cursor: pointer;
        color: rgba(18,19,56,1);
        font-weight: 600;
      }
    }

  }
}

.attach-obj-new{
  width: 100%;
  min-width: 320px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  .attach-title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    //background-color: #CCC;
    border-top: 1px solid #CCC
  }
  .autoheight{
    height: auto !important;
    padding-bottom: 30px !important;
  }
  .attaches{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    padding-bottom: 15px;
    height: 130px;
    overflow: hidden;
    i{
      font-size: 30pt;
      margin-bottom: 10px;
      color: rgba(18,19,56,0.8);
    }
    a{
      width: 25%;
      border: 1px solid #CCC;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      padding:5px;
      align-items: center;
      justify-content: space-between;
      .img{
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      p{
        font-weight: 500;
        font-size: 0.53rem;
      }
    }
    .docx{
      width: 59px;
      height: 48px;
      background-image: url("../../assets/images/docicon.svg");
    }
    .xlsx{
      width: 58px;
      height: 57px;
      background-image: url("../../assets/images/excicon.svg");
    }
    .jpg{
      width: 59px;
      height: 56px;
      background-image: url("../../assets/images/jpgicon.svg");
    }
    .pdf{
      width: 50px;
      height: 48px;
      background-image: url("../../assets/images/pdficon.svg");
    }
  }
  .button{
    cursor: pointer;
    padding: 5px 0;
    margin-top: -20px;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    background-color: rgba(1,1,1,0.5);
    color: #FFF;
    border-radius: 0 0 10px 5px;
  }
}

