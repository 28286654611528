.hovhover:hover{
  opacity: 0.7;
  cursor: pointer;
  border: 1px solid #CCC;
  box-shadow: 2px 2px 10px #6c757d;
}

.controll_man{
  font-size: 12px;
  display: flex;
  border: 1px solid #CCC;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  margin-right: 10px;
  .controll_man_photo{
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    width: 80px;
    height: 80px;


  }
  .btns{
    margin-left: 10px;
    .name{
      font-weight: 700;
    }
    .dev{}
    .control_btn{
      padding: 10px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 7px;
      border: 1px solid #CCC;
      cursor: pointer;
    }
    .control_btn:hover{
      background-color: #6c757d;
      color: #FFF;
    }
  }
}

.ctrl_plus {
  display: flex;
}