.docx {
  width: 59px;
  height: 48px;
  background-image: url("../../../assets/images/docicon.svg");
}

.xlsx {
  width: 58px;
  height: 57px;
  background-image: url("../../../assets/images/excicon.svg");
}

.jpg {
  width: 59px;
  height: 56px;
  background-image: url("../../../assets/images/jpgicon.svg");
}

.pdf {
  width: 50px;
  height: 48px;
  background-image: url("../../../assets/images/pdficon.svg");
}

.newcrew{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  width: calc(100% - 45px);
  height: 100%;
  .newcrew_title{
    font-weight: 800;
    font-size: 20pt;
    width: 70%;
    display: flex;
    justify-content: center;
  }
  .newcrew_btn{
    width: 250px;
    height: 40px;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: rgb(60, 136, 1);
    //border-radius: 2px;
    //margin: 10px 0;
    //color: #FFF;
    //font-weight: 600;
    //padding: 5px 25px;
    //cursor: pointer;
    background-color: rgba(60, 136, 1, 0);
    padding: 10px;
    border: 1px solid #CCC;
    border-radius: 4px;
    cursor: pointer;
    color: #454545;
    transition: 0.2s;
    
  }
  .newcrew_list{
    width: 100%;
    height: 100%;
    .newcrew_list_strs{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      .newcrew_list_strock {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #454545 !important;
        width: 70%;
        height: 60px;
        border-radius: 1px;
        margin-top: -1px;
        .newcrew_list_strock_pp{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 5px 10px;
          border-right: 1px solid #454545;
          min-width: 80px;
          width: 10%;
          height: 50px;
        }
        .newcrew_list_strock_name{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 5px 10px;
          border-right: 1px solid #454545;
          width: 60%;
          height: 50px;
        }
        .newcrew_list_strock_num{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 5px 10px;
          border-right: 1px solid #454545;
          width: 15%;
          height: 50px;
        }
        .newcrew_list_strock_open,.newcrew_list_strock_delete{
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 10px;
          width: 10%;
          height: 50px;
          cursor: pointer;
          transition: 0.2s;
          &:hover{
            background: rgba(0,0,0,0.8);
            color: #FFF;
          }
        }
        .newcrew_list_strock_delete{
          border-left: 1px solid #333333;
          width: 5%;
        }
      }


      .block_docs {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        a:hover{
          background-color: #D9D9D9;
          cursor: alias;
        }

        a {
          width: 100px;
          border: 1px solid #CCC;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          margin: 5px;
          padding: 5px;
          align-items: center;
          justify-content: space-between;

          .img {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }

          p {
            font-weight: 500;
            font-size: 0.53rem;
          }
        }
      }
    }
  }
  .newcrew_block{
    border: 4px dotted #CCC;
    padding: 20px;
    border-radius: 20px;
    width: calc(100% - 40px);
    max-width: 800px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .slash{
      width: 100%;
      height: 1px;
      background-color: #CCC;
      margin-bottom: 10px;
    }
    .newcrew_block_inpt{
      display: flex;
      padding: 5px;
      height: 30px;
      flex-wrap: wrap;
      width: calc(100% - 40px);
      margin-right: 20px;
      border: 4px solid #CCC;
      border-radius: 5px;
      margin-bottom: 20px;
    }
    .pul-inpt{
      width: calc(50% - 40px);

    }
    .newcrew_block_inputns{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .newcrew_block_selects{
      margin: 10px 0;
      width: calc(100% - 20px);
    }
    .newcrew_block_plusman{
      width: 100%;
      .newcrew_block_plusman_left{
        //width: 50%;
        .newcrew_block_plusman_right_picker{
          width: calc(100% - 20px);
          display: flex;
          justify-content: space-between;
        }
        .newcrew_block_plusman__btn{
          width: 200px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #CCC;
          border-radius: 5px;
          margin: 10px 0;
          cursor: pointer;
          transition: .2s;
        }
        .newcrew_block_plusman__btn:hover{
          border: 1px solid #454545;
          transition: .2s;
        }
      }
      .newcrew_block_plusman_right{}
    }


  }
}
.checkbox-container{
  display: flex;
  align-items: center;
  p{
    font-weight: 300;
    font-size: 0.8rem;
  }
}
.checkmark{
  border: 1px solid #aaa;
}
.modal_crew_view{
  .title{
    font-size: 1.2rem;
    font-weight: 500;
    margin-left: 20px;
  }
  .p{
    font-size: 1.1rem;
    margin-left: 20px;
  }
  .pm{
    font-size: 1rem;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  padding: 20px;
}
.view-select{
  width: 90%;
  margin: 20px 0;
}
.view_crew{
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px;

  overflow: hidden;
  width: 90%;
  .view_crew_row{
    display: flex;
    border-bottom: 1px solid #ccc;
    .view_crew_colimn{
      padding: 10px;
      border-right: 1px solid #ccc;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .s1{
      width: 2%;
    }
    .s2{
      width: 40%;
    }
    .s3{
      width: 46%;
    }
    .s5{
      width: 2%;
    }
    .c1{
      width: 3%;
    }
    .c2{
      width: 25%;
    }
    .c3{
      width: 40%;
    }
    .c4{
      width: 20%;
    }
    .c5{
      width: 2%;
    }
    i{
      cursor: pointer;
    }
    .view_crew_colimn:last-child {
      border-right: none;
    }
  }
}
.view_crew_btn{
  .noactive{
    background-color: #333333;
  }
}

.ifchange{
  display:inline-block;
  width: auto;
  font-size: 0.6rem;
  font-weight: 800;
  text-transform: uppercase;
  color: #aaaaaa;
  margin: -5px 0 5px 20px;
  cursor: pointer;
  &:hover{
    color: #333333;
  }
}
.input{
  margin: 5px 20px;
  input{
    border: 1px solid #999;
    font-weight: 500;
    font-size: 0.9rem;
    color: #333333;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: transparent;
  }
  border: none;
}
.delete-window{
  margin-top: -35px;
  width: 80%;
  text-align: center;
  display: flex;
  flex-direction: column;
  .buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button{
      width: 40%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
    }
    .da{
      background-color: rgba( 205, 92, 92,1);
      &:hover{
        background-color: rgba( 255, 92, 92,1);
      }
    }
    .net{
      background-color: rgba( 230, 230, 230,1);
      &:hover{
        background-color: rgba( 150, 150, 150,1);
      }
    }
  }

}

.modal_crew_files{
  padding: 20px;
  .buttons_files{
    margin-top: -30px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button{
      display: flex;
      flex-direction: row;
      width: 40%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      background-color: rgba( 230, 230, 230,1);
      text-align: center;
      justify-content: center;
      align-items: center;
      &:hover{
        background-color: rgba( 150, 150, 150,1);
      }
    }
    i{
      margin-left: 10px;
    }
  }
}
.file-table {
  margin: 20px 0;

  .table-wrapper {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #eee;
    border-radius: 6px;
    table {
      width: 100%;

      border-collapse: collapse;
      font-family: 'Segoe UI', sans-serif;
      font-size: 14px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
      th {
        background: #f4f4f4;
        padding: 12px;
        text-align: left;
        border-bottom: 2px solid #ddd;
      }

      td {
        padding: 10px 12px;
        border-bottom: 1px solid #eee;
      }

      tr:hover {
        background: #fafafa;
      }
      .ctr{
        text-align: center;
      }
      button {
        background: #007bff;
        color: white;
        border: none;
        padding: 6px 10px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 13px;

        &:hover {
          background: #0056b3;
        }

        &.delete {
          background: #dc3545;

          &:hover {
            background: #a71d2a;
          }
        }
      }
    }
  }
}
.title_crew{
  margin-top: -20px;
}