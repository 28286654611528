.table_list {
  margin-top: 10px;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .table_list_cap{
    width: 100%;
    height: 30px;
    border-radius: 5px 5px 0 0;
    background: #03AEAE;
  }

  .table_list_strock{
    width: calc(100% - 2px);
    min-height: 50px;
    border: 1px solid #454545;
    display: flex;
    justify-content: flex-start;
    margin-top: -1px;
    font-size: 10pt;
    cursor: pointer;
    .flex_center{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .table_list_strock_datein{
      width: 7.95%;
      min-height: 50px;
      border-right: 1px solid #454545;
    }
    .table_list_strock_dateto{
      width: 15%;
      min-height: 50px;
      border-right: 1px solid #454545;
      text-align: center;
    }
    .table_list_strock_title{
      width: 28.3%;
      min-height: 50px;
      border-right: 1px solid #454545;
    }
    .table_list_strock_icon{
      width: 4.15%;
      min-height: 50px;
      border-right: 1px solid #454545;
    }
    .table_list_strock_performance{
      width: 16.7%;
      min-height: 50px;
      border-right: 1px solid #454545;
      font-size: 10px;
      justify-content: space-between;
      .table_list_strock_p_indicate{
        width: 131px;
        height: 14px;
        border: 1px solid #CCC;
        border-radius: 4px;
        margin-left: 5px;
        .table_list_strock_p_indicate_bluegreen{
          width: 96%;
          height: 70%;
          border: 1px solid #03AEAE;
          border-radius: 3px;
          .table_list_strock_p_indicate_bg_indicate{
            //width: 96%;
            height: 90%;
            border: none;
            border-radius: 3px;
            background-image: url("../../../assets/images/indicate.svg");
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }
      .table_list_strock_p_percent{
        width: 40px;
      }
      .table_list_strock_p_time{
        width: 60px;
        min-height: 60%;
        margin-right: 5px;
        border: 1px solid #454545;
        border-radius: 4px;
        background: #FFF;
        padding: 5px;
      }
    }
    .table_list_strock_status{
      width: 10%;
      min-height: 50px;
      border-right: 1px solid #454545;
    }
    .table_list_strock_category{
      width: 7.65%;
      min-height: 50px;
      border-right: 1px solid #454545;
      font-size: 10px;
    }
    .table_list_strock_priority{
      width:30%;
      min-height: 50px;
      border-right: 1px solid #454545;
    }
    .table_list_strock_edit{
      width: 3.85%;
      min-height: 50px;
      border-right: 1px solid #454545;
      transition: .2s;
    }
    .table_list_strock_edit:hover{
      background: #03AEAE;
      transition: .1s;
    }
    .table_list_strock_close{
      width: 3.85%;
      min-height: 50px;
      transition: .2s;
    }
    .table_list_strock_close:hover {
      background: #DC7700;
      transition: .1s;
    }
  }
  .table_list_strock:hover{
    background: #D9FFE3;
  }
}