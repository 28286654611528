@media screen and (min-width: 1200px){
  .fastbtns{
    width: calc(35% - 20px);
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    position: relative;
    .fast_btns_more{
      position: absolute;
      bottom: -40px;
      right: 0px;
      padding: 5px 30px;
      border: 1px solid #CCC;
      border-radius: 8px;
      color: #CCC;
      cursor: pointer;
      transition: 0.2s;
    }

    .fast_btns_more:hover{
      border: 1px solid #454545;
      color: #454545;
      transition: 0.2s;
    }

    .fastbtns_block {

      width: 100%;
      height: 120px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;


      .fastbtns_block_btn:hover{
        transition: 0.5s;
        .fastbtns_block_btn_radius {
          animation-name: fastbtn;
          animation-duration: 0.2s;
          Animation-timing-function: linear;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        @keyframes fastbtn {
          0% {
            width: 40%;
            height: 60px;
            margin-top: 60px;
          }
          100% {
            width: 50%;
            height: 80px;
            margin-top: 40px;
          }
        }
        .fastbtns_block_btn_icon {
          position: absolute;
          width: 100%;
          height: 120px;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: -20px;
          z-index: 2;


          i {
            font-size: 50px;
            margin-right: 20px;
            animation-name: fastbtnicon;
            animation-duration: 0.9s;
            Animation-timing-function: linear;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
          }
        }
        @keyframes fastbtnicon {
          0% {
            font-size: 50px;
            margin-right: 20px;
            rotate: 0deg;

          }
          100% {

            font-size: 50px;
            margin-right: 20px;
            rotate: 3deg;

          }
        }

      }

      .fastbtns_block_btn {
        position: relative;
        width: 48%;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        cursor: pointer;
        transition: 0.5s;
        .fastbtns_block_btn_box {
          position: absolute;
          width: 100%;
          height: 120px;
          //background: #DC7700;
          border-radius: 10px;
          z-index: 0;
        }
        .fastbtns_block_btn_radius {
          position: absolute;
          width: 40%;
          height: 60px;
          display: flex;
          border-radius: 120% 0 10px 0;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: 60px;
          z-index: 1;
          background: #00E4E4;
        }

        .fastbtns_block_btn_icon {
          position: absolute;
          width: 100%;
          height: 120px;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: -20px;
          z-index: 2;
          i{
            font-size: 50px;
            margin-right: 20px;
            color: rgba(0,0,0,0.5)
          }
        }

        .fastbtns_block_btn_text {
          position: absolute;
          width: 82%;
          height: 100px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 15px;
          //margin-top: -90px;
          z-index: 3;
        }
      }
    }
  }
  .btns_nodes{
    width: 100%;

    .btns_nodes_title{
      display: flex;
      justify-content: flex-start;
      .btns_nodes_title_name{
        font-size: 24pt;
        margin-right: 50px;
        font-weight: 800;
        height: 35px;
        color: rgb(18, 19, 56);
      }
    }

    .btns_nodes_list{
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .btns_nodes_list_btn{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 300px;
        padding: 30px;
        border: 3px solid #DDD;
        margin: 10px 20px 10px 0px;
        border-radius: 10px;
        transition: 0.2s;
        cursor: pointer;
        z-index: 1;
        .btns_nodes_list_btn_icon{
          font-size: 30pt;
          margin-right: 50px;
          font-weight: 800;
          color: rgb(18, 19, 56);
        }
        .btns_nodes_list_btn_name{
          font-size: 16pt;
        }
      }

      .btns_nodes_list_btn:hover{
        border: 3px solid #454545;
        transition: 0.2s;
      }


    }

    .glam{
      border: 3px solid rgb(18, 19, 56) !important;
    }

  }
}
@media screen and (min-width: 800px) and (max-width: 1200px){
  .fastbtns{
    margin-top: 20px;
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    position: relative;
    .fast_btns_more{
      position: absolute;
      bottom: -40px;
      right: 0px;
      padding: 5px 30px;
      border: 1px solid #CCC;
      border-radius: 8px;
      color: #CCC;
      cursor: pointer;
      transition: 0.2s;
    }

    .fast_btns_more:hover{
      border: 1px solid #454545;
      color: #454545;
      transition: 0.2s;
    }

    .fastbtns_block {

      width: 100%;
      height: 120px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;


      .fastbtns_block_btn:hover{
        transition: 0.5s;
        .fastbtns_block_btn_radius {
          animation-name: fastbtn;
          animation-duration: 0.2s;
          Animation-timing-function: linear;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        @keyframes fastbtn {
          0% {
            width: 40%;
            height: 60px;
            margin-top: 60px;
          }
          100% {
            width: 50%;
            height: 80px;
            margin-top: 40px;
          }
        }
        .fastbtns_block_btn_icon {
          position: absolute;
          width: 100%;
          height: 120px;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: -20px;
          z-index: 2;


          i {
            font-size: 50px;
            margin-right: 20px;
            animation-name: fastbtnicon;
            animation-duration: 0.9s;
            Animation-timing-function: linear;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
          }
        }
        @keyframes fastbtnicon {
          0% {
            font-size: 50px;
            margin-right: 20px;
            rotate: 0deg;

          }
          100% {

            font-size: 50px;
            margin-right: 20px;
            rotate: 3deg;

          }
        }

      }

      .fastbtns_block_btn {
        position: relative;
        width: 48%;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        cursor: pointer;
        transition: 0.5s;
        .fastbtns_block_btn_box {
          position: absolute;
          width: 100%;
          height: 120px;
          //background: #DC7700;
          border-radius: 10px;
          z-index: 0;
        }
        .fastbtns_block_btn_radius {
          position: absolute;
          width: 40%;
          height: 60px;
          display: flex;
          border-radius: 120% 0 10px 0;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: 60px;
          z-index: 1;
          background: #00E4E4;
        }

        .fastbtns_block_btn_icon {
          position: absolute;
          width: 100%;
          height: 120px;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: -20px;
          z-index: 2;
          i{
            font-size: 50px;
            margin-right: 20px;
            color: rgba(0,0,0,0.5)
          }
        }

        .fastbtns_block_btn_text {
          position: absolute;
          width: 82%;
          height: 100px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 15px;
          //margin-top: -90px;
          z-index: 3;
        }
      }
    }
  }
  .btns_nodes{
    width: 100%;

    .btns_nodes_title{
      display: flex;
      justify-content: flex-start;
      .btns_nodes_title_name{
        font-size: 24pt;
        margin-right: 50px;
        font-weight: 800;
        height: 35px;
        color: rgb(18, 19, 56);
      }
    }

    .btns_nodes_list{
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .btns_nodes_list_btn{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 300px;
        padding: 30px;
        border: 3px solid #DDD;
        margin: 10px 20px 10px 0px;
        border-radius: 10px;
        transition: 0.2s;
        cursor: pointer;
        z-index: 1;
        .btns_nodes_list_btn_icon{
          font-size: 30pt;
          margin-right: 50px;
          font-weight: 800;
          color: rgb(18, 19, 56);
        }
        .btns_nodes_list_btn_name{
          font-size: 16pt;
        }
      }

      .btns_nodes_list_btn:hover{
        border: 3px solid #454545;
        transition: 0.2s;
      }


    }

    .glam{
      border: 3px solid rgb(18, 19, 56) !important;
    }

  }
}
@media screen and (max-width: 800px){
  .fastbtns{
    margin-top: 20px;
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    position: relative;
    .fast_btns_more{
      position: absolute;
      bottom: -40px;
      right: 0px;
      padding: 5px 30px;
      border: 1px solid #CCC;
      border-radius: 8px;
      color: #CCC;
      cursor: pointer;
      transition: 0.2s;
    }

    .fast_btns_more:hover{
      border: 1px solid #454545;
      color: #454545;
      transition: 0.2s;
    }

    .fastbtns_block {

      width: 100%;
      height: 120px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;


      .fastbtns_block_btn:hover{
        transition: 0.5s;
        .fastbtns_block_btn_radius {
          animation-name: fastbtn;
          animation-duration: 0.2s;
          Animation-timing-function: linear;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        @keyframes fastbtn {
          0% {
            width: 40%;
            height: 60px;
            margin-top: 60px;
          }
          100% {
            width: 50%;
            height: 80px;
            margin-top: 40px;
          }
        }
        .fastbtns_block_btn_icon {
          position: absolute;
          width: 100%;
          height: 120px;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: -20px;
          z-index: 2;


          i {
            font-size: 50px;
            margin-right: 20px;
            animation-name: fastbtnicon;
            animation-duration: 0.9s;
            Animation-timing-function: linear;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
          }
        }
        @keyframes fastbtnicon {
          0% {
            font-size: 50px;
            margin-right: 20px;
            rotate: 0deg;

          }
          100% {

            font-size: 50px;
            margin-right: 20px;
            rotate: 3deg;

          }
        }

      }

      .fastbtns_block_btn {
        position: relative;
        width: 48%;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        cursor: pointer;
        transition: 0.5s;
        .fastbtns_block_btn_box {
          position: absolute;
          width: 100%;
          height: 120px;
          //background: #DC7700;
          border-radius: 10px;
          z-index: 0;
        }
        .fastbtns_block_btn_radius {
          position: absolute;
          width: 40%;
          height: 60px;
          display: flex;
          border-radius: 120% 0 10px 0;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: 60px;
          z-index: 1;
          background: #00E4E4;
        }

        .fastbtns_block_btn_icon {
          position: absolute;
          width: 100%;
          height: 120px;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: -20px;
          z-index: 2;
          i{
            font-size: 50px;
            margin-right: 20px;
            color: rgba(0,0,0,0.5)
          }
        }

        .fastbtns_block_btn_text {
          position: absolute;
          width: 82%;
          height: 100px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 15px;
          //margin-top: -90px;
          z-index: 3;
        }
      }
    }
  }
  .btns_nodes{
    width: 100%;

    .btns_nodes_title{
      display: flex;
      justify-content: flex-start;
      .btns_nodes_title_name{
        font-size: 24pt;
        margin-right: 50px;
        font-weight: 800;
        height: 35px;
        color: rgb(18, 19, 56);
      }
    }

    .btns_nodes_list{
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .btns_nodes_list_btn{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 300px;
        padding: 30px;
        border: 3px solid #DDD;
        margin: 10px 20px 10px 0px;
        border-radius: 10px;
        transition: 0.2s;
        cursor: pointer;
        z-index: 1;
        .btns_nodes_list_btn_icon{
          font-size: 30pt;
          margin-right: 50px;
          font-weight: 800;
          color: rgb(18, 19, 56);
        }
        .btns_nodes_list_btn_name{
          font-size: 16pt;
        }
      }

      .btns_nodes_list_btn:hover{
        border: 3px solid #454545;
        transition: 0.2s;
      }


    }

    .glam{
      border: 3px solid rgb(18, 19, 56) !important;
    }

  }
}
