.gallery-cover-slider {
  position: relative;
  width: calc(100%);
  max-width:900px;
  height: 200px;
  background-color: #DC7700;
  display: flex;
  justify-content: center;
  align-items: center;
  .gallery-board{
    position: relative;
    max-width: 800px;
    height: 90%;
    background-color: #FFFFFF;
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .gallery-board-covers{
      position: relative;
      width: 180px;
      height: 120px;
      background-size: cover;
      background-position: center center;
    }
  }
}
