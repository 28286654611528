@media screen and (min-width: 1200px){
  .list_mess{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .list_messages{
    position: relative;
    width: 68%;
    min-height: 60vh;
    margin-top: -27px;
    margin-right: 20px;
    //justify-content: space-between;
    //align-items: flex-start;

    .grayfon{
      position: sticky;
      top: 60px;
      width: calc(100% + 2px);
      height: 40px;
      margin-top: 0px;
      background-color: #ececec;
      z-index: 10;
    }
    .list_messages_col{
      width: 100%;
      height: 100%;
      border-radius: 15px;
      z-index: 0;

      .list_messages_col_title{
        width: calc(100% - 26px);
        height: 40px;
        padding: 13px;
        display: none;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 80px;
        border: 1px solid #CCC;
        border-radius: 15px 15px 0 0;
        background-color: #FFFFFF;
        z-index: 10;

        .new_mess{
          position: absolute;
          margin-top: 179px;
          top: -180px;
          left: 0px;
          width: 100%;
          //height: 300px;
          background-color: #FFFFFF;
          padding: 10px 0;
          z-index: 10;
          border: 1px solid #CCC;
          margin-left: -1px;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
          display: none;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .new_mess_title{
            width: calc(100% - 20px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            .new_mess_title_createname{
              margin-left: 10px;
              font-weight: 600;
            }
            .new_mess_title_close{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px !important;
              height: 40px;
              cursor: pointer;
              i{
                font-size: 18pt;
              }
            }
            .new_mess_title_close:hover{
              opacity: 0.8;
            }
          }

          .new_mess_create{
            width: calc(100% - 20px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            .new_mess_create_select{
              width: 80% !important;
            }
            //.custom-select__control{
            //  display: flex;
            //  width: 200% !important;
            //}
            .new_mess_create_btn{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20% !important;
              background-color: rgb(18, 19, 56);
              height: 40px;
              border-radius: 2px;
              color: #FFFFFF;
              cursor: pointer;
              i{
                display: none;
              }
            }
          }

        }
        .new_mess_active{
          display: flex;
        }
        .list_messages_col_title_left{
          width: 75%;
          .list_messages_col_title_left_sel{}
        }

        .list_messages_col_title_right{
          width: 25%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .list_messages_col_title_right_new{
            font-size: 19pt;
            color: #454545;
            margin: 0 10px;
            transition: 0.2s;
            cursor: pointer;
          }
          .list_messages_col_title_right_sett{
            font-size: 19pt;
            color: #454545;
            margin: 0 10px;
            transition: 0.2s;
            cursor: pointer;
          }
          i:hover{
            opacity: 0.8;
            transition: 0.2s;
          }
        }

      }
      .list_messages_col_str:hover{
        border-top: 1px solid #CCC;
        border-bottom: 1px solid #CCC;
        height: 98px;
        background-color: rgba(0,0,0,0.001);
        .list_messages_col_str_mess{
          border: none;
        }
      }
      .list_messages_col_str{
        border-left: 1px solid #CCC;
        border-right: 1px solid #CCC;
        background-color: #FFFFFF;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        //padding: 0 0 10px 0;
        .list_messages_col_str_ava{
          position: relative;
          width: 60px;
          height: 60px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
          border-radius: 50%;
          margin: 20px 20px 20px 20px;
        }
        .list_messages_col_str_mess{
          width: calc(100% - 60px - 40px - 20px);
          height: 60px;
          padding-top: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #CCC;
          .list_messages_col_str_mess_left{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            .list_messages_col_str_mess_left_name{
              font-weight: 600;
            }
            .list_messages_col_str_mess_left_text{}
          }

          .list_messages_col_str_mess_right{
            .list_messages_col_str_mess_right_date{
              color: #CCC;
            }
          }

        }

      }
      .list_messages_col_bottom{
        width: calc(100% - 26px);
        height: 0px;
        padding: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 80px;
        border: 1px solid #CCC;
        border-radius: 0 0 15px 15px;
        background-color: #FFFFFF;
        z-index: 0;
      }


      .history_mess{
        display: none;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: calc(100vh - 180px - 60px);
        min-height: 500px;
        background-color: #FFFFFF;
        border: none;
        border-left: 1px solid #CCC;
        border-right: 1px solid #CCC;
        //overflow-x: auto;
        .history_mess_pen {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: calc(100% - 40px);
          padding: 20px;
          position: relative;
          .history_mess_pen_letter{
            width: calc(100% - 20px);
            height: 100px;
            border-radius: 10px;
            border: 1px solid #CCC;
            resize: none;
            outline: none;
            padding: 10px;
          }
          .history_mess_pen_letter:focus{
            border: 1px solid #329DD9;
          }
          .history_mess_pen_btns{
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          .srepbtn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            border-radius: 10px;
            margin-top: 10px;
            margin-right: 10px;
            i{
              font-size: 16pt;
              //margin-left: 10px;
            }
          }
          .srepbtn:hover{
            opacity: 0.8;
          }
          .history_mess_pen_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 30px;
            margin-top: 10px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            border-radius: 10px;
            cursor: pointer;
            i{
              font-size: 16pt;
              margin-left: 10px;
            }
          }
          .history_mess_pen_btn:hover{
            opacity: 0.8;
          }
        }

        .history_mess_list{
          overflow-x: auto;
          width: calc(100% - 20px);
          .history_mess_list_block{
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .history_mess_list_block_ava{
              width: 60px;
              height: 60px;
              background-size: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              border-radius: 50%;
              margin-right: 20px;
            }
            .history_mess_list_block_content{
              width: calc(100% - 120px);
              padding: 10px;
              border-radius: 10px;
              background-color: #F1F1F1;

              .history_mess_list_block_content_name{
                font-weight: 600;
                margin: 10px;
              }
              .history_mess_list_block_content_message{
                margin: 10px;
              }
              .history_mess_list_block_content_dateandstatus{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                font-size: 10pt;
                .history_mess_list_block_content_date{
                  margin-right: 20px;
                }
                .history_mess_list_block_content_status{
                  margin-right: 10px;
                }
              }

            }

          }
          .autorch{
            background-color: #FFFFFF !important;
            border: 1px solid #CCC;
          }
        }


      }

      .activate {
        display: flex;
      }

    }


  }
  .menu_mess{
    position: sticky;
    top: 80px;
    width: calc(25% - 26px);
    height: calc(60vh);
    background-color: #FFFFFF;
    margin-top: 13px;
    border: 1px solid #CCC;
    border-radius: 15px;
    padding: 10px;
    .menu_mess_list{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-bottom: 1px solid #CCC;
      .menu_mess_list_btn{
        width: calc(100% - 20px);
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .menu_mess_list_btn:hover{
        background-color: rgba(0,0,0,0.1);
        border-radius: 10px;
      }
    }

  }
  .custom-select {
    &__control {
      border: none !important;
      border-radius: 0 !important;

      &--menu-is-open {
        //border: none !important;
        border-color: #454545 !important;
      }

      &--is-focused {
        //border: none !important;
        border-color: #454545 !important;
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1200px){
  .list_mess{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .list_messages{
    position: relative;
    width: 68%;
    min-height: 60vh;
    margin-top: -27px;
    margin-right: 20px;
    //justify-content: space-between;
    //align-items: flex-start;

    .grayfon{
      position: sticky;
      top: 60px;
      width: calc(100% + 2px);
      height: 40px;
      margin-top: 0px;
      background-color: #ececec;
      z-index: 10;
    }
    .list_messages_col{
      width: 100%;
      height: 100%;
      border-radius: 15px;
      z-index: 0;

      .list_messages_col_title{
        width: calc(100% - 26px);
        height: 40px;
        padding: 13px;
        display: none;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 80px;
        border: 1px solid #CCC;
        border-radius: 15px 15px 0 0;
        background-color: #FFFFFF;
        z-index: 10;

        .new_mess{
          position: absolute;
          margin-top: 179px;
          top: -180px;
          left: 0px;
          width: 100%;
          //height: 300px;
          background-color: #FFFFFF;
          padding: 10px 0;
          z-index: 10;
          border: 1px solid #CCC;
          margin-left: -1px;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
          display: none;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .new_mess_title{
            width: calc(100% - 20px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            .new_mess_title_createname{
              margin-left: 10px;
              font-weight: 600;
            }
            .new_mess_title_close{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px !important;
              height: 40px;
              cursor: pointer;
              i{
                font-size: 18pt;
              }
            }
            .new_mess_title_close:hover{
              opacity: 0.8;
            }
          }

          .new_mess_create{
            width: calc(100% - 20px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            .new_mess_create_select{
              width: 80% !important;
            }
            //.custom-select__control{
            //  display: flex;
            //  width: 200% !important;
            //}
            .new_mess_create_btn{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20% !important;
              background-color: rgb(18, 19, 56);
              height: 40px;
              border-radius: 2px;
              color: #FFFFFF;
              cursor: pointer;
              i{
                display: none;
              }
            }
          }

        }
        .new_mess_active{
          display: flex;
        }
        .list_messages_col_title_left{
          width: 75%;
          .list_messages_col_title_left_sel{}
        }

        .list_messages_col_title_right{
          width: 25%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .list_messages_col_title_right_new{
            font-size: 19pt;
            color: #454545;
            margin: 0 10px;
            transition: 0.2s;
            cursor: pointer;
          }
          .list_messages_col_title_right_sett{
            font-size: 19pt;
            color: #454545;
            margin: 0 10px;
            transition: 0.2s;
            cursor: pointer;
          }
          i:hover{
            opacity: 0.8;
            transition: 0.2s;
          }
        }

      }
      .list_messages_col_str:hover{
        border-top: 1px solid #CCC;
        border-bottom: 1px solid #CCC;
        height: 98px;
        background-color: rgba(0,0,0,0.001);
        .list_messages_col_str_mess{
          border: none;
        }
      }
      .list_messages_col_str{
        border-left: 1px solid #CCC;
        border-right: 1px solid #CCC;
        background-color: #FFFFFF;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        //padding: 0 0 10px 0;
        .list_messages_col_str_ava{
          width: 60px;
          height: 60px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
          border-radius: 50%;
          margin: 20px 20px 20px 20px;
        }
        .list_messages_col_str_mess{
          width: calc(100% - 60px - 40px - 20px);
          min-height: 60px;
          padding-top: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #CCC;
          .list_messages_col_str_mess_left{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            .list_messages_col_str_mess_left_name{
              font-weight: 600;
              font-size: 11pt;
            }
            .list_messages_col_str_mess_left_text{
              font-size: 9pt !important;
            }
          }

          .list_messages_col_str_mess_right{
            .list_messages_col_str_mess_right_date{
              color: #CCC;
            }
          }

        }

      }
      .list_messages_col_bottom{
        width: calc(100% - 26px);
        height: 0px;
        padding: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 80px;
        border: 1px solid #CCC;
        border-radius: 0 0 15px 15px;
        background-color: #FFFFFF;
        z-index: 0;
      }


      .history_mess{
        display: none;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: calc(100vh - 180px - 60px);
        min-height: 500px;
        background-color: #FFFFFF;
        border: none;
        border-left: 1px solid #CCC;
        border-right: 1px solid #CCC;
        //overflow-x: auto;
        .history_mess_pen {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: calc(100% - 40px);
          padding: 20px;
          position: relative;
          .history_mess_pen_letter{
            width: calc(100% - 20px);
            height: 100px;
            border-radius: 10px;
            border: 1px solid #CCC;
            resize: none;
            outline: none;
            padding: 10px;
          }
          .history_mess_pen_letter:focus{
            border: 1px solid #329DD9;
          }
          .history_mess_pen_btns{
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          .srepbtn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            border-radius: 10px;
            margin-top: 10px;
            margin-right: 10px;
            i{
              font-size: 16pt;
              //margin-left: 10px;
            }
          }
          .srepbtn:hover{
            opacity: 0.8;
          }
          .history_mess_pen_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 30px;
            margin-top: 10px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            border-radius: 10px;
            cursor: pointer;
            i{
              font-size: 16pt;
              margin-left: 10px;
            }
          }
          .history_mess_pen_btn:hover{
            opacity: 0.8;
          }
        }

        .history_mess_list{
          overflow-x: auto;
          width: calc(100% - 20px);
          .history_mess_list_block{
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .history_mess_list_block_ava{
              width: 60px;
              height: 60px;
              background-size: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              border-radius: 50%;
              margin-right: 20px;
            }
            .history_mess_list_block_content{
              width: calc(100% - 120px);
              padding: 10px;
              border-radius: 10px;
              background-color: #F1F1F1;

              .history_mess_list_block_content_name{
                font-weight: 600;
                margin: 10px;
              }
              .history_mess_list_block_content_message{
                margin: 10px;
              }
              .history_mess_list_block_content_dateandstatus{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                font-size: 10pt;
                .history_mess_list_block_content_date{
                  margin-right: 20px;
                }
                .history_mess_list_block_content_status{
                  margin-right: 10px;
                }
              }

            }

          }
          .autorch{
            background-color: #FFFFFF !important;
            border: 1px solid #CCC;
          }
        }


      }

      .activate {
        display: flex;
      }
    }
  }
  .menu_mess{
    position: sticky;
    top: 80px;
    width: calc(25% - 26px);
    height: calc(60vh);
    background-color: #FFFFFF;
    margin-top: 13px;
    border: 1px solid #CCC;
    border-radius: 15px;
    padding: 10px;
    .menu_mess_list{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-bottom: 1px solid #CCC;
      .menu_mess_list_btn{
        width: calc(100% - 20px);
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .menu_mess_list_btn:hover{
        background-color: rgba(0,0,0,0.1);
        border-radius: 10px;
      }
    }

  }
  .custom-select {
    &__control {
      border: none !important;
      border-radius: 0 !important;

      &--menu-is-open {
        //border: none !important;
        border-color: #454545 !important;
      }

      &--is-focused {
        //border: none !important;
        border-color: #454545 !important;
      }
    }
  }
}

@media screen and (max-width: 800px){
  .list_mess{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .list_messages{
    position: relative;
    width: 100%;
    min-height: 60vh;
    margin-top: -27px;
    margin-right: 20px;
    //justify-content: space-between;
    //align-items: flex-start;
    .grayfon{
      position: sticky;
      top: 60px;
      width: calc(100% + 2px);
      height: 40px;
      margin-top: 0px;
      background-color: #ececec;
      z-index: 10;
    }
    .list_messages_col{
      width: calc(100% + 40px);
      height: 100%;
      border-radius: 15px;
      margin-left: -10px;
      z-index: 0;
      .list_messages_col_title{
        width: calc(100% - 26px);
        height: 40px;
        padding: 13px;
        display: none;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 80px;
        border: 1px solid #CCC;
        border-radius: 15px 15px 0 0;
        background-color: #FFFFFF;
        z-index: 10;
        margin-top: -30px;

        .new_mess{
          position: absolute;
          margin-top: 179px;
          top: -180px;
          left: 0px;
          width: 100%;
          //height: 300px;
          background-color: #FFFFFF;
          padding: 10px 0;
          z-index: 10;
          border: 1px solid #CCC;
          margin-left: -1px;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
          display: none;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .new_mess_title{
            width: calc(100% - 20px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            .new_mess_title_createname{
              margin-left: 10px;
              font-weight: 600;
            }
            .new_mess_title_close{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px !important;
              height: 40px;
              cursor: pointer;
              i{
                font-size: 18pt;
              }
            }
            .new_mess_title_close:hover{
              opacity: 0.8;
            }
          }

          .new_mess_create{
            width: calc(100% - 20px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            .new_mess_create_select{
              width: 80% !important;
            }
            //.custom-select__control{
            //  display: flex;
            //  width: 200% !important;
            //}
            .new_mess_create_btn{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px !important;
              height: 40px;
              background-color: rgb(18, 19, 56);
              height: 40px;
              border-radius: 2px;
              color: #FFFFFF;
              cursor: pointer;
              .name-btns{
                display: none;
                //color: red;
              }
            }

          }

        }
        .new_mess_active{
          display: flex;
        }
        .list_messages_col_title_left{
          width: 75%;
          .list_messages_col_title_left_sel{}
        }

        .list_messages_col_title_right{
          width: 25%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .list_messages_col_title_right_new{
            font-size: 19pt;
            color: #454545;
            margin: 0 10px;
            transition: 0.2s;
            cursor: pointer;
          }
          .list_messages_col_title_right_sett{
            font-size: 19pt;
            color: #454545;
            margin: 0 10px;
            transition: 0.2s;
            cursor: pointer;
          }
          i:hover{
            opacity: 0.8;
            transition: 0.2s;
          }
        }

      }
      .list_messages_col_str:hover{
        border-top: 1px solid #CCC;
        border-bottom: 1px solid #CCC;
        height: 98px;
        background-color: rgba(0,0,0,0.001);
        .list_messages_col_str_mess{
          border: none;
        }
      }
      .list_messages_col_str{
        border-left: 1px solid #CCC;
        border-right: 1px solid #CCC;
        background-color: #FFFFFF;
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        //padding: 0 0 10px 0;
        .list_messages_col_str_ava{
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
          border-radius: 50%;
          margin: 20px 20px 20px 20px;
        }
        .list_messages_col_str_mess{
          width: calc(100% - 40px - 40px - 20px);
          height: 100%;
          min-height: 60px;
          padding-top: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          border-bottom: 1px solid #CCC;
          .list_messages_col_str_mess_left{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: content-box !important;
            .list_messages_col_str_mess_left_name{
              font-weight: 600;
              font-size: 11pt;
              height: content-box !important;
            }
            .list_messages_col_str_mess_left_text{
              font-size: 9pt !important;
              height: content-box !important;
            }
          }
          .list_messages_col_str_mess_right{

            .list_messages_col_str_mess_right_date{
              color: #CCC;
              font-size: 10pt;
            }
          }

        }

      }
      .list_messages_col_bottom{
        width: calc(100% - 26px);
        height: 0px;
        padding: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 80px;
        border: 1px solid #CCC;
        border-radius: 0 0 15px 15px;
        background-color: #FFFFFF;
        z-index: 0;
        margin-bottom: -20px;
      }
      .history_mess{
        display: none;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: calc(100vh - 180px - 60px);
        min-height: 500px;
        background-color: #FFFFFF;
        border: none;
        border-left: 1px solid #CCC;
        border-right: 1px solid #CCC;
        //overflow-x: auto;
        .history_mess_pen {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: calc(100% - 40px);
          padding: 20px;
          position: relative;
          .history_mess_pen_letter{
            width: calc(100% - 20px);
            height: 70px;
            border-radius: 10px;
            border: 1px solid #CCC;
            resize: none;
            outline: none;
            padding: 10px;
          }
          .history_mess_pen_letter:focus{
            border: 1px solid #329DD9;
          }
          .history_mess_pen_btns{
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          .srepbtn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 10px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            border-radius: 10px;
            margin-top: 10px;
            margin-right: 10px;
            i{
              font-size: 16pt;
              //margin-left: 10px;
            }
          }
          .srepbtn:hover{
            opacity: 0.8;
          }
          .history_mess_pen_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 30px;
            margin-top: 10px;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
            border-radius: 10px;
            cursor: pointer;
            i{
              font-size: 16pt;
              margin-left: 10px;
            }
          }
          .history_mess_pen_btn:hover{
            opacity: 0.8;
          }
        }

        .history_mess_list{
          overflow-x: auto;
          width: calc(100% - 20px);
          .history_mess_list_block{
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .history_mess_list_block_ava{
              width: 40px;
              height: 40px;
              background-size: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              border-radius: 50%;
              margin-right: 10px;
            }
            .history_mess_list_block_content{
              width: calc(100% - 90px);
              padding: 10px;
              border-radius: 10px;
              background-color: #F1F1F1;

              .history_mess_list_block_content_name{
                font-weight: 600;
                margin: 10px;
                font-size: 10pt;
              }
              .history_mess_list_block_content_message{
                margin: 10px;
                font-size: 10pt;
                audio{
                  width: 280px;
                  margin-left: -30px;
                }
              }
              .history_mess_list_block_content_dateandstatus{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                font-size: 8pt;
                .history_mess_list_block_content_date{
                  margin-right: 20px;
                }
                .history_mess_list_block_content_status{
                  margin-right: 10px;
                }
              }

            }

          }
          .autorch{
            background-color: #FFFFFF !important;
            border: 1px solid #CCC;
          }
        }


      }
      .activate {
        display: flex;
      }
    }


  }
  .menu_mess{
    position: sticky;
    top: 80px;
    width: calc(25% - 26px);
    height: calc(60vh);
    background-color: #FFFFFF;
    margin-top: 13px;
    border: 1px solid #CCC;
    border-radius: 15px;
    padding: 10px;
    display: none;
    .menu_mess_list{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-bottom: 1px solid #CCC;
      .menu_mess_list_btn{
        width: calc(100% - 20px);
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .menu_mess_list_btn:hover{
        background-color: rgba(0,0,0,0.1);
        border-radius: 10px;
      }
    }

  }
  .custom-select {
    &__control {
      border: none !important;
      border-radius: 0 !important;

      &--menu-is-open {
        //border: none !important;
        border-color: #454545 !important;
      }

      &--is-focused {
        //border: none !important;
        border-color: #454545 !important;
      }
    }
  }
}

.list_messages_col_str_ava,.history_mess_list_block_ava {
  position: relative;
  .online1{
    font-size: 0.8rem;
  }
}