.blackselect{
  margin: 0 5px;
  .helmet{
    cursor: pointer;
    background-color: rgb(18, 19, 56);
    width: 40px;
    height: 40px;
    padding: 5px;
    display: flex;

    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    i{
      color: white;
      font-size: 2rem;
      margin-top: 5px;
      width: 40px;
      height: 40px;
      text-align: center;
      &:hover{
        //color: rgba(18, 19, 56, 0.7);
      }
    }
  }

  .menu{
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    margin-top: 60px;
    height: calc(100% - 60px);
    background: rgba(1,1,1,0.7);
    overflow: auto;
    .quit{
      display: flex;
      justify-content: space-between;
      padding: 5px;
      margin: 15px 15px 0 0;
      .quiti{
        cursor: pointer;
        font-size: 1.5rem;
        color: rgba(200,100,100,0.7);
        &:hover{
          color: rgba(200,100,100,1);
        }
      }
      .search{
        margin: 0 0 0 10px;
        width: 100%;
        div{
          position: relative;
          .close{
            position: absolute;
            top:5px;
            right: 40px;
            font-size: 1.3rem;
            color: #aaa;
            cursor: pointer;
            &:hover{
              color: #fff;
            }
          }
        }
        input{
          color: #FFFFFF;
          //text-transform: uppercase;
          background: transparent;
          max-width: 400px;
          padding: 5px;
          width: 100%;
        }
      }
    }
    .list{
      .row{
        padding: 5px 20px;
        color: #FFFFFF;
        cursor: pointer;
        &:hover,&.active{
          background: rgba(1,1,1,0.5);
        }
      }
    }
  }
}
.disable{
  .helmet{
    background-color: rgb(200, 200, 200);
  }
}
