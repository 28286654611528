@media screen and (min-width: 1200px){
    .slider_block{
        width: 60%;
        aspect-ratio: 3 / 1 !important;
        background-size: contain;
        background-position: center center;
        border-radius: 10px;
        position: relative;
        .small-link{
            color: rgba(18,19,56,1);
            font-size: 0.7rem;
            cursor: pointer;
            font-weight: 500;
        }
        .small-link:hover{
            color: #aaa;
            text-decoration: underline !important;
        }
        .overlay{
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background-color: rgba(1,1,1,0);
            z-index: -1;
        }
        .data{
            width: 60%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .text{
                text-align: center;
                font-size: 0.9rem;
                font-weight: 600;
                text-transform: uppercase;
                p{
                    margin: 7px 0;
                }
                .big{
                    font-size: 1.2rem;
                }
            }
            .button{
                cursor: pointer;
                background-color:rgba(18,19,56,1);
                color: white;
                margin: 3px;
                padding: 10px 30px;
                width: 160px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: 0.8rem;
                border-radius: 10px;
                z-index: 2;
            }
        }
    }
}
@media screen and (min-width: 800px) and (max-width: 1200px){
    .slider_block{
        width: 100%;
        background-size: cover;
        background-position: center center;
        border-radius: 10px;
        position: relative;
        aspect-ratio: 3 / 1 !important;
        .small-link{
            color: rgba(18,19,56,1);
            font-size: 0.7rem;
            cursor: pointer;
            font-weight: 500;
        }
        .small-link:hover{
            color: #aaa;
            text-decoration: underline !important;
        }
        .overlay{
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background-color: rgba(1,1,1,0);
            z-index: -1;
        }
        .data{
            width: 60%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .text{
                text-align: center;
                font-size: 0.9rem;
                font-weight: 600;
                text-transform: uppercase;
                p{
                    margin: 7px 0;
                }
                .big{
                    font-size: 1.2rem;
                }
            }
            .button{
                cursor: pointer;
                background-color:rgba(18,19,56,1);
                color: white;
                margin: 3px;
                padding: 10px 30px;
                width: 160px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: 0.8rem;
                border-radius: 10px;
                z-index: 2;
            }
        }
    }
}
@media screen and (min-width: 420px) and (max-width: 800px){
    .slider_block{
        width: 100%;
        aspect-ratio: 3 / 1 !important;
        background-size: cover;
        background-position: center center;
        border-radius: 10px;
        position: relative;

        .small-link{
            color: rgba(18,19,56,1);
            font-size: 0.7rem;
            cursor: pointer;
            font-weight: 500;
        }
        .small-link:hover{
            color: #aaa;
            text-decoration: underline !important;
        }
        .overlay{
            position: absolute;
            top:0;
            left: 0;
            width: 90%;
            height: 100%;
            border-radius: 10px;
            background-color: rgba(1,1,1,0);
            z-index: -1;
        }
        .data{
            width: 60%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .text{
                text-align: center;
                font-size: 0.9rem;
                font-weight: 600;
                text-transform: uppercase;
                p{
                    margin: 7px 0;
                }
                .big{
                    font-size: 1.2rem;
                }
            }
            .button{
                cursor: pointer;
                background-color:rgba(18,19,56,1);
                color: white;
                margin: 3px;
                padding: 10px 30px;
                width: 160px;
                height: 20px;
                display: flex;
                margin-left: 10px;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: 0.8rem;
                border-radius: 10px;
                z-index: 2;
            }
        }
    }
}

@media screen and (max-width: 420px){
    .slider_block{
        width: 100%;
        background-size: cover;
        background-position: center center;
        border-radius: 10px;
        position: relative;
        aspect-ratio: 3 / 1 !important;
        .small-link{
            color: rgba(18,19,56,1);
            font-size: 0.7rem;
            cursor: pointer;
            font-weight: 500;
        }
        .small-link:hover{
            color: #aaa;
            text-decoration: underline !important;
        }
        .overlay{
            position: absolute;
            top:0;
            left: 0;
            width: 90%;
            height: 100%;
            border-radius: 10px;
            background-color: rgba(1,1,1,0);
            z-index: -1;
        }
        .data{
            width: 60%;
            height: 100%;
            display: flex;
            margin-left: 30px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .text{
                text-align: center;
                font-size: 0.7rem;
                font-weight: 600;
                text-transform: uppercase;
                p{
                    margin: 7px 0;
                }
                .big{
                    font-size: 1.0rem;
                }
            }
            .button{
                cursor: pointer;
                background-color:rgba(18,19,56,1);
                color: white;
                margin: 3px;
                padding: 10px 30px;
                width: 160px;
                height: 20px;
                display: flex;
                margin-left: 20px;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: 0.8rem;
                border-radius: 10px;
                z-index: 2;
            }
        }
    }
}

.slider_block {
    background-repeat: no-repeat;
    aspect-ratio: 3.3 / 1 !important;
    background-size: cover !important;
    position: relative;
    .about-button {
        position: absolute;
        bottom: 30px;
        right: 30px;
        width: 13%;
        text-transform: uppercase;
        display: inline-block;
        padding: 6px;
        background: linear-gradient(90deg, #0074D9 0%, #CACACA 100%);
        color: #fff;
        font-size: 0.9rem;
        font-weight: bolder;
        text-align: center;
        border: none;
        border-radius: 40px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            background: linear-gradient(90deg, #005bb5 0%, #b0b0b0 100%);
        }

        &:active {
            transform: scale(0.98); // Небольшое уменьшение при клике
        }
    }
}
@media screen and (max-width: 1600px){
    .slider_block {
        .about-button {
            font-size: 0.8rem;
        }
    }
}
@media screen and (max-width: 1450px){
    .slider_block {
        width: 65%;
        .about-button {
            font-size: 0.7rem;
        }
    }
}
@media screen and (max-width: 1300px){
    .fastbtns {
        width: 100%;
    }
    .slider_block {
        width: 100%;
        .about-button {

            font-size: 0.8rem;
        }
    }
}
@media screen and (max-width: 700px){
    .slider_block {

        .about-button {
            bottom: 15px;
            right: 15px;
            font-size: 0.6rem;
        }
    }
}
@media screen and (max-width: 550px){
    .slider_block {
        .about-button {
            bottom: 10px;
            right: 10px;
            font-size: 0.45rem;
        }
    }
}
@media screen and (max-width:400px){
    .slider_block {
        .about-button {
            left: 47%;
            width: 30%;
            bottom: 12px;
            font-size: 0.7rem;
        }
    }
}