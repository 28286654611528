.ogmlist{
  width: 100%;
  height: 100%;
  .ogmlist_title{
    font-size: 18pt;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .ogmlist_btns{
    display: flex;
    .ogmlist_upload{
      background-color: rgb(18, 19, 56);
      color: white;
      padding: 10px 16px 10px 10px;
      width: 200px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      margin: 0 20px 0 0;
      border-radius: 2px;
      transition: .2s;
    }
    .ogmlist_upload:hover{
      opacity: 0.8;
      transition: .2s;
    }
    .ogmlist_save{background-color: rgb(18, 19, 56);
      color: white;
      padding: 10px 16px 10px 10px;
      width: 200px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      margin: 0 20px 0 0;
      border-radius: 2px;
      transition: .2s;}
    .info{
      display: flex;
      flex-direction: row;
      .select_block{
        height: 40px;
        display: flex;
        min-width: 300px;
        .select_block_title{
          display: flex;
          align-items: center;
          margin-right: 20px;
          margin-bottom: 10px;
        }
        .select{
          display: flex;
          align-items: center;
          min-width:200px;
          width: 200px;
        }
      }
    }

  }
  .disable{
    background-color: rgb(100, 100, 100) !important;
  }
  .ogmlist_list{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    .dop-info{
      border-bottom: 4px solid #CCC;
      width: 1220px;
      padding: 20px 0;
      .item{
        border: 2px solid #CCC;
      }
      .table-container {
        width: 1200px;
        overflow-x: auto; /* добавляем горизонтальную прокрутку, если таблица шире контейнера */
      }
      .table {
        display: flex;
        flex-direction: column;
      }
      .rowsmall {
        display: flex;
      }
      .cell {
        border: 1px solid #000;
        padding: 3px;
        font-size: 0.6rem;
        min-width: 30px;
        max-width: 32px;
      }
      .middle-column {
        min-width: 60px; /* задаем ширину для "широких" столбцов */
        max-width: 60px;
      }
      .wide-column {
        min-width: 120px; /* задаем ширину для "широких" столбцов */
        max-width: 120px;
      }
      .wbig-column{
        min-width: 300px; /* задаем ширину для "широких" столбцов */
        max-width: 300px;
      }
      .big-column{
        min-width: 200px; /* задаем ширину для "широких" столбцов */
        max-width: 200px;
      }
    }
    .title{
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    .ogmlist_list_line > * {
      display: flex;
      align-self: stretch;
      height: auto !important;
    }
    .ogmlist_list_line{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: fit-content;
      min-height: 40px;
      border: 4px solid #CCC;
      font-size: 10pt;
      align-self: stretch;
      .ogmlist_list_line_name{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch !important;
        width: 300px;
        min-height: 40px;
        height: 100%;
        border: none;
        border-right: 4px solid #CCC;
        background-color: #F1F1F1;
      }
      .ogmlist_list_line_price{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        width: 280px;
        padding: 0 10px;
        min-height: 40px;
        height: auto !important;
        border: none;
        border-right: 4px solid #CCC;
      }
      .ogmlist_list_line_group{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        width: 280px;
        padding: 0 10px;
        min-height: 40px;

        border: none;
        border-right: 4px solid #CCC;
      }
      .ogmlist_list_line_cropname{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 280px;
        padding: 0 10px;

        height: 100%;
        border: none;
      }
      .icons-container{
        width: 40px;
        margin-left: -40px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
      .ogmlist_list_line_del{
        margin-left: -20px;
        width: 20px;
        height: 20px;
        font-size: 16pt;
        margin-top: 5px;
        font-weight: 700;
        color: #CCC;
        transition: .2s;
        cursor: pointer;
      }
      .ogmlist_list_line_del:hover{
        color: #454545;
        transition: .2s;
      }
      .title{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 280px;
        min-height: 40px;
        height: 100%;
        border: none;
        border-right: 4px solid #CCC;
        font-weight: 700;
        font-size: 12pt;
        background-color: #F1F1F1;
      }
      .nametitle{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 100%;
        border: none;
        border-right: 4px solid #CCC;
        font-weight: 700;
        font-size: 12pt;
        background-color: #F1F1F1;
      }
      .borderrightnone{
        border-right: none;
      }
      .leftborder{
        border-left: 4px solid #CCC;
      }
      .inputs{
        outline: none;
        background-color: #FFFFFF;
      }
      .inputs:focus{
        outline: none;
        background-color: #9aa9ce;
        width: 280px;
        padding: 0 10px;

      }
      .button{
        text-decoration: underline;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 600;
      }.button:hover{
        color: white;
      background-color: rgba(18,19,56,1);
      }
    }
    .bordertopnone{
      border-top: none;
    }

  }
  .newlineogm{
    padding: 20px;
    border: 4px dotted #CCC;
    width: fit-content;
    border-radius: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .line{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .line_title{
        font-size: 16pt;
        font-weight: 800;
        margin-right: 20px;
      }
    }
    .ogmlist_list_line_new{
      width: 251px;
      padding: 9px;
      margin-right: 20px;
      outline: none;
      border: 2px solid #454545;
      border-radius: 5px;
    }
    .small{
      width: 100px;
    }
    .ogmlist_plus{
      background-color: rgb(18, 19, 56);
      color: white;
      padding: 10px 16px 10px 10px;
      width: 247px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      margin: 0 20px 0 0;
      border-radius: 2px;
      transition: .2s;
      margin-top: 10px;
    }
    .ogmlist_plus:hover{
      opacity: 0.8;
      transition: .2s;
    }
  }
}
.ktudoc{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .info{
    margin-top: 10px;
    .input-container{
      height: 40px;
      margin-bottom: 20px;
      width: 100%;
      input{
        width: 60%;
      }
    }
    label{
      text-transform: uppercase;
    }
  }
  .signals{
    display: flex;
    flex-direction: column !important;
  }
}
.control-panel{
  width: 100%;
  display: flex;
  flex-direction: column;

  .ogmlist_btns{
    width: 50%;

  }
  .select-month{
    width: 50%;
  }
}
.red{
  background-color: #e74c3c !important;
}

.small{
  .title{
    font-size: 0.8rem !important;
  }
}
.w250{
  width: 250px !important;
}
.w200{
  width: 200px !important;
}
.w150{
  width: 150px !important;
}
.w100{
  width: 100px !important;
}
.w50{
  width: 50px !important;
}
.select_block_title{
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;
}
.buttons{
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.red-solid-border-4{

}

.main-title{
  width: 100%;
  text-align: left;
  margin: 20px;
}
.sort{
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: bold;
  width: 100%;
  margin-bottom: 10px;
  color: #454545;
  span{
    font-size: 0.7rem;
    color: #151515;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 20px;
  }
  span:hover{
    color: #454545;
  }
}