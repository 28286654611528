.custom-audio-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-audio-player button {
  background-color: #131537;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
}

.custom-audio-player button:hover {
  background-color: #131537;
}

.custom-audio-player input[type="range"] {
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
}




