@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shantell+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

$wwhiteColor:rgba(255,255,255,1);
$whiteColor:rgba(236,236,236,1);
$whiteHoverColor:rgba(200,200,200,1);
$blackColor:rgba(30,30,30,1);
$greyColor:rgba(116,116,116,1);
$wgreyColor:rgba(217,217,217,1);
$wwgreyColor:rgba(240,240,240,1);
$dgreyColor:rgba(97,118,118,1);
$darkGreyColor:rgba(80,80,80,1);
$redColor:rgba(215,26,0,1);
$dredColor:rgba(215,26,0,0.5);
$dblueColor:rgba(30,74,139,1);
$wblueColor:rgba(50,157,217,1);
$wblueColort:rgba(50,157,217,0.9);
$purpleColor:rgba(147, 112, 219, 1);
$orangeColor:#FEAA2D;
$blueColor:#00E4E4;
$greenColor:#04B800;
$fileColor:rgba(253,224,183,1);
$yellowColor:rgba(255, 255, 0, 1);
$transBlackColor:rgba(30,30,30,0.8);
$transwwBlackColor:rgba(60,60,60,0.5);
$transwBlackColor:rgba(30,30,30,0.6);
$transBlueColor: rgba(2, 24, 68, 0.7);
$transdBlueColor:rgba(13,17,52,0.81);
$transWhiteColor:rgba(230,230,230,0.7);

$headerHeight:80px;
$newsheaderHeight:115px;

$objwidth:384px;
$calendarwidth:384px;
$workswidth:420px;
$worksHeight:220px;
*{
  //outline: 1px solid #ff5e5e;
}
.news_block_title{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  .news_block_title_name{
    font-size: 24pt;
    margin-right: 50px;
    font-weight: 800;
    height: 35px;
    color: rgb(18, 19, 56);
  }
  .news_block_title_more{
    //margin-top: 5px;
    font-size: 14pt;
    cursor: pointer;
  }
  .news_block_title_create{
    font-size: 14pt;
    padding: 5px 30px;
    margin: 0 0 0 20px;
    border: 1px solid #CCC;
    border-radius: 8px;
    color: #CCC;
    cursor: pointer;
    transition: 0.2s;
  }
  .news_block_title_create:hover{
    border: 1px solid #454545;
    color: #454545;
    transition: 0.2s;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.yellow{
  background-color:$yellowColor !important;
}
.green{
  background-color: $greenColor !important;
}
.blue{
  background-color: $blueColor !important;
}
.dblue{
  background-color: $dblueColor !important;
}
.dgrey{
  background-color: $dgreyColor !important;
}
.red{
  background-color: $redColor !important;
}
.purple{
  background-color: $purpleColor !important;
}
.orange{
  background-color: $orangeColor !important;
}
*{
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}
a{
  text-decoration: none !important;
  color: black;
}
hr{
  border: solid 2px $whiteColor;
}
html{
  height: 100%;
  body{
    height: calc(100% - 40px);
    background-color: $whiteColor;
  }
}
.flex{
  display: flex !important;
}
.block{
  display: block !important;
}
/********************************Пиздец Важно ***************/
.new_container{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  min-height: 100vh;
  .up_path{
    width: 100%;
    height: 40px;
    padding: 10px 0;
    background: rgba(18,19,56,1);
    color: #F1F1F1;
    position: sticky;
    top: 0px;
    z-index: 100;

  }

  .main_path{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: calc(100vh - 40px - 20px);
  }
}
/*************************************************************/

#root {
  height: 100%;
  .App{
    min-height: calc(100% - $headerHeight);
    .navbar{
      nav {
        display: flex;
        align-items: center;
        height:$headerHeight;
        background-color: $whiteColor;
        justify-content: space-between;
        border-bottom: 1px solid $blackColor;
        .head{
          height: 100%;
          display: flex;
          align-items: center;
          #logo {
            width: 92px;
            height: 85%;
            margin-left: 30px;
            margin-right: 30px;
            background-image: url("../images/logo.svg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
          }
          .textlogo{
            height: 100%;
            text-transform: uppercase;
            font-weight: bolder;
          }
          .span{
            color: $greyColor;
            display: block;
            text-transform: none;
            font-weight: bolder;
          }
        }
        ul{
          height: 100%;
          margin-left: 20px;
          display: flex;
          list-style-type: none;
          li{
            height: 100%;
            display: flex;
            text-transform: uppercase;
            font-weight: bold;
            padding: 0 25px;
            cursor: pointer;
            a{
              height: 100%;
              display: flex;
              align-items: center;
              .post-icon{
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url("../images/post.svg");
                width: 42px;
                height: 30px;
              }
              p{
                font-size: 14px;
                color:$blackColor;
              }
            }
          }li:hover{
             background-color: $whiteHoverColor;
           }

        }
      }
      hr{
        height: 40px;
        background-color: $wgreyColor;
        border: none;
        width: 100%;
      }
    }
  }

  .flex{
    display: flex;
  }
  .container{
    min-height: 100%;
    .title{
      width: 100%;
      font-weight: 500;
      font-size: 1rem;
      display: flex;
      align-items: center;
    }

    .right_block{
      width: calc(80%);
      flex: 0 0 calc(80%);
      min-height: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      //justify-content: space-between;
      flex-wrap: wrap;
      .stroka{
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

    }
  }
}
.blocksobj {
  margin: 10px;
  display: flex;
  min-width: 384px;
  max-width: 384px;
  max-height: 384px;
  flex-wrap: wrap;
  justify-content: space-between;
  div{
    max-width: 100px;
    width: 100px;
    height: 40px;
    margin: 7px;
    padding: 35px;
    border: 1px solid $blackColor;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    p{
      font-size: 0.8rem;
      font-weight: 600;
      justify-content: center;
      text-align: left;
      text-transform: uppercase;
    }
  }
}
.rows{
  width: 384px;
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.graphobj{
  width: 384px;
  height: 120px;
  border-bottom: 5px solid $wgreyColor;
  position: relative;
  margin-top: 20px;
  .rotate{
    position: absolute;
    bottom: -52px;
    left: 200px;
    //width: 200px;
    transform: rotate(270deg);
    div{
      width: 120px;
      //margin-top: 20px;
      p{
        width: 100%;
        font-weight: 500;
        font-size: 0.8rem;
        margin-left: 3px;
        //height: 100%;
      }
      .number{
        position: absolute;
        width: 10px;
        left:-26px;
        transform: rotate(90deg);

      }
      .process{
        width: 100px;
        height: 18px;
        background-color: aqua;
        margin-bottom: 20px;
        margin-top: 3px;
      }
    }

  }
  .title{
    position: absolute;
    bottom: 0;
    font-weight: 700;
    font-size: 1rem;
    margin: 4px;
  }
}
.selectmonth{
  display: flex;
  border: 1px solid $wgreyColor;
  height: 50px;
  margin-top: 7px;
  justify-content: space-between;
  .icon{
    cursor: pointer;
    padding: 0 10px;
    i{
      color: $wgreyColor;
      font-size: 3rem;
    }
  }
  .icon.r{
    border-left: 1px solid $wgreyColor;
  }
  .icon.l{
    border-right: 1px solid $wgreyColor;
  }
  .select{
    display: flex;
    justify-content: center;
    align-items: center;
    p{
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 700;
      color: $wgreyColor;
    }
  }
}


.calendarobj{
  width: $calendarwidth;
  height: $calendarwidth;
  margin: 10px;
  .calendar_content{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    margin-top: -$calendarwidth;
    h4{
      margin-top: 20px;
      margin-left: 14px;
      font-weight: 600;
    }
    .dates{
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      div{
        width: calc(($calendarwidth - 40px) / 7);
        height: calc(($calendarwidth - 40px) / 7);
        display: flex;
        align-items: center;
        cursor: pointer;
        p{
          width: 100%;
          text-align: center;
        }
        .modal_clue{
          position: fixed;
          margin-left: -25px;
          margin-top: -120px;
          padding: 10px;
          width: 200px;
          height: 60px;
          display: none;
          background-color: $wwgreyColor;
          border: 1px solid $blackColor;
          border-radius: 5px;
          h5{
            font-weight: 600;
            font-size: 0.9rem;
          }
          p{
            text-align: left;
            font-size: 0.7rem;
          }
        }

        .n-4{
          margin-left: -75px;
        }
        .n-5{
          margin-left: -120px;
        }
        .n-6{
          margin-left: -165px;
        }
      }
      div.hov:hover{
        background-color: $wgreyColor;
      }
      .today{
        background-color: $wgreyColor;
      }
      .over{
        background-color: red;
      }
      .over:hover{
        background-color: red;
      }
      .pending{
        background-color: #FEAA2D;
      }
      .pending:hover{
        background-color: #FEAA2D;
      }
    }
  }
  .calendar_border{
    width: $objwidth;
    display: flex;
    flex-direction: column;
    .back{
      width: $calendarwidth;
      height: $calendarwidth;
    }
    .cal_bor_one{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      div{
        width:224px;
        height: 164px;
        border-top: 3px solid $orangeColor;
        border-left: 3px solid $orangeColor;
        border-radius: 10px 0 0 0;
      }
    }
    .cal_bor_two{
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      margin-top: -$calendarwidth;
      div{
        width:131px;
        height:353px;
        border-top: 3px solid $blueColor;
        border-right: 3px solid $blueColor;
        border-radius: 0 10px 0 0;
      }
    }
    .cal_bor_three{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-top: -$calendarwidth;
      div{
        width:339px;
        height: 188px;
        border-left: 3px solid $greenColor;
        border-bottom: 3px solid $greenColor;
        border-radius: 0 0 0 10px;
      }
    }
  }
}
.worksobj{
  width: 100%;
  overflow: hidden;
  height: $worksHeight;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .scrollOffset{
    height: 100%;
    display: flex;
    transition: transform 0.3s ease;
    .work2{
      border: 1px solid $blackColor;

    }
    .work{

      width: $workswidth;
      flex-shrink: 0;
      margin: 10px;
      background: #FFF;
      .top-block{
        display: flex;
        justify-content: space-between;
        p{
          text-align: center;
          color: $whiteColor;
        }
        .date{
          background-color: $dgreyColor;
          padding: 10px 0;
          width: 40%;
          p{
            font-weight: 700;
            font-size: 1.1rem;
          }
        }
        .level{
          background-color: $purpleColor;
          padding: 10px 0;
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: center;
          p{
            font-size: 0.7rem;
            font-weight: 500;
          }
        }
      }
      .info2{
        height: 100%;
        justify-content: center !important;
        background-color: white !important;
        flex-wrap: nowrap !important;
        align-items: center;

        .texts{
          //width: 80%;
        }
        p{
          display: flex;
          justify-content: space-between;
          align-items: center;
          span{
            width: 15px;
            height: 35px;
            margin-right: 10px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../images/way.svg");
          }
        }
        .img{
          margin-right: 15px;
          width: 59px;
          height: 79px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        .out{
          background-image: url('../images/out.svg');
        }
        .in{
          background-image: url('../images/in.svg');
        }
        .tmc{
          width: 78px;
          height: 77px;
          margin-left: 10px;
          background-image: url('../images/tmc.svg');
        }
      }
      .info-block{
        height: calc($worksHeight - 100px);
        background-color: $wgreyColor;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        h4{
          width: 100%;
          font-weight: 700;
          font-size: 1rem;
        }
        p{
          margin-left: 10px;
        }
        hr{
          display: block;
          width: 100%;
          height: 0;
          border: none;
          border-top: 1px solid $blackColor;
          margin: 10px 0;
        }
        .end{
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          width: 100%;
        }
      }
    }
    
    .br_grey{
      background: $wgreyColor;
      padding: 0px;
    }
  }



  .buttonsBlock2{
    height: $worksHeight !important;
  }
  .buttonsBlock{
    width: 100%;
    margin-top: - $worksHeight;
    height: calc($worksHeight - 0px);
    display: flex;
    justify-content: space-between;
    z-index: 1001;
    align-items: center;
    .pust{
      min-width: 1px;
      height: 100%;
      z-index: 900;
    }
    .button{
      cursor: pointer;
      background-color: $transBlackColor;
      height: 100%;
      width: 40px;
      border: none;
      z-index: 2000;
      display: flex;
      justify-content: center;
      align-items: center;
      i{
        font-size: 3rem;
        color:$wgreyColor;
      }
    }
    .leftb{
      border-radius: 0 10px 10px 0;
      i{
        font-size: 3rem;
        color:$wgreyColor;
      }
    }
    .rightb{
      border-radius: 10px 0 0 10px;

    }
  }
}

.bold{
  font-weight: 800;
}
.auth{
  height: 100vh;
  width: 100vw;
  background-image: url('../images/backimg.jpg');
  background-size: cover;
  .auth_back{
    width: 100vw !important;
    height: 100%;
    background-color: $transBlueColor;
    display: flex;
    align-items: center;
    justify-content: center;
    .auth_form{
      width: 280px;
      //text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo{
        width: 80%;
        height: 225px;

        margin-top: -200px;
        margin-bottom: 20px;
        background-image: url("../images/logorsu.svg");
        background-size: cover;

      }
      .inauth{
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        input:focus, input:-webkit-autofill, input {
          width: 100%;
          max-width: 300px;
          padding: 8px;
          margin-top: 5px;
          margin-bottom: 15px;
          background-color: transparent !important;
          border: none;
          border-bottom: 1px solid $whiteColor;
          color: $wwhiteColor !important;
          -webkit-text-fill-color: $wwhiteColor !important;
          outline: none;
          transition: background-color 6000s ease-in-out 0s;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          transition: background-color 60000s ease-in-out 0s; /* выставляется желаемая задержка */
        }
        input::placeholder{
          color: $whiteColor;
          font-size: 0.7rem;
          text-transform: uppercase;
        }
        .button {
          width: 100%;
          max-width: 300px;
          background-color: $whiteColor;
          color: $dgreyColor;
          margin-top: 10px;
          padding: 10px;
          cursor: pointer;
          border: 5px solid $whiteColor;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
        }
        .button:active {
          background-color: $dgreyColor;
          color: $whiteColor;
        }
        .password-input{
          width: 100%;
          max-width: 320px;
          position: relative;
          display: flex;
          justify-content: center;
          .eye{
            position: absolute;
            top:10px;
            right: 10px;
            cursor: pointer;
            color: #ddd;
            &:hover{
              color: #fff;
            }
          }
        }

      }

    }
  }
}
.backimg{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-image: url('../images/backimg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  .backcol{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $transWhiteColor;
  }
}
.newsnavbar{
  position: fixed;
  width: 100%;
  z-index: 999999999;
  nav{
    display: flex;
    align-items: center;
    height:$newsheaderHeight;
    background-color: $transdBlueColor;
    justify-content: space-between;
    .rhead,.head{
      height: 100%;
      display: flex;
      align-items: center;
      #logo {
        width: 86px;
        height: 76%;
        margin-left: 70px;
        margin-right: 30px;
        background-image: url("../images/logorsu.svg");
        background-position: center;
        background-size: cover;

        background-repeat: no-repeat;
        display: flex;
      }
      p{
        color: $whiteColor;
      }
      .button{
        background-color: transparent;
        border: 1px solid $whiteColor;
        width: 120px;
        height: 40px;
        color: $whiteColor;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 600;
        margin: 0 20px;
        cursor: pointer;
      }
      .textlogo{
        height: 100%;
        text-transform: uppercase;
        font-weight: bolder;
        color: $whiteColor;
        a{
          color: inherit;
        }
      }
      .span{
        color: $greyColor;
        display: block;
        text-transform: none;
        font-weight: bolder;
      }
    }

    ul{
      height: 100%;
      margin-left: 20px;
      display: flex;
      list-style-type: none;
      li{
        height: 100%;
        display: flex;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 25px;
        cursor: pointer;
        a{
          height: 100%;
          display: flex;
          align-items: center;
          p{
            font-size: 14px;
            color:$blackColor;
          }
        }
      }li:hover{
         background-color: $whiteHoverColor;
       }

    }
  }
}

.thispage{
  display: flex;
  flex-direction: column;
  min-height: 100%;

}

.newspage {
  //min-height: calc(100vh - $headerHeight - 65px);
  //width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    height: $newsheaderHeight;
    background-color: $transdBlueColor;
    justify-content: space-between;
    //position: relative;
    //margin-top: 30px;
  }
  .main_path {
    min-height: calc(100vh - $headerHeight  - 65px);
    width: 100%;
    .new_back {
      width: 100%;
      margin-top: 110px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .info_news {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-width: 60%;
        margin-top: 20px;

        .polosa {
          width: 0;
          border: 1px solid $whiteColor;
          height: 100%;
          background-color: $whiteColor;
          margin-right: 10px;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: calc(50% - 40px);
          margin: 0 15px;
          background-color: $whiteColor;
          border-right: 5px;

          p {
            margin-left: 25px;
            font-size: 0.8rem;
            margin-bottom: 15px;
          }

          .button {
            background-color: $wblueColor;
            padding: 15px;
            margin: 10px;
            color: $whiteColor;
            text-transform: uppercase;
            text-align: center;
            font-size: 1rem;
            border-right: 5px;
          }
        }

        .between {
          background-color: transparent;
          justify-content: space-between;
        }
      }

      .rows_news {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .news {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 320px;

        margin-top: 40px;

        .news_img {
          min-width: 50%;
          height: 100%;
          background-size: cover;
        }

        .news_text {
          background-color: $whiteColor;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            margin: 20px;
            font-weight: 800;
            font-size: 1.1rem;
          }

          .text {
            margin: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: start;

          }

          .buttom_block {
            display: flex;
            justify-content: flex-end;

            a {
              background-color: $wblueColor;
              padding: 10px 60px;
              margin: 20px;
              border-radius: 5px;
              color: $whiteColor;
              font-size: 0.8rem;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
.one_news_block{
  min-height: calc(100% - $newsheaderHeight - $newsheaderHeight - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 130px;
  width: 100%;
  .block{
    width: 60%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .info_new{
    .title{
      font-weight: 800;
      font-size: 1.2rem;
    }
  }
  .gallery{
    width: 100%;
    .gallery_app{
      text-align: center;
      margin-top: 20px;
      .img {
        width: 100%;
        height: 500px;
        margin-bottom: 10px;
        background-size: cover;
        background-position: center;
      }
      .button_block{
        width: 100%;
        margin-top: - 510px;
        height: 500px;
        display: flex;
        justify-content: space-between;
        z-index: 1001;
        align-items: center;
        .button{
          height: 100%;
          background-color: $transdBlueColor;
          width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          i{
            color: $whiteColor;
            font-size: 2rem;
          }
        }
      }
    }




  }

}
.footer{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  height:$newsheaderHeight;
  background-color: $transdBlueColor;
  justify-content: space-between;
  position: relative;
  margin-top: 30px;
}
#canvasContainer {
  width: 470px;
  max-height: calc(100vh - $headerHeight - 45px);
  overflow: hidden;
  cursor: grab;
  position: relative;
}
#documentway{
  //background-color: $transBlackColor;
}

#search{
  width:33%;
  min-width: 250px;
  margin: 15px;
  display: flex;
  max-height: 53px;
  flex-direction: row;
  div{
    min-width: 38px;
    height: 38px;
    background-image: url("../images/search.svg");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  input:focus, input:-webkit-autofill, input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-right: 30px;
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid $blackColor;
    color: $blackColor !important;
    outline: none;
    transition: background-color 6000s ease-in-out 0s;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 6000s ease-in-out 0s; /* выставляется желаемая задержка */
  }
  input::placeholder{
    color: $blackColor !important;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
}
.title-obj{
  width:auto;
  margin: 20px 20px 0;
  height: 30px;
  background-color: $wgreyColor;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  h1{
    color: $darkGreyColor;
    font-size: 0.8rem;
  }
}
.change-obj{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 53px;
  margin: 20px 10px 10px 10px;
  cursor: pointer;
  p{
    font-size: 0.6rem;
    text-transform: uppercase;
    color: $greyColor;
  }
  div{
    width: 56px;
    height: 50px;
    background-image: url("../images/change.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.change-obj:hover{
  p{
    text-decoration: underline;
  }
 }
.wrap-buttons-obj{
  max-width: 516px;
  width: 100%;
  min-width: 30%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  .wrap-button{
    margin: 10px 5px 5px 10px ;
    min-width:200px;
    width: calc(50% - 100px);
    border: 1px solid $blackColor;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 30px;
    cursor: pointer;
    p{
      color: $blackColor;
      font-size: 0.8rem;
    }
    i{
      font-size: 1rem;
    }
  }
}
#DocumentPage{
  display: flex;
  flex-direction: row;
  justify-content: left;
  height: 100%;
  .right-block{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    .top-box{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      .left-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
      }
      .right-box{
        padding-right: 30px;
      }
    }
    .next-box{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .left-box{
        width: 40%;
        //min-width: 470px;
      }
      .right-box{
        margin-top: 20px;
        padding-right: 30px;
        width: calc(60% - 30px);
        .top-box-inside{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          .top-box-inside-left{
            width: 48%;
            min-width: 320px;
            margin-bottom: 10px;
            margin-left: 10px;
          }
          .top-box-inside-right{
            width: 48%;
            min-width: 320px;
            margin-bottom: 10px;
            margin-left: 10px;
          }
        }
        .bottom-box{

        }

        .dis-none{
          display: none;
        }
      }
    }
  }
}
.performers-obj{
  min-width: 320px;
  width: 100%;
  height: 100%;
  border: 1px solid $blackColor;
  display: flex;
  flex-direction: column;
  .title-performers {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: $wgreyColor;
    p{

    }
  }
  .info-box{
    padding:0 20px;
    p{
      font-size: 0.8rem;
      margin-top: 5px;
    }
    h3{
      margin-top: 20px;
    }
    h4{
      font-size: 0.9rem;
      margin-top: 5px;
    }
    .list-button{
      cursor: pointer;
      color: $wblueColor;
      text-transform: uppercase;
      font-size: 0.6rem;
      font-weight: 600;
    }
    .works-list{
      display: none;
      transition: display 0.5s ease;
      p{
        cursor: pointer;
        color: $wblueColor;
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 600;
      }
    }
  }
  .people-list{
    margin-top: 20px;
  }
}
.results-obj{
  margin-top: 30px !important;
  margin-bottom: 30px;
    .button-text{
      font-size: 0.6rem;
      font-weight: 600;
      cursor: pointer;
      color: $wblueColor;
      text-transform: uppercase;
      display: flex;
      justify-content: right;
      padding-top: 4px;
    }
    .attaches{
      justify-content: center;

      a{
        width: 13% !important;
      }
    }
}
.attach-obj{
  width: 100%;
  min-width: 320px;
  border: 1px solid $blackColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  .attach-title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: $wgreyColor;
  }
  .autoheight{
    height: auto !important;
    padding-bottom: 30px !important;
  }
  .attaches{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    padding-bottom: 15px;
    height: 130px;
    overflow: hidden;
    a{
      width: 25%;
      border: 1px solid $wgreyColor;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      padding:5px;
      align-items: center;
      justify-content: space-between;
      .img{
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      p{
        font-weight: 500;
        font-size: 0.53rem;
      }
    }
    .docx{
      width: 59px;
      height: 48px;
      background-image: url("../images/docicon.svg");
    }
    .xlsx{
      width: 58px;
      height: 57px;
      background-image: url("../images/excicon.svg");
    }
    .jpg{
      width: 59px;
      height: 56px;
      background-image: url("../images/jpgicon.svg");
    }
    .pdf{
      width: 50px;
      height: 48px;
      background-image: url("../images/pdficon.svg");
    }
  }
  .button{
    cursor: pointer;
    padding: 5px 0;
    margin-top: -20px;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    background-color: rgba(1,1,1,0.5);
    color: $whiteColor;
  }
}
.tasks-obj{
  max-width: 100%;
  min-width: 320px;
  border: 1px solid $blackColor;
  .top-box{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    div{
      display: flex;
      justify-content: center;
      padding: 10px;
      color: $whiteColor;
    }
    .status{
      width: calc(40%);
    }
    .level{
      width: calc(60%);
    }
    p{
      color: inherit;
    }
  }
  .info{
    display: flex;
    flex-direction: row;
    margin: 10px;
    width: 100%;
    justify-content: left;
    padding-right: 10px;
    p{
      font-size: 0.75rem;
      margin-top: 2px;
    }
    .button{
      font-size: 0.6rem;
      font-weight: 600;
      cursor: pointer;
      color: $wblueColor;
      text-transform: uppercase;
      display: flex;
      justify-content: right;
      padding-top: 4px;
    }
    .text{
      font-size: 0.65rem;
      max-height: 78px;
      overflow: hidden;
      transition: max-height 3s ease;
    }
    .text.expanded{
      max-height: 1000px;
    }
    h3{
      font-size: 0.9rem;
    }
    .left-box-info{
      width: 15%;
      margin-right: 10px;
    }
    .right-box-info{
      width: calc(85% - 40px);
    }
  }
}
.lk-form{
  display: flex;
  flex-direction: column;
  margin: 10px;
  input,select{
    width: 236px;
    height: 40px;
    padding: 5px;
    border: 1px solid #000;
    margin-bottom: 10px;
  }
  input{
    width: 225px;
    height: 28px;
  }
  .button{
    background: #617676;
    width: 236px;
    height: 40px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
}
.bg-wgreyColor {
          background-color: $wgreyColor;
        }
.form {
          display: flex;
          flex-direction: column;
          margin: 10px;

          textarea {
            min-height: 100px;
            resize: none;
            border: 1px solid #000;
          }
  select{
    width: 100%;
    height: 40px;
    padding: 5px;
    border: 1px solid #000;
    margin-bottom: 10px;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  input{
            width: 100%;
            height: 40px;
            padding: 5px;
            border: 1px solid #000;
            margin-bottom: 10px;
            border-radius: 0;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            option{
              background-color: #fff;  // Цвет фона опции
              color: #333;            // Цвет текста опции
              padding: 8px;
            }
          }

          input {
            width: calc(100% - 12px);
            height: 28px;
          }

          .file-input {
            background-color: $wwhiteColor;
          }

          .button {
            background: #617676;
            height: 40px;
            color: #FFF;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
          }

        }
.docbuttons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  .button{
    display: flex;
    justify-content: center;
    background-color: $wgreyColor;
    height: 40px;
    width: 30%;
    align-items: center;
    cursor: pointer;
    p{
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.7rem;
    }
  }
  .button:hover{
    background-color: $greyColor;
    color: $whiteColor;
  }
}
.intoIcon{
  background-image: url("../images/intoIcon.svg");
  width: 80%;
  height: 80%;
  background-repeat: no-repeat;
  background-position: center center;
}
.outIcon{
  background-image: url("../images/outIcon.svg");
  width: 80%;
  height: 80%;
  background-repeat: no-repeat;
  background-position: center center;
}
.edit_task_list_icon{
  background-image: url("../images/edittask.svg");
  width: 80%;
  height: 80%;
  background-repeat: no-repeat;
  background-position: center center;
}
.del_task_list_icon{
  background-image: url("../images/deltask.svg");
  width: 80%;
  height: 80%;
  background-repeat: no-repeat;
  background-position: center center;
}
.back_button{
  position: fixed;
  top:130px;
  left:20px;
  background-color: $wblueColor;
  width: 200px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $whiteColor;
  font-size: 1rem;
  text-transform: uppercase;
  i{
    margin-right: 10px;
    font-size: 1.4rem;
  }
}
#ObjectPage{
  display: flex;
  flex-direction: row;
  justify-content: left;
  height: 100%;

  .right-block {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    .top-box {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      .left-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .obj_block_button{
          display: flex;
        }

        .button{
          align-items: center;
          margin: 10px 5px 5px 10px ;
          min-width:200px;
          width: calc(20% - 100px);
          height: 20px;
          border: 1px solid $blackColor;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          padding: 10px 10px 10px 10px;
          cursor: pointer;

          p{
            color: $blackColor;
            font-size: 0.8rem;
          }
          i{
            font-size: 1rem;
          }
        }
      }

      .right-box {
        padding-right: 30px;
      }
    }

    .next-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .left-box {
        width: 40%;
        //min-width: 470px;
      }

      .right-box {
        margin-top: 20px;
        padding-right: 30px;
        width: calc(60% - 30px);

        .top-box-inside {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;

          .top-box-inside-left {
            width: 48%;
            min-width: 320px;
            margin-bottom: 10px;
            margin-left: 10px;
          }

          .top-box-inside-right {
            width: 48%;
            min-width: 320px;
            margin-bottom: 10px;
            margin-left: 10px;
          }
        }

        .bottom-box {

        }

        .dis-none {
          display: none;
        }
      }
    }
  }
}


.nav_main_menu{
  width: 100%;
  height: 40px;
  background: rgba(5,5,5,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  list-style-position: outside;
  li{
    a{
      color: #FFF;
    }
  }
  li:hover ul {
    display: flex;
    justify-content: center;
    transition: 0.5s;
    list-style-type:  none;
  }
  .nav_main_menu_item{
    padding: 0 15px;
    margin-left: -1px;
    border-left: 1px solid #FFF;
    border-right: 1px solid #FFF;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    list-style-type: none;
    list-style-position: outside;
    position: relative;
    ul{
      display: none;
      //list-style-position: inside;
      position: fixed;
      width: 100%;
      min-height: 60px;
      top: 120px;
      left: 0;
      background: rgb(0,0,0);
      background: rgba(0,0,0,0.7);
      backdrop-filter: blur(5px);
      z-index: 1;
      transition: 0.5s;
      li {
        padding: 20px 30px 10px 30px;
        height: 100%;

      }
      li:hover{
        text-decoration: underline;
        border-radius: 0 0 3px 3px;
      }
    }



  }

  .nav_main_menu>li ul {
    position: absolute;
    display: none;
    left: 200px;
    background: #66d0;
    width: 200px;
    top: 0px;
    list-style-position: inside;
    padding: 0;
    margin: 0;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
  }

  .nav_main_menu_item:hover{
    background: rgba(0,0,0,0.7);
    transition: 0.3s;
  }
}
.chat{
  .video{
    margin-top: 50px;
    video{
      width: 300px;
      height: 300px;
      background-color: #333333;
    }
  }
  .users-list{
    .user{
      background-color: rgba(1,1,1,0.2);
      padding: 5px;
      cursor: pointer;
      margin: 5px;
    }
    .selected{
      background-color: #333333;
      color: #cccccc;
    }
  }
  .buttons{
    .button{
      background: #617676;
      width: 236px;
      height: 40px;
      color: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
//650 340
.file-manager{
  width: 100%;
  .file-buttons{
    margin: 20px;
    display: flex;
    flex-direction: row;

    .button{
      border-radius: 3px;
      padding: 10px;
      font-size: 0.8rem;
      margin: 0 10px 0 0;
      cursor: pointer;
      i{
        margin: 0 5px;
        font-size: 0.8rem;
      }
    }
    .button:hover{
      background-color: $wgreyColor;
    }
    .grey{
      background-color:rgba(18,19,56,1);
      color: $whiteColor;
      padding: 10px 16px 10px 10px;
    }
    .grey:hover{
      background-color: $dgreyColor;
    }
  }
  .file-header{
    border-top: 1px solid $wgreyColor;
    border-bottom: 1px solid $wgreyColor;
    padding: 16px;
    margin: 20px 20px 0 20px;
    .file-path{
      display: flex;
      justify-content: space-between;
      i{
        font-size: 1.6rem;
        color: $greyColor;
        cursor: pointer;
        margin: 0 10px;
      }
      i:hover,.selected{
        color: $blackColor;
      }
      i.selected{
        color: $blackColor;
      }
      .r{

      }
      .l{
        display: flex;
        flex-direction: row;
        .path{
          display: flex;
          flex-direction: row;
          .path-item{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .ch{
              font-size: 0.6rem;
              margin-top: 5px;
              cursor: default;
            }
            p{
              font-size: 0.8rem;
              cursor: pointer;

            }
            p:hover{
              text-decoration: underline;
            }
          }
        }

      }
    }
  }
  .files{
    .list{
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      .del{
        color: $dredColor;
      }
    }
    .file{
      padding: 10px;
      div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      i{
        color: #cccccc;
      }
    }
    .file:hover{
      background-color: rgba(1,1,1,0.07);
    }
    .selected{
      background-color: rgba(1,1,1,0.03);
    }
  }
  .files-table{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: left;
    padding: 20px;
    .file{
      margin: 0 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      i{
        font-size: 3rem;
      }
      p{
        margin-top: 3px;
        font-size: 0.6rem;
        text-align: center;
        max-width: 3.5rem;
        word-wrap: break-word;
      }
    }
  }
  .files-list{
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding: 20px;
    .file{
      padding: 3px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      i{
        font-size: 1.3rem;

      }
      p{
        margin-top: 3px;
        margin-left: 5px;
        font-size: 0.6rem;
        text-align: center;
      }
    }
  }
  .modal_win_wight{
    .modal_win_wight_krest{
      background-color: $wblueColor !important;
    }
    .delete-modal{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .text{
        margin: 0 40px 60px 40px;
        p{
          font-size: 1.2rem;
        }
      }
      .buttons{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .button{
          cursor: pointer;
          background-color: $wblueColor;
          height: 40px;
          padding: 0 50px;
          margin: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $whiteColor;
          font-size: 1rem;
          text-transform: uppercase;
          transition: transform 0.3s ease;
          opacity: 1;
        }
        .button.da{
          background-color: $dredColor;
        }
      }
    }
  }
.draganddrop{
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
  .upload-button {
    background-color:rgba(18,19,56,1);
    color: $whiteColor;
    padding: 10px 16px 10px 10px;
    width: 40%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-top: -30px;
    cursor: pointer;
  }
}
  .create-dir{
    h4{
      font-size: 1.6rem;
      margin: 20px;
      font-weight: 500;
    }
    h5{
      font-size: 1.2rem;
      font-weight: 500;
    }
    .inputs{
      .file-select{
        width: 100%;
      }
    }
    .file-buttons{
      justify-content: right;
      .button{
        margin: 20px 0 0 0;
      }

    }
  }
}
.copy{
  h4{
    font-size: 1.6rem;
    margin: 0 20px;
    font-weight: 500;
  }
  h5{
    font-size: 1.2rem;
    font-weight: 400;
    margin: 20px;
  }
  .inputs{
    .file-select{
      width: 100%;
    }
  }
  .file-buttons{
    justify-content: right;
    .button{
      margin: 20px 0 0 0;
    }

  }
}
.hidden-upload{
  display: none;
}
.red-auth-border{
  border-bottom: 2px solid $dredColor !important;
}

::-webkit-scrollbar {
  width: 6px; /* Ширина полосы прокрутки */
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: rgba(5, 5, 5, 0.7);
  border-radius: 5px; /* Скругление углов бегунка */
}
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Цвет трека */
}
::-webkit-scrollbar-corner {
  background: transparent; /* Цвет угла */
}
.red-solid-select-border{
  .select__control{
    border-color: $dredColor !important;
  }
}
.red-color{
  color: $dredColor;
}
.red-color-label{
  color: $dredColor !important;
}
.red-solid-button-border{
  border-bottom: 1px solid $dredColor !important;
}
.red-dotted-border{
  border-color: $dredColor !important;
}
//.news_block_list_box{
//  color: black;
//}
//.news_block_title_more{
//  color: #333333;
//}
//.news_block_title_more:hover{
//  color: black;
//}
//.navbar_block_dopmenu_list_strock{
//  color: #333333;
//}
//.navbar_block_dopmenu_list_strock:hover{
//  text-decoration: underline !important;
//  color: #333333;
//}

.auth_form{
  .first-link{
    margin: 20px 0;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 0.7rem;
    text-transform: uppercase;
    text-decoration: underline !important;
  }
}

.lk-select{
  margin-left: 20px;
  width: calc(100% + 20px);
  display: flex;
  align-items: center;
  margin-top: 6px;
  div{
    margin-top: -4px;
  }
}
.sizes-form{
  display: flex;
  justify-content: space-between !important;
  width: calc(100% - 40px);
}

.status-icon1{
  position: absolute;
  bottom: -5px;
  right: -5px;
  font-size: 2.5rem;
  color: $wblueColort;
}
.status-icon2{
  position: absolute;
  bottom: -5px;
  right: -5px;
  font-size: 1.3rem;
  color: $wblueColort;
}
.status-image{
  background-image: url("../images/palm.svg");
  background-size: cover;
  width: 60px;
  height: 60px;
}
.status-image2{
  background-image: url("../images/palm.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
}


.notacceses{
  margin-top: 20px;
  margin-left: 270px;
}
@media screen and (max-width: 1050px) {
  .notacceses{
    margin-top: 20px;
    margin-left: 90px;
  }
}
@media screen and (max-width: 800px) {
  .notacceses{
    margin-top: 20px;
    margin-left: 20px;
  }
}