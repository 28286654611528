.textbox{
  position: relative;
  border-top: 1px solid #bbb;
  padding: 10px 0;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  .circle{
    margin-top: 13px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i{
      font-size: 1.4rem;
      color: #aaa;
    }

  }
  .circle:hover{
    i{
      font-size: 1.4rem;
      color: #888;
    }
  }
  .right{

  }
  .left{

  }
  .textarea{
    width: calc(100% - 120px);
    border: 1px solid #ddd;
    border-radius: 10px;
    height: auto;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    textarea{
      height: 30px;
      width: calc(100% - 120px);
      min-height: 22px;
      max-height: 200px;
      resize: none;
      padding: 10px 20px;
      outline: none;
      color: #333333;
      font-size: 0.9rem;
      border: none;
      background-color: transparent;
      &::placeholder {
        font-size: 0.8rem;
        color: #888;
        opacity: 1;
      }
    }
    .emojicontainer {
      position: relative;
      display: inline-block;
      .emojibutton {
        position: absolute;
        bottom: 80px;
        right: 10px;
        font-size: 15px;
        padding: 5px;
        cursor: pointer;
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
      .emojipicker {
        position: absolute;
        width: 120px;
        bottom: -12px;
        right: 10px;
        display: flex;
        flex-wrap: wrap;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      }
      .emoji{
        font-size: 16px;
        padding: 4px;
        cursor: pointer;
      }

      .emoji:hover {
        background-color: #f0f0f0;
      }
    }

    .circle2{
      margin-top: 13px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        margin-right: 14px;
        font-size: 1.4rem;
        color: #aaa;
      }
    }
    .circle2:hover{
      i{
        font-size: 1.4rem;
        color: #888;
      }
    }
  }
  .attachments{
    position: absolute;
    width: 200px;
    left: 10px;
    top:-38px;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    .ul{
      padding: 5px;
      .li{
        padding: 5px;
        cursor: pointer;
        color: #aaaaaa;
        font-size: 0.8rem;
        i{
          font-size: 1.4rem;
          margin-right: 14px;
        }
      }
      .li:hover{
        color: #888;
      }
    }
  }
}