.container {
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1000px;
  .chatlist{
    width: 69%;
    border: 1px solid #bbb;
    border-radius: 12px;
    background-color: #f0f0f0;
    .header{
      height: 50px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #bbb;
      padding: 0 20px;
      .opened{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        .back{
          height: 100%;
          width: 15%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          i{
            font-size: 1.4rem;
            color: #aaa;
          }
          div{
            color: #bbb;
            font-size: 0.7rem;
            margin-left: 5px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        .back:hover{
          i{
            color: #888;
          }
          div{
            color: #888;

          }
        }

        .center{
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .name{
            font-weight: 400;
            font-size: 0.8rem;
            color: #454545;
          }
          .status{
            cursor: pointer;
            font-size: 0.6rem;
            color: #aaaaaa;
          }
        }
        .right{
          width: 15%;
          display: flex;
          height: 100%;
          .ava{
            position: relative;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            border-radius: 40px;
          }
        }
      }
      .left{
        width: 85%;
        display: flex;
        align-items: center;
      }
      .right{
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: right;
        i{
          color: #454545;
          font-size: 1.5rem;
          margin: 0 5px;
          cursor: pointer;
        }
        i:hover{
          color: #999;
        }
      }

      .smsgcontrol{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .nm{
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: .9rem;
          font-weight: 500;
          i{
            margin-left: 10px;
            font-size: 1.2rem;
            color: #aaa;
            cursor: pointer;
          }
          i:hover{
            color: #333;
          }
        }
        .remove{
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          .redir{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 12px 0 12px;
            font-size: .7rem;
            font-weight: 500;
            text-transform: uppercase;
            height: 30px;
            border: 2px solid rgba(255, 255, 255, 0.3);
            border-radius: 10px;
            cursor: pointer;
            background-color: rgb(18, 19, 56);
            color: #FFFFFF;
          }
          i{
            margin-right: 20px;
            font-size: 1.4rem;
            color: #aaa;
            cursor: pointer;
          }
          i:hover{
            color: #333;
          }
        }

      }
    }
    .chats{
      min-height: 40px;

      .list{
        width: calc(100% - 20px);
        margin: 10px;
        min-height: 100px;

        .chat{
          height: 70px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          cursor: pointer;
          position: relative;
          .ava{
            position: relative;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            min-width: 50px;
            max-width: 50px;
            height: 50px;
            border-radius: 50px;
            margin: 5px;
          }
          .chatlabel{

            width: calc(100% - 130px);
            margin: 0 10px;
            .chatname{
              max-width: 100%;
              max-height: 30px;
              overflow: hidden;
              font-size: 0.8rem;
              font-weight: 600;
            }
            .message{
              max-width: 100%;
              max-height: 30px;
              overflow: hidden;
              font-size: 0.8rem;
              margin: 5px;
            }
          }
          .chatdate{
            min-width: 50px;
            max-width: 50px;
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            margin-right: 10px;
            .date{
              font-size: 0.8rem;
              color: #aaaaaa;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .bluecircle{
              margin: 8px;
              width: 16px;
              height: 16px;
              font-size: 0.6rem;
              border-radius: 12px;
              background-color: rgba(18, 19, 56,0.5);
              display: flex;
              justify-content: center;
              align-items: center;
              color: #FFFFFF;
            }
          }
        }
        .chat:hover{
          background-color: #eee;
        }
      }
      .multy{
        min-height: 300px;
        .groupcontrol{
          .control{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 5px;
            border-top: 1px solid #bbb;
            .groupimage{
              margin-left: 10px;
              min-width: 50px;
              max-width: 50px;
              border-radius: 50px;
              height: 50px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              cursor: pointer;
            }
            input{
              border: none;
              background-color: transparent;
              padding: 5px;
              width: 80%;
              outline: none;
              margin: 0 20px;
            }
          }
        }
      }
      .messages{
        min-height: 300px;
        max-height: calc(100vh - 300px);
        overflow-y: scroll;
        .messagein{
          width: calc(100% - 20px);
          padding: 10px;
          display: flex;
          cursor: pointer;
          .status{
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .7rem;
            color: #aaaaaa;
          }
          .ava{
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            min-width: 40px;
            max-width: 40px;
            height: 40px;
            border-radius: 40px;
            margin-left: 10px;
          }
          .bodymessage{
            margin: 0 20px;
            position: relative;
            .name{
              font-size: .8rem;
              font-weight: 600;
              span{
                font-size: .7rem;
                color: #bbb;
              }
              .selectedi{
                position: absolute;
                top:50%;
                right: -10px;
              }
            }
            .text{
              margin: 5px 0;
              font-size: .8rem;
              word-break: break-word;
            }
          }
        }
        .messageinhovered:hover{
          background-color: rgba(200,200,200,0.1);
        }
        .selected{
          background-color: rgba(200,200,200,0.3);
        }
      }
      .textbox{
        position: relative;
        border-top: 1px solid #bbb;
        padding: 10px 0;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        .circle{
          margin-top: 13px;
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          i{
            font-size: 1.4rem;
            color: #aaa;
          }

        }
        .circle:hover{
          i{
            font-size: 1.4rem;
            color: #888;
          }
        }
        .right{

        }
        .left{

        }
        .textarea{
          width: calc(100% - 120px);
          border: 1px solid #ddd;
          border-radius: 10px;
          height: auto;
          background-color: #FFFFFF;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          textarea{
            height: 30px;
            width: calc(100% - 120px);
            min-height: 22px;
            max-height: 200px;
            resize: none;
            padding: 10px 20px;
            outline: none;
            color: #333333;
            font-size: 0.9rem;
            border: none;
            background-color: transparent;
            &::placeholder {
              font-size: 0.8rem;
              color: #888;
              opacity: 1;
            }
          }
          .emojicontainer {
            position: relative;
            display: inline-block;
            .emojibutton {
              position: absolute;
              bottom: 80px;
              right: 10px;
              font-size: 15px;
              padding: 5px;
              cursor: pointer;
              background-color: #f0f0f0;
              border: 1px solid #ccc;
              border-radius: 5px;
            }
            .emojipicker {
              position: absolute;
              width: 120px;
              bottom: -12px;
              right: 10px;
              display: flex;
              flex-wrap: wrap;
              background-color: white;
              border: 1px solid #ccc;
              border-radius: 5px;
              padding: 10px;
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            }
            .emoji{
              font-size: 16px;
              padding: 4px;
              cursor: pointer;
            }

            .emoji:hover {
              background-color: #f0f0f0;
            }
          }

          .circle2{
            margin-top: 13px;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            i {
              margin-right: 14px;
              font-size: 1.4rem;
              color: #aaa;
            }
          }
          .circle2:hover{
            i{
              font-size: 1.4rem;
              color: #888;
            }
          }
        }
        .attachments{
          position: absolute;
          width: 200px;
          left: 10px;
          top:-38px;
          display: flex;
          flex-wrap: wrap;
          background-color: white;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 10px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          .ul{
            padding: 5px;
            .li{
              padding: 5px;
              cursor: pointer;
              color: #aaaaaa;
              font-size: 0.8rem;
              i{
                font-size: 1.4rem;
                margin-right: 14px;
              }
            }
            .li:hover{
              color: #888;
            }
          }
        }
      }
      .nomessages{
        font-weight:400;
        font-size: 0.8rem;
        margin: 20px;
      }
    }
  }
  .menu{
    width: calc(28% - 32px);
    padding: 16px;
    background-color: #f0f0f0;
    border: 1px solid #bbb;
    border-radius: 12px;
    .control{
      div{
        margin-bottom: 10px;
        cursor: pointer;
        color: #454545;
        font-size: 0.9rem;
      }
      div:hover{
        color: #999999;
      }
      .activemenu{
        font-weight: 600;
      }
    }
    .opened{
      margin-top: 20px;
      border-top: 1px solid #aaa;
      .chatslist{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        margin: 10px 0;
        cursor: pointer;
        .chatlink{
          font-size: 0.8rem;
          width: 90%;
        }
        .del{
          width: 10%;
          color: #cccccc;
        }
        .del:hover{
          color: #333;
        }
      }
    }
  }
}
.greencircle{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #3C8801;
}
.button{
  cursor: pointer;
  background-color: rgb(18, 19, 56);
  color: white;
  margin: 3px;
  padding: 10px 30px;
  width: 160px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  border-radius: 10px;
  z-index: 2;
}
.margin10{
  width: 10px;
}
.filesbox{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 46px);
  height: auto;
  margin: 10px 0 0 0;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .fileslist{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    width: calc(100% - 40px);
    height: 100%;
    .file {
      width: 100%;
      padding: 3px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      .l{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        color: #aaa;
        span{
          font-size: .6rem;
          color: rgba(200,10,10,0.5);
        }
        i{
          margin: 5px;
        }
      }
    }
  }
}
.messagefiles{
  display: flex;
  flex-direction: column;
  width: 100%;
  .file{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin: 3px;
    cursor: pointer;
    i{
      font-size: 1rem;
      color: #999;
      margin-right: 3px;
    }
    p{
      word-break: break-word;
      font-size: .9rem;
      color: #aaaaaa;
    }
  }
  .file:hover{
    i{
      color: #454545;
    }
    p{
      color: #454545;
    }
  }
  .imagemessage{
    width: 130px;
    height: 130px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

  }
}
.settings{
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  overflow-y: auto;
  .title{
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #aaaaaa;
    padding-bottom: 10px;
  }
  .info{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #aaaaaa;

    .ava{
      width: 70px;
      height: 70px;
      border-radius: 70px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .chatname{
      display: flex;
      flex-direction: column;
      margin: 5px 10px;
      .name{
        font-size: 0.9rem;
      }
      .status{
        font-size: 0.7rem;
        color: #aaaaaa;
      }
    }
  }
  .settingmenu{
    margin: 10px 0;
    border-bottom: 1px solid #aaa;
    .btn{
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      color: #555;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7rem;
      i{
        margin-right: 15px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        background-color: rgba(18, 19, 56,0.8);
        color: #FFFFFF;
        border-radius: 40px;
      }
    }
    .btn:hover{
      color: #000;
    }
  }
  .partipants{
    padding: 10px 0;
    .add{
      cursor: pointer;
      border-radius: 40px;
    }
    .add:hover{
      background-color: #e6e6e6;
    }
    .part{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin: 10px 0;
      .ava{
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: #FFFFFF;
        background-position: center;
        background-size: cover;
      }
      .icon{
        width: 40px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        i{
          font-size: 1.8rem;
          color: #aaaaaa;
        }
      }
      .nameman{
        width: calc(100% - 40px);
        margin: 5px;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .inname{
          .name{
            color: #333333;
            font-size: 0.8rem;
          }
          .status{
            font-size: 0.7rem;
            color: #aaaaaa;
          }
        }
        .set{
          font-size: 0.8rem;
          cursor: pointer;
        }
      }
    }
  }
  .exit{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    height: 20px;
    padding-top: 10px;
    border-top: 1px solid #aaaaaa;
    color: rgba(200,40,40,0.7);
  }
  .exit:hover{
    color:rgba(200,40,40,1);
  }
}

.attachments{
  min-width: 350px;
  width: 520px;
  .attheader{
    display: flex;

    border-bottom: 2px solid #aaaaaa;
    z-index: 100000;
    .li{
      margin-bottom: -2px;
      margin-right: 5px;
      padding: 6px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      color: #333333;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8rem;
    }
    .li:hover,.selected{
      border-bottom: 2px solid rgba(18, 19, 56,0.8);
    }
  }
  .smallgallery{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    max-height: 90vh;
    overflow-y: auto;
    .fileitem{
      width: 140px;
      height: 140px;
      margin: 10px;
      background-size: cover;
      background-position: center;
      cursor: pointer;
    }
  }
  .fileslist{
    max-height: 90vh;
    overflow-y: auto;
    .fileitem{

      .file{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.9rem;
        padding: 10px;
        cursor: pointer;
        word-break: break-word;
        i{
          font-size: 2rem;
        }
      }
      .file:hover{
        background-color: #cccccc;
      }
    }

  }
}
.delete{
  .title{
    border-bottom: 1px solid #cccccc;
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: -40px;
    padding: 10px 5px;

  }
  .text{
    border-bottom: 1px solid #cccccc;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 10px;
    padding: 10px 5px 15px 5px;
  }
  .buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    .button{
      font-size: .7rem;
      width: auto;
      background-color: #cccccc;
      height: 20px;
      padding: 6px 26px;
      border-radius: 0;
      z-index: 0;
    }
    .da{
      background-color: rgb(18, 19, 56);
    }
  }
}



@media screen and (max-width: 800px){
  .container {
    .chatlist {
      width: 100%;
    }
    .menu{
      display: none;
    }
    .circle{
      padding: 0 !important;
    }
    .textbox{
      .right{

      }
    }
  }
}


@media screen and (max-width: 475px){
  .container {
    width: calc(100% + 40px);

    padding: 0;
    margin-top: -20px;
    margin-left: -20px;
    border: none;
    border-radius: 0;
    .chats{

    }
    .messages{
      max-width: 100%;
      width: 100%;
      max-height: calc(100vh - 218px) !important;
      audio{
        width: 100%;
      }
      .textarea{

      }
    }
  }
  .attachments {
    min-width: 350px;
    width: 350px;
    .attheader{
      width: calc(100% - 40px);
    }
  }
}