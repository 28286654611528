@media screen and (min-width: 1080px) and (max-width: 1550px) {

  #root .container .right_block {
    width: calc(100% - 120px);
    //flex: 0 0 calc(100% - 120px);
  }

  //#DocumentPage{
  //  .right-block {
  //
  //    .next-box {
  //      .left-box {
  //
  //      }
  //      .right-box{
  //
  //      }
  //    }
  //  }
  //}
  .bottom-box {

  }
}