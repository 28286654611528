
.imgcomment{
  width: 100%;
  height: 100%;
  //background-color: #292f33;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .imgcomment_list{
    width: 100%;
    max-height: 70%;
    min-height: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    .imgcomment_list_block {
      display: flex;
      width: calc(100% - 20px);
      padding: 10px;

      .imgcomment_list_block_avatar {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        background-color: #6c757d;
        margin-right: 10px;
        background-size: cover;
        background-position: center center;
      }

      .imgcomment_list_block_text {
        width: calc(100% - 50px);
        display: flex;
        flex-direction: column;

        .imgcomment_list_block_text_name {
          font-weight: 700;
        }

        .imgcomment_list_block_text_message {
          word-break: break-word;
        }
      }
    }
    @media screen and (min-width:  820px) {
      .imgcomment_list_block {
        font-size: 10pt;
      }
    }
  }
  @media screen and (min-width:  820px){
    .imgcomment_form{
      font-size: 10pt;
      width: 100%;
      //height: 20%;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #6c757d;
      .imgcomment_form_input{
        width: calc(100% - 20px);
        //height: calc(100% - 30px);
        padding: 20px 10px 10px 10px;
        display: flex;
        .imgcomment_form_input_myavatar{
          width: 45px;
          height: 45px;
          background-color: #292f33;
          border-radius: 30px;
          background-position: center center;
          background-size: cover;
        }
        .imgcomment_form_input_textarea{
          outline: none;
          resize: none;
          padding: 10px;
          width: calc(100% - 80px);
          //height: calc(100% - 50px);
          margin-left: 10px;
          border: none;
        }
      }
      .imgcomment_form_enter{
        width: calc(100% - 20px);
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        .imgcomment_form_emoji{
          width: 38px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          border: 1px solid #6c757d;
          border-radius: 4px;
        }
        .imgcomment_form_enter_btn{
          width: 120px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(18, 19, 56);
          color: #FFF;
          border-radius: 4px;
          cursor: pointer;
        }


        //position: absolute;
        //bottom: 10px;
        //right: 40px;
      }
    }
  }
  @media screen and (max-width:  820px){
    .imgcomment_form{
      width: 100%;
      //height: 20%;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #6c757d;
      .imgcomment_form_input{
        width: calc(100% - 20px);
        //height: calc(100% - 30px);
        padding: 20px 10px 10px 10px;
        display: flex;
        .imgcomment_form_input_myavatar{
          width: 45px;
          height: 45px;
          background-color: #292f33;
          border-radius: 30px;
          background-position: center center;
          background-size: cover;
        }
        .imgcomment_form_input_textarea{
          outline: none;
          resize: none;
          padding: 10px;
          width: calc(100% - 80px);
          //height: calc(100% - 50px);
          margin-left: 10px;
          border: none;
        }
      }
      .imgcomment_form_enter{
        width: calc(100% - 20px);
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        .imgcomment_form_emoji{
          width: 38px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          border: 1px solid #6c757d;
          border-radius: 4px;
        }
        .imgcomment_form_enter_btn{
          width: 120px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(18, 19, 56);
          color: #FFF;
          border-radius: 4px;
          cursor: pointer;
        }


        //position: absolute;
        //bottom: 10px;
        //right: 40px;
      }
    }
  }

}
@media screen and (max-height: 600px){
  .imgcomment{
    width: 100%;
    height: 100%;
    //background-color: #292f33;
    flex-direction: column;
    justify-content: space-between;
    display: none;
    .imgcomment_list{
      width: 100%;
      max-height: 80%;
      min-height: calc(100% - 300px);
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;

      .imgcomment_list_block{
        display: flex;
        width: calc(100% - 20px);
        padding: 10px;
        .imgcomment_list_block_avatar{
          width: 40px;
          height: 40px;
          border-radius: 25px;
          background-color: #6c757d;
          margin-right: 10px;
          background-size: cover;
          background-position: center center;
        }
        .imgcomment_list_block_text{
          width: calc(100% - 50px);
          display: flex;
          flex-direction: column;
          .imgcomment_list_block_text_name{
            font-weight: 700;
          }
          .imgcomment_list_block_text_message{
            word-break: break-word;
          }
        }
      }
    }
    .imgcomment_form{
      width: 100%;
      //height: 20%;
      min-height: 120px;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #6c757d;
      .imgcomment_form_input{
        width: calc(100% - 20px);
        height: calc(100% - 30px);
        padding: 20px 10px 10px 10px;
        display: flex;
        .imgcomment_form_input_myavatar{
          width: 45px;
          height: 45px;
          background-color: #292f33;
          border-radius: 30px;
          background-position: center center;
          background-size: cover;
        }
        .imgcomment_form_input_textarea{
          outline: none;
          resize: none;
          padding: 10px;
          width: calc(100% - 80px);
          //height: calc(100% - 50px);
          margin-left: 10px;
          border: none;
        }
      }
      .imgcomment_form_enter{
        width: calc(100% - 20px);
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        .imgcomment_form_emoji{
          width: 38px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          border: 1px solid #6c757d;
          border-radius: 4px;
        }
        .imgcomment_form_enter_btn{
          width: 120px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(18, 19, 56);
          color: #FFF;
          border-radius: 4px;
          cursor: pointer;
        }


        //position: absolute;
        //bottom: 10px;
        //right: 40px;
      }
    }
  }
}
.galarylist{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;
}

.namegalary{
  color: rgb(18, 19, 56);
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 20px;
}

.descriptiongalary{
  font-size: 16pt;
  margin-top: 20px;
}

.filesofgalary{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;


  .pluswall{
    width: 320px;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #CCC;
    margin: 0 10px 20px 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s;
    background-position: center center;
    background-size: cover;
    i{
      font-size: 45pt;
      color: #CCC;
      margin-bottom: 15px;
      padding-top: 10px;
    }
  }

  .pluswall:hover{
    border: 1px solid #454545;
    transition: 0.2s;
    i{
      color: #454545;
    }
  }

  .pluscenter{
    width: 200px;
    padding: 10px;
    min-height: 150px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #CCC;
    margin: 0 10px 20px 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s;
    i{
      font-size: 45pt;
      color: #CCC;
      margin-bottom: 15px;
      padding-top: 10px;
    }
  }

  .pluscenter:hover{
    border: 1px solid #454545;
    transition: 0.2s;
    i{
      color: #454545;
    }
  }

  .filesofgalary_block{
    width: 200px;
    padding: 10px;
    min-height: 150px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #292f33;
    margin: 0 10px 20px 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s;
    i{
      font-size: 45pt;
      color: #6c757d;
      margin-bottom: 15px;
      padding-top: 10px;
    }
    .filesofgalary_block_name{
      font-size: 10pt;
      word-break: break-word;
    }
  }
  .filesofgalary_block:hover{
    transition: 0.2s;
    i{
      color: #454545;
    }
  }
}

.plusimgitem{
  width: 300px;
  height: auto;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #CCC;
  color: #CCC;
  font-size: 30pt;
  cursor: pointer;
}
.plusimgitem:hover{
  border: 1px solid #454545;
  color: #454545;
}
@media screen and (min-width: 1200px) {
  .galleryitem {
    //max-width: calc(33.333% - 10px); /* три изображения в строке с учетом отступа */
    box-sizing: border-box;
    //max-height: 300px;
    position: relative;
    //background-color: rgba(0,0,0,1);
    border-radius: 8px;
    cursor: pointer;
    .coverboard{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.8);
      border-radius: 8px;
      display: none;
      justify-content: center;
      align-items: center;
      i{
        color: #FFFFFF;
        font-size: 40pt;
      }
    }
  }
}

@media screen and (min-width: 820px) and (max-width: 1200px) {
  .plusimgitem{
    width: 300px;
    height: auto !important;
    min-height: 50px !important;
  }
  .galleryitem {
    max-width: 250px; /* три изображения в строке с учетом отступа */
    box-sizing: border-box;
    //max-height: 300px;
    position: relative;
    //background-color: rgba(0,0,0,1);
    border-radius: 8px;
    cursor: pointer;
    img{
      max-width: 100% !important;
    }
    .coverboard{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.8);
      border-radius: 8px;
      display: none;
      justify-content: center;
      align-items: center;
      i{
        color: #FFFFFF;
        font-size: 40pt;
      }
    }
  }
}

@media screen and (min-width: 650px) and (max-width: 820px) {
  .plusimgitem{
    width: 300px;
    height: auto !important;
    min-height: 50px !important;
  }
  .galleryitem {
    //max-width: calc(33.333% - 10px); /* три изображения в строке с учетом отступа */
    box-sizing: border-box;
    max-width: 300px;
    //max-height: 300px;
    position: relative;
    //background-color: rgba(0,0,0,1);
    border-radius: 8px;
    cursor: pointer;
    img{
      max-width: 100% !important;
    }
    .coverboard{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.8);
      border-radius: 8px;
      display: none;
      justify-content: center;
      align-items: center;
      i{
        color: #FFFFFF;
        font-size: 40pt;
      }
    }
  }
}
@media screen and (min-width: 500px) and (max-width: 650px) {
  .plusimgitem{
    width: calc(100% + 30px);
    height: 50px !important;
    //box-sizing: border-box;
  }
  .galleryitem {
    //max-width: calc(33.333% - 10px); /* три изображения в строке с учетом отступа */
    box-sizing: border-box;
    max-width: 100%;
    //max-height: 300px;
    position: relative;
    //background-color: rgba(0,0,0,1);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      width: 100% !important;
      height: auto;
      box-sizing: border-box;
    }
    .coverboard{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.8);
      border-radius: 8px;
      display: none;
      justify-content: center;
      align-items: center;
      i{
        color: #FFFFFF;
        font-size: 40pt;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .plusimgitem{
    width: calc(100% + 30px);
  }
  .galleryitem {
    //max-width: calc(33.333% - 10px); /* три изображения в строке с учетом отступа */
    //box-sizing: border-box;
    //max-height: 300px;
    position: relative;
    //background-color: rgba(0,0,0,1);
    border-radius: 8px;
    cursor: pointer;
    margin-left: -10px;
    img{
      width: auto !important;
      max-width: calc(100% + 10px) !important;
    }
    .coverboard{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.8);
      border-radius: 8px;
      display: none;
      justify-content: center;
      align-items: center;
      i{
        color: #FFFFFF;
        font-size: 40pt;
      }
    }
  }
}

.galleryitem:hover{
  .coverboard{
    display: flex;
  }
}

.galleryitem img {
  max-width: 500px;
  width: auto;
  max-height: 300px;
  height: auto;
  display: block;
  border-radius: 8px;
}

.galary{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  .covers_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;


    .fadeIn {
      animation: fadeInAnimation 1s ease-in-out forwards;
    }

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
        transform: scale(0.9);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    @media screen and (min-width: 722px){
      .plus_admin {
        position: relative;
        width: calc(20px * 16);
        height: calc(20px * 12);
        margin: 0 20px 20px 0;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.5s;
        color: #CCC;
        border: 1px solid #CCC;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40pt;
      }
    }
    @media screen and (max-width: 722px){
      .plus_admin {
        position: relative;
        width: calc(100vw - 42px);
        height: calc(20px * 12);
        margin: 0 20px 20px 0;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.5s;
        color: #CCC;
        border: 1px solid #CCC;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40pt;
      }
    }

    .plus_admin:hover {
      color: #454545;
      border: 1px solid #454545;
    }
    @media screen and (min-width: 722px){
    .covers_list_block {
      animation: fadeInAnimation 1s ease-in-out forwards;
      position: relative;
      width: calc(20px * 16);
      height: calc(20px * 12);
      margin: 0 20px 20px 0;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: 0.5s;

      .covers_list_block_imgs {
        width: calc(20px * 16);
        height: calc(20px * 9);
        background-size: cover;
        background-position: center center;
        border-radius: 8px 8px 0 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: 0.5s;
      }

      .covers_list_block_title {
        width: calc(100% - 20px);
        padding: 5px 10px;
        font-size: 10pt;
        transition: 0.5s;
      }

      .covers_list_block_date {
        font-size: 10pt;
        width: calc(100% - 20px);
        padding: 5px 10px;
        display: flex;
        justify-content: flex-end;
        transition: 0.5s;
      }

      .blackhover {
        position: absolute;
        top: 0px;
        left: 0px;
        display: none;
        width: calc(20px * 16);
        height: calc(20px * 12);
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 8px;

        transition: 0.5s;
      }

      transition: 0.5s;
    }
  }
    @media screen and (max-width: 722px){
      .covers_list_block{
        animation: fadeInAnimation 1s ease-in-out forwards;
        position: relative;
        box-sizing: content-box;
        width: 100vw;
        height: calc(100vw / 16 * 12);
        margin: 0 0 20px -20px;
        background-color: #fff;
        //border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: 0.5s;

        .covers_list_block_imgs{
          width: 100vw;
          height: calc(100vw / 16 * 9);
          //box-sizing: content-box;
          background-size: cover;
          background-position: center center;
          //border-radius: 8px 8px 0 0;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: 0.5s;
        }
        .covers_list_block_title{
          width: calc(100% - 20px);
          padding: 5px 10px;
          font-size: 10pt;
          transition: 0.5s;
        }
        .covers_list_block_date{
          font-size: 10pt;
          width: calc(100% - 20px);
          padding: 5px 10px;
          display: flex;
          justify-content: flex-end;
          transition: 0.5s;
        }

        .blackhover{
          position: absolute;
          top: 0px;
          left: 0px;
          display: none;
          width: 100vw;
          height: calc(100vw / 16 * 12);
          background-color: rgba(0,0,0,0.6);
          //border-radius: 8px;

          transition: 0.5s;
        }

        transition: 0.5s;
      }
    }

    .covers_list_block:hover{

      transition: 0.5s;
      .blackhover{
        display: flex;

        transition: 0.5s;
      }
    }

  }
  @media screen and (min-width: 722px) {
    .create_albom {
      position: absolute;
      top: 0;
      width: calc(20px * 16);
      height: calc(20px * 12);
      background-color: #FFF;
      border: 1px solid #CCC;
      border-radius: 8px;
      box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.2);
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .imgcover{
        width: 100%;
        height: calc(20px * 9);
        //background-color: #292f33;
        border-radius: 8px 8px 0 0;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1 ;
        background-size: cover;
        background-position: center center;
      }

      .create_albom_photo {
        margin-top: 5px;
        width: 95%;
        height: calc(100% - 65px);
        border: 1px solid #CCC;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 45pt;
        background-size: cover;
        background-position: center center;

        i {
          opacity: 0.5;
          cursor: pointer;
        }

        i:hover {
          opacity: 0.8;
        }
      }

      .create_albom_name {
        margin-top: -35px;
        width: calc(90% - 20px);
        height: 20px;
        padding: 5px 10px;
        border: none;
        border-bottom: 3px solid #454545;
        box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 5px;
        margin-bottom: 10px;
        outline: none;

      }

      .create_albom_btns {
        display: flex;
        width: 90%;
        justify-content: space-between;

        div {
          cursor: pointer;
        }

        .create_albom_cancel {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 30px;
          background-color: #FFFFFF;
          border-radius: 5px;
          border: 1px solid #CCC;
        }

        .create_albom_create {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 30px;
          background-color: #FFFFFF;
          border-radius: 5px;
          border: 1px solid #CCC;
        }
      }
    }
  }
  @media screen and (max-width: 722px) {
    .imgcover{
      width: 100%;
      height: calc(20px * 9);
      //background-color: #292f33;
      border-radius: 8px 8px 0 0;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -1 ;
      background-size: cover;
      background-position: center center;
    }
    .create_albom {
      position: absolute;
      top: 0;
      width: calc(100vw - 42px);
      height: calc(20px * 12);
      background-color: #FFF;
      border: 1px solid #CCC;
      border-radius: 8px;
      box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.2);
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .create_albom_photo {
        margin-top: 5px;
        width: 95%;
        height: calc(100% - 65px);
        border: 1px solid #CCC;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 45pt;
        background-size: cover;
        background-position: center center;

        i {
          opacity: 0.5;
          cursor: pointer;
        }

        i:hover {
          opacity: 0.8;
        }
      }

      .create_albom_name {
        margin-top: -35px;
        width: calc(90% - 20px);
        height: 20px;
        padding: 5px 10px;
        border: none;
        border-bottom: 3px solid #454545;
        box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 5px;
        margin-bottom: 10px;
        outline: none;

      }

      .create_albom_btns {
        display: flex;
        width: 90%;
        justify-content: space-between;

        div {
          cursor: pointer;
        }

        .create_albom_cancel {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 30px;
          background-color: #FFFFFF;
          border-radius: 5px;
          border: 1px solid #CCC;
        }

        .create_albom_create {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 30px;
          background-color: #FFFFFF;
          border-radius: 5px;
          border: 1px solid #CCC;
        }
      }
    }
  }
}
@media screen and (min-width: 1650px){
  .btn_more{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    width: calc(20px * 16 * 4 + 15px * 4);
    height: 50px;
    border-radius: 25px;
    border: 1px solid #D9D9D9;
    color: #6c757d;
    cursor: pointer;
    transition: 0.2s;
  }

}
@media screen and (min-width: 1318px) and (max-width: 1650px){
  .btn_more{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    width: calc(20px * 16 * 3 + 15px * 3);
    height: 50px;
    border-radius: 25px;
    border: 1px solid #D9D9D9;
    color: #6c757d;
    cursor: pointer;
    transition: 0.2s;
  }

}

@media screen and (min-width: 722px) and (max-width: 1318px){
  .btn_more{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    width: calc(20px * 16 * 2 + 15px * 2);
    height: 50px;
    border-radius: 25px;
    border: 1px solid #D9D9D9;
    color: #6c757d;
    cursor: pointer;
    transition: 0.2s;
  }

}

@media screen and (max-width: 722px){
  .btn_more{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    width: calc(100%);
    height: 50px;
    border-radius: 25px;
    border: 1px solid #D9D9D9;
    color: #6c757d;
    cursor: pointer;
    transition: 0.2s;
  }

}

.btn_more:hover{
  color: #454545;
  border: 1px solid #454545;
  transition: 0.2s;
}