.filter{
  display: flex;
  flex-direction: row;
  .select{
    margin: 10px;
  }
}
.button{
  cursor: pointer;

  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 5px;

  &:hover{
    color: #aaaaaa;
  }
}