@media screen and (min-width: 1025px){
  .controll_welding{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 20px;
    width: 900px;
    height: 100%;

    .controll_welding_cap{
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      min-height: 50px;

      .controll_welding_cap_controller{
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .controll_welding_cap_controller_plus{
          width: 40px;
          height: 40px;
          border: 2px solid #ADADAD;
          border-radius: 4px;
          background-image: url("../../../assets/images/plus.svg");
          background-position: center center;
          background-size: 70%;
          background-repeat: no-repeat;
          margin: 0 5px 5px 0;
          cursor: pointer;
          transition: 0.3s;
        }

        .controll_welding_cap_controller_plus:hover{
          background-color: #FFF;
          transition: 0.3s;
        }
        .controll_welding_cap_controller_filter{
          width: 40px;
          height: 40px;
          border: 2px solid #ADADAD;
          border-radius: 4px;
          background-image: url("../../../assets/images/filter.svg");
          background-position: center center;
          background-size: 70%;
          background-repeat: no-repeat;
          margin: 0 5px 5px 5px;
          cursor: pointer;
          transition: 0.3s;
        }

        .controll_welding_cap_controller_filter:hover{
          background-color: #FFF;
          transition: 0.3s;
        }
      }
      .controll_welding_cap_title{
        width: 50%;
        height: 100%;
        display: flex;
        ailign-items: flex-end;
        flex-direction: column;
        .controll_welding_cap_title_text{
          display: flex;
          justify-content: flex-end;
        }
        .controll_welding_cap_title_ym{
          display: flex;
          justify-content: flex-end;
        }
      }

    }
    .controll_welding_list{
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 100px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .controll_welding_list_strock{
        width: 100%;
        max-width: 900px;
        min-height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border: 1px solid #454545;
        margin-top: -1px;
        font-size: 10pt;
        .controll_welding_list_strock_pp{
          width: 5%;
          min-height: 40px;
          border: none;
          border-right: 1px solid #454545;
          display: flex;
          justify-content: center;
          align-items: center;
          .numbertext{
            display: none;
            margin-left: 4px;
          }
        }
        .controll_welding_list_strock_num{
          width: 10%;
          min-height: 40px;
          border: none;
          border-right: 1px solid #454545;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_date{
          width: 10%;
          min-height: 40px;
          border: none;
          border-right: 1px solid #454545;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_total{
          width: 6%;
          min-height: 40px;
          border: none;
          border-right: 1px solid #454545;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
            margin-left: 4px;
            display: none;
          }
        }
        .controll_welding_list_strock_autor{
          width: 28%;
          min-height: 40px;
          border: none;
          border-right: 1px solid #454545;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_obj{
          width: 15%;
          min-height: 40px;
          border: none;
          border-right: 1px solid #454545;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_status{
          width: 14%;
          min-height: 40px;
          border: none;
          border-right: 1px solid #454545;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        .button{
          cursor: pointer;
        }
        .controll_welding_list_strock_comm{
          width: 13%;
          min-height: 40px;
          border: none;
          border-right: 1px solid #454545;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_editor{
          display: flex;
          width: 12%;
          min-height: 40px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          .controll_welding_list_strock_editor_open{
            width: 75%;
            min-height: 40px;
            border-right: 1px solid #454545;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .controll_welding_list_strock_editor_open:hover{
            background-color: #333333;
            color: #FFFFFF;
          }
          .controll_welding_list_strock_editor_del{
            width: 25%;
            min-height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .controll_welding_list_strock_editor_del:hover{
            background-color: rosybrown;
            color: #FFFFFF;
          }
        }

        .controll_welding_list_strock_editor_up{
          width: 12%;
          min-height: 40px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }



    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1025px){
  .controll_welding{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 20px;
    max-width: 900px;
    height: 100%;

    .controll_welding_cap{
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      min-height: 50px;

      .controll_welding_cap_controller{
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .controll_welding_cap_controller_plus{
          width: 40px;
          height: 40px;
          border: 2px solid #ADADAD;
          border-radius: 4px;
          background-image: url("../../../assets/images/plus.svg");
          background-position: center center;
          background-size: 70%;
          background-repeat: no-repeat;
          margin: 0 5px 5px 0;
          cursor: pointer;
          transition: 0.3s;
        }

        .controll_welding_cap_controller_plus:hover{
          background-color: #FFF;
          transition: 0.3s;
        }
        .controll_welding_cap_controller_filter{
          width: 40px;
          height: 40px;
          border: 2px solid #ADADAD;
          border-radius: 4px;
          background-image: url("../../../assets/images/filter.svg");
          background-position: center center;
          background-size: 70%;
          background-repeat: no-repeat;
          margin: 0 5px 5px 5px;
          cursor: pointer;
          transition: 0.3s;
        }

        .controll_welding_cap_controller_filter:hover{
          background-color: #FFF;
          transition: 0.3s;
        }
      }
      .controll_welding_cap_title{
        width: 50%;
        height: 100%;
        display: flex;
        ailign-items: flex-end;
        flex-direction: column;
        .controll_welding_cap_title_text{
          display: flex;
          justify-content: flex-end;
        }
        .controll_welding_cap_title_ym{
          display: flex;
          justify-content: flex-end;
        }
      }

    }
    .controll_welding_list{
      display: flex;
      width: calc(100% + 0px);
      height: 100%;
      min-height: 100px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 0px;

      .controll_welding_list_strock{
        //width: 100%;
        //max-width: 900px;
        //min-height: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        border: 1px solid #454545;
        border-radius: 20px;
        padding: 20px;
        margin-top: -1px;
        font-size: 10pt;
        margin-bottom: 10px;
        .controll_welding_list_strock_pp{
          width: 80px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          .numbertext{
            display: block;
            margin-left: 4px;
          }
        }
        .controll_welding_list_strock_num{
          //width: 10%;
          width: 80px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_date{
          width: 100px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_total{
          width: 100px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
            margin-right: 4px;
            display: block;
          }
        }
        .controll_welding_list_strock_autor{
          width: 200px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_obj{
          width: 60px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_status{
          width: 200px;
          min-height: 40px;
          border: none;
          //background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        .button{
          cursor: pointer;
        }
        .controll_welding_list_strock_comm{
          width: 90px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_editor{
          display: flex;
          width: 150px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          justify-content: center;
          align-items: center;
          .controll_welding_list_strock_editor_open{
            width: 75%;
            min-height: 40px;
            border-right: 1px solid #454545;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .controll_welding_list_strock_editor_open:hover{
            background-color: #333333;
            color: #FFFFFF;
          }
          .controll_welding_list_strock_editor_del{
            width: 25%;
            min-height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .controll_welding_list_strock_editor_del:hover{
            background-color: rosybrown;
            color: #FFFFFF;
          }
        }

        .controll_welding_list_strock_editor_up{
          width: 12%;
          min-height: 40px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .titcontroll{
        display: none;
      }


    }
  }
}

@media screen and (max-width: 800px){
  .controll_welding{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 20px;
    max-width: 900px;
    height: 100%;

    .controll_welding_cap{
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      min-height: 50px;

      .controll_welding_cap_controller{
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .controll_welding_cap_controller_plus{
          width: 40px;
          height: 40px;
          border: 2px solid #ADADAD;
          border-radius: 4px;
          background-image: url("../../../assets/images/plus.svg");
          background-position: center center;
          background-size: 70%;
          background-repeat: no-repeat;
          margin: 0 5px 5px 0;
          cursor: pointer;
          transition: 0.3s;
        }

        .controll_welding_cap_controller_plus:hover{
          background-color: #FFF;
          transition: 0.3s;
        }
        .controll_welding_cap_controller_filter{
          width: 40px;
          height: 40px;
          border: 2px solid #ADADAD;
          border-radius: 4px;
          background-image: url("../../../assets/images/filter.svg");
          background-position: center center;
          background-size: 70%;
          background-repeat: no-repeat;
          margin: 0 5px 5px 5px;
          cursor: pointer;
          transition: 0.3s;
        }

        .controll_welding_cap_controller_filter:hover{
          background-color: #FFF;
          transition: 0.3s;
        }
      }
      .controll_welding_cap_title{
        width: 50%;
        height: 100%;
        display: flex;
        ailign-items: flex-end;
        flex-direction: column;
        .controll_welding_cap_title_text{
          display: flex;
          justify-content: flex-end;
        }
        .controll_welding_cap_title_ym{
          display: flex;
          justify-content: flex-end;
        }
      }

    }
    .controll_welding_list{
      display: flex;
      width: calc(100% + 40px);
      height: 100%;
      min-height: 100px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: -20px;

      .controll_welding_list_strock{
        //width: 100%;
        //max-width: 900px;
        //min-height: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        border: 1px solid #454545;
        border-radius: 20px;
        padding: 20px;
        margin-top: -1px;
        font-size: 10pt;
        margin-bottom: 10px;
        .controll_welding_list_strock_pp{
          width: 80px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          .numbertext{
            display: block;
            margin-left: 4px;
          }
        }
        .controll_welding_list_strock_num{
          //width: 10%;
          width: 80px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_date{
          width: 100px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_total{
          width: 100px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
            margin-right: 4px;
            display: block;
          }
        }
        .controll_welding_list_strock_autor{
          width: 200px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_obj{
          width: 60px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_status{
          width: 200px;
          min-height: 40px;
          border: none;
          //background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        .button{
          cursor: pointer;
        }
        .controll_welding_list_strock_comm{
          width: 90px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .controll_welding_list_strock_editor{
          display: flex;
          width: 150px;
          min-height: 40px;
          border: none;
          background-color: #FFFFFF;
          border-radius: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          justify-content: center;
          align-items: center;
          .controll_welding_list_strock_editor_open{
            width: 75%;
            min-height: 40px;
            border-right: 1px solid #454545;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .controll_welding_list_strock_editor_open:hover{
            background-color: #333333;
            color: #FFFFFF;
          }
          .controll_welding_list_strock_editor_del{
            width: 25%;
            min-height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .controll_welding_list_strock_editor_del:hover{
            background-color: rosybrown;
            color: #FFFFFF;
          }
        }

        .controll_welding_list_strock_editor_up{
          width: 12%;
          min-height: 40px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .titcontroll{
        display: none;
      }


    }
  }
}

.rs-input-group {
border-radius: 0 !important;
}

.statuses{
  width: calc(100% - 40px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important;
  align-items: center;
  .button{

    text-transform: uppercase;
    font-weight: 600;
    width: 50%;
  }
}