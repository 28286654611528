.naturepower{
  margin: 0 auto;
  max-width: 1000px;
  .baner{
    width: 100%;
    aspect-ratio: 3 / 1 !important;
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
  }
  .texts{
    h4{
      font-size: 16px;
      font-weight: normal;
      margin: 8px 0;
    }
    p{
      margin: 10px 0;
      font-size: 16px;
      line-height: 130%;
      text-align: justify;
    }
    span{
      font-weight: bold;
    }
    .b{
      font-weight: bold;
    }
    .w{
      font-size: 20px;
    }
    ul{
      margin: 20px;
      li{
        p{
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
    .kupons{
      display: flex;
      flex-direction: row;
      .sleva{
        width: calc(50% - 40px);
        margin: 10px;
        cursor: pointer;
        position: relative;
        img{
          width: 100%;
        }
        i{
          position: absolute;
          right: 15px;
          font-size: 1.6rem;
          color: rgba(1,1,1,0.5);
          &:hover{
            color: rgba(1,1,1,1);
          }
        }
        .fa-download{
          bottom: 15px;
        }
        .fa-print{
          top:15px;
        }
      }

    }
  }
}
@media screen and (max-width: 800px){
  .naturepower{
    .texts{
      .kupons{
        flex-wrap: wrap;
        .sleva{
          width: calc(100% - 10px);
          margin: 2.5px;
          cursor: pointer;
          img{
            width: 100%;
          }
        }
      }
    }
  }
}