.container{
  margin: 0 20px;
  .title{
    margin: 5px;
  }
  .button{
    display: inline-block;
    flex-direction:row;
    color: #FFFFFF;
    background-color: #333333;
    text-align: center;
    padding: 5px 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    margin: 0 10px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    &:hover{
      background: #3a3a3a; /* Подсветка строки */
      color: #aaa;
    }
  }
  .sorts{
    display: flex;
    flex-direction: row;
    margin-top: 6px;

    .sort{
      margin: 0 3px;
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      &:hover{
        color: #333333;
      }
    }
  }
  .tableContainer {
    width: 97%;
    height: 76vh;
    margin: 4px 0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    font-size: 0.8rem;
  }

  .table {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #444; /* Рамка таблицы */
  }

  .head, .tr {
    display: flex;
    width: 100%;
    text-align: left;

  }

  .head {
    position: sticky;
    top: 0;
    background: #333;
    color: #fff;
    font-weight: bold;
    padding: 12px 0;
    text-transform: uppercase;
    border-bottom: 2px solid #444;
  }

  .tr {
    cursor: pointer;
    padding: 10px 0;
    transition: background 0.3s;
  }

  .tr:hover {
    background: #3a3a3a; /* Подсветка строки */
    color: #FFFFFF;
  }

  .even {
    background: #f2f2f2; /* Чередование строк */
  }

  .odd {
    background: #e0e0e0;
  }
  .important{
    font-weight: bold;
    font-size: 0.9rem;
    margin-right: 5px;
  }
  .td {
    flex: 1;
    padding: 10px 15px;
    border-right: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }
  .small{
    flex: 0.2;
  }
  .small2{
    flex: 0.5;
  }
  .td:last-child {
    border-right: none;
  }

  .leftborder{
    border-left: 1px solid #333333;
  }

  .noData {
    padding: 15px;
    text-align: center;
    color: #bbb;
    font-style: italic;
  }
}