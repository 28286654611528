.right-block-ymweldings{
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
}


.ymwelding_head{
width: 100%;
display: flex;
justify-content: flex-start;
flex-wrap: wrap;
align-items: center;
margin-bottom: 20px;

  .ymwelding_head_btnlast{
    width: 236px;
    height: 40px;
    background: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ymwelding_head_nameobj {
    margin: 0 10px;
    max-width: 600px;
    span{
      color: #000;
      font-weight: 700;
      margin-right: 20px;
    }
  }
  .ymwelding_head_passobj {
    width: 236px;
    height: 40px;
    background: #74A94C;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 500px){
  .ymwelding_controller{
    max-width: calc(236px * 2 + 22px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .ymwelding_controller_ym{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .ymwelding_controller_ym_select{
        width: 236px;
        height: 40px;
        padding: 5px;
        border: 1px solid #000;
        margin-bottom: 10px;
        :focus, :hover, :active, :after{
          outline: 0;
          outline-offset: 0;
        }
      }
      .ymwelding_controller_ym_pluss{
        background: #617676;
        width: 236px;
        height: 40px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .ymwelding_controller_ym_pluss:hover{
        opacity: 0.9;
      }
    }

    .ymwelding_controller_sistembtns{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .ymwelding_controller_sistembtns_crews{
        background: #617676;
        width: 236px;
        height: 40px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }
      .ymwelding_controller_sistembtns_itogs{
        background: #E49D4B;
        width: 236px;
        height: 40px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }
      .ymwelding_controller_sistembtns_viewsjob{
        background: #4CC4C5;
        width: 236px;
        height: 40px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (max-width: 500px){
  .ymwelding_controller{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .ymwelding_controller_ym{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .back-button{
        width: calc(100% - 30px) !important;
      }
      .ymwelding_controller_ym_select{
        width: 100%;
        height: 40px;
        padding: 5px;
        border: 1px solid #000;
        margin-bottom: 10px;
        :focus, :hover, :active, :after{
          outline: 0;
          outline-offset: 0;
        }
      }
      .ymwelding_controller_ym_pluss{
        background: #617676;
        width: 100%;
        height: 40px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .ymwelding_controller_ym_pluss:hover{
        opacity: 0.9;
      }
    }

    .ymwelding_controller_sistembtns{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      .back-button{
        width: calc(100% - 30px) !important;
      }
      .ymwelding_controller_sistembtns_crews{
        background: #617676;
        width: 236px;
        height: 40px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }
      .ymwelding_controller_sistembtns_itogs{
        background: #E49D4B;
        width: 236px;
        height: 40px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }
      .ymwelding_controller_sistembtns_viewsjob{
        background: #4CC4C5;
        width: 236px;
        height: 40px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }
    }
  }
}


.back-button:hover{
  opacity: 0.8;
  transition: .2s;
}
.ymwelding_slice{
  width: 100%;
  height: 1px;
  background: #CCC;
  margin-bottom: 10px;
}

.ymwelding_years{
  width: calc(100% - 20px);
  border: 4px solid #CCC;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: #FFFFFF;
  padding-bottom: 10px;
  @media screen and (min-width: 500px) {
    .ymwelding_years_head {
      width: 300px;
      height: 35px;
      background: rgb(18, 19, 56);
      border-radius: 17px 0 2px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
    }
  }
  @media screen and (max-width: 500px) {
    .ymwelding_years_head {
      width: 200px;
      height: 35px;
      background: rgb(18, 19, 56);
      border-radius: 17px 0 2px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
    }
  }
  @media screen and (min-width: 500px){
    .ymwelding_years_body{
      width: calc(95% - 20px);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 10px;
      .ymwelding_years_body_month:hover{
        background-color: rgba(0,0,0,0.2);
        transition: .2s;
      }
      .ymwelding_years_body_month{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 236px;
        height: 40px;
        border: 4px solid #CCC;
        margin: 10px 5px 0 5px;
        border-radius: 5px;
        transition: .2s;
        .ymwelding_years_body_month_text{
          margin-left: 20px;
        }
        .ymwelding_years_body_month_settings{
          background: #D9D9D9;
          width: 40px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #505050;
          font-family: Montserrat;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border-radius: 1px;
          transition: .2s;
        }
        .ymwelding_years_body_month_settings:hover{
          background: #454545;
          color: #FFFFFF;
          transition: .2s;
        }
      }
    }
  }
  @media screen and (max-width: 500px){
    .ymwelding_years_body{
      width: calc(100%);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 10px;
      .ymwelding_years_body_month:hover{
        background-color: rgba(0,0,0,0.2);
        transition: .2s;
      }
      .ymwelding_years_body_month{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 236px;
        height: 40px;
        border: 4px solid #CCC;
        margin: 10px 5px 0 5px;
        border-radius: 5px;
        transition: .2s;
        .ymwelding_years_body_month_text{
          margin-left: 20px;
        }
        .ymwelding_years_body_month_settings{
          background: #D9D9D9;
          width: 40px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #505050;
          font-family: Montserrat;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border-radius: 1px;
          transition: .2s;
        }
        .ymwelding_years_body_month_settings:hover{
          background: #454545;
          color: #FFFFFF;
          transition: .2s;
        }
      }
    }
  }
}

.modalcrew{
  display: none;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.7);
  z-index: 100;
  .modalcrew_white{
    width: 600px;
    height: 90%;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .modalcrew_white_krestik{
      margin-top: 20px;
      width: 95%;
      display: flex;
      justify-content: flex-end;
      i{
        font-size: 20pt;
        cursor: pointer;
        transition: .2s;
        margin-right: 10px;
      }
      i:hover{
        opacity: 0.8;
        transition: .2s;
      }

    }
    .modalcrew_white_modal{
      width: calc(100% - 40px);
      height: calc(100% - 80px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .modalcrew_white_modal_title{
        font-size: 20pt;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
      }
      .modalcrew_white_modal_plus{
        width: calc(100% - 10px);
        margin-bottom: 10px;
        padding: 0 5px;
      }
      .modalcrew_white_modal_inputs{
        width: calc(100% - 22px);
        height: 40px;
        padding: 0 10px;
        margin-bottom: 10px;
        margin-top: -10px;
        border: 1px solid #CCC;
        border-radius: 3px;
      }
      .modalcrew_white_modal_btn{
        width: 100%;
        height: 60px;
        border: 1px solid #CCC;
        border-radius: 4px;
        margin: 10px 0 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #454545;
        cursor: pointer;
        transition: .2s;
      }
      .modalcrew_white_modal_btn:hover{
        background-color: #E1E1E1;
        border: 1px solid #E1E1E1;
        transition: .2s;
      }
      .modalcrew_white_modal_list{
        width: calc(100% - 20px);
        height: 70%;
        border: 1px dotted #CCC;
        border-radius: 4px;
        overflow-y: auto;
        padding: 0 10px;
        .modalcrew_white_modal_list_crew{
          width: calc(100% - 30px);
          padding: 10px;
          margin-top: 10px;
          border: 4px solid #CCC;
          border-radius: 14px;
          display: flex;
          justify-content: space-between;
          .modalcrew_white_modal_list_crew_name{
            width: calc(100% - 40px);
          }
          .modalcrew_white_modal_list_crew_krest{
            i{
              font-size: 16pt;
              transition: 0.2s;
              cursor: pointer;
            }
            i:hover{
              opacity: 0.8;
              transition: 0.2s;
            }
          }
        }

      }

    }
    .position{
      justify-content: flex-start;
    }
  }
}

.modal_docs{
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100vw - 10px);
  min-height: 100vh;
  padding-bottom: 20px;
  z-index: 100;
  background-color: rgba(255,255,255,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  .krest{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    i{
      font-size: 20pt;
    }
  }
  .print{
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 20px 40px;
    border: 1px solid #CCC;
    cursor: pointer;
  }
  .print:hover{

  }
  .doc{
    width: 80vw;
    margin-top: 20px;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:  center;
    overflow-y: auto;

    .title{
      width: 100%;
      font-size: 18px;
      font-weight: 600;
    }
    .doc_title{
      width: calc(100% - 3px);
      height: calc(100% - 0px);
      display: flex;
      margin-left: 2px;
      div{
        border: 1px solid #454545;
        margin: 0  0 -1px -1px;
        //padding: 10px;
      }
      .doc_title_pp{
        width: 5%;
        min-width: 60px;
        display: flex;
        justify-content: center;
        align-items:  center;
      }
      .doc_title_name{
        width: 20%;
        min-width: 260px;
      }
      .doc_title_unit{
        width: 10%;
        min-width: 60px;
      }
      .doc_title_fact{
        width: 10%;
        min-width: 60px;
      }
      .doc_title_project{
        width: 10%;
        min-width: 60px;
      }
      .doc_title_delta{
        width: 10%;
        min-width: 60px;
      }
      .doc_title_compl{
        width: 15%;
        min-width: 60px;
      }
    }

  }
}