.container{
  .data{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    .buttons{
      display: flex;
      width: 120px;
      margin-top: 6px;
      flex-direction: column;
      justify-content: flex-start;
      text-align: center;

      .button{
        width: calc(100% - 30px);
        height: 40px;
        font-size: .7rem;
        margin: 15px 0;
        background-color: rgba(1,1,1,0.7);
        border: 3px solid #333;
        border-radius: 3px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
      }
      .button:hover{
        background-color: rgba(1,1,1,0.3);
      }
    }
    .table{
      max-width: calc(100% - 240px);
      width: calc(100% - 240px);
      min-height: calc(100vh - 220px);
      max-height: calc(100vh - 220px);
      overflow-y: auto;
      overflow-x: hidden;
      background-color: rgba(1,1,1,0.1);
      border:2px solid #aaa;
      display: flex;
      flex-direction: column;

      .row{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: .6rem;
          max-width: 30%;
        }
      }
      .row2{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        div{
          display: flex;
          font-size: .9rem;
          margin: 2px;
          max-width: 80%;
          overflow: hidden;
        }
      }
    }
  }

  .testing{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    .buttons{
      display: flex;
      margin-top: 6px;
      margin-left: 10px;
      flex-direction: column;
      justify-content: flex-start;
      .button{
        height: 40px;
        font-size: .7rem;
        padding: 0 20px;
        margin: 15px 0;
        background-color: rgba(1,1,1,0.7);
        border: 3px solid #333;
        border-radius: 3px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
      }
      .button:hover{
        background-color: rgba(1,1,1,0.3);
      }
    }
  }

  h1{
    font-size: 0.9rem;
    color: rgba(1,1,1,0.8);
    font-weight: bold;
    text-transform: uppercase;
  }

}
.red{
  background-color: #ff2d55 !important;
}