.tabelsvodka{
  display: flex;
  flex-direction: column;
  min-width: content-box;
  position: absolute;
}

.tabelsvodka_up{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .tabelsvodka_up_backbtn{
    width: 236px;
    height: 40px;
    background: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .tabelsvodka_up_otdel{
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 20px;
  }
}
.plus_plusbtn{
  width: 236px;
  height: 38px;
  background: #617676;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  cursor: pointer;
  border-radius: 3px;
}

.plus_input{
  width: 230px;
  height: 36px;
  border: 1px solid #CCC;
  border-radius: 3px;
  outline: none;
  padding: 0px 10px;
}

.tabelsvodka_pult{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .tabelsvodka_pult_plusman{
    display: flex;
    flex-wrap: wrap;
    .tabelsvodka_pult_plusman_plusbtn{
      width: 236px;
      height: 36px;
      background: #617676;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      cursor: pointer;
    }
  }
  .tabelsvodka_pult_daysdop{
    display: flex;
    //flex-direction: column;
    flex-wrap: wrap;
    margin-top: 20px;
    .tabelsvodka_pult_daysdop_days{
      display: flex;
      flex-wrap: wrap;

      max-width: calc(44px * 16);
      .day{
        width: 38px;
        height: 38px;
        border: 1px solid #CCC;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 4px 4px 0;
        cursor: pointer;
        transition: 0.2s;
      }
      .day:hover{
        background-color: #292f33;
        color: #FFF;
        transition: 0.2s;
      }
    }
    .tabelsvodka_pult_daysdop_dop{
      .tabelsvodka_pult_daysdop_dop_inputs{
        display: flex;
        margin-bottom: 4px;
      }
      .tabelsvodka_pult_daysdop_dop_list{
        width: 100%;
        height: 40px;
        border: 1px solid #CCC;
        border-radius: 3px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;
        .tabelsvodka_pult_daysdop_dop_list_view{
          height: 80%;
          padding: 0 0 0 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #CCC;
          margin-left: 5px;
          margin-right: 2px;
          border-radius: 3px;
          .tabelsvodka_pult_daysdop_dop_list_view_text{
            margin-right: 10px;
          }
          .tabelsvodka_pult_daysdop_dop_list_view_del{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 25px;
            border-left: 1px solid #CCC;
            background-color: #ff5e5e;
            color: #FFF;
            cursor: pointer;
            transition: 0.2s;
          }
          .tabelsvodka_pult_daysdop_dop_list_view_del:hover{
            background-color: #454545;
            transition: 0.2s;
          }
        }
      }
    }
  }
  .tabelsvodka_pult_filter{
    display: flex;
    flex-wrap: wrap;
    .tabelsvodka_pult_btn{
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;
      border: 1px solid #CCC;
      border-radius: 3px;
      margin: 0 4px 4px 0;
      cursor: pointer;
      transition: 0.2s;
    }
    .tabelsvodka_pult_btn:hover{
      background-color: #292f33;
      color: #FFF;
    }
  }
}

.tabelsvodka_table{
  display: flex;
  flex-direction: column;
  .tabelsvodka_table_strock{
    display: flex;
    margin-top: -1px;
    .tabelsvodka_table_strock_num{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 40px;
      height: 80px;
      border: 1px solid #454545;
      .tabelsvodka_table_strock_num_strock{}
      .tabelsvodka_table_strock_num_trash{}
    }

    .tabelsvodka_table_strock_name {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 250px;
      height: 80px;
      border: 1px solid #454545;
      margin-left: -1px;
      font-size: 10pt;

      .tabelsvodka_table_strock_name_fio {
        padding: 4px;
        font-weight: 700;
      }

      .tabelsvodka_table_strock_name_dev {
        padding: 4px;
      }

      .tabelsvodka_table_strock_name_delete {
        padding: 4px;
        width: calc(100% - 8px);
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #6c757d;
        color: #FFFFFF;
        cursor: pointer;
      }

      .tabelsvodka_table_strock_name_delete:hover {
        opacity: 0.8;
      }
    }

    .tabelsvodka_table_strock_date{
      display: flex;
      flex-direction: column;
      width: 60px;
      height: 80px;
      border: 1px solid #454545;
      margin-left: -1px;
      .tabelsvodka_table_strock_date_tit{
        width: 60px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .tabelsvodka_table_strock_date_oclock{
        width: 60px;
        height: calc(100% - 40px);
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .tabelsvodka_table_strock_date_oclock:focus{
        box-shadow: inset 0px 0px 5px #454545;
      }
    }

    .tabelsvodka_table_strock_comment{
      display: flex;
      flex-direction: column;
      width: 300px;
      height: 80px;
      border: 1px solid #454545;
      margin-left: -1px;
      .tabelsvodka_table_strock_comment_tit{
        width: 300px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .tabelsvodka_table_strock_comment_text{
        width: 300px;
        height: calc(100% - 40px);
        border: none;
        outline: none;
      }
      .tabelsvodka_table_strock_comment_text:focus{
        box-shadow: inset 0px 0px 5px #454545;
      }
    }

    .tabelsvodka_table_strock_dop{
      display: flex;
      flex-direction: column;
      width: 60px;
      height: 80px;
      border: 1px solid #454545;
      margin-left: -1px;
      .tabelsvodka_table_strock_date_tit{
        width: 60px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .tabelsvodka_table_strock_date_oclock{
        width: 60px;
        height: calc(100% - 39px);
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .tabelsvodka_table_strock_date_oclock:focus{
        box-shadow: inset 0px 0px 5px #454545;
      }
    }

  }
}