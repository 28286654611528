.rec{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .exit{
    font-size: 2rem;
    color: rgba(3,3,3,0.8);
    position: absolute;
    top:80px;
    right: 30px;
    cursor: pointer;
  }
}
.glass{
  position: fixed;
  cursor: pointer;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.2);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spherevoice{
  position: relative;
  display: flex;
  z-index: 10010000000;
  justify-content: center;
  align-items: center;
  padding: -5px -20px;
  border-radius: 105px;
  background: linear-gradient(145deg, #F1F1F1, #131537); /* Градиентный фон */
  //box-shadow: 7px 7px 14px #0d0e27, -7px -7px 14px #24275d;
  margin: 0;
  width: 180px;
  height: 180px;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
  cursor: pointer;
  .tit{
    position: absolute;
    bottom: 50px;
    i{
      font-size: 1.8rem;
    }
  }
  .reload{
    position: absolute;
    background-color: #131537 !important;
    width: 162px;
    height: 162px;
    border-radius: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 36pt;
    z-index: 1001;
  }
  .control{
    position: absolute;
    top: 200px;
    audio{
      width: 200px;
    }
    .history_mess_pen_btn{
      width: 200px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #131537 !important;
      color: #FFFFFF;
      border-radius: 28px;
    }
  }
}
.mic-container {
  max-width: 200px !important;
  height: 200px;
  //border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  padding: 0 !important;
  //background-color: #FFC0CB !important;
}

.soundwave {
  width: 200px !important;
  height: 200px;
  border-radius: 100px;
  transform: scale(0.8); /* Уменьшаем размер содержимого */
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
  margin: 0 !important;
  padding: -50px !important;
}
