.modal_crew{
  margin-top: 20px;
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
;
  .modal_crew_message{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .modal_crew_btns{
    display: flex;
    justify-content: space-around;
    width: 100%;
    .modal_crew_btns_plus{
      background-color: rgb(18, 19, 56);
      color: white;
      margin: 10px;
      padding: 10px 16px 10px 10px;
      width: 150px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      border-radius: 0px;
      border: none;
    }
    .modal_crew_btns_cancel{
      background-color: rgba(215, 26, 0, 0.5);
      color: white;
      margin: 10px;
      padding: 10px 16px 10px 10px;
      width: 150px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: pointer;
      border-radius: 0px;
      border: none;
    }
  }

}