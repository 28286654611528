.container{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  .overlay{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 101;
    cursor: pointer;
    background-color: rgba(1,1,1,0.3);
    backdrop-filter: blur(3px);
    i{
      position: absolute;
      top:40px;
      right: 40px;
      color: white;
      font-size: 3rem;
    }
  }
  .window{
    position: relative;
    width: 460px;
    min-height:350px;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    z-index: 102;
    border-radius: 10px;
    .header{
      width: calc(100% - 40px);
      border-bottom: 1px solid #aaaaaa;
      padding: 20px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 600;
      color: #555;
      display: flex;
      align-items: center;
      justify-content: left;
      i{
        margin: 0 20px 0 0;
        cursor: pointer;
        font-size: 1.3rem;

      }
    }
    .title{
      width: calc(100% - 20px);

      padding: 10px;
      text-transform: uppercase;
      font-size: 0.6rem;
      font-weight: 600;
      color: #888;
    }

    .stickers{
      width: calc(100% - 20px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 10px;
      .sticker,.bigsticker{
        width: 100px;
        height: 100px;
        border-radius: 80px;
        margin: 5px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center;
        background-size: contain;
        cursor: pointer;

      }
      .sticker:hover{
        background-color: rgba(1,1,1,0.1);
      }
      .bigsticker{
        width: 400px;
        height: 400px;
      }
    }
  }
  .button{

  }
}
.textarea{
  width: calc(100% - 120px);
  border: 1px solid #ddd;
  border-radius: 10px;
  height: auto;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  textarea{
    height: 30px;
    width: calc(100% - 120px);
    min-height: 22px;
    max-height: 200px;
    resize: none;
    padding: 10px 20px;
    outline: none;
    color: #333333;
    font-size: 0.9rem;
    border: none;
    background-color: transparent;
    &::placeholder {
      font-size: 0.8rem;
      color: #888;
      opacity: 1;
    }
  }
  .emojicontainer {
    position: relative;
    display: inline-block;
    .emojibutton {
      position: absolute;
      bottom: 80px;
      right: 10px;
      font-size: 15px;
      padding: 5px;
      cursor: pointer;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    .emojipicker {
      position: absolute;
      width: 120px;
      bottom: -12px;
      right: 10px;
      display: flex;
      flex-wrap: wrap;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
    .emoji{
      font-size: 16px;
      padding: 4px;
      cursor: pointer;
    }

    .emoji:hover {
      background-color: #f0f0f0;
    }
  }

  .circle2{
    margin-top: 13px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      margin-right: 14px;
      font-size: 1.4rem;
      color: #aaa;
    }
  }
  .circle2:hover{
    i{
      font-size: 1.4rem;
      color: #888;
    }
  }
}
.attachments{
  position: absolute;
  width: 200px;
  left: 10px;
  top:-38px;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  .ul{
    padding: 5px;
    .li{
      padding: 5px;
      cursor: pointer;
      color: #aaaaaa;
      font-size: 0.8rem;
      i{
        font-size: 1.4rem;
        margin-right: 14px;
      }
    }
    .li:hover{
      color: #888;
    }
  }
}

@media screen and (max-width: 460px){
  .window{
    width: 350px !important;
  }
}