.menu_burger{
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  justify-content: flex-start;
  background-color: rgba(18,19,56,1);
  color: #FFFFFF;
  padding-bottom: 40px;
  .navbar_block_menu_strock{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px);
    max-width: 350px;
    min-height: 60px;
    border-bottom: 1px solid #FFFFFF;
    .navbar_block_menu_strock_icon{
      width: 30px;
      height: 30px;
      //background-color: rgba(18,19,56,1);
      border-radius: 2px;
      margin-right: 20px;
      position: relative;
      .messindicate{
        position: absolute;
        top: -2px;
        right: -3px;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: red;
        animation: blink 2s infinite alternate;
      }
      @keyframes blink {
        0% { opacity: 1; }
        50% { opacity: 0.6; }
        100% { opacity: 1; }
      }
    }
    .navbar_block_menu_strock_description{
      color: #FFFFFF;
      font-size: 14pt;
    }
  }
  .navbar_block_dopmenu_list_strock{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100vw - 40px - 20px);
    max-width: 350px;
    height: 20px;
    border-bottom: 1px solid #FFFFFF;
    padding: 10px;
    background-color: #F1F1F1;
    margin-top: 5px;
    border-radius: 5px;
    .navbar_block_dopmenu_list_icon{
      color: rgba(18,19,56,1);
      margin-right: 20px;
    }
    .navbar_block_dopmenu_list_description{
      color: rgba(18,19,56,1);
    }
  }
  .icon_home{
    background-image: url("icons/home.svg");
    background-size: 70%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .icon_news{
    background-image: url("icons/news.svg");
    background-size: 70%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .icon_com{
    background-image: url("icons/comunity.svg");
    background-size: 70%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .icon_forest{
    background-image: url("icons/forest.svg");
    background-size: 70%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .icon_doc{
    background-image: url("icons/doc.svg");
    background-size: 50%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .icon_task{
    background-image: url("icons/task.svg");
    background-size: 65%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .icon_lk{
    background-image: url("icons/lk.svg");
    background-size: 65%;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
@media screen and (min-width: 1200px){
  .quest_director{
    max-width: 350px;
    .quest_director_up{
      display: flex;
      flex-direction: column;
      align-items: center;
      .quest_director_up_title{
        font-size: 16pt !important;
        text-align: center;
      }
      .quest_director_up_customer{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        .quest_director_up_customer_name{
          min-width: calc(100% - 100px);
          padding: 0 50px
        }
      }
      .quest_director_up_theme{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        .quest_director_up_theme_name{
          min-width: calc(100% - 100px);
          padding: 0 50px
        }
      }
      .quest_director_up_question{
        width: 80%;
      }
    }
    .quest_director_btns{
      width: 300px;
      .quest_director_btns_cancel{
        margin: 0px;
        padding: 5px;
      }
      .quest_director_btns_post{
        margin: 0px;
        padding: 5px;
      }
    }
  }
  .head_block{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head_block_logo{
      color: #F1F1F1;
    }
    .head_block_search{
      display: flex;
      justify-content: space-between;
      width: 30%;
      height: 36px;
      padding: 0 0 0 15px;

      border: 2px solid rgba(255,255,255,0.3);
      border-radius: 40px;
      .head_block_search_input{
        background: rgba(255,255,255,0);
        border: none;
        color: #F1F1F1;
        width: 90%;
      }
      .head_block_search_input:focus {
        outline: none;
        border: none;
      }
      .head_block_search_btn{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: rgba(255,255,255,0.2);
        background-image: url('../../assets/images/newsearch.svg');
        background-size: cover;
        box-shadow: -5px 5px 10px 0px rgba(0,0,0,0.35);
        transition: 0.2s;
        cursor: pointer;
      }
      .head_block_search_btn:hover{
        opacity: 0.8;
        transition: 0.2s;
      }

    }
    .head_block_right{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .head_block_callback{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 20px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        cursor: pointer;
      }
      .head_block_callback:hover{
        opacity: 0.8;
      }
      .head_block_burger{
        display: none;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 20px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        cursor: pointer;
      }
      .head_block_burger:hover{
        opacity: 0.8;
      }
      .head_block_lk{
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #F1F1F1;
        .head_block_lk_photo{
          width: 40px;
          height: 40px;
          background-image: url('../../../public/profile/photoprofile.png');
          background-size: cover;
          background-position: center center;
          border-radius: 100%;
          margin-right: 10px;
        }
        .head_block_lk_name{
          margin-right: 10px;
        }
      }
      .head_block_questions{
        width: 36px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

  }
}
@media screen and (min-width: 800px) and (max-width: 1200px){
  .quest_director{
    max-width: 350px;
    .quest_director_up{
      display: flex;
      flex-direction: column;
      align-items: center;
      .quest_director_up_title{
        font-size: 14pt !important;
        text-align: center;
      }
      .quest_director_up_customer{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        .quest_director_up_customer_name{
          min-width: calc(100% - 100px);
          padding: 0 50px
        }
      }
      .quest_director_up_theme{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        .quest_director_up_theme_name{
          min-width: calc(100% - 100px);
          padding: 0 50px
        }
      }
      .quest_director_up_question{
        width: 80%;
      }
    }
    .quest_director_btns{
      width: 300px;
      .quest_director_btns_cancel{
        margin: 0px;
        padding: 5px;
      }
      .quest_director_btns_post{
        margin: 0px;
        padding: 5px;
      }
    }
  }
  .head_block{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head_block_logo{
      color: #F1F1F1;
    }
    .head_block_search{
      display: flex;
      justify-content: space-between;
      width: 30%;
      height: 36px;
      padding: 0 0 0 15px;

      border: 2px solid rgba(255,255,255,0.3);
      border-radius: 40px;
      .head_block_search_input{
        background: rgba(255,255,255,0);
        border: none;
        color: #F1F1F1;
        width: 90%;
      }
      .head_block_search_input:focus {
        outline: none;
        border: none;
      }
      .head_block_search_btn{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: rgba(255,255,255,0.2);
        background-image: url('../../assets/images/newsearch.svg');
        background-size: cover;
        box-shadow: -5px 5px 10px 0px rgba(0,0,0,0.35);
        transition: 0.2s;
        cursor: pointer;
      }
      .head_block_search_btn:hover{
        opacity: 0.8;
        transition: 0.2s;
      }

    }
    .head_block_right{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .head_block_callback{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 20px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        cursor: pointer;
      }
      .head_block_callback:hover{
        opacity: 0.8;
      }
      .head_block_burger{
        display: none;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 20px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        cursor: pointer;
      }
      .head_block_burger:hover{
        opacity: 0.8;
      }
      .head_block_lk{
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #F1F1F1;

        .head_block_lk_photo{
          width: 40px;
          height: 40px;
          background-image: url('../../../public/profile/photoprofile.png');
          background-size: cover;
          background-position: center center;
          border-radius: 100%;
          margin-right: 10px;
        }
        .head_block_lk_name{
          margin-right: 10px;
          font-size: 10pt;
        }
      }
      .head_block_questions{
        width: 36px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

  }
}
@media screen and (min-width: 500px) and (max-width: 800px){
  .quest_director{
    max-width: 350px;
    .quest_director_up{
      display: flex;
      flex-direction: column;
      align-items: center;
      .quest_director_up_title{
        font-size: 12pt !important;
        text-align: center;
      }
      .quest_director_up_customer{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        .quest_director_up_customer_name{
          min-width: calc(100% - 100px);
          padding: 0 50px
        }
      }
      .quest_director_up_theme{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        .quest_director_up_theme_name{
          min-width: calc(100% - 100px);
          padding: 0 50px
        }
      }
      .quest_director_up_question{
        width: 80%;
      }
    }
    .quest_director_btns{
      width: 300px;
      .quest_director_btns_cancel{
        margin: 0px;
        padding: 5px;
      }
      .quest_director_btns_post{
        margin: 0px;
        padding: 5px;
      }
    }
  }

  .head_block{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head_block_logo{
      color: #F1F1F1;
    }
    .head_block_search{
      display: flex;
      justify-content: space-between;
      width: 30%;
      height: 36px;
      padding: 0 0 0 15px;

      border: 2px solid rgba(255,255,255,0.3);
      border-radius: 40px;
      .head_block_search_input{
        background: rgba(255,255,255,0);
        border: none;
        color: #F1F1F1;
        width: 90%;
      }
      .head_block_search_input:focus {
        outline: none;
        border: none;
      }
      .head_block_search_btn{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: rgba(255,255,255,0.2);
        background-image: url('../../assets/images/newsearch.svg');
        background-size: cover;
        box-shadow: -5px 5px 10px 0px rgba(0,0,0,0.35);
        transition: 0.2s;
        cursor: pointer;
      }
      .head_block_search_btn:hover{
        opacity: 0.8;
        transition: 0.2s;
      }

    }
    .head_block_right{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .head_block_callback{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 20px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        cursor: pointer;
      }
      .head_block_callback:hover{
        opacity: 0.8;
      }
      .head_block_burger{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 20px;
        margin-right: 20px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        cursor: pointer;
      }
      .head_block_burger:hover{
        opacity: 0.8;
      }
      .head_block_lk{
        display: none;
        margin-left: 20px;
        //display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #F1F1F1;

        .head_block_lk_photo{
          width: 40px;
          height: 40px;
          background-image: url('../../../public/profile/photoprofile.png');
          background-size: cover;
          background-position: center center;
          border-radius: 100%;
          margin-right: 10px;
        }
        .head_block_lk_name{
          margin-right: 10px;
          font-size: 10pt;
        }
      }
      .head_block_questions{
        width: 36px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

  }
}

@media screen and (max-width: 500px){
  .quest_director{
    max-width: 350px;
    .quest_director_up{
      display: flex;
      flex-direction: column;
      align-items: center;
      .quest_director_up_title{
          font-size: 12pt !important;
        text-align: center;
      }
      .quest_director_up_customer{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        .quest_director_up_customer_name{
          min-width: calc(100% - 100px);
          padding: 0 50px
        }
      }
      .quest_director_up_theme{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        .quest_director_up_theme_name{
          min-width: calc(100% - 100px);
          padding: 0 50px
        }
      }
      .quest_director_up_question{
        width: 80%;
      }
    }
    .quest_director_btns{
      width: 300px;
      .quest_director_btns_cancel{
        margin: 0px;
        padding: 5px;
      }
      .quest_director_btns_post{
        margin: 0px;
        padding: 5px;
      }
    }
  }

  .head_block{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head_block_logo{
      color: #F1F1F1;
    }
    .head_block_search{
      display: flex;
      justify-content: space-between;
      width: 30%;
      height: 36px;
      padding: 0 0 0 15px;

      border: 2px solid rgba(255,255,255,0.3);
      border-radius: 40px;
      .head_block_search_input{
        background: rgba(255,255,255,0);
        border: none;
        color: #F1F1F1;
        width: 90%;
      }
      .head_block_search_input:focus {
        outline: none;
        border: none;
      }
      .head_block_search_btn{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: rgba(255,255,255,0.2);
        background-image: url('../../assets/images/newsearch.svg');
        background-size: cover;
        box-shadow: -5px 5px 10px 0px rgba(0,0,0,0.35);
        transition: 0.2s;
        cursor: pointer;
      }
      .head_block_search_btn:hover{
        opacity: 0.8;
        transition: 0.2s;
      }

    }
    .head_block_right{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .head_block_callback{
        display: none;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 20px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        cursor: pointer;
      }
      .head_block_callback:hover{
        opacity: 0.8;
      }
      .head_block_burger{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 20px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        cursor: pointer;
      }
      .head_block_burger:hover{
        opacity: 0.8;
      }
      .head_block_lk{
        display: none;
        margin-left: 20px;
        //display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #F1F1F1;

        .head_block_lk_photo{
          width: 40px;
          height: 40px;
          background-image: url('../../../public/profile/photoprofile.png');
          background-size: cover;
          background-position: center center;
          border-radius: 100%;
          margin-right: 10px;
        }
        .head_block_lk_name{
          margin-right: 10px;
          font-size: 10pt;
        }
      }
      .head_block_questions{
        width: 36px;
        height: 36px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 40px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

  }
}