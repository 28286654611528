
@media screen and (min-width: 800px){
  .lk_block{
    display: flex;
    flex-direction: column;
    .lk_block_params_navigation{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;
      //justify-content: flex-start;
      //align-items: flex-start;
      .lk_block_params_navigation_btn{
        min-height: 40px;
        height: content-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #CCC;
        width: content-box;
        cursor: pointer;
        transition: .2s;
        padding: 0 20px;
        margin: 0 5px 5px 0;
      }
      .lk_block_params_navigation_btn:hover{
        transition: .2s;
        color: #FFF;
        background-color: #454545;
      }
    }
    .lk_block_params{
      display: flex;

      .lk_block_params_photo{
        position: relative;
        display: flex;
        flex-direction: column;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 250px;
        height: 360px;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.5s;

        .lk_block_params_photo_black{
          position: absolute;
          width: 250px;
          height: 360px;
          background-color: rgba(0,0,0,0.5);
          border-radius: 10px;
          display: none;
          transition: 0.5s;
        }
        .lk_block_params_photo_open{
          display: none;
          transition: 0.5s;
          justify-content: flex-end;
          align-items: flex-end;
          width: 230px;
          height: 340px;
          position: absolute;
          color: #F1F1F1;
          font-size: 20pt;
          i{
            padding: 10px;
            border: 1px solid #F1F1F1;
            border-radius: 10px;
            cursor: pointer;
          }

        }
        .lk_block_params_photo_upload{
          display: none;
          transition: 0.5s;
          justify-content: flex-end;
          align-items: flex-end;
          width: 230px;
          height: 340px;
          position: absolute;
          color: #F1F1F1;
          font-size: 20pt;
          i{
            padding: 10px;
            border: 1px solid #F1F1F1;
            border-radius: 10px;
            cursor: pointer;
          }

        }

        .lk_block_params_photo_open:hover{
          opacity: 0.8;
          color: rgba(255,255,255,0.8);
        }
        .lk_block_params_photo_upload:hover{
          opacity: 0.8;
          color: rgba(255,255,255,0.8);
        }

      }
      .lk_block_params_photo:hover{
        transition: 0.5s;
        .lk_block_params_photo_black{
          display: block;
          transition: 0.5s;
        }

        .lk_block_params_photo_open{
          display: flex;
          transition: 0.5s;
          justify-content: flex-end;
          align-items: flex-end;
          width: 230px;
          height: 340px;
          position: absolute;
          color: #F1F1F1;
          font-size: 20pt;
          i{
            padding: 10px;
            border: 1px solid #F1F1F1;
            border-radius: 10px;
            cursor: pointer;
          }
        }
        .lk_block_params_photo_upload{
          display: flex;
          transition: 0.5s;
          justify-content: flex-end;
          align-items: flex-end;
          width: 180px;
          height: 340px;
          position: absolute;
          color: #F1F1F1;
          font-size: 20pt;
          i{
            padding: 10px;
            border: 1px solid #F1F1F1;
            border-radius: 10px;
            margin: 0 5px;
            cursor: pointer;
          }

        }
      }
      .lk_block_params_personaldata{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 400px;
        min-height: 100%;
        margin: 0 20px;
        .lk_block_params_personaldata_edit{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .lk_block_params_personaldata_edit_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 20px;
            border: 1px solid #CCC;
            border-radius: 2px;
            cursor: pointer;
          }
          .lk_block_params_personaldata_edit_btn:hover{
            opacity: 0.8;
          }
        }
        .lk_block_params_personaldata_fio{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 20px);
          margin-top: 20px;
          padding: 5px 10px;
          background-color: #FFF;
          border-radius: 2px;
          border: none;
        }
        .lk_block_params_personaldata_dev{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 20px);
          margin-top: 10px;
          padding: 10px 10px;
          background-color: #FFF;
          border-radius: 2px;
          border: none;
        }
        .lk_block_params_personaldata_otdel{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 20px);
          margin-top: 10px;
          padding: 10px 10px;
          background-color: #FFF;
          border-radius: 2px;
          border: none;
        }
        .lk_block_params_personaldata_tn{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 20px);
          margin-top: 10px;
          padding: 10px 10px;
          background-color: #FFF;
          border-radius: 2px;
        }

        .lk_block_params_personaldata_login{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 10px;
          .lk_block_params_personaldata_login_name{
            width: 50%;
          }
          .lk_block_params_personaldata_login_btnpassword{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            border: 1px solid #CCC;
            border-radius: 2px;
            cursor: pointer;
          }
          .lk_block_params_personaldata_login_btnpassword:hover{
            opacity: 0.8;
          }
        }
      }

    }

  }
  .change-password-form{
    width: 100%;
    .info{
      h3{
        margin-left: 20px;
        margin-bottom: 10px;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1rem;
      }
    }
    .inputs{
      display: flex;
      flex-direction: column;
      width: calc(100% - 60px);
      input{
        margin-bottom: 10px;
      }
      label{
        font-size: 0.7rem;
        margin-left: 24px;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 500;
      }
      hr{
        height: 0;
        border: none;
        margin-left: 20px;
        margin-bottom: 10px;
        width: calc(100% + 20px);
      }
    }
    .buttons{
      padding: 0;
      .button{
        margin: 0;
      }
    }
  }
  .displaynone{
    display: none !important;
  }
}

@media screen and (max-width: 800px){
  .lk_block{

    .lk_block_params_navigation{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .lk_block_params_navigation_btn{
        margin: 5px 0;
        min-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-items: center;
        border: 1px solid #CCC;
        width: 100%;
        cursor: pointer;
        transition: .2s;
      }
      .lk_block_params_navigation_btn:hover{
        transition: .2s;
        color: #FFF;
        background-color: #454545;
      }
    }
    .lk_block_params{
      display: flex;
      flex-direction: column;

      .lk_block_params_photo{
        position: relative;
        display: flex;
        flex-direction: column;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 250px;
        height: 360px;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.5s;

        .lk_block_params_photo_black{
          position: absolute;
          width: 250px;
          height: 360px;
          background-color: rgba(0,0,0,0.5);
          border-radius: 10px;
          display: none;
          transition: 0.5s;
        }
        .lk_block_params_photo_open{
          display: none;
          transition: 0.5s;
          justify-content: flex-end;
          align-items: flex-end;
          width: 230px;
          height: 340px;
          position: absolute;
          color: #F1F1F1;
          font-size: 20pt;
          i{
            padding: 10px;
            border: 1px solid #F1F1F1;
            border-radius: 10px;
            cursor: pointer;
          }

        }
        .lk_block_params_photo_upload{
          display: none;
          transition: 0.5s;
          justify-content: flex-end;
          align-items: flex-end;
          width: 230px;
          height: 340px;
          position: absolute;
          color: #F1F1F1;
          font-size: 20pt;
          i{
            padding: 10px;
            border: 1px solid #F1F1F1;
            border-radius: 10px;
            cursor: pointer;
          }

        }

        .lk_block_params_photo_open:hover{
          opacity: 0.8;
          color: rgba(255,255,255,0.8);
        }
        .lk_block_params_photo_upload:hover{
          opacity: 0.8;
          color: rgba(255,255,255,0.8);
        }

      }
      .lk_block_params_photo:hover{
        transition: 0.5s;
        .lk_block_params_photo_black{
          display: block;
          transition: 0.5s;
        }

        .lk_block_params_photo_open{
          display: flex;
          transition: 0.5s;
          justify-content: flex-end;
          align-items: flex-end;
          width: 230px;
          height: 340px;
          position: absolute;
          color: #F1F1F1;
          font-size: 20pt;
          i{
            padding: 10px;
            border: 1px solid #F1F1F1;
            border-radius: 10px;
            cursor: pointer;
          }
        }
        .lk_block_params_photo_upload{
          display: flex;
          transition: 0.5s;
          justify-content: flex-end;
          align-items: flex-end;
          width: 180px;
          height: 340px;
          position: absolute;
          color: #F1F1F1;
          font-size: 20pt;
          i{
            padding: 10px;
            border: 1px solid #F1F1F1;
            border-radius: 10px;
            margin: 0 5px;
            cursor: pointer;
          }

        }
      }
      .lk_block_params_personaldata{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        min-height: 100%;
        margin: 0;
        .lk_block_params_personaldata_edit{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .lk_block_params_personaldata_edit_btn{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 20px;
            border: 1px solid #CCC;
            border-radius: 2px;
            cursor: pointer;
          }
          .lk_block_params_personaldata_edit_btn:hover{
            opacity: 0.8;
          }
        }
        .lk_block_params_personaldata_fio{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 20px);
          margin-top: 20px;
          padding: 5px 10px;
          background-color: #FFF;
          border-radius: 2px;
          border: none;
        }
        .lk_block_params_personaldata_dev{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 20px);
          margin-top: 10px;
          padding: 10px 10px;
          background-color: #FFF;
          border-radius: 2px;
          border: none;
        }
        .lk_block_params_personaldata_otdel{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 20px);
          margin-top: 10px;
          padding: 10px 10px;
          background-color: #FFF;
          border-radius: 2px;
          border: none;
        }
        .lk_block_params_personaldata_tn{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 20px);
          margin-top: 10px;
          padding: 10px 10px;
          background-color: #FFF;
          border-radius: 2px;
        }
        .lk_block_params_personaldata_login{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 10px;
          .lk_block_params_personaldata_login_name{
            width: 50%;
          }
          .lk_block_params_personaldata_login_btnpassword{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            border: 1px solid #CCC;
            border-radius: 2px;
            cursor: pointer;
          }
          .lk_block_params_personaldata_login_btnpassword:hover{
            opacity: 0.8;
          }
        }
      }

    }

  }
  .change-password-form{
    width: 100%;
    .info{
      h3{
        margin-left: 20px;
        margin-bottom: 10px;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1rem;
      }
    }
    .inputs{
      display: flex;
      flex-direction: column;
      width: calc(100% - 60px);
      input{
        margin-bottom: 10px;
      }
      label{
        font-size: 0.7rem;
        margin-left: 24px;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 500;
      }
      hr{
        height: 0;
        border: none;
        margin-left: 20px;
        margin-bottom: 10px;
        width: calc(100% + 20px);
      }
    }
    .buttons{
      padding: 0;
      .button{
        margin: 0;
      }
    }
  }
  .displaynone{
    display: none !important;
  }
}
.text{
  font-size: 0.7rem;
  margin-top: -7px;
}
span{
  margin-left: 10px;
}
.medok{
  color: #28a745;
}
.medsk{
  color: #FEAA2D;
}
.medne{
  color: #e74c3c;
}
.srok{
  border: 3px solid #e74c3c;
  animation: pulse 1s infinite;
}

.err{
  i{
    color: #e74c3c;
    font-size: 1.2rem;
    animation: pulse 1s infinite;
  }
}
@keyframes pulse {
  0% {
    border-color:  #e74c3c; /* Исходный цвет бордера */
    color: black;
  }
  50% {
    border-color: transparent; /* Исчезновение бордера */
  }
  100% {
    color: #e74c3c;
    border-color:  #e74c3c; /* Возвращение цвета */
  }
}
.link-block{
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #333;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 660px;
  cursor:pointer;
  span{
    color: #28a745;
  }
  &:hover{
    color: #FFFFFF;
    background-color: #333333;
  }
  i{
    font-size: 1.5rem;
    margin-right:10px;
  }
  a{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
