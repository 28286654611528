.new_structure{
  width: 100%;
  height: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: 1360px){
    .selector{
      display: none;
    }
  }
  @media screen and (max-width: 1360px){
    .selector{
      display: flex;
    }
  }
  @media screen and (min-width: 1360px){
    .leftmenu{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 300px;
      background-color: #FFFFFF;
      min-height: 200px;
      border-radius: 5px;
      //padding: 10px;
      .devstrock{
        width: calc(100% - 20px);
        text-transform: uppercase;
        cursor: pointer;
        padding: 7px 10px;
        border-radius: 5px;
      }
      .devstrock:hover{
        background-color: #292f33;
        color: #FFFFFF;
      }
    }
  }
  @media screen and (max-width: 1360px){
    .leftmenu{
      display: none;
    }
  }
  @media screen and (min-width: 1360px){
    .deps{
      width: calc(100% - 320px);
      .superviser{
        .title{
          font-size: 16pt;
          font-weight: 600;
        }
        .block{
          width: 100%;
          min-height: 200px;
          background-color: #FFFFFF;
          margin-top: 10px;
          border-radius: 5px;
          display: flex;
          .ava{
            width: 140px;
            height: 180px;
            margin: 10px;
            border-radius: 4px;
            background-size: cover;
            background-position: center center;
          }
          .description{
            margin: 10px;
            .name{
              font-size: 14pt;
            }
            .dev{
              font-weight: 600;
            }
            .email{}
            .ats{}
            .mobile{}
            .postbtn{
              max-width: 150px;
              padding: 10px 20px;
              margin-top: 10px;
              border: 1px solid #454545;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              cursor: pointer;
            }
          }
        }
        .otdel{
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;
          align-items: flex-start;
          .man{
            margin-top: 10px;
            width: calc(100% / 2 - 10px);
            min-height: 200px;
            background-color: #FFFFFF;
            display: flex;
            .ava{
              width: 140px;
              height: 180px;
              margin: 10px;
              border-radius: 4px;
              background-size: cover;
              background-position: center center;
            }
            .description{
              margin: 10px;
              .name{
                font-size: 14pt;
              }
              .dev{
                font-weight: 600;
              }
              .email{}
              .ats{}
              .mobile{}
              .postbtn{
                cursor: pointer;
                max-width: 150px;
                padding: 10px 20px;
                margin-top: 10px;
                border: 1px solid #454545;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
              }
            }

          }
        }
      }
    }
  }
  @media screen and (min-width: 500px) and (max-width: 1360px){
    .deps{
      width: calc(100% - 0px);
      .superviser{
        .title{
          font-size: 16pt;
          font-weight: 600;
        }
        .block{
          width: 100%;
          min-height: 200px;
          background-color: #FFFFFF;
          margin-top: 10px;
          border-radius: 5px;
          display: flex;
          .ava{
            width: 140px;
            height: 180px;
            margin: 10px;
            border-radius: 4px;
            background-size: cover;
            background-position: center center;
          }
          .description{
            margin: 10px;
            .name{
              font-size: 14pt;
            }
            .dev{
              font-weight: 600;
            }
            .email{}
            .ats{}
            .mobile{}
            .postbtn{
              cursor: pointer;
              max-width: 150px;
              padding: 10px 20px;
              margin-top: 10px;
              border: 1px solid #454545;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
            }
          }
        }
        .otdel{
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;
          align-items: flex-start;
          .man{
            margin-top: 10px;
            width: calc(100% - 0px);
            min-height: 200px;
            background-color: #FFFFFF;
            display: flex;
            .ava{
              width: 140px;
              height: 180px;
              margin: 10px;
              border-radius: 4px;
              background-size: cover;
              background-position: center center;
            }
            .description{
              margin: 10px;
              .name{
                font-size: 14pt;
              }
              .dev{
                font-weight: 600;
              }
              .email{}
              .ats{}
              .mobile{}
              .postbtn{
                cursor: pointer;
                max-width: 150px;
                padding: 10px 20px;
                margin-top: 10px;
                border: 1px solid #454545;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
              }
            }

          }
        }
      }
    }
  }
  @media screen and (max-width: 500px){
    .deps{
      width: calc(100% - 0px);
      .superviser{
        .title{
          font-size: 14pt;
          font-weight: 600;
        }
        .block{
          width: calc(100% + 20px);
          min-height: 200px;
          background-color: #FFFFFF;
          margin-top: 10px;
          border-radius: 5px;
          display: flex;
          .ava{
            width: 98px;
            height: 140px;
            margin: 10px 0 10px 10px;
            border-radius: 4px;
            background-size: cover;
            background-position: center center;
          }
          .description{
            width: calc(100% - 130px);
            margin: 10px;
            .name{
              font-size: 12pt;
            }
            .dev{
              font-weight: 600;
              font-size: 10pt;
            }
            .email{font-size: 10pt;}
            .ats{font-size: 10pt;}
            .mobile{font-size: 10pt;}
            .postbtn{
              cursor: pointer;
              font-size: 10pt;
              max-width: 150px;
              padding: 10px 20px;
              margin-top: 10px;
              border: 1px solid #454545;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
            }
          }
        }
        .otdel{
          display: flex;
          flex-wrap: wrap;
          width: calc(100% + 20px);
          justify-content: space-between;
          align-items: flex-start;
          .man{
            margin-top: 10px;
            width: calc(100% + 20px);
            min-height: 200px;
            background-color: #FFFFFF;
            display: flex;
            border-radius: 5px;
            .ava{
              width: 98px;
              height: 140px;
              margin: 10px;
              border-radius: 4px;
              background-size: cover;
              background-position: center center;
            }
            .description{
              width: calc(100% - 130px);
              margin: 10px;
              .name{
                font-size: 12pt;
              }
              .dev{
                font-weight: 600;
                font-size: 10pt;
              }
              .email{font-size: 10pt;}
              .ats{font-size: 10pt;}
              .mobile{font-size: 10pt;}
              .postbtn{
                cursor: pointer;
                font-size: 10pt;
                max-width: 150px;
                padding: 10px 20px;
                margin-top: 10px;
                border: 1px solid #454545;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
              }
            }

          }
        }
      }
    }
  }

}